import React from 'react';
import {useTranslation} from 'react-i18next';

import descriptionImg from '../../images/gsm/descr-img.jpg';

export const GsmDescription = props => {
    const [t] = useTranslation('services');

    return (
        <div className="container">
            <section className="gsm-descr">
                <div className="gsm-descr__section">
                    <h3 className="gsm-descr__title">{t('gsm.description.title')}</h3>
                    <p className="gsm-descr__paragraph">
                        {t('gsm.description.text')}
                    </p>
                </div>
                <div className="gsm-descr__img">
                    <img src={descriptionImg} alt="GSM control" />
                </div>
            </section>
        </div>
    );
};
