import React from 'react';
import {_discount} from '../../../globalConsts';
import closeButton from "../../../images/basket/close-btn.svg";
import {
    decreaseProductQuantity,
    deleteProductFromBasket, increaseProductQuantity,
} from "../../../redux/actionCreators/basketActions";
import {connect} from "react-redux";
import LocalizedLink from '../../globalComponents/LocalizedLink';

const CheckoutProductContainer = props => {
    const increaseQuantity = () => {
        props.increaseProductQuantity(props.basketItem.id);
    };

    const decreaseQuantity = () => {
        props.decreaseProductQuantity(props.basketItem.id);
    };

    const deleteProduct = () => {
        props.deleteProductFromBasket(props.basketItem.id);
    };

    return (
        <div className="basket-product">
            <div className="basket-product__img">
                <img src={props.basketItem.images[0]} alt={props.basketItem.name} />
            </div>

            <div className="basket-product__section-wrap">
                <div className="basket-product__top-section">
                    <LocalizedLink to={`/product/${props.basketItem.slug}`} className="basket-product__name" href="/">
                        {props.basketItem.name}
                    </LocalizedLink>

                    <div className="close-btn" onClick={() => {
                        deleteProduct()
                        if(props.products.length <= 1) {
                            localStorage.setItem('basket', JSON.stringify([]))
                        }
                    }}>
                        <img
                            src={closeButton}
                            alt="delete"
                        />
                    </div>
                </div>

                <div className="basket-product__bottom-section">
                    <div className="basket-counter">
                        <div className="basket-counter__dec" onClick={decreaseQuantity}>-</div>
                        <div className="basket-counter__quantity">
                            {props.basketItem.quantity}
                        </div>
                        <div className="basket-counter__inc" onClick={increaseQuantity}>+</div>
                    </div>

                    <div className="basket-product__price">{`${(props.basketItem.final_price * props.basketItem.quantity * (1 - _discount)).toLocaleString()} грн`}</div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps  = {
    deleteProductFromBasket,
    increaseProductQuantity,
    decreaseProductQuantity,
};

export const CheckoutProduct = connect(null, mapDispatchToProps)(CheckoutProductContainer);
