import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import checkMark from '../../images/checkmark.svg';
import {setCheckoutPersonalInformation} from '../../redux/actionCreators/checkoutActions';
import {Input} from '../globalComponents/Input/Input';
import {DeliveryForm} from "./DeliveryForm";
import {EquipmentInstallationForm} from "./EquipmentInstallationForm";



const CheckoutEntityContainer = props => {
    const [t] = useTranslation(['checkout', 'common']);
    const form = t('common:form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;

    const formFields = [
        {
            name: 'org_name',
            title: form.organizationName,
            type: 'text',
        },
        {
            name: 'EDRPOU',
            title: form.EDRPOU,
            type: 'text',
        },
        {
            name: 'name',
            title: form.name,
            type: 'text',
        },
        {
            name: 'last_name',
            title: form.lastName,
            type: 'text',
        },
        {
            name: 'phone',
            title: form.phone,
            type: 'tel',
        },
        {
            name: 'email',
            title: form.email,
            type: 'email',
        },
    ];

    const {
        changeWayForPay,
        toggleOrderOfEquipmentInstallation,
        changeCallToConfirm,
        setComment,
        setIsCommentInputActive
    } = props.handleFunctions;

    const {
        wayForPay,
        callToConfirm,
        comment,
        isCommentInputActive,
        isEquipmentInstallationOrdered,
    } = props.values;

    const [contactDetailsEntityFormValues, setContactDetailsEntityFormValues] = useState({
        org_name: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage
        },
        EDRPOU: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage
        },
        name: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage
        },
        last_name: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage
        },
        phone: {
            value: '+380',
            error: false,
            wasTouched: false,
            errorMessage
        },
        email: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage
        },
    });

    useEffect(() => {
        props.setCheckoutPersonalInformation(contactDetailsEntityFormValues);
    }, [contactDetailsEntityFormValues]);

    useEffect(() => {
        if(props.isLogged && props.user.is_organization) {
            const formInfoToSet = {
                org_name: {
                    ...contactDetailsEntityFormValues.org_name,
                    value: props.user.org_name,
                    wasTouched: true,
                },
                EDRPOU: {
                    ...contactDetailsEntityFormValues.EDRPOU,
                    value: props.user.EDRPOU,
                    wasTouched: true,
                },
                name: {
                    ...contactDetailsEntityFormValues.name,
                    value: props.user.name,
                    wasTouched: true,
                },
                last_name: {
                    ...contactDetailsEntityFormValues.last_name,
                    value: props.user.name,
                    wasTouched:true,
                },
                phone: {
                    ...contactDetailsEntityFormValues.phone,
                    value: props.user.phone,
                    wasTouched: true,
                },
                email: {
                    ...contactDetailsEntityFormValues.email,
                    value: props.user.email,
                    wasTouched: true,
                }
            }

            setContactDetailsEntityFormValues(formInfoToSet);
        }
    }, [props.user, props.isLogged]);

    const handleContactEntityChange = event => {
        const {name, value} = event.target;

        setContactDetailsEntityFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
                error: false,
            },
        }));
    };

    const validateContactField = (name, value) => {
        setContactDetailsEntityFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                wasTouched: true,
            },
        }));

        if(!value.trim()) {
            setContactDetailsEntityFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    error: true,
                },
            }));

            return false;
        }

        if(name === 'phone') {
            const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

            if(!regEx.test(value)) {
                setContactDetailsEntityFormValues(prevState => ({
                    ...prevState,
                    phone: {
                        ...prevState.phone,
                        error: true,
                        errorMessage: form.notValidFormat
                    }
                }));

                return false;
            }
        }

        return true;
    };


    return (
        <div className={`basket-form__body basket-form__body--entity ${props.isActive ? 'active' : ''}`}>
            <div className="basket-form__section">
                <h3 className="basket-form__section-title">{t('contactsTitle')}</h3>
                {
                    formFields.map(field => (
                        <Input
                            key={field.name}
                            className={`form__input ${contactDetailsEntityFormValues[field.name].error ? 'invalid' : ''} ${!contactDetailsEntityFormValues[field.name].error && contactDetailsEntityFormValues[field.name].wasTouched ? 'valid' : ''}`}
                            type={field.type}
                            name={field.name}
                            placeholder=""
                            required
                            value={contactDetailsEntityFormValues[field.name].value}
                            handleChange={
                                event => {
                                    handleContactEntityChange(event);
                                    validateContactField(event.target.name, event.target.value);
                                }
                            }
                            handleBlur={event => validateContactField(event.target.name, event.target.value)}
                            title={field.title}
                            isErrorOccurred={contactDetailsEntityFormValues[field.name].error && contactDetailsEntityFormValues[field.name].wasTouched}
                            errorMessage={contactDetailsEntityFormValues[field.name].errorMessage}
                        />
                    ))
                }
            </div>

            <DeliveryForm />

            <div className="basket-form__section">
                <h3 className="basket-form__section-title">{t('payment.title')}</h3>
                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="payment"
                        value="factor"
                        checked={wayForPay === 'factor'}
                        onChange={changeWayForPay}
                    />
                    <span className="radio">{t('payment.factor')}</span>
                </label>
            </div>

            <div className="basket-form__section basket-form__section--checkbox-wrap">
                <label className="checkbox-wrap">
                    <input
                        type="checkbox"
                        name="installationOrder"
                        checked={isEquipmentInstallationOrdered}
                        onChange={toggleOrderOfEquipmentInstallation}
                    />
                    <span className="checkbox">
                        <img src={checkMark} alt="checkmark" />
                        <span>{t('installation.title')}</span>
                    </span>
                </label>

                {
                    (isEquipmentInstallationOrdered) && (<EquipmentInstallationForm />)
                }

                <button className="comment-add" onClick={() => setIsCommentInputActive(prevState => !prevState)}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 0.5H2C1.17275 0.5 0.5 1.17275 0.5 2V15.5L4.49975 12.5H14C14.8273 12.5 15.5 11.8273 15.5 11V2C15.5 1.17275 14.8273 0.5 14 0.5ZM14 11H4.00025L2 12.5V2H14V11Z"
                            fill="#5C83C4"
                        />
                    </svg>
                    <span className="comment-add__text">{t('comment')}</span>
                </button>

                <label className={`basket-form__comment textarea-wrap ${isCommentInputActive ? 'active' : ''}`}>
                    <textarea
                        className="form__input form__textarea"
                        name="comment"
                        value={comment}
                        onChange={event => setComment(event.target.value)}
                        cols="30" rows="4"
                    />
                    <span>{t('common:form.comment')}</span>
                </label>

                <label className="checkbox-wrap">
                    <input
                        type="checkbox"
                        checked={!callToConfirm}
                        onChange={changeCallToConfirm}
                    />
                    <span className="checkbox">
                        <img src={checkMark} alt="checkmark" />
                        <span>{t('callback')}</span>
                    </span>
                </label>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        user: store.userAccountState.user,
        isLogged: store.userAccountState.isLogged,
    };
};

const mapDispatchToProps = {
    setCheckoutPersonalInformation,
};

export const CheckoutEntity = connect(mapStateToProps, mapDispatchToProps)(CheckoutEntityContainer);
