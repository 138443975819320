import React from 'react';

import tabArrow from '../../images/tab-arrow.svg';

export const SupportQuestion = props => {
    return (
        <div className="support__tab-wrap">
            <div
                className={`support__tab ${props.isActive ? 'active' : ''}`}
                onClick={() => props.changeActiveQuestion(props.questionIndex)}
            >
                <h3 className="support__tab-title">
                    {props.question.question}
                </h3>

                <img alt="arrow" className={`arrow-down ${props.isActive ? 'active' : ''}`} src={tabArrow} />
            </div>

            <div className="support__tab-drop">
                <p className="support__drop-p">
                    {props.question.answer}
                </p>
            </div>
        </div>
    );
};
