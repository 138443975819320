import React from 'react';

export const PrevArrow = props => {
    return (
        <div onClick={props.onClick} className="arrow arrow-left slick-arrow">
            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.363644 15.1219L15.1646 0.361867C15.6495 -0.121444 16.4346 -0.120631 16.9188 0.364367C17.4025 0.849302 17.4013 1.63486 16.9163 2.11855L2.99632 16.0001L16.9168 29.8815C17.4017 30.3652 17.403 31.1503 16.9193 31.6353C16.6766 31.8784 16.3586 32 16.0407 32C15.7236 32 15.4069 31.8792 15.1646 31.6378L0.363644 16.8782C0.130082 16.6458 -0.000980377 16.3296 -0.000980377 16.0001C-0.000980377 15.6706 0.130457 15.3547 0.363644 15.1219Z"
                    fill="#1A3B70"/>
            </svg>
        </div>
    );
};
