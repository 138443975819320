import React from 'react';
import {connect} from 'react-redux'

import {_languages} from '../../../../globalConsts';

const LangSelect = props => {
    const changeLanguage = language => {
        props.toggleMenu(false);
        localStorage.setItem('language', JSON.stringify(language));
        window.location.reload();
    };

    return (
        <div className={`header-top__lang ${props.isActive ? 'active' : ''}`} onMouseLeave={() => props.toggleMenu(false)}>
            <div className="header-top__lang-item-select" onClick={() => props.toggleMenu(true)}>
                <div className="header-top__lang-current">{props.language.title}</div>
                <svg className="arrow-down" width="20" height="20" viewBox="0 0 20 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="#DBE2EA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            {
                _languages.map((language) => {
                    return (
                        <div
                            className="header-top__lang-item"
                            key={language.key}
                            onClick={() => changeLanguage(language)}
                        >
                            {language.title}
                        </div>
                    )
                })
            }
        </div>
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
    };
};

export default connect(mapStateToProps)(LangSelect)