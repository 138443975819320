import React, {useEffect, useReducer} from 'react';
import {useTranslation} from 'react-i18next';
import {createLead} from '../../../API/birixApi/createLead';
import {callbackFormFields} from '../../../globalConsts';
import ReactGA from 'react-ga';
import closeButton from '../../../images/close-btn.svg';
import {connect} from "react-redux";
import {toggleApplicationSuccess, toggleCallback, toggleModal} from '../../../redux/actionCreators/modalActions';
import createFormState from '../../../utils/stateReducers/createFormState';
import formReducer from '../../../utils/stateReducers/formReducer';
import Form from '../Form/Form';

const CallbackContainer = props => {
    const [t] = useTranslation('common');
    const [callbackFormValues, dispatchCallbackFormValues] = useReducer(formReducer, callbackFormFields, createFormState);

    useEffect(() => {
        if(props.isLogged) {
            const formInfoToSet = {
                name: {
                    ...callbackFormValues.name,
                    value: props.user.name,
                    wasTouched: true,
                },
                lastName: {
                    ...callbackFormValues.lastName,
                    value: props.user.last_name,
                    wasTouched: true,
                },
                phone: {
                    ...callbackFormValues.phone,
                    value: props.user.phone,
                    wasTouched: true,
                },
                email: {
                    ...callbackFormValues.email,
                    value: props.user.email,
                    wasTouched: true,
                },
            };

            dispatchCallbackFormValues({type: 'set_all_values', payload: formInfoToSet});
        }
    }, [props.user, props.isLogged]);

    const onCallSubmit = () => {
        const userInfo = {
            name: `${callbackFormValues.name.value} ${callbackFormValues.lastName.value}`,
            phone: callbackFormValues.phone.value,
            comment: callbackFormValues.comment.value,
        };

        createLead(userInfo, 'callback', props.googleId, props.utmParams);
        ReactGA.event({
            category: 'Order',
            action: 'callback-order',
            label: 'Заказ обратного звонка',
        });
        openApplicationSuccessModal();
    };

    const openApplicationSuccessModal = () => {
        props.toggleCallback(false);
        props.toggleApplicationSuccess(true);
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleCallback(false);
    };

    return (
        <div className={`form-modal form-modal--callback ${props.callback ? 'active' : ''}`}>
            <div className="modal__close" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>


            <Form
                className="form-modal__body"
                formValues={callbackFormValues}
                dispatchFormValues={dispatchCallbackFormValues}
                formFields={callbackFormFields}
                onSubmitSuccess={onCallSubmit}
            >
                <h6 className="form-modal__title">{t('callBackModal.title')}</h6>

                <p className="form-modal__subtitle">
                    {t('callBackModal.subtitle')}
                </p>
            </Form>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        callback: store.modalsState.callback,
        isLogged: store.userAccountState.isLogged,
        user: store.userAccountState.user,
        googleId: store.globalReducer.googleId,
        utmParams: store.globalReducer.utmParams,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleCallback,
    toggleApplicationSuccess,
};

export const Callback = connect(mapStateToProps, mapDispatchToProps)(CallbackContainer);
