import React from 'react';
import {useTranslation} from 'react-i18next';

import headerBg from '../../images/rent/header-bg.webp'
import {OrderServiceButton} from '../globalComponents/OrderServiceButton';

export const RentHeader = props => {
    const [t] = useTranslation('services');

    return (
        <section className="rent-header">
            <div className="rent-header__bg">
                <img src={headerBg} alt="rent" />
            </div>
            <div className="rent-header-container">
                <div className="rent-header__body-wrap">
                    <div className="rent-header__body">
                        <h1 className="rent-header__title">{t('rent.title')}</h1>
                        <h2 className="rent-header__subtitle">{t('rent.subtitle')}</h2>
                        <OrderServiceButton service={t('rent.title')} />
                    </div>
                </div>
            </div>
        </section>
    );
};
