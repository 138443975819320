import React from 'react';
import {CompareFilterCategorySelector} from "./CompareFilterCategorySelector";
import {CompareFilterViewSelector} from "./CompareFilterViewSelector";

export const CompareFilter = props => {
    return (
        <div className="compare__filter">
            <CompareFilterCategorySelector
                categories={props.categories}
                selectedCategory={props.selectedCategory}
                selectCategory={props.selectCategory}
                productsCompare={props.productsCompare}
                products={props.products}
            />

            <CompareFilterViewSelector
                reverse={props.reverse}
                setRevers={props.setReverse}
                changeFilterView={props.changeFilterView}
                filterViewValue={props.filterViewValue}
                productsCompare={props.productsCompare}
            />
        </div>
    )
};
