import {GET_EQUIPMENT} from "../actionConsts/actionConsts";

import ico1 from '../../images/header/drop-ico/ico1.svg';
import ico2 from '../../images/header/drop-ico/ico2.svg';
import ico3 from '../../images/header/drop-ico/ico3.svg';
import ico4 from '../../images/header/drop-ico/ico4.svg';
import ico5 from '../../images/header/drop-ico/ico5.svg';
import ico6 from '../../images/header/drop-ico/ico6.svg';
import ico7 from '../../images/header/drop-ico/ico7.svg';
import ico8 from '../../images/header/drop-ico/ico8.svg';
import ico9 from '../../images/header/drop-ico/ico9.svg';
import ico10 from '../../images/header/drop-ico/ico10.svg';
import ico11 from '../../images/header/drop-ico/ico11.svg';
import ico12 from '../../images/header/drop-ico/ico12.svg';

const equipmentImages = {
    'avtomobilni-trekeri-1': ico1,
    'datciki-rivnya-palnogo-2': ico3,
    'videoreyestratori-dlya-transportu-3': ico5,
    'bezpeka-vantaziv-4': ico7,
    'personalni-trekeri-5': ico9,
    'can-sina-6': ico11,
    'oxoronni-mayaki-7': ico2,
    'datciki-aksesuari-8': ico4,
    'vitratni-materiali-ta-komplektuyuci-9': ico6,
    'meteostanciyi-10': ico8,
    'kvadrokopteri-11': ico10,
    'gps-mayaki-12': ico12,
};

const initialEquipment = {
    equipment: [
        {
            name: '',
            image: '',
            slug: '',
        },
    ],
};

const equipmentReducer = (state = initialEquipment, action) => {
    switch (action.type) {
        case GET_EQUIPMENT: {
            const equipment = action.payload;

            const newEquipment = equipment
                .filter(item => item.slug !== 'kvadrokopteri-11' && item.slug !== 'meteostanciyi-10')
                .map(item => ({
                    ...item,
                    image: equipmentImages[item.slug],
                }));

            return {
                equipment: [...newEquipment],
            }
        }
        default:
            return state;
    }
};

export default equipmentReducer;