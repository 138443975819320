import React from 'react';
import {useTranslation} from 'react-i18next';

import Slider from 'react-slick';

export const ProductImplement = props => {
    const [t] = useTranslation('productCard');

    const settings = {
        className: 'implementions__slider',
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: false,
        dots: true,
        waitForAnimate: true,
        dotsClass: 'main-sl-dots',
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1545,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1185,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 748,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    return(
        <section className="implementions">
            <h3 className="implementions__title">{t('implementationTitle')}</h3>

            <Slider {...settings}>
                {
                    props.implementations.map((implementation, index) => (
                        <div key={index} className="cases__item-wrap">
                            <div className="cases__item-container">
                                <div className="cases__item-bg">
                                    <img src={implementation.image} alt={implementation.title} />
                                </div>

                                <article className="cases__item">
                                    <div className="cases__item-title">{implementation.title}</div>
                                    <div className="cases__item-subtitle">Новая автоматизированная система проверки качества
                                        и&nbsp;мониторинга предоставляет рабочим на конвейере и&nbsp;внешним поставщикам
                                        основные инструменты оценки качества, ускорения решения проблем, усовершенствования
                                    </div>
                                    <a className="cases__item-details" href="#" tabIndex="0">
                                        Подробнее
                                        <svg width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1 4C0.723858 4 0.5 4.22386 0.5 4.5C0.5 4.77614 0.723858 5 1 5V4ZM23.3536 4.85355C23.5488 4.65829 23.5488 4.34171 23.3536 4.14645L20.1716 0.964466C19.9763 0.769204 19.6597 0.769204 19.4645 0.964466C19.2692 1.15973 19.2692 1.47631 19.4645 1.67157L22.2929 4.5L19.4645 7.32843C19.2692 7.52369 19.2692 7.84027 19.4645 8.03553C19.6597 8.2308 19.9763 8.2308 20.1716 8.03553L23.3536 4.85355ZM1 5H23V4H1V5Z"
                                                fill="white"/>
                                        </svg>
                                    </a>
                                </article>
                            </div>
                        </div>
                    ))
                }
            </Slider>
        </section>

    );
};
