import React from 'react'
import {useTranslation} from 'react-i18next';

const CompareFilterCategorySelectorItem = props => {
    const [t] = useTranslation('common');
    const categoriesTranslation = t('categories', {returnObjects: true});
    const productsLength = []

    props.products.map(data => {
        if (data.category.slug === props.category) {
            productsLength.push(data)
        }
    })

    return (
        <div
            className="compare__filter-category"
            onClick={
                () => {
                    props.selectCategory(props.category);
                    props.setIsDropSelectActive(false);
                }
            }
        >
            {`${categoriesTranslation[props.category]} (${productsLength.length})`}
        </div>
    )
}

export default CompareFilterCategorySelectorItem