import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {setSolutionForOrder} from '../../redux/actionCreators/globalActions';
import {toggleModal, toggleSolutionOrder} from '../../redux/actionCreators/modalActions';

const OrderSolutionButtonContainer = props => {
    const [t] = useTranslation('common');

    const openSolutionOrderModal = () => {
        props.setSolutionForOrder(props.solution);
        props.toggleModal(true);
        props.toggleSolutionOrder(true);
    };

    return (
        <button className="btn-accent" onClick={openSolutionOrderModal}>{t('orderSolutionButton')}</button>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleSolutionOrder,
    setSolutionForOrder,
};

export const OrderSolutionButton = connect(null, mapDispatchToProps)(OrderSolutionButtonContainer);
