import React from 'react';

import service1 from '../../../images/header/drop-ico/serv-ico1.svg';
import service2 from '../../../images/header/drop-ico/serv-ico2.svg';
import service3 from '../../../images/header/drop-ico/serv-ico3.svg';
import service4 from '../../../images/header/drop-ico/serv-ico4.svg';
import service5 from '../../../images/header/drop-ico/serv-ico5.svg';
import service6 from '../../../images/header/drop-ico/serv-ico6.svg';
import LocalizedLink from '../LocalizedLink';

export const FooterServices = props => {
    const servicesList = [
        {
            name: props.translation.servicesList.gps,
            slug: 'gps',
            icon: service1,
        },
        {
            name: props.translation.servicesList.scontrol,
            slug: 'scontrol',
            icon: service5,
        },
        {
            name: props.translation.servicesList.personal,
            slug: 'personal',
            icon: service3,
        },
        {
            name: props.translation.servicesList.gsm,
            slug: 'gsm',
            icon: service2,
        },
        {
            name: props.translation.servicesList.rent,
            slug: 'rent',
            icon: service4,
        },
        {
            name: props.translation.servicesList.otherServices,
            slug: 'other-services',
            icon: service6,
        },
    ];

    return (
        <nav className="footer__section footer__section--list">
            <h4 onClick={props.toggleMenu} className="footer__list-title">
                {props.translation.title}
                <div className={`plus ${props.isActive? 'active': ''}`} />
            </h4>

            <ul className={`footer__list ${props.isActive ? 'active' : ''}`}>
                {
                    servicesList.map(service => (
                        <li key={service.slug} className="footer__item">
                            <LocalizedLink to={`/services/${service.slug}`}>{service.name}</LocalizedLink>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
};
