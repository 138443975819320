import React from 'react';

export const NextLabel = props => {
    return (
        <div className="label-arrow next-label-arrow">
            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.57585 4L0.850197 1.17731C0.590128 0.907988 0.590128 0.471322 0.850197 0.201995C1.11027 -0.0673323 1.53192 -0.0673323 1.79199 0.201995L4.98854 3.51234C5.24861 3.78167 5.24861 4.21833 4.98854 4.48766L1.79199 7.798C1.53192 8.06733 1.11027 8.06733 0.850197 7.798C0.590128 7.52868 0.590128 7.09201 0.850197 6.82268L3.57585 4Z" fill="#1A3B70"/>
            </svg>
        </div>
    );
};
