import {_api} from "../apiConsts";

export const userRegistration = (userInfo, isOrganization) => {
    return window.axios.post(`${_api}/auth`, {
        params: {
            query: `
                mutation (
                    $name: String!
                    $lastName: String!
                    $email: String!
                    $phone: String!
                    $password: String!
                    $passwordConfirmation: String!
                    $bitrix_user_id: Int!
                    $bitrix_company_id: Int
                    $isOrganization: Boolean
                ) {
                    register(
                        name: $name
                        last_name: $lastName
                        email: $email
                        phone: $phone
                        password: $password
                        password_confirmation: $passwordConfirmation 
                        bitrix_user_id: $bitrix_user_id
                        bitrix_company_id: $bitrix_company_id
                        is_organization: $isOrganization
                    ) {email}
                }
            `,
            variables: {
                name: userInfo.name,
                lastName: userInfo.lastName,
                email: userInfo.email,
                phone: userInfo.phone,
                password: userInfo.password,
                passwordConfirmation: userInfo.passwordConfirmation,
                bitrix_user_id: userInfo.userId,
                bitrix_company_id: userInfo.companyId,
                isOrganization,
            },
        },
    }).then(response => window._.head(response.data))
        .catch(error => console.log(error));
};
