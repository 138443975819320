import React from 'react';
import {useTranslation} from 'react-i18next';

import {SuitableTabs} from "../globalComponents/SuitableTabs";

export const ProductDescription = props => {
    const [t] = useTranslation('productCard');

    return (
        <article className="product-description">
            <div className="product-description__wrap">
                <h5 className="product-description__title section-title">{t('descriptionTitle')}</h5>
                <div className="product-description__content">
                    <div className="product-description__main-section">
                        <div dangerouslySetInnerHTML={{__html: props.description}} />
                    </div>

                    <div className="product-description__secondary-section">
                        <div dangerouslySetInnerHTML={{__html: props.descriptionSecondary}} />
                    </div>
                </div>

                <SuitableTabs solutionsFor={props.solutionsFor} />
            </div>
        </article>
    );
};
