import React from 'react';

import './Modal.scss';

import {connect} from "react-redux";
import {
    toggleApplicationSuccess,
    toggleBasket,
    toggleCallback,
    toggleCitySelect,
    toggleCommercialOffer, toggleDealSuccess,
    toggleEmptyBasket,
    toggleEmptyCompare,
    toggleEntry,
    toggleModal,
    toggleOrderIdModal,
    togglePasswordRecovery,
    togglePasswordResetError,
    togglePasswordResetModal,
    togglePasswordResetSuccess,
    toggleQuestion,
    toggleRegistrationSuccess,
    toggleServiceOrder,
    toggleSolutionOrder,
    toggleSubscribe,
} from '../../../redux/actionCreators/modalActions';
import {ApplicationSuccess} from './ApplicationSuccess';
import {DealSuccessModal} from './DealSuccessModal';
import {OrderServiceModal} from './OrderServiceModal';
import {OrderSolutionModal} from './OrderSolutionModal';
import {PasswordResetErrorModal} from './PasswordResetErrorModal';
import {PasswordResetModal} from './PasswordResetModal';
import {PasswordResetSuccessModal} from './PasswordResetSuccessModal';
import {Subscribe} from "./Subscribe";
import {CommercialOffer} from "./CommercialOffer";
import {Question} from "./Question";
import {BasketModal} from "./BasketModal/BasketModal";
import {EntryModal} from "./EntryModal/EntryModal";
import {Callback} from "./Callback";
import {PasswordRecoveryModal} from "./PasswordRecoveryModal";
import {EmptyBasketModal} from "./EmptyBasketModal";
import {EmptyCompareModal} from "./EmptyCompareModal";
import {CitySelect} from "./CitySelect";
import {RegistrationSuccessModal} from "./RegistrationSuccessModal";
import {OrderMessageModal} from "./OrderMessageModal";


const ModalContainer = props => {
    const closeModals = () => {
        props.toggleModal(false);
        props.toggleSubscribe(false);
        props.toggleCommercialOffer(false);
        props.toggleQuestion(false);
        props.toggleBasket(false);
        props.toggleEntry(false);
        props.toggleCallback(false);
        props.togglePasswordRecovery(false);
        props.toggleEmptyBasket(false);
        props.toggleEmptyCompare(false);
        props.toggleCitySelect(false);
        props.toggleApplicationSuccess(false);
        props.toggleServiceOrder(false);
        props.toggleSolutionOrder(false);
        props.togglePasswordResetModal(false);
        props.togglePasswordResetError(false);
        props.togglePasswordResetSuccess(false);
        props.toggleOrderIdModal(false);
        props.toggleDealSuccess({
            isActive: false,
        });
    };

    return (
        <>
            {
                props.modalState.modal && (
                    <div
                        className={`modal ${props.modalState.modal ? 'active' : ''}`}
                        onClick={closeModals}
                    />
                )
            }


            {
                props.modalState.subscribe && <Subscribe />
            }
            {
                props.modalState.commercialOffer && <CommercialOffer />
            }
            {
                props.modalState.question && <Question />
            }
            {
                props.modalState.basket && <BasketModal />
            }
            {
                props.modalState.entry && <EntryModal />
            }
            {
                props.modalState.passwordRecovery && <PasswordRecoveryModal />
            }
            {
                props.modalState.callback && <Callback />
            }
            {
                props.modalState.emptyBasket && <EmptyBasketModal />
            }
            {
                props.modalState.emptyCompare && <EmptyCompareModal />
            }
            {
                props.modalState.citySelect && <CitySelect />
            }
            {
                props.modalState.registrationSuccess && <RegistrationSuccessModal />
            }
            {
                props.modalState.applicationSuccess && <ApplicationSuccess />
            }
            {
                props.modalState.solutionOrder && <OrderSolutionModal />
            }
            {
                props.modalState.serviceOrder && <OrderServiceModal />
            }
            {
                props.modalState.passwordReset && <PasswordResetModal />
            }
            {
                props.modalState.passwordResetSuccess && <PasswordResetSuccessModal />
            }
            {
                props.modalState.passwordResetError && <PasswordResetErrorModal />
            }
            {
                props.modalState.orderId && <OrderMessageModal />
            }
            {
                props.modalState.dealSuccess.isActive && <DealSuccessModal />
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        modalState: state.modalsState
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleSubscribe,
    toggleCommercialOffer,
    toggleQuestion,
    toggleBasket,
    toggleEntry,
    toggleCallback,
    togglePasswordRecovery,
    toggleEmptyBasket,
    toggleEmptyCompare,
    toggleCitySelect,
    toggleRegistrationSuccess,
    toggleApplicationSuccess,
    toggleServiceOrder,
    toggleSolutionOrder,
    togglePasswordResetModal,
    togglePasswordResetError,
    togglePasswordResetSuccess,
    toggleOrderIdModal,
    toggleDealSuccess,
};

export const Modal = connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
