import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {_language} from '../globalConsts';
import resources from './resourses';

const options = {
    order: ['path'],
};

const languageDetector = new LanguageDetector(null, options);

i18n
    .use(initReactI18next)
    .use(languageDetector)
    .init({
        resources,
        fallbackLng: _language.key,
        debug: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true,
            useSuspense: true,
        }
    });

export default i18n;
