import React from 'react';
import {useTranslation} from 'react-i18next';

export const SupportMain = props => {
    const [t] = useTranslation('support');
    const supportFirst = t('main.supportFirst', {returnObjects: true});
    const supportSecond = t('main.supportSecond', {returnObjects: true});

    return (
        <div className="support__text-container">
            <p className="support__paragraph">
                {t('main.title')}
            </p>

            <ul className="support__list">
                <p className="support__list-item">{`${supportFirst.title}:`}</p>
                {
                    supportFirst.list.map((item, index) => (
                        <li key={index} className="support__list-item">— {item}</li>
                    ))
                }
            </ul>

            <ul className="support__list">
                <p className="support__list-item">{`${supportSecond.title}:`}</p>
                {
                    supportSecond.list.map((item, index) => (
                        <li key={index} className="support__list-item">— {item}</li>
                    ))
                }
            </ul>
        </div>
    );
};
