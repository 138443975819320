import {SET_SELECTED_OPPORTUNITIES, SET_SELECTED_TRANSPORT_TYPES} from '../actionConsts/actionConsts';

export const setSelectedTransportTypes = payload => {
    return {
        type: SET_SELECTED_TRANSPORT_TYPES,
        payload,
    };
};

export const setSelectedOpportunities = payload => {
    return {
        type: SET_SELECTED_OPPORTUNITIES,
        payload,
    };
};
