import {
    ADD_PRODUCT_COMPARE,
    DELETE_PRODUCT_COMPARE,
    DELETE_PRODUCTS_BY_CATEGORY_COMPARE,
    GET_COMPARE_FROM_LOCAL_STORAGE,
} from '../actionConsts/actionConsts';

const initialCompareState = {
    products: []
};

const compareReducer = (state = initialCompareState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_COMPARE: {
            if(state.products
                .filter(product => product.category.slug === action.payload.category.slug)
                .length === 4
            ) return state;
            if(state.products.some(item => item.id === action.payload.id)) {
                return state
            } else {
                return {
                    ...state,
                    products: [...state.products, action.payload]
                };
            }
        }
        case DELETE_PRODUCT_COMPARE: {
            if(state.products.length <= 1) localStorage.setItem('compare', JSON.stringify([]));
            return {
                ...state,
                products: state.products.filter(product => product.id !== action.payload)
            };
        }
        case DELETE_PRODUCTS_BY_CATEGORY_COMPARE: {
            return  {
                ...state,
                products: state.products.filter(product => product.category.name !== action.payload),
            };
        }
        case GET_COMPARE_FROM_LOCAL_STORAGE:
            return {
                ...state,
                products: action.payload,
            };
        default:
            return state;
    }
};

export default compareReducer;
