import React, {useState, useEffect} from 'react';
import Nouislider from 'nouislider-react';

import 'nouislider/distribute/nouislider.css';
import {connect} from 'react-redux';
import {applyPriceFilter} from '../../../redux/actionCreators/catalogActions';
import {getProducts} from '../../../redux/actionCreators/getActionCreators/catalogActions';

const CatalogFilterPriceContainer = ({prices, applyPriceFilter, applyFilters, translation}) => {
    const [cost, setCost] = useState([prices.min, prices.max]);
    const [priceValue, setPriceValue] = useState([prices.min, prices.max]);

    useEffect(() => {
        applyPriceFilter(priceValue);
    }, [priceValue]);

    useEffect(() => {
        applyPriceFilter([prices.min, prices.max])
        setCost([prices.min, prices.max]);
    }, [prices]);

    const changeStart = event => {
        setPriceValue([event.target.valueAsNumber, priceValue[1]]);
    };

    const changeEnd = event => {
        setPriceValue([priceValue[0], event.target.valueAsNumber]);
    };

    const applyFilter = () => {
        setCost(priceValue);
        applyFilters();
    };

    const handleUpdate = value => {
        setPriceValue([Math.floor(value[0]), Math.floor(value[1])]);
    };

    return (
        <div className="filter__price filter__container">
            {
                (prices.min !== prices.max) && (
                    <>
                        <div className="filter__title">{translation.title}</div>
                        <div className="filter__price-input-wrap">
                            <label className="filter__from-wrap">
                                <span>{translation.from}</span>
                                <input
                                    type="number"
                                    name="from-value"
                                    id="range-from"
                                    value={priceValue[0]}
                                    onChange={changeStart}
                                />
                            </label>

                            <label className="filter__to-wrap">
                                <span>{translation.to}</span>
                                <input
                                    type="number"
                                    name="to-value"
                                    id="range-to"
                                    value={priceValue[1]}
                                    onChange={changeEnd}
                                />
                            </label>
                        </div>


                        <Nouislider
                            connect={true}
                            range={{min: prices.min, max: prices.max}}
                            start={cost}
                            onUpdate={handleUpdate}
                        />
                    </>
                )
            }
            <button
                onClick={applyFilter}
                className="filter__accept-btn btn-ghost-secondary"
            >
                {translation.applyButtonText}
            </button>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        appliedFilters: state.catalogState.appliedFilters,
        currentCategory: state.catalogState.categoryDetails,
    };
};

const mapDispatchToProps = {
    applyPriceFilter,
    getProducts,
};

export const CatalogFilterPrice = connect(mapStateToProps, mapDispatchToProps)(CatalogFilterPriceContainer);
