import AboutCompany from '../components/AboutCompany';
import AdditionalServices from '../components/AdditionalServices';
import Blog from '../components/Blog';
import BlogArticle from '../components/BlogArticle';
import Cart from '../components/Cart';
import Cases from '../components/Cases';
import CasesArticle from '../components/CasesArticle';
import Catalog from '../components/Catalog';
import Checkout from '../components/Checkout';
import ChosenSolutions from '../components/ChosenSolutions';
import Compare from '../components/Compare';
import Contacts from '../components/Contacts';
import DeliveryPay from '../components/DeliveryPay';
import ForClients from '../components/ForClients';
import GpsMonitoring from '../components/GpsMonitoring';
import Gsm from '../components/Gsm';
import IndustrySolution from '../components/IndustrySolution';
import MainPage from '../components/MainPage';
import PayThankCard from '../components/PayThankCard/PayThankCard';
import PersonalService from '../components/PersonalService';
import ProductCard from '../components/ProductCard';
import Rent from '../components/Rent';
import Scontrol from '../components/Scontrol';
import Sitemap from '../components/Sitemap/Sitemap';
import SolutionChoosing from '../components/SolutionChoosing';
import Tariffs from '../components/Tariffs';
import TechnicalSupport from '../components/TechnicalSupport';
import TransportSolution from '../components/TransportSolution';
import UserAccount from '../components/UserAccount';
import Warranty from '../components/Warranty';
import Partnership from "../components/Partnership/Partnership";

const routes = [
    {
        path: '',
        component: MainPage,
    },
    {
        path: 'cart',
        component: Cart,
    },
    {
        path: 'catalog/:category',
        component: Catalog,
    },
    {
        path: 'catalog/brand/:brand',
        component: Catalog,
    },
    {
        path: 'product/:product',
        component: ProductCard,
    },
    {
        path: 'services/gps',
        component: GpsMonitoring,
    },
    {
        path: 'services/personal',
        component: PersonalService,
    },
    {
        path: 'services/scontrol',
        component: Scontrol,
    },
    {
        path: 'services/gsm',
        component: Gsm,
    },
    {
        path: 'services/partnership',
        component: Partnership,
    },
    {
        path: 'services/other-services',
        component: AdditionalServices,
    },
    {
        path: 'services/rent',
        component: Rent,
    },
    {
        path: 'cases',
        component: Cases,
    },
    {
        path: 'cases/article/:article',
        component: CasesArticle,
    },
    {
        path: 'compare',
        component: Compare,
    },
    {
        path: 'tariffs',
        component: Tariffs,
    },
    {
        path: 'support',
        component: TechnicalSupport,
    },
    {
        path: 'contacts',
        component: Contacts,
    },
    {
        path: 'order',
        component: Checkout,
    },
    {
        path: 'pay',
        component: DeliveryPay,
    },
    {
        path: 'about-us',
        component: AboutCompany,
    },
    {
        path: 'user',
        component: UserAccount,
    },
    {
        path: 'solution/industry/:solutionType?',
        component: IndustrySolution,
    },
    {
        path: 'solution/transport/:solutionType?',
        component: TransportSolution,
    },
    {
        path: 'for-clients',
        component: ForClients,
    },
    {
        path: 'warranty',
        component: Warranty,
    },
    {
        path: 'choose-solution',
        component: SolutionChoosing,
    },
    {
        path: 'chosen-solutions',
        component: ChosenSolutions,
    },
    {
        path: 'blog',
        component: Blog,
    },
    {
        path: 'blog/article/:article',
        component: BlogArticle,
    },
    {
        path: 'sitemap',
        component: Sitemap,
    },
    {
        path: 'pay_thank_card/:orderId',
        component: PayThankCard,
    },
    // {
    //     path: 'partnership',
    //     component: Partnership,
    // }
];


export default routes;
