export const getTokenFormLocalStorage = () => localStorage.getItem('token');
export const getBasketItemsFromLocalStorage = () => {
    const basket = localStorage.getItem('basket');

    return basket ? JSON.parse(basket).map(product => ({
        ...product,
        quantity: product.quantity >= 1 ? product.quantity : 1,
    })) : [];
};

export const getCompareItemsFromLocalStorage = () => {
    const compare = localStorage.getItem('compare');

    return compare ? JSON.parse(compare) : [];
};

