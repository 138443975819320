import React from 'react';

import './Rent.scss';
import {useTranslation} from 'react-i18next';
import {RentHeader} from "./RentHeader";
import {RentDescription} from "./RentDescription";
import {RentProducts} from "./RentProducts";
import {RentPrices} from "./RentPrices";
import {CapabilitiesSlider} from "../globalComponents/CapabilitiesSlider";
import capabilityIco5 from "../../images/transport-solutions/capabilities-ico5.svg";
import budgeting from '../../images/rent/budgeting.svg';
import repair from '../../images/rent/repair.svg';
import tablet from '../../images/rent/tablet.svg';
import monitoring from '../../images/rent/monitoring.svg';
import {MetaTagsComponent} from "../globalComponents/MetaTagsComponent";

export const Rent = props => {
    const [t] = useTranslation('services');
    const capabilitiesTranslation = t('rent.capabilities', {returnObjects: true});
    const title = t('rent.title');

    const capabilities = [
        {
            description: capabilitiesTranslation.cashCosts,
            icon: budgeting,
        },
        {
            description: capabilitiesTranslation.rent,
            icon: monitoring,
        },
        {
            description: capabilitiesTranslation.repair,
            icon: repair,
        },
        {
            description: capabilitiesTranslation.support,
            icon: capabilityIco5,
        },
        {
            description: capabilitiesTranslation.optimisation,
            icon: tablet,
        },
    ];

    return (
        <main className="rent">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={t('rent.subtitle')}
            />

            <RentHeader />
            <RentDescription />
            {
                (capabilities.length > 0) && (
                    <CapabilitiesSlider
                        title={t('rent.capabilitiesSliderTitle')}
                        capabilities={capabilities}/>

                )
            }
            <RentProducts />
            <RentPrices />
        </main>
    );
};
