import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {_image} from '../../API/apiConsts';
import LocalizedLink from '../globalComponents/LocalizedLink';

export const BlogItem = props => {
    const [t] = useTranslation('blog');
    const sections = t('sections', {returnObjects: true});
    const [articleClass, setArticleClass] = useState('useful');

    const setClassForArticle = () => {
        switch (props.article.section.name) {
            case sections.news:
                setArticleClass('news');
                break;
            case sections.useful:
                setArticleClass('useful');
                break;
            case sections.recc:
                setArticleClass('recc');
                break;
            case sections.cases:
                setArticleClass('cases');
                break;
            default: setArticleClass('news')
        }
    };

    useEffect(() => {
        setClassForArticle();
    }, []);

    return (
        <LocalizedLink to={`/blog/article/${props.article.id}`} className="blog-item" >
            <div className="blog-item__image-wrap">
                <img src={`${_image}/${props.article.image}`} alt={props.article.title}/>
            </div>
            <div className="blog-item__info">
                <div className="blog-item__category-wrap">
                    <div className={`blog-item__category-section blog-item__category-section--${articleClass}`}>
                        {props.article.section.name}
                    </div>
                    <div className="blog-item__category-subject">{props.article.topic.name}</div>
                </div>

                <h3 className="blog-item__category-title">
                    {props.article.title}
                </h3>

                <p className="blog-item__category-subtitle">
                    {props.article.short_description}
                </p>
            </div>
        </LocalizedLink>
    );
};
