import React from 'react';

import './CalendarControl.scss';
import 'react-calendar/dist/Calendar.css';
import Calendar from "react-calendar";
import {connect} from 'react-redux';
import {NextLabel} from "./NextLabel";
import {PrevLabel} from "./PrevLabel";

const CalendarControlContainer = props => {
    return (
        <Calendar
            onChange={props.handleChange}
            value={props.value}
            nextLabel={<NextLabel />}
            prevLabel={<PrevLabel />}
            next2Label={false}
            prev2Label={false}
            tileContent=""
            className={'calendar-control'}
            tileClassName='calendar-tile-control'
            showNeighboringMonth={true}
            minDetail="month"
            locale={props.language.key}
        />
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
    };
};

export const CalendarControl = connect(mapStateToProps)(CalendarControlContainer);
