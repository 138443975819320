import React from "react";

import {connect} from "react-redux";
import {UserAccountBonus} from "./UserAccountBonus";
import {UserAccountPersonalFormIndividual} from "./UserAccountPersonalFormIndividual";
import {UserAccountPersonalFormEntity} from "./UserAccountPersonalFormEntity";
import {UserAccountPersonalPasswordForm} from "./UserAccountPersonalPasswordForm";

const UserAccountPersonalContainer = props => {
    return (
        <div className={`user-account__container user-account__container--personal ${props.isActive ? 'active' : ''}`}>
            <section className="user-account__personal user-account__personal--individual">
                <div className="user-account__forms-wrap">
                    {
                        props.user.is_organization
                            ? (
                                <UserAccountPersonalFormEntity user={props.user} />
                            )
                            : (
                                <UserAccountPersonalFormIndividual user={props.user} />
                            )
                    }
                    <UserAccountPersonalPasswordForm />
                </div>

                <UserAccountBonus bonusPoint={props.bonusPoints} />
            </section>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.userAccountState.user,
        bonusPoints: state.userAccountState.user.bonus_points,
    };
};

export const UserAccountPersonal = connect(mapStateToProps)(UserAccountPersonalContainer);
