import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import plusIcon from "../../../images/product-card/plus-ico.svg";
import {connect} from "react-redux";
import {addMultipleProductsToBasket} from "../../../redux/actionCreators/basketActions";
import {toggleBasket, toggleModal} from "../../../redux/actionCreators/modalActions";

const ProductSetSliderContentContainer = props => {
    const [t] = useTranslation('productCard');
    const [products, setProducts] = useState({
        current: null,
        second: null,
    });

    useEffect(() => {
        setProducts(prevState => ({
            ...prevState,
            current: props.set.products.filter(product => product.slug === props.currentProductSlug)[0],
            second: props.set.products.filter(product => product.slug !== props.currentProductSlug)[0],
        }));
    }, [props.currentProductSlug]);


    const buyProductsSet = () => {
        const parsedProducts = [
            {
                ...products.current,
                quantity: 1,
                final_price: products.current.final_price - props.set.discount / 2,
            },
            {
                ...products.second,
                quantity: 1,
                final_price: products.second.final_price - props.set.discount / 2,
            }
        ];

        props.addMultipleProductsToBasket(parsedProducts);
        props.toggleModal(true);
        props.toggleBasket(true);
    };

    return (
        <div className="product-set__slide">
            <div className="product-set__header">
                <h6 className="product-set__set-text">{t('productSet.title')}</h6>
                <span className="product-set__set-name">{props.set.name}</span>
                <span className="product-set__set-number">{`№${props.set.id}`}</span>
            </div>

            <div className="product-set__slide-item">
                <div className="product-set__slide-main">
                    {
                        (products.current) && (
                            <div className="product-set__slide-card">
                                <span className="product-set__current-product-label">{t('productSet.currentProduct')}</span>
                                <div className="product-set__card-content">
                                    <div className="product-set__card-img">
                                        <img src={products.current.images[0]} alt={products.current.name} />
                                    </div>

                                    <div className="product-set__card-info">
                                        <p className="product-set__card-name">{products.current.name}</p>
                                        <p className="product-set__card-price">{`${products.current.final_price.toLocaleString()} грн`}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                    <div className="product-set__plus-ico">
                        <img src={plusIcon} alt="plus"/>
                    </div>

                    {
                        products.second && (
                            <div className="product-set__slide-card">
                                <div className="product-set__card-content">
                                    <div className="product-set__card-img">
                                        <img alt={products.second.name} src={products.second.images[0]} />
                                    </div>

                                    <div className="product-set__card-info">
                                        <p className="product-set__card-name">{products.second.name}</p>
                                        <p className="product-set__card-price">{`${products.second.final_price.toLocaleString()} грн`}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="product-set__slide-footer">
                    <div className="product-set__total-price">
                        <span className="total-price__title">Всего:</span>
                        <span className="total-price__old">{`${props.set.separated_price.toLocaleString()} грн`}</span>
                        <span className="total-price__new">{`${props.set.final_price.toLocaleString()} грн`}</span>
                    </div>

                    <button
                        className="btn-accent product-set__buy-btn"
                        tabIndex="-1"
                        // onClick={buyProductsSet}
                    >
                        {t('productSet.buySetButton')}
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    addMultipleProductsToBasket,
    toggleModal,
    toggleBasket,
};

export const ProductSetSliderContent = connect(null, mapDispatchToProps)(ProductSetSliderContentContainer);
