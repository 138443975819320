import React from 'react';
import {useTranslation} from 'react-i18next';

import closeButton from "../../../images/catalog/close-btn.svg";
import {CatalogFilterCategory} from './CatalogFilterCategory';
import {CatalogFilterEmpty} from './CatalogFilterEmpty';
import {CatalogFilterPrice} from "./CatalogFilterPrice";
import {CatalogFilterBrand} from "./CatalogFilterBrand";
import {connect} from "react-redux";
import {CatalogSpecificationFilters} from "./CatalogSpecificationFilters";

const CatalogFilterContainer = props => {
    const [t] = useTranslation('catalog');
    const isCategoryFilterVisible = (props.filterOptions.categories.length > 1);
    const isPricesFilerVisible = (props.filterOptions.prices.min !== props.filterOptions.prices.max);
    const isBrandFilterVisible = (props.filterOptions.brands.length > 1 );
    const isSpecificationsFilterVisible = (Object.keys(props.filterOptions.specifications).some(key => props.filterOptions.specifications[key].length));

    return (
        <aside className={`catalog__filter filter modal-filtermd ${props.isActive ? 'active' : ''}`}>
            <div className="filter__mobile-close-btn">
                <img src={closeButton} alt="close button" onClick={props.toggleFilterMenu}/>
            </div>

            <div className="filter__header">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12 21C12 20.7348 12.1054 20.4804 12.2929 20.2929C12.4804 20.1054 12.7348 20 13 20H19C19.2652 20 19.5196 20.1054 19.7071 20.2929C19.8946 20.4804 20 20.7348 20 21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21ZM8 15C8 14.7348 8.10536 14.4804 8.29289 14.2929C8.48043 14.1054 8.73478 14 9 14H23C23.2652 14 23.5196 14.1054 23.7071 14.2929C23.8946 14.4804 24 14.7348 24 15C24 15.2652 23.8946 15.5196 23.7071 15.7071C23.5196 15.8946 23.2652 16 23 16H9C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15ZM4 9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H27C27.2652 8 27.5196 8.10536 27.7071 8.29289C27.8946 8.48043 28 8.73478 28 9C28 9.26522 27.8946 9.51957 27.7071 9.70711C27.5196 9.89464 27.2652 10 27 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9Z"
                          fill="#1A3B70"
                    />
                </svg>
                <h6 className="filter__header-title">{t('catalogFilter.filterTitle')}</h6>
            </div>

            {
                (isPricesFilerVisible || isBrandFilterVisible || isPricesFilerVisible || isCategoryFilterVisible)
                    ? (
                        <>
                            {
                                (isPricesFilerVisible) && (
                                    <CatalogFilterPrice
                                        prices={props.filterOptions.prices}
                                        applyFilters={props.applyFilters}
                                        translation={t('catalogFilter.catalogFilerPrice', {returnObjects: true})}
                                    />
                                )
                            }

                            {
                                (isBrandFilterVisible) && (
                                    <CatalogFilterBrand
                                        brands={props.filterOptions.brands}
                                        filterBrandTitle={t('catalogFilter.catalogFilterBrandTitle')}
                                    />
                                )
                            }

                            {
                                (isSpecificationsFilterVisible) && (
                                    <CatalogSpecificationFilters
                                        specifications={props.filterOptions.specifications}
                                    />
                                )
                            }

                            {
                                (isCategoryFilterVisible) && (
                                    <CatalogFilterCategory
                                        categories={props.filterOptions.categories}
                                        filterCategoryTitle={t('catalogFilter.catalogFilterCategoryTitle')}
                                    />
                                )
                            }
                        </>
                    )
                    : (
                        <CatalogFilterEmpty filterEmptyText={t('catalogFilter.catalogFilterEmptyText')}/>
                    )
            }

        </aside>
    );
};

const mapStateToProps = state => {
    return {
        filterOptions: state.catalogState.filterOptions,
    };
};

const mapDispatchToProps = {

};

export const CatalogFilter = connect(mapStateToProps, mapDispatchToProps)(CatalogFilterContainer);
