import React, {useEffect, useState, useRef, useCallback} from 'react';

import './Catalog.scss';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {EmptyPage} from '../globalComponents/EmptyPage/EmptyPage';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PreLoader} from '../globalComponents/PreLoader';
import {CatalogFilter} from './CatalogFilter';
import {CatalogHeader} from './CatalogHeader';
import {BreadCrumbs} from '../globalComponents/BreadCrumbs';
import {getBrandCatalogInformation, getCatalogInformation, getFilteredProducts} from '../../API/requests';
import {getCategoryDetails, getFilters, getProducts} from '../../redux/actionCreators/getActionCreators/catalogActions';
import {getDiscountCatalogInformation} from '../../API/requests';
import {CatalogMain} from './CatalogMain';
import Page404 from '../Page404';

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

const CatalogContainer = props => {
    const [t] = useTranslation(['catalog', 'common']);
    const categoriesTranslation = t('common:categories', {returnObjects: true});
    const isMounted = useRef(false);
    const params = useParams();

    const [isErrorOccurred, setIsErrorOccurred] = useState(false);
    const [isCatalogEmpty, setIsCatalogEmpty] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isNewProductsLoading, setIsNewProductsLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const prevPage = usePrevious(activePage);
    const [numberOfPages, setNumberOfPages] = useState(4);
    const [isFilterMenuActive, setIsFilterMenuActive] = useState(false);


    useEffect(() => {
        setIsErrorOccurred(false);
        saveCatalogDetails(params.category);
    }, [params.category]);

    useEffect(() => {
        if (isMounted.current && isLoaded) {
            if (activePage === prevPage) {
                setActivePage(1);
                applyFilters(1);
            } else {
                window.scrollTo(0, 0);
                applyFilters(activePage);
            }

        } else isMounted.current = true;
    }, [props.sortProperty, props.appliedFilters.specifications, props.appliedFilters.brands, props.appliedFilters.categories, activePage]);

    const applyFilters = async pageNumber => {
        setIsNewProductsLoading(true);
        const result = await getFilteredProducts(
            props.categoryDetails.slug,
            props.appliedFilters,
            props.sortProperty,
            props.match.params.brand,
            pageNumber,
        );

        setNumberOfPages(result.last_page);
        props.getProducts(result.data);
        setIsNewProductsLoading(false);
    };

    const saveCatalogDetails = async category => {
        try {
            setIsCatalogEmpty(false);
            setIsLoaded(false);

            if (category) {
                if (category === 'discount-products') {
                    const catalogDetails = await getDiscountCatalogInformation(category);
                    if (catalogDetails) {
                        setNumberOfPages(catalogDetails.discountProducts.last_page);
                        props.getProducts(catalogDetails.discountProducts.data);
                        props.getFilters(catalogDetails.discountProductsFilters);
                    }

                    props.getCategoryDetails({
                        name: t('discountProductsName'),
                        slug: category,
                    });
                } else {
                    const catalogDetails = await getCatalogInformation(category);

                    if (catalogDetails.products.data.length === 0) setIsCatalogEmpty(true);

                    setNumberOfPages(catalogDetails.products.last_page);
                    props.getProducts(catalogDetails.products.data);
                    props.getFilters(catalogDetails.categoryFilters);
                    props.getCategoryDetails(catalogDetails.category);
                }
            } else {
                const brandName = props.match.params.brand;
                const catalogDetails = await getBrandCatalogInformation(brandName);

                setNumberOfPages(catalogDetails.brandProducts.last_page);
                props.getProducts(catalogDetails.brandProducts.data);
                props.getFilters(catalogDetails.brandCatalogFilters);

                props.getCategoryDetails({
                    name: brandName,
                    slug: brandName,
                });
            }

            setIsLoaded(true);
        } catch (error) {
            setIsErrorOccurred(true);
        }
    };

    const changeActivePage = payload => {
        switch (payload) {
            case 'next': {
                setActivePage(prevState => {
                    if (prevState === numberOfPages) return prevState;

                    return prevState + 1;
                });
                break;
            }
            case 'prev': {
                setActivePage(prevState => {
                    if (prevState === 1) return prevState;

                    return prevState - 1;
                });
                break;
            }
            default:
                setActivePage(payload);
        }
    };

    const toggleFilterMenu = useCallback(
        () => {
            setIsFilterMenuActive(prevState => !prevState);
        }, []);

    return (
        <>
            {
                isErrorOccurred
                    ? (
                        <Page404/>
                    )
                    : (
                        <main className="catalog">
                            <MetaTagsComponent
                                title={categoriesTranslation[params.category] || props.categoryDetails.slug}
                                seoTitle={props.categoryDetails.seo_keywords}
                                seoDescription={props.categoryDetails.seo_description}
                            />

                            {
                                isLoaded
                                    ? (
                                        <div className="container">
                                            <BreadCrumbs
                                                links={[
                                                    {
                                                        title: t('breadCrumbs.catalog'),
                                                        link: `catalog${props.match.params.brand ? '/brand' : ''}/${props.categoryDetails.slug}`,
                                                    },
                                                    {
                                                        title: categoriesTranslation[params.category] || props.categoryDetails.slug,
                                                        link: `catalog${props.match.params.brand ? '/brand' : ''}/${props.categoryDetails.slug}`,
                                                    },
                                                ]}
                                            />

                                            <div>
                                                <div className="catalog__content">
                                                    {
                                                        isCatalogEmpty
                                                            ? (
                                                                <EmptyPage/>
                                                            )
                                                            : (
                                                                <>
                                                                    <CatalogFilter
                                                                        isActive={isFilterMenuActive}
                                                                        toggleFilterMenu={toggleFilterMenu}
                                                                        applyFilters={applyFilters}
                                                                    />

                                                                    <div className="catalog__section">
                                                                        <CatalogHeader
                                                                            toggleFilterMenu={toggleFilterMenu}
                                                                            applyFilters={applyFilters}
                                                                            categoryTitle={categoriesTranslation[props.categoryDetails.slug] || props.categoryDetails.slug}
                                                                        />
                                                                        {
                                                                            isNewProductsLoading
                                                                                ? (
                                                                                    <PreLoader/>
                                                                                )
                                                                                : (
                                                                                    <CatalogMain
                                                                                        products={props.products}
                                                                                        isLoaded={isLoaded}
                                                                                        category={props.match.params.category}
                                                                                        numberOfPages={numberOfPages}
                                                                                        activePage={activePage}
                                                                                        changeActivePage={changeActivePage}
                                                                                        categoryDescription={props.categoryDetails.description}
                                                                                    />
                                                                                )
                                                                        }

                                                                        <div className={`catalog__description`} dangerouslySetInnerHTML={{__html: props.categoryDetails.description}}/>
                                                                    </div>
                                                                </>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <PreLoader/>
                                    )
                            }
                        </main>
                    )
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        equipment: state.equipmentState.equipment,
        products: state.catalogState.products,
        categoryDetails: state.catalogState.categoryDetails,
        appliedFilters: state.catalogState.appliedFilters,
        sortProperty: state.catalogState.sortProperty,
    };
};

const mapDispatchToProps = {
    getCategoryDetails,
    getProducts,
    getFilters,
};

export const Catalog = connect(mapStateToProps, mapDispatchToProps)(CatalogContainer);
