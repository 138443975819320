import axios from "axios";
import {addCompany, assignedById} from './bitrixAPIConsts';

export const createCompany = async (userInfo, googleId, utmParams) => {
    let sourceDescription = '';

    if (googleId) {
        sourceDescription += `\ngaClientId: ${googleId}`;
    }

    let fields = {
        TITLE: userInfo.orgName,
        COMPANY_TYPE: "CUSTOMER",
        ASSIGNED_BY_ID: assignedById,
        COMMENTS: sourceDescription,
        OPENED: "Y",
    }

    if (utmParams) {
        for (const paramKey in utmParams) {
            if (utmParams.hasOwnProperty(paramKey)) {
                fields = {
                    ...fields,
                    [paramKey]: utmParams[paramKey]
                }
            }
        }
    }

    const response = await axios.post(addCompany, {fields});

    return response.data.result;
};
