import React, {useState} from 'react';
import {SolutionOpportunitySelectDetails} from "./SolutionOpportunitySelectDetails";

export const SolutionOpportunitySelect = props => {
    const [activeOpportunity, setActiveOpportunity] = useState(0);

    const selectOpportunity = indexToSet => {
        setActiveOpportunity(indexToSet);
    };

    return (
        <div className="industry-solutions__opportunities">
            <h3 className="opportunities-selector__title">{props.title}</h3>
            <div className="opportunities-selector">
                <div className="opportunities-selector__tabs">
                    {
                        props.solution.opportunities.map((opportunity, index) => (
                            <div
                                key={index}
                                className={`opportunities-selector__tab ${index === activeOpportunity ? 'active' : ''}`}
                                onClick={() => selectOpportunity(index)}
                            >
                                <div className="opportunities-selector__tab-ico">
                                    <img className="tab-ico" src={opportunity.icon} alt={opportunity.title} />
                                </div>
                                <p className="opportunities-selector__tab-name">{opportunity.title}</p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <SolutionOpportunitySelectDetails activeOpportunity={props.solution.opportunities[activeOpportunity]} />
        </div>
    );
};