import React from 'react';
import {useTranslation} from 'react-i18next';

import Slider from 'react-slick';
import {connect} from "react-redux";
import LocalizedLink from '../LocalizedLink';

import {SuitableTabs} from "../SuitableTabs";
import {toggleBasket, toggleModal} from "../../../redux/actionCreators/modalActions";
import {addProductToBasket} from "../../../redux/actionCreators/basketActions";
import {PrevArrow} from "./PrevArrow";
import {NextArrow} from "./NextArrow";

const SolutionOpportunitySelectDetailsContainer = props => {
    const [t] = useTranslation('common');

    const settings = {
        className: 'opportunities__slider',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        // adaptiveHeight: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            }
        ],
    };

    const addProductToBasket = product => {
        props.toggleModal(true);
        props.toggleBasket(true);
        props.addProductToBasket(product);
    };

    return (
        <div className="opportunities">
            <div className="opportunities-content-wrap">
                <div className="opportunities__slider-wrap">
                    {
                        (props.activeOpportunity.products.length > 0) && (
                            <Slider {...settings}>
                                {
                                    props.activeOpportunity.products.map(product => (
                                        <div key={product.id} className="opportunities__slide-item">
                                            <LocalizedLink to={`/product/${product.slug}`}  target="_blank" >
                                                <div className="opportunities__slide-img">
                                                    <img src={product.images[0]} alt={product.name} />
                                                </div>

                                                <h3 className="opportunities__product-name">{product.name}</h3>
                                                <p className="opportunities__product-descr">{product.title}</p>
                                            </LocalizedLink>

                                            <div className="opportunities__slide-footer">
                                                <div className="opportunities__price">{`${product.final_price.toLocaleString()} грн`}</div>
                                                <button
                                                    className="opportunities__buy-btn btn-accent"
                                                    tabIndex="0"
                                                    onClick={() => addProductToBasket({
                                                        ...product,
                                                        quantity: 1,
                                                    })}
                                                >
                                                    {t('addToBasketButton')}
                                                </button>
                                            </div>
                                        </div>
                                    ))
                                }
                            </Slider>
                        )
                    }
                </div>

                <div className="opp-info">
                    <div className="opp-info__info-wrap">
                        <h3 className="opp-info__info-title">{props.activeOpportunity.title}</h3>
                        <p className="opp-info__descr" dangerouslySetInnerHTML={{__html: props.activeOpportunity.description}} />
                        <SuitableTabs solutionsFor={props.activeOpportunity.solution_for}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        modal:  state.modalsState.modal,
        basket: state.modalsState.basket,
    };
};

const mapDispatchToProps = {
    toggleBasket,
    toggleModal,
    addProductToBasket,
};

export const SolutionOpportunitySelectDetails = connect(mapStateToProps, mapDispatchToProps)(SolutionOpportunitySelectDetailsContainer);
