import React from 'react';

import './Warranty.scss';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';



export const Warranty = props => {
    const [t] = useTranslation('warranty');
    const title = t('title');
    const goodsReturn = t('return', {returnObjects: true});
    const warranty = t('warranty', {returnObjects: true});
    const highlighted = t('highlighted', {returnObjects: true});

    return (
        <div className="container">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={`${t('descriptionFirst')} ${t('link')}`}
            />

            <main className="warranty">
                <h1 className="warranty__main-title">{title}</h1>
                <div className="warranty__text-container">
                    <p className="warranty__p">
                        {t('descriptionFirst')}<a href="https://zakon.rada.gov.ua/laws/show/1023-12#Text" rel="noreferrer" target="_blank"> {t('link')}</a>.</p>
                    <p className="warranty__p">{t('descriptionSecond')}</p>
                </div>

                <h2 className="warranty__title">{goodsReturn.title}</h2>
                <div className="warranty__container">
                    <p className="warranty__p-highlighted">
                        {goodsReturn.subtitle}:
                    </p>

                    <ul className="warranty__list-secondary">
                        {
                            goodsReturn.options.map((option, index) => (
                                <li key={index} className="warranty__li-secondary">{option}</li>
                            ))
                        }
                    </ul>
                    <p className="warranty__p">{goodsReturn.description}</p>
                </div>

                <h2 className="warranty__title">{warranty.title}</h2>
                <ul className="warranty__list-main">

                    {
                        warranty.options.map((option, index) => (
                            <li key={index} className="warranty__li-main">{option}</li>
                        ))
                    }
                </ul>

                <div className="warranty__highlighted-container">
                    <h2 className="warranty__hl-title">
                        {highlighted.title}:
                    </h2>
                    {
                        highlighted.options.map((option, index) => (
                            <div key={index} className="warranty__hl-p">{`— ${option}`}</div>
                        ))
                    }
                </div>
            </main>
        </div>
    );
};