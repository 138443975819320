import React, {useEffect} from 'react';

import './Cases.scss';
import {useTranslation} from 'react-i18next';

import {connect} from "react-redux";
import {getCases} from "../../API/requests";
import {getCasesArticles} from "../../redux/actionCreators/getActionCreators/casesActions";
import {EmptyPage} from '../globalComponents/EmptyPage/EmptyPage';
import LocalizedLink from '../globalComponents/LocalizedLink';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

const CasesContainer = props => {
    const [t] = useTranslation('cases');

    useEffect(() => {
        saveCases();
    }, []);

    const saveCases = () => {
        getCases().then(cases => props.getCasesArticles(cases));
    };

    return (
        <div className="container">
            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={
                    props.cases.reduce((acc, caseItem, index) => {
                        if(acc.length > 160) return acc;
                        return index === 0 ? caseItem.name : `${acc}, ${caseItem.name}`
                    }, '')
                }
            />

            <main className="cases">
                <h1 className="cases__title">{t('title')}</h1>
                {
                    props.cases.length > 0
                        ? (
                            <section className="cases__body">
                                {
                                    window._.chunk(props.cases, 3).map((chunk, index) => (
                                        chunk.map((caseItem, caseIndex) => (
                                            <LocalizedLink
                                                to={`/cases/article/${caseItem.id}`}
                                                key={caseIndex}
                                                className={`cases__item-wrap ${((index % 2 === 0 && caseIndex === 0) || (index % 2 !== 0 && caseIndex === 2)) ? 'cases__item-wrap--big': ''}`}
                                            >
                                                <div className="cases__item-container">
                                                    <div className="cases__item-bg">
                                                        <img alt={caseItem.name} src={caseItem.image} />
                                                    </div>
                                                    <article className="cases__item">
                                                        <h2 className="cases__item-title">
                                                            {caseItem.name}
                                                        </h2>

                                                        <div className="cases__item-subtitle" dangerouslySetInnerHTML={{__html: caseItem.short_description}} />


                                                        <div className="cases__item-details">
                                                            {t('details')}
                                                            <svg width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M1 4C0.723858 4 0.5 4.22386 0.5 4.5C0.5 4.77614 0.723858 5 1 5V4ZM23.3536 4.85355C23.5488 4.65829 23.5488 4.34171 23.3536 4.14645L20.1716 0.964466C19.9763 0.769204 19.6597 0.769204 19.4645 0.964466C19.2692 1.15973 19.2692 1.47631 19.4645 1.67157L22.2929 4.5L19.4645 7.32843C19.2692 7.52369 19.2692 7.84027 19.4645 8.03553C19.6597 8.2308 19.9763 8.2308 20.1716 8.03553L23.3536 4.85355ZM1 5H23V4H1V5Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </article>
                                                </div>
                                            </LocalizedLink>
                                        ))
                                    ))
                                }
                            </section>
                        )
                        : (
                            <EmptyPage />
                        )
                }
            </main>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        cases: state.casesState.cases,
    };
};

const mapDispatchToProps = {
    getCasesArticles,
};

export const Cases = connect(mapStateToProps, mapDispatchToProps)(CasesContainer);
