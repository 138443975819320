import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {searchProducts} from '../../../../API/requests';
import LocalizedLink from '../../LocalizedLink';

export const HeaderTopMenuSearch = props => {
    const [t] = useTranslation('header');
    const searchPlaceholder = t('headerTopMenu.searchPlaceholder');

    useEffect(() => {
        if(props.searchInputState.searchMenu.value.trim().length >= 2) {
            searchProducts(props.searchInputState.searchMenu.value)
                .then(search => props.searchInputState.setSearchMenu(prevState => ({
                    ...prevState,
                    products: [...search]
                })));
        } else props.searchInputState.setSearchMenu(prevState => ({
            ...prevState,
            products: []
        }));
    }, [props.searchInputState.searchMenu.value]);

    const toggleSearchInput = newStateValue => {
        props.searchInputState.setSearchMenu(prevState => ({
            ...prevState,
            isActive: newStateValue,
        }));
    };

    const handleChange = event => {
        props.searchInputState.setSearchMenu(prevState => ({
            ...prevState,
            value: event.target.value,
        }))
    };

    return (
        <div className="header-top__search-box">
            <div
                className={`header-top__search-wrap ${props.searchInputState.searchMenu.isActive ? 'active' : ''}`}
                onMouseOver={()=> toggleSearchInput(true)}
            >
                <input
                    className={`header-top__search ${props.searchInputState.searchMenu.isActive ? 'active' : ''}`}
                    type="text"
                    name="search"
                    value={props.searchInputState.searchMenu.value}
                    placeholder={searchPlaceholder}
                    onChange={handleChange}
                    autoComplete="off"
                />

                <button
                    className="header-top__search-ico"
                >
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.9411 20.8822C9.55536 20.8822 6 17.3269 6 12.9411C6 8.55536 9.55536 5 13.9411 5C18.3269 5 21.8822 8.55536 21.8822 12.9411C21.8822 15.038 21.0695 16.9451 19.7418 18.3646L23.7679 23.3972C24.1332 23.8538 24.0592 24.5201 23.6025 24.8855C23.1459 25.2508 22.4796 25.1767 22.1143 24.7201L18.1028 19.7057C16.8926 20.4518 15.4671 20.8822 13.9411 20.8822ZM13.9412 18.7646C17.1574 18.7646 19.7647 16.1573 19.7647 12.9411C19.7647 9.72486 17.1574 7.1176 13.9412 7.1176C10.725 7.1176 8.11771 9.72486 8.11771 12.9411C8.11771 16.1573 10.725 18.7646 13.9412 18.7646Z"
                            fill="white"
                        />
                    </svg>
                </button>

                {
                    (props.searchInputState.searchMenu.products.length > 0) && (
                        <div className={`header-top__search-products ${props.searchInputState.searchMenu.isActive ? 'active' : ''}`}>
                            <ul className="search-products__list">
                                {
                                    props.searchInputState.searchMenu.products.map(product => (
                                        <li key={product.id} className="search-products__item">
                                            <LocalizedLink
                                                to={`/product/${product.slug}`}
                                                className="search-products__link"
                                                onClick={() => props.searchInputState.setSearchMenu(prevState => ({
                                                    ...prevState,
                                                    isActive: false,
                                                    value: '',
                                                }))}
                                            >
                                                <p className="search-products__title">{product.name}</p>
                                                <p className="search-products__price">{`${product.final_price.toLocaleString()} грн`}</p>
                                            </LocalizedLink>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        </div>
    );
};
