import React from 'react';

import './Sitemap.scss';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import LocalizedLink from '../globalComponents/LocalizedLink';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

const Sitemap = props => {
    const [t] = useTranslation(['header', 'footer']);
    const navItemsTranslation = t('navItems', {returnObjects: true});
    const servicesTranslation = t('headerBottomNav.menuComponents.services', {returnObjects: true});

    const servicesList = [
        {
            name: servicesTranslation.gps,
            slug: 'gps',
        },
        {
            name: servicesTranslation.scontrol,
            slug: 'scontrol',
        },
        {
            name: servicesTranslation.personal,
            slug: 'personal',
        },
        {
            name: servicesTranslation.gsm,
            slug: 'gsm',
        },
        {
            name: servicesTranslation.rent,
            slug: 'rent',
        },
        {
            name: servicesTranslation.otherServices,
            slug: 'other-services',
        },
    ];
    const navItems = [
        {
            name: navItemsTranslation.discount,
            link: 'catalog/discount-products',
        },
        {
            name: navItemsTranslation.cases,
            link: 'cases',
        },
        {
            name: navItemsTranslation.tariffs,
            link: 'tariffs',
        },
        {
            name: navItemsTranslation.support,
            link: 'support',
        },
        {
            name: navItemsTranslation.pay,
            link: 'pay',
        },
        {
            name: navItemsTranslation.blog,
            link: 'blog',
        },
        {
            name: navItemsTranslation.aboutUs,
            link: 'about-us',
        },
        {
            name: navItemsTranslation.forClients,
            link: 'for-clients',
        },
        {
            name: navItemsTranslation.contacts,
            link: 'contacts',
        },
    ];

    return (
        <div className="container">
            <MetaTagsComponent title={t('footer:siteMap')} />
            <section className="sitemap">
                <h1 className="sitemap__header">{t('footer:siteMap')}</h1>
                <div className="sitemap__sections">
                    <div className="sitemap__sections-item sections-item">
                        <h2 className="sections-item__header">{t('headerBottomNav.menuComponents.equipmentTitle')}</h2>

                        <ul className="sections-item__list">
                            {
                                props.equipment.map((item, index) => (
                                    <li key={index} className="sections-item__list-item">
                                        <LocalizedLink to={`/catalog/${item.slug}`}>{item.name}</LocalizedLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className="sitemap__sections-item sections-item">
                        <h2 className="sections-item__header">{t('headerBottomNav.menuComponents.servicesTitle')}</h2>

                        <ul className="sections-item__list">
                            {
                                servicesList.map((item, index) => (
                                    <li key={index} className="sections-item__list-item">
                                        <LocalizedLink to={`/services/${item.slug}`}>{item.name}</LocalizedLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className="sitemap__sections-item sections-item">
                        <h2 className="sections-item__header">{t('headerBottomNav.menuComponents.solutions.industrySolutionsTitle')}</h2>

                        <ul className="sections-item__list">
                            {
                                props.industrySolutions.map((item, index) => (
                                    <li key={index} className="sections-item__list-item">
                                        <LocalizedLink to={`/solution/industry/${item.type}`}>{item.name}</LocalizedLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className="sitemap__sections-item sections-item">
                        <h2 className="sections-item__header">{t('headerBottomNav.menuComponents.solutions.transportSolutionsTitle')}</h2>

                        <ul className="sections-item__list">
                            {
                                props.transportSolutions.map((item, index) => (
                                    <li key={index} className="sections-item__list-item">
                                        <LocalizedLink to={`/solution/transport/${item.type}`}>{item.name}</LocalizedLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className="sitemap__sections-item sections-item">
                        <h2 className="sections-item__header">{t('navItems.forClients')}</h2>

                        <ul className="sections-item__list">
                            {
                                navItems.map((item, index) => (
                                    <li key={index} className="sections-item__list-item">
                                        <LocalizedLink to={`/${item.link}`}>{item.name}</LocalizedLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        equipment: store.equipmentState.equipment,
        industrySolutions: store.solutionsState.industrySolutions,
        transportSolutions: store.solutionsState.transportSolutions,

    };
};

export default connect(mapStateToProps)(Sitemap);
