import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {deleteProductsByCategoryFromCompare} from '../../../redux/actionCreators/compareActions';
import CompareFilterCategorySelectorItem from "./CompareFilterCategorySelectorItem";

const CompareFilterCategorySelectorContainer = props => {
    const [t] = useTranslation(['compare', 'common']);

    const categoriesTranslation = t('common:categories', {returnObjects: true});
    const [isDropSelectActive, setIsDropSelectActive] = useState(false);

    const toggleDropSelect = () => {
        setIsDropSelectActive(prevState => !prevState);
    };

    return (
        <div className="compare__filter-section">
            <h6 className="compare__filter-title">{t('categories')}:</h6>

            <div className="compare__filter-category-drop" onClick={toggleDropSelect} >

                <div className="compare__filter-category">
                    {`${categoriesTranslation[props.selectedCategory]} (${props.productsCompare.length})`}
                </div>

                <div className="compare__arrow-down">
                    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M12.9984 13.5282L6.12144 7.57082C5.88744 7.35685 5.56244 7.23297 5.19844 7.23297C4.48344 7.23297 3.89844 7.73974 3.89844 8.35912C3.89844 8.67445 4.04144 8.95598 4.27544 9.15869L12.0754 15.9156C12.3094 16.1183 12.6344 16.2422 12.9984 16.2422C13.3624 16.2422 13.6874 16.1183 13.9214 15.9156L21.7214 9.15869C21.9554 8.95598 22.0984 8.67445 22.0984 8.35912C22.0984 7.73974 21.5134 7.23297 20.7984 7.23297C20.4344 7.23297 20.1094 7.35685 19.8754 7.55956L12.9984 13.5282Z"
                                  fill="#1A3B70" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="22.523" height="26" fill="white"
                                      transform="translate(0 23) rotate(-90)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>

            <div className={`compare__filter-category-drop-body ${isDropSelectActive ? 'active' : ''}`}>
                {
                    props.categories.map((category, i) => (
                        <CompareFilterCategorySelectorItem
                            setIsDropSelectActive={setIsDropSelectActive}
                            selectCategory={props.selectCategory}
                            category={category}
                            key={i}
                            products={props.products}
                        />
                    ))
                }
            </div>
            <button
                className="compare__clean-list-btn"
                onClick={() => props.deleteProductsByCategoryFromCompare(props.selectedCategory)}
            >
                {t('cleanList')}
            </button>
        </div>
    );
};

const mapDispatchToProps = {
    deleteProductsByCategoryFromCompare,
};

export const CompareFilterCategorySelector = connect(null, mapDispatchToProps)(CompareFilterCategorySelectorContainer);
