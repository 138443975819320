import {applyMiddleware, createStore, compose} from 'redux';
import rootReducer from '../redux/reducers/rootReducer';
import {logger} from 'redux-logger/src';

const store = createStore(
    rootReducer,
    // compose(applyMiddleware(logger), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
);


store.subscribe(() => {
    const basketItems = store.getState().basketState.basketItems;
    if(basketItems.length > 0) {
        localStorage.setItem('basket', JSON.stringify(basketItems))
    }
    if(store.getState().compareState.products.length > 0) {
        localStorage.setItem('compare', JSON.stringify(store.getState().compareState.products))
    }
});

export default store;
