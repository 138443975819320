export const getCase = id => {
    return window.axios.get('', {
        params: {
            query: `
                query($id: ID!) {
                    case(id: $id) {
                        id
                        name
                        image
                        short_description
                        client_logo
                        client_description
                        problem
                        solution
                        result
                        feedback
                        benefits {
                            title
                            value
                        }
                        products {
                            id
                            name
                            slug
                            images
                            final_price
                            bonus_points
                            short_description
                        }
                    }
                }
            `,
            variables: {
                id,
            }
        },
    }).then(response => response.data.data.case)
        .catch(error => console.log(error));
};
