import React from "react";
import LocalizedLink from '../LocalizedLink';

export const FooterClients = props => {
    const forClientsListItems = [
        {
            title: props.translation.forClientsListItems.discount,
            link: 'catalog/discount-products',
        },
        {
            title: props.translation.forClientsListItems.blog,
            link: 'blog',
        },
        {
            title: props.translation.forClientsListItems.tariffs,
            link: 'tariffs',
        },
        {
            title: props.translation.forClientsListItems.support,
            link: 'support',
        },
        {
            title: props.translation.forClientsListItems.pay,
            link: 'pay',
        },
        {
            title: props.translation.forClientsListItems.warranty,
            link: 'warranty',
        },
        {
            title: props.translation.forClientsListItems.aboutUs,
            link: 'about-us',
        },
        {
            title: props.translation.forClientsListItems.contacts,
            link: 'contacts',
        },
    ];

    return (
        <nav className="footer__section footer__section--list">
            <h4 onClick={props.toggleMenu} className="footer__list-title">
                {props.translation.title}
                <div className={`plus ${props.isActive? 'active': ''}`} />
            </h4>

            <ul className={`footer__list ${props.isActive ? 'active' : ''}`}>
                {
                    forClientsListItems.map((item, index) => (
                        <li key={index} className="footer__item"><LocalizedLink to={`/${item.link}`}>{item.title}</LocalizedLink></li>
                    ))
                }
            </ul>
        </nav>
    );
}