import React, {useState, useEffect} from "react";
import checkmark from "../../../images/checkmark.svg";
import {connect} from "react-redux";
import {applyBrandFilter} from "../../../redux/actionCreators/catalogActions";

const CatalogFilterBrandContainer = ({applyBrandFilter, filterBrandTitle, brands}) => {
    const [selectedBrands, setSelectedBrands] = useState({});

    useEffect(() => {
        if(Object.keys(selectedBrands).length) {
           applyBrandFilter(selectedBrands)
        }
    },  [selectedBrands]);

    const handleBrandSelectChange = event => {
        const brandName = event.target.name;

        setSelectedBrands(prevState => {
            if(prevState[brandName]) {
                return {
                    ...prevState,
                    [brandName]: false,
                };
            } else {
                return {
                    ...prevState,
                    [brandName]: true,
                };
            }
        });
    };

    return (
        <div className="filter__checkbox-section filter__container">
            <div className="filter__title">{filterBrandTitle}</div>
            <form className="filter__form">
                {
                    brands.map(brand => (
                        <label key={brand} className="checkbox-wrap">
                            <input
                                type="checkbox"
                                checked={selectedBrands.brand}
                                name={brand}
                                onChange={handleBrandSelectChange}
                            />
                            <span className="checkbox">
                                <img src={checkmark} alt="checkmark" />
                                <span>{brand}</span>
                            </span>
                        </label>
                    ))
                }
            </form>
        </div>
    );
};

const mapDispatchToProps = {
    applyBrandFilter,
};

export const CatalogFilterBrand = connect(null, mapDispatchToProps)(CatalogFilterBrandContainer);

