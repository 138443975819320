export const getCatalogInformation = (
    category,
    page = 1,
    perPage = 12,
) => {
        return window.axios.get('', {
            params: {
                query: `
                    query(
                        $category: String!, 
                        $page: Int!, 
                        $perPage: Int!,
                    ) {
                        products(
                            category: $category, 
                            pagination: {
                                page: $page,
                                per_page: $perPage,
                            }
                        ) {
                            data {
                                id
                                name
                                slug
                                images
                                short_description
                                discount
                                discount_percent
                                bonus_points
                                final_price
                                price
                                advantages
                                specifications
                                category {
                                    name
                                    slug
                                }
                            }
                            last_page
                        }
                        category(slug: $category) {
                            name
                            slug
                            description
                            seo_title
                            seo_description
                        }
                        categoryFilters(category: $category)
                    }
                `,
                variables: {
                    category,
                    page,
                    perPage,
                },
            },
        }).then(response => response.data.data)
            .catch(error => console.log(error));
};
