import React from 'react';

export const NextArrow = props => {
    return (
        <div onClick={props.onClick} className="arrow arrow-right slick-arrow">
            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.9176 15.1219L2.11666 0.361867C1.63173 -0.121444 0.846608 -0.120631 0.362484 0.364367C-0.121264 0.849302 -0.120014 1.63486 0.364985 2.11855L14.2849 16.0001L0.364484 29.8815C-0.120451 30.3652 -0.121701 31.1503 0.361984 31.6353C0.604671 31.8784 0.922607 32 1.24054 32C1.55767 32 1.87435 31.8792 2.1166 31.6378L16.9176 16.8782C17.1512 16.6458 17.2822 16.3296 17.2822 16.0001C17.2822 15.6706 17.1508 15.3547 16.9176 15.1219Z"
                    fill="#1A3B70"
                />
            </svg>
        </div>
    );
};
