export const getRecommendedProducts = () => {
    return window.axios.get('', {
        params: {
            query: `
                query {
                    recommendedProducts {
                        id
                        name
                        slug
                        images
                        final_price
                        short_description
                        bonus_points
                        specifications
                        category {
                            slug
                            name
                        }
                    }
                }
            `
        }
    }).then(response => response.data.data.recommendedProducts)
        .catch(error => console.log(error));
};
