import React from 'react';
import {useTranslation} from 'react-i18next';

import service1 from '../../../../images/header/drop-ico/serv-ico1.svg';
import service2 from '../../../../images/header/drop-ico/serv-ico2.svg';
import service3 from '../../../../images/header/drop-ico/serv-ico3.svg';
import service4 from '../../../../images/header/drop-ico/serv-ico4.svg';
import service5 from '../../../../images/header/drop-ico/serv-ico5.svg';
import service6 from '../../../../images/header/drop-ico/serv-ico6.svg';
import service03 from '../../../../images/header/drop-ico/serv-ico03.svg';

import {DropMenuBanner} from "../../DropMenuBanner";
import LocalizedLink from '../../LocalizedLink';

const ServicesMenu = props => {
    const [t] = useTranslation('header');
    const servicesTranslation = t('headerBottomNav.menuComponents.services', {returnObjects: true});
    const servicesList = [
        {
            name: servicesTranslation.gps,
            slug: 'gps',
            icon: service1,
        },
        {
            name: servicesTranslation.scontrol,
            slug: 'scontrol',
            icon: service5,
        },
        {
            name: servicesTranslation.wialonBlock,
            slug: 'partnership',
            icon: service03,
        },
        {
            name: servicesTranslation.personal,
            slug: 'personal',
            icon: service3,
        },
        {
            name: servicesTranslation.gsm,
            slug: 'gsm',
            icon: service2,
        },
        {
            name: servicesTranslation.rent,
            slug: 'rent',
            icon: service4,
        },
        {
            name: servicesTranslation.otherServices,
            slug: 'other-services',
            icon: service6,
        },
    ];

    return (
        <div className={`header-bottom__drop-menu header-bottom__drop-menu--services ${props.isActive ? 'active' : ''}`}>
            <ul className="header-bottom__catalog-drop">
            {servicesList.map(item => (
                <li
                    key={item.name}
                    className="header-bottom__drop-menu-item"
                >
                    <LocalizedLink
                        to={`/services/${item.slug}`}
                        onClick={() => {
                            props.toggleMenu();
                            props.closeSideMenu();
                        }}
                    >
                        <div className="ico-wrap">
                            <img
                                className="ico"
                                src={item.icon}
                                alt="Мониторинг транспорта"
                            />

                        </div>
                        {item.name}
                    </LocalizedLink>
                </li>
            ))}
            </ul>

            <DropMenuBanner toggleMenu={props.toggleMenu} />
        </div>
    );
};

export default ServicesMenu;
