import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const ScrollToTop = props => {
    const location = useLocation();

    useEffect(() => {
        if(!location.hash) {
            window.scrollTo(0, 0);
        }
    }, [location.pathname]);

    return null;
};
