import {
    GET_INDUSTRY_SOLUTION,
    GET_INDUSTRY_SOLUTIONS,
    GET_OPPORTUNITIES,
    GET_TRANSPORT_SOLUTION,
    GET_TRANSPORT_SOLUTIONS,
} from '../../actionConsts/actionConsts';

export const getIndustrySolution = payload => {
    return {
        type: GET_INDUSTRY_SOLUTION,
        payload,
    };
};

export const getTransportSolution = payload => {
    return {
        type: GET_TRANSPORT_SOLUTION,
        payload,
    };
};

export const getTransportSolutions = payload => {
    return {
        type: GET_TRANSPORT_SOLUTIONS,
        payload,
    };
};

export const getIndustrySolutions = payload => {
    return {
        type: GET_INDUSTRY_SOLUTIONS,
        payload,
    };
};

export const getOpportunities = payload => {
    return {
        type: GET_OPPORTUNITIES,
        payload,
    };
};
