import React from 'react';
import {useTranslation} from 'react-i18next';

export const RentDescription = props => {
    const [t] = useTranslation('services');
    const advantages = t('rent.advantages', {returnObjects: true});

    return (
        <div className="container">
            <section className="rent-description">
                <div className="rent-description__section">
                    <h3 className="rent-description__title">{t('rent.description.title')}</h3>
                    <p className="rent-description__subtitle">
                        {t('rent.description.subtitle')}
                    </p>
                </div>

                <div className="rent-description__section">
                    <h3 className="rent-description__list-title">
                        {t('rent.advantagesTitle')}
                    </h3>

                    <ul className="rent-description__list">
                        {
                            advantages.map((advantage, index) => (
                                <li key={index} className="rent-description__list-item">{advantage}</li>
                            ))
                        }
                    </ul>
                </div>
            </section>
        </div>
    );
};
