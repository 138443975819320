import React from 'react';
import {CheckoutOrderResult} from './CheckoutOrderResult';

import {CheckoutProduct} from "./CheckoutProduct";
import {connect} from "react-redux";

const CheckoutOrderContainer = props => {
    return (
            <div className="basket-container__body">
                <h4 className="basket-container__title">Ваш заказ</h4>
                <div className="basket-container__products">
                    {
                        props.basketItems.map(basketItem => (
                            <CheckoutProduct key={basketItem.id} products={props.basketItems} basketItem={basketItem}/>
                        ))
                    }
                </div>

                <CheckoutOrderResult
                    totalPrice={props.totalPrice}
                    submitOrder={props.submitOrder}
                    totalBonuses={props.totalBonuses}
                    discountAmount={props.discountAmount}
                />
            </div>
    );
};

const mapStateToProps = state => {
    return {
        basketItems: state.basketState.basketItems,
    };
};

export const CheckoutOrder = connect(mapStateToProps)(CheckoutOrderContainer);
