export const sendSupportRequest = (userInfo, leadId) => {
    window.axios.get('', {
        params: {
            query: `
                mutation(
                    $id: Int!
                    $name: String!
                    $phone: String!
                    $email: String!
                    $comment: String
                ) {
                    technicalSupportToMail(
                        support_id: $id
                        name: $name
                        phone: $phone
                        email: $email
                        comment: $comment
                    )             
                }
            `,
            variables: {
                id: leadId,
                name: userInfo.name,
                phone: userInfo.phone,
                email: userInfo.email,
                comment: userInfo.comment,
            },
        },
    });
};
