import {_api} from '../apiConsts';

export const resetPassword = (userInfo, token) => {
    return window.axios.post(`${_api}/auth`, {
        params: {
            query: `
                mutation (
                    $email: String!
                    $password: String!
                    $passwordConfirmation: String!
                    $token: String!
                ) {
                    resetPassword(
                        email: $email
                        password: $password
                        password_confirmation: $passwordConfirmation
                        token: $token
                    )
                }
            `,
            variables: {
                email: userInfo.email,
                password: userInfo.password,
                passwordConfirmation: userInfo.passwordConfirmation,
                token,
            },
        },
    }).then(response => window._.head(response.data))
        .catch(error => console.log(error));
};
