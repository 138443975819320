import React from 'react';

import {connect} from "react-redux";
import {EmptyPage} from '../../globalComponents/EmptyPage/EmptyPage';
import {UserAccountOrderHistoryFooter} from './UserAccountOrderHistoryFooter';
import {UserAccountOrderHistoryHeader} from './UserAccountOrderHistoryHeader';
import {UserAccountOrderHistoryOrder} from './UserAccountOrderHistoryOrder';

const UserAccountOrderHistoryContainer = props => {
    return (
        <section className={`user-account__order-history order-history ${props.isActive ? 'active' : ''}`}>
            {
                props.orders.length > 0
                    ? (

                            props.orders.map(order => (
                                <div key={order.external_order_id} className="order-history__order">
                                    <UserAccountOrderHistoryHeader order={order}/>

                                    {
                                        order.order_items.map(orderItem => (
                                            <UserAccountOrderHistoryOrder key={orderItem.product.id} orderItem={orderItem} />
                                        ))
                                    }

                                    <UserAccountOrderHistoryFooter order={order} />
                                </div>
                            ))
                    )
                    : (
                        <EmptyPage />
                    )
            }
        </section>
    );
};

const mapStateToProps = state => {
    return {
        orders: state.userAccountState.user.orders,
    };
};

export const UserAccountOrderHistory = connect(mapStateToProps)(UserAccountOrderHistoryContainer);