import {
    CHANGE_LANGUAGE,
    CHANGE_USER_LOCATION, SET_GOOGLE_ID,
    SET_PRODUCT_FOR_OFFER, SET_SERVICE_FOR_ORDER,
    SET_SOLUTION_FOR_OFFER, SET_SOLUTION_FOR_ORDER, SET_UTM_PARAMETERS,
} from '../actionConsts/actionConsts';

const initialState = {
    language: {key: 'uk', title: 'Укр'},
    userLocation: '',
    productForCommercialOffer: '',
    solutionForCommercialOffer: {},
    resetPasswordToken: '',
    solutionForOrder: '',
    serviceForOrder: '',
    googleId: '',
    utmParams: {

    },
};

const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case CHANGE_USER_LOCATION:
            return {
                ...state,
                userLocation: action.payload,
            };
        case SET_PRODUCT_FOR_OFFER: {
            return {
                ...state,
                productForCommercialOffer: action.payload,
            };
        }
        case SET_SOLUTION_FOR_OFFER: {
            return {
                ...state,
                solutionForCommercialOffer: action.payload,
            };
        }
        case SET_SOLUTION_FOR_ORDER: {
            return {
                ...state,
                solutionForOrder: action.payload,
            };
        }
        case SET_SERVICE_FOR_ORDER: {
            return {
                ...state,
                serviceForOrder: action.payload,
            };
        }
        case SET_GOOGLE_ID: {
            return {
                ...state,
                googleId: action.payload,
            };
        }
        case SET_UTM_PARAMETERS: {
            return {
                ...state,
                utmParams: {
                    ...action.payload,
                },
            };
        }
        default: return state;
    }
};

export default globalReducer;
