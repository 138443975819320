import axios from "axios";
import {addLead, assignedById} from "./bitrixAPIConsts";

export const createLead = async (userInfo, type, googleId, utmParams) => {
    const date = new Date();
    const types = {
        'vacancy': 'Вакансія',
        'callback': 'Зворотній дзвінок',
        'support': 'Техпідтримка',
        'serviceOrder': 'Замовлення послуги',
        'solutionOrder': 'Замовлення рішення',
        'question': 'Запитання',
        'commercialOffer': 'Комерційна пропозиція',
    };

    let description = '';
    if(userInfo.comment) {
        description += `Коментар: ${userInfo.comment}`;
    } else if(userInfo.question) {
        description += `Питання: ${userInfo.question}`;
    }

    if(userInfo.itemForOffer) {
        description += `\n Пропозиція щодо: ${userInfo.itemForOffer}`
    }

    if (googleId) {
        description += `\ngaClientId: ${googleId}`;
    }

    const orderType = userInfo.service || userInfo.solution;

    let fields = {
        TITLE: `Заявка "${types[type]}" ${orderType || ""} від ${userInfo.name}`,
        NAME: userInfo.name,
        DATE_CREATE: date,
        OPENED: "Y",
        ASSIGNED_BY_ID: assignedById,
        SOURCE_DESCRIPTION: description,
        SOURCE_ID: "STORE",
        EMAIL: [
            {
                VALUE: userInfo.email,
            }
        ],
        PHONE: [
            {
                VALUE: userInfo.phone,
            },
        ],
    }


    if (utmParams) {
        for (const paramKey in utmParams) {
            if (utmParams.hasOwnProperty(paramKey)) {
                fields = {
                    ...fields,
                    [paramKey]: utmParams[paramKey]
                }
            }
        }
    }

    return axios.post(`${addLead}`, {
        fields,
    }).then(response => response.data.result)
        .catch(error => console.log(error));
};
