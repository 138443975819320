import React from 'react';
import {useTranslation} from 'react-i18next';

export const UserAccountOrderHistoryHeader = props => {
    const [t] = useTranslation('account');

    return (
        <div className="order-history__order-header">
            <p className="order-history__order-number">{`${t('orders.order')} №${props.order.external_order_id}, ${props.order.created_date}`}</p>
            <div className="order-history__right-wrap">
                <div className="order-history__value-title">{t('orders.orderQuantity')}</div>
                <div className="order-history__value-title">{t('orders.orderPrice')}</div>
            </div>
        </div>
    );
};
