import React from 'react';

import logo from '../../../../images/logo.svg';
import LocalizedLink from '../../LocalizedLink';
import {HeaderTopNav} from "./HeaderTopNav";
import {HeaderTopMenu} from "./HeaderTopMenu";

export const HeaderTop = props => {
    return (
        <div className="header-top">
            <div className="container-header">
                <div className="header-mobile__section">
                    <div className="header-mobile__logo-wrap">
                        <LocalizedLink to={`/`}>
                            <img alt="Total Control" src={logo} />
                        </LocalizedLink>
                    </div>
                </div>

                <HeaderTopNav />
                <HeaderTopMenu
                    searchInputState={props.searchInputState}
                    phoneMenuState={props.phoneMenuState}
                />
            </div>
        </div>
    );
};
