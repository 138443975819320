import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './CasesArticle.scss';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

import {Subscribe} from '../globalComponents/Subscribe';
import {BreadCrumbs} from '../globalComponents/BreadCrumbs';
import {CasesArticleHeader} from './CasesArticleHeader';
import {CasesArticleEquipment} from './CasesArticleEquipment';
import {CasesArticleSolution} from './CasesArticleSolution';
import {CasesArticleResult} from './CasesArticleResult';
import {CasesArticleTask} from './CasesArticleTask';
import {CasesArticleClient} from './CasesArticleClient';
import {CasesArticleClientDetails} from './CasesArticleClientDetails';
import {getCase} from '../../API/requests';
import {getCaseArticle} from '../../redux/actionCreators/getActionCreators/casesActions';

const CasesArticleContainer = props => {
    const [t] = useTranslation('cases');

    useEffect(() => {
        saveArticle(props.match.params.article)
    }, [props.match.params.article]);

    const saveArticle = id => {
        getCase(id).then(caseArticle => {
            props.getCaseArticle(caseArticle);
        });
    };

    return (
        <>
            <MetaTagsComponent
                title={props.case.name}
                seoTitle={props.case.name}
                seoDescription={props.case.short_description}
            />

            <div className="container">
                <BreadCrumbs
                    links={[
                        {
                            title: t('title'),
                            link: 'cases',
                        },
                        {
                            title: props.case.name,
                            link: `cases/article/${props.case.id}`,
                        },
                    ]}
                />
            </div>


            <CasesArticleHeader
                title={props.case.name}
                image={props.case.image}
                caseTitleTranslation={t('caseTitleTranslation')}
            />

            <main className="cases-article-wrap">
                <article className="cases-article">
                    <p
                        className="cases-article__paragraph cases-article__paragraph--first"
                        dangerouslySetInnerHTML={{__html: props.case.short_description}}
                    />

                    <CasesArticleClient
                        client_logo={props.case.client_logo}
                        client_description={props.case.client_description}
                        title={t('caseClient')}
                    />

                    <CasesArticleTask problem={props.case.problem} title={t('caseTask')}/>
                    <CasesArticleEquipment products={props.case.products} title={t('caseEquipment')}/>
                    <CasesArticleSolution solution={props.case.solution} title={t('caseSolution')}/>
                    <CasesArticleResult solution={props.case.result} title={t('caseResult')}/>
                    <CasesArticleClientDetails feedback={props.case.feedback} benefits={props.case.benefits}/>
                </article>
            </main>

            <Subscribe/>
        </>
    );
};

const mapStateToProps = state => {
    return {
        case: state.caseState.case,
    };
};

const mapDispatchToProps = {
    getCaseArticle,
};

export const CasesArticle = connect(mapStateToProps, mapDispatchToProps)(CasesArticleContainer);
