import aboutUsRu from './localization/ru/aboutUs.json';
import accountRu from './localization/ru/account.json';
import blogRu from './localization/ru/blog.json';
import cartRu from './localization/ru/cart.json';
import casesRu from './localization/ru/cases.json';
import catalogRu from './localization/ru/catalog.json';
import checkoutRu from './localization/ru/checkout.json';
import commonRu from './localization/ru/common.json';
import compareRu from './localization/ru/compare.json';
import contactsRu from './localization/ru/contacts.json';
import footerRu from './localization/ru/footer.json';
import forClientsRu from './localization/ru/forClients.json';
import headerRu from './localization/ru/header.json';
import mainPageRu from './localization/ru/mainPage.json';
import payRu from './localization/ru/pay.json';
import productCardRu from './localization/ru/productCard.json';
import servicesRu from './localization/ru/services.json';
import solutionChoosingRu from './localization/ru/solutionChoosing.json';
import solutionsRu from './localization/ru/solutions.json';
import supportRu from './localization/ru/support.json';
import tariffsRu from './localization/ru/tariffs.json';
import warrantyRu from './localization/ru/warranty.json';
import aboutUsUa from './localization/uk/aboutUs.json';
import accountUa from './localization/uk/account.json';
import blogUa from './localization/uk/blog.json';
import cartUa from './localization/uk/cart.json';
import casesUa from './localization/uk/cases.json';
import catalogUa from './localization/uk/catalog.json';
import checkoutUa from './localization/uk/checkout.json';
import commonUa from './localization/uk/common.json';
import compareUa from './localization/uk/compare.json';
import contactsUa from './localization/uk/contacts.json';
import footerUa from './localization/uk/footer.json';
import forClientsUa from './localization/uk/forClients.json';
import headerUa from './localization/uk/header.json';
import mainPageUa from './localization/uk/mainPage.json';
import payUa from './localization/uk/pay.json';
import productCardUa from './localization/uk/productCard.json';
import servicesUa from './localization/uk/services.json';
import solutionChoosingUa from './localization/uk/solutionChoosing.json';
import solutionsUa from './localization/uk/solutions.json';
import supportUa from './localization/uk/support.json';
import tariffsUa from './localization/uk/tariffs.json';
import warrantyUa from './localization/uk/warranty.json';
import partenrshipUa from './localization/uk/partnership.json';
import partenrshipRu from './localization/ru/partnership.json';

const resources = {
    uk: {
        header: headerUa,
        footer: footerUa,
        mainPage: mainPageUa,
        catalog: catalogUa,
        services: servicesUa,
        account: accountUa,
        common: commonUa,
        solutions: solutionsUa,
        cases: casesUa,
        aboutUs: aboutUsUa,
        cart: cartUa,
        contacts: contactsUa,
        blog: blogUa,
        solutionChoosing: solutionChoosingUa,
        support: supportUa,
        pay: payUa,
        tariffs: tariffsUa,
        warranty: warrantyUa,
        compare: compareUa,
        productCard: productCardUa,
        forClients: forClientsUa,
        checkout: checkoutUa,
        partnership: partenrshipUa
    },
    ru: {
        header: headerRu,
        footer: footerRu,
        mainPage: mainPageRu,
        catalog: catalogRu,
        services: servicesRu,
        account: accountRu,
        common: commonRu,
        solutions: solutionsRu,
        cases: casesRu,
        aboutUs: aboutUsRu,
        cart: cartRu,
        contacts: contactsRu,
        blog: blogRu,
        solutionChoosing: solutionChoosingRu,
        support: supportRu,
        pay: payRu,
        tariffs: tariffsRu,
        warranty: warrantyRu,
        compare: compareRu,
        productCard: productCardRu,
        forClients: forClientsRu,
        checkout: checkoutRu,
        partnership: partenrshipRu
    },
};

export default resources;
