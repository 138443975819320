import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import EquipmentMenu from './EquipmentMenu';
import ServicesMenu from "./ServicesMenu";
import servicesImg from "../../../../images/header/services.svg";
import catalog from "../../../../images/header/catalog.svg";
import solutionsImg from "../../../../images/header/solutions.svg";
import arrowDownBlack from "../../../../images/header/ar-down--black.svg";

import {SolutionsMenu} from "./SolutionsMenu";
const timeToClose = 400;

export const HeaderBottomNav = props => {
    const [t] = useTranslation('header');
    const menuComponentsTranslation = t('headerBottomNav.menuComponents', {returnObjects: true});
    const [menus, setMenus] = useState({
        'equipment': false,
        'services': false,
        'solutions': false,
    });

    const menuComponents = {
        'equipment': {
            title: menuComponentsTranslation.equipmentTitle,
            icon: catalog,
            timer: null,
            component:
                <EquipmentMenu
                    isActive={menus.equipment}
                    toggleMenu={() => toggleMenu('equipment')}
                    closeSideMenu={props.closeSideMenu}
                />,
        },
        'services': {
            title: menuComponentsTranslation.servicesTitle,
            icon: servicesImg,
            timer: null,
            component:
                <ServicesMenu
                    isActive={menus.services}
                    toggleMenu={() => toggleMenu('services')}
                    closeSideMenu={props.closeSideMenu}
                />,
        },
        'solutions': {
            title: menuComponentsTranslation.solutionsTitle,
            icon: solutionsImg,
            timer: null,
            component:
                <SolutionsMenu
                    isActive={menus.solutions}
                    toggleMenu={() => toggleMenu('solutions')}
                    closeSideMenu={props.closeSideMenu}
                />,
        },
    };

    const toggleMenu = menuName => {
        setMenus(prevState => ({
            ...prevState,
            [menuName]: !prevState[menuName]
        }));

        props.overflowRef.current.scrollTo(0, 0);
    };

    const closeMenuWithInterval = menuName => {
        if(window.innerWidth > 1250) {
            menuComponents[menuName].timer = setTimeout(() => {
                setMenus(prevState => {
                    clearTimeout(menuComponents[menuName].timer);

                    return  {
                        ...prevState,
                        [menuName]: false,
                    }
                })
            }, timeToClose);
        } else {
            setMenus(prevState => ({
                ...prevState,
                [menuName]: false,
            }));
        }
    };

    const cancelClosingMenu = menuName => {
        clearTimeout(menuComponents[menuName].timer);
    };

    return (
        <div className="header-bottom__nav">
            {
                Object.keys(menuComponents).map(component=> (
                    <div
                        key={component}
                        className="header-bottom__nav-dropdown"
                        onMouseLeave={() => closeMenuWithInterval(component)}
                        onMouseEnter={() => cancelClosingMenu(component)}
                    >
                        <div className="header-bottom__drop-item">
                            <div
                                className="header-bottom__drop-btn"
                                onClick={() => toggleMenu(component)}
                            >
                                <img
                                    alt="catalog icon"
                                    src={menuComponents[component].icon}
                                />

                                <div className="header-bottom__drop-btn-text">{menuComponents[component].title}</div>

                                <img
                                    className={`arrow-down ${menus[component] ? 'active' : ''}`}
                                    alt="arrow icon"
                                    src={arrowDownBlack}
                                />

                            </div>
                        </div>

                        {menuComponents[component].component}
                    </div>
                ))
            }
        </div>
    );
};
