export const getStaticService = id => {
    return window.axios.get('', {
        params: {
            query: `
                query($id: ID!) {
                    staticService(id: $id) {
                        id
                        data
                    }
                }
            `,
            variables: {
                id
            },
        },
    }).then(response => response.data.data.staticService)
        .catch(error => console.log(error))
};