import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import closeButton from '../../../images/close-btn.svg';
import {toggleDealSuccess, toggleModal} from '../../../redux/actionCreators/modalActions';

export const DealSuccessModalContainer = props => {
    const [t] = useTranslation('common')

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleDealSuccess({
            isActive: false,
        });
    };

    return (
        <div className={`alert-wrap alert-subs ${props.dealSuccess.isActive ? 'active' : ''}`}>
            <div className="modal__close" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>

            <div className="alert deal-success">
                <h5 className="alert__title">{t('applicationSuccessModal.title')}</h5>

                {
                    (props.dealSuccess.isCallbackRequested) && (
                        <p className="alert__subtitle">
                            {t('applicationSuccessModal.subtitle')}
                        </p>
                    )
                }

                <button className="alert__confirm-btn" onClick={closeModals}>
                    {t('applicationSuccessModal.submitButton')}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        dealSuccess: store.modalsState.dealSuccess,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleDealSuccess,
};

export const DealSuccessModal = connect(mapStateToProps, mapDispatchToProps)(DealSuccessModalContainer);
