import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useHistory} from 'react-router-dom';

import './ChosenSolutions.scss';
import useLocalizedPath from '../../customHooks/useLocalizedPath';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {ChosenSolutionPage} from "./ChosenSolutionPage";
import {connect} from "react-redux";

const ChosenSolutionsContainer = props => {
    const [t] = useTranslation('solutionChoosing');
    const localizedSolutionPath = useLocalizedPath('choose-solution');

    const routerHistory = useHistory();
    const [activeTab, setActiveTab] = useState(0);
    const [transportTypes, setTransportTypes] = useState([]);

    const changeActiveTab = indexOfTab => {
        setActiveTab(indexOfTab);
    };

    useEffect(() => {
        if(!props.selectedTypesOfTransport.length) {
            routerHistory.push(localizedSolutionPath);
        }

        parseSolutions();
    }, []);

    const parseSolutions = () => {
        const transportTypesToSet = props.transportSolutions
            .filter(solution => props.selectedTypesOfTransport
                .some(transport => transport === solution.type))
            .map(solution => ({
                ...solution,
                opportunities: solution.opportunities
                    .filter(opportunity => props.selectedOpportunities
                        .some(selectedOpportunity => selectedOpportunity === opportunity.title))
            }));

        setTransportTypes(transportTypesToSet);
    };

    return (
        <main className="solution-choose">
            <MetaTagsComponent
                title={t('choosing.title')}
                seoTitle={t('choosing.title')}
                seoDescription={t('choosing.title')}
            />

            <div className="container">
                <div className="solution-choose__tabs">
                    {
                        transportTypes.map((transportType, index) => (
                            <h2
                                key={index}
                                className={`solution-choose__tab ${index === activeTab ? 'active' : ''}`}
                                onClick={() => changeActiveTab(index)}
                            >
                                {transportType.name}
                            </h2>
                        ))
                    }
                </div>
            </div>

            <div className="solution-choose__content-wrap">
                <div className="solution-choose__content">
                    {
                        transportTypes.map((transportType, index) => (
                            <ChosenSolutionPage
                                key={index}
                                isActive={index === activeTab}
                                transportType={transportType}
                            />
                        ))
                    }
                </div>
            </div>
        </main>
    );
};

const mapStateToProps = state => {
    return {
        selectedTypesOfTransport: state.choosingSolutionState.selectedTypesOfTransport,
        selectedOpportunities: state.choosingSolutionState.selectedOpportunities,
        transportSolutions: state.solutionsState.transportSolutions,
        language: state.globalReducer.language
    };
};

export const ChosenSolutions = connect(mapStateToProps)(ChosenSolutionsContainer);
