import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import closeButton from '../../../images/close-btn.svg';
import {toggleModal, togglePasswordResetSuccess} from '../../../redux/actionCreators/modalActions';

const PasswordResetSuccessModalContainer = props => {
    const [t] = useTranslation('common');

    const closeModals = () => {
        props.togglePasswordResetSuccess(false);
        props.toggleModal(false);
    };

    return (
        <div className={`alert-wrap alert-subs ${props.passwordResetSuccess ? 'active' : ''}`}>
            <div className="modal__close" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>

            <div className="alert">
                <h5 className="alert__title">
                    {/*{t('subscribeModal.title')}*/}
                    Пароль успішно змінено
                </h5>
                <p className="alert__subtitle">
                    Дякуємо за користування нашим сервісом
                    {/*{t('subscribeModal.subtitle')}*/}
                </p>
                <button className="alert__confirm-btn" onClick={closeModals}>
                    {t('submitButton')}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        passwordResetSuccess: store.modalsState.passwordResetSuccess,
    };
};

const mapDispatchToProps = {
    toggleModal,
    togglePasswordResetSuccess,
};

export const PasswordResetSuccessModal = connect(mapStateToProps, mapDispatchToProps)(PasswordResetSuccessModalContainer);
