const formReducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'set_value': {
            return {
                ...state,
                [payload.name]: {
                    ...state[payload.name],
                    value: payload.value,
                    wasTouched: true,
                    error: false,
                },
            };
        }
        case 'set_touch_state': {
            return {
                ...state,
                [payload.name]: {
                    ...state[payload.name],
                    wasTouched: true,
                },
            };
        }
        case 'set_error': {
            return {
                ...state,
                [payload.name]: {
                    ...state[payload.name],
                    error: true,
                    errorMessage: payload.errorMessage,
                },
            };
        }
        case 'set_all_values': {
            return {
                ...state,
                ...payload
            }
        }
        default: return
    }
};

export default formReducer;
