export const getBrandCatalogInformation = (brand) => {
    return window.axios.get('', {
        params: {
            query: `
                query(
                    $brand: String!
                ) {
                    brandProducts (
                        brand: $brand,
                    ) {
                        data {
                            id
                            name
                            slug
                            images
                            short_description
                            discount
                            discount_percent
                            final_price
                            price
                            advantages
                            bonus_points
                            specifications
                            category {
                                name
                                slug
                            }
                        }
                        last_page
                    }
                    brandCatalogFilters(brand: $brand)
                }
            `,
            variables: {
                brand,
            }
        }
    }).then(response => response.data.data)
        .catch(error => console.log(error));
};
