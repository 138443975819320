import {_api} from "../apiConsts";


export const getCurrentUser = () => {
    return window.axios.post(`${_api}/auth`, {
        params: {
            query: `
                query {
                    user {
                        id
                        is_organization
                        bitrix_user_id
                        bitrix_company_id
                        name
                        last_name
                        fathers_name
                        email
                        phone
                        bonus_points
                        legal
                        orders {
                            external_order_id
                            status
                            status_human
                            delivery_method
                            delivery_method_human
                            payment_method
                            payment_method_human
                            back_call
                            created_date
                            created_date_human
                            products_amount
                            delivery_price
                            total_amount
                            bill_to
                            ship_to
                            installation
                            order_items {
                                quantity
                                total_price
                                product {
                                    id
                                    slug
                                    images
                                    name
                                    short_description
                                    final_price
                                    bonus_points
                                }
                            }
                        }
                    }
                }
            `,
        },
    }).then(response => window._.head(response.data).data.user)
        .catch(error => console.log(error));
};
