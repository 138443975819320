import React from 'react'

import axios from 'axios'
import {useTranslation} from 'react-i18next';

import {useHistory} from 'react-router-dom';

import {connect} from 'react-redux';
import {_api} from '../../API/apiConsts';
import useLocalizedPath from '../../customHooks/useLocalizedPath';
import {toggleUserLogin} from '../../redux/actionCreators/userActions';

const UserAccountExitContainer = props => {
    const [t] = useTranslation('account');
    const localizedMainPath = useLocalizedPath('');
    const routerHistory = useHistory();

    const logoutUser = () => {
        props.toggleUserLogin(false);
        localStorage.removeItem('token');
        routerHistory.push(localizedMainPath)

        window.axios = axios.create({
            baseURL: _api,
        })
    };

    return (
        <button onClick={logoutUser} className="btn-ghost-secondary">
            {t('personal.exitButton')}
        </button>
    );
};

const mapDispatchToProps = {
    toggleUserLogin,
};

export const UserAccountExit = connect(null, mapDispatchToProps)(UserAccountExitContainer);
