import React from 'react';

import './PreLoader.scss';

import preloader from '../../../images/preloader.svg';

export const PreLoader = props => {
    return (
        <div className="preloader">
            <img
                className="preloader__image"
                src={preloader}
                alt="Loading"
            />
        </div>
    );
};
