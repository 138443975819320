import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import LocalizedLink from '../../globalComponents/LocalizedLink';

const CompareFilterViewSelectorContainer = props => {
    const [t] = useTranslation('compare');

    return (
        <div className={`compare__filter-section ${props.productsCompare.length < 2 ? 'disabled' : ''}`}>
            <h6 className="compare__filter-title">{t('filter.title')}:</h6>
            <form className="compare__form">
                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="filter"
                        disabled={props.productsCompare.length < 2}
                        checked={props.reverse === 0}
                        onChange={() => props.setRevers(0)}
                    />
                    <span className="radio">{t('filter.parameters.allParameters')}</span>
                </label>

                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="filter"
                        disabled={props.productsCompare.length < 2}
                        checked={props.reverse === 1}
                        onChange={() => props.setRevers(1)}
                    />
                    <span className="radio">{t('filter.parameters.differences')}</span>
                </label>

                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="filter"
                        disabled={props.productsCompare.length < 2}
                        checked={props.reverse === 2}
                        onChange={() => props.setRevers(2)}
                    />
                    <span className="radio">{t('filter.parameters.matches')}</span>
                </label>
                <LocalizedLink to={`/catalog/${props.currentCategory.slug}`} className="btn-ghost-secondary">{t('addProduct')}</LocalizedLink>
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentCategory: state.catalogState.categoryDetails,
    };
};

export const CompareFilterViewSelector = connect(mapStateToProps)(CompareFilterViewSelectorContainer);
