import React, {useState} from 'react';

export const AboutCompanyVacanciesTabs = props => {
    const [activeTab, setActiveTab] = useState(0);

    const changeActiveTab = indexOfTab => {
        setActiveTab(prevState => {
            return prevState === indexOfTab
                ? -1
                : indexOfTab
        });
    };

    return (
        <div className="vacancies__tabs">
            {
                props.vacancies.map((vacancy, index) => (
                    <div
                        key={vacancy.id}
                        className={`vacancies__tab ${index === activeTab ? 'active' : ''}`}
                        onClick={() => {
                            changeActiveTab(index);
                            props.setSelectedVacancy(vacancy.id)
                        }}
                    >
                        <div className="vacancies__header">
                            <h4 className="vacancies__header-title">{vacancy.name}</h4>
                            <div className="vacancies__header-arrow">
                                <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M15.3525 14L8.40938 20.9431C8.16 21.1794 8.01562 21.5075 8.01562 21.875C8.01562 22.5969 8.60625 23.1875 9.32812 23.1875C9.69563 23.1875 10.0238 23.0431 10.26 22.8069L18.135 14.9319C18.3713 14.6956 18.5156 14.3675 18.5156 14C18.5156 13.6325 18.3713 13.3044 18.135 13.0681L10.26 5.19313C10.0238 4.95688 9.69563 4.8125 9.32812 4.8125C8.60625 4.8125 8.01562 5.40313 8.01562 6.125C8.01562 6.4925 8.16 6.82063 8.39625 7.05688L15.3525 14Z"
                                          fill="#4F4F4F" />
                                </svg>
                            </div>
                        </div>

                        <div className="vacancies__tab-body" dangerouslySetInnerHTML={{__html: vacancy.description}} />
                    </div>
                ))
            }
        </div>
    );
};
