import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";

import closeBtn from '../../../images/close-btn.svg';
import {toggleModal, toggleRegistrationSuccess} from "../../../redux/actionCreators/modalActions";

const RegistrationSuccessModalContainer = props => {
    const [t] = useTranslation('common');

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleRegistrationSuccess(false);
    };

    return (
        <div className="alert-wrap alert-registration active">
            <div className="modal__close">
                <img
                    src={closeBtn}
                    alt="close"
                    onClick={closeModals}
                />
            </div>

            <div className="alert">
                <h5 className="alert__title">{t('registrationSuccessModal.title')}</h5>
                <p className="alert__subtitle">{t('registrationSuccessModal.subtitle')}</p>
                <button className="alert__confirm-btn" onClick={closeModals}>{t('submitButton')}</button>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        registrationSuccess: state.modalsState.registrationSuccess,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleRegistrationSuccess,
};

export const RegistrationSuccessModal = connect(mapStateToProps, mapDispatchToProps)(RegistrationSuccessModalContainer);
