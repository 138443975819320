import {ADD_PRODUCT_BASKET, GET_BASKET_FROM_LOCAL_STORAGE} from "../../actionConsts/actionConsts";

export const addProductToBasket = payload => {
    return {
        type: ADD_PRODUCT_BASKET,
        payload,
    }
};
export const getBasketFromLocalStorage = payload => {
    return {
        type: GET_BASKET_FROM_LOCAL_STORAGE,
        payload,
    }
}