import {
    ADD_MULTIPLE_PRODUCTS_BASKET,
    ADD_PRODUCT_BASKET, CLEAR_BASKET,
    DECREASE_PRODUCT_QUANTITY,
    DELETE_PRODUCT_BASKET,
    GET_BASKET_FROM_LOCAL_STORAGE,
    INCREASE_PRODUCT_QUANTITY,
    TOGGLE_PRODUCT_DELETE_FLAG,
} from '../actionConsts/actionConsts';

const initialBasketState = {
    basketItems: [],
};

const basketReducer = (state = initialBasketState, action) => {
    switch (action.type) {
        case ADD_PRODUCT_BASKET: {
            const isAlreadyInBasket = state
                .basketItems.some(basketItem => basketItem.id === action.payload.id);

            if(isAlreadyInBasket) return state;

            return {
                ...state,
                basketItems: [...state.basketItems, action.payload]
            };
        }
        case DELETE_PRODUCT_BASKET:
            return {
                ...state,
                basketItems: [...state.basketItems.filter(item => item.id !== action.payload)]
            };
        case TOGGLE_PRODUCT_DELETE_FLAG: {
            const indexOfProductToChange = state
                .basketItems.findIndex(item => item.id === action.payload.id);

            const newBasketItem = {
                ...state.basketItems[indexOfProductToChange],
                isDeleted: action.payload.flag,
            };

            const newBasketItems = [...state.basketItems];
            newBasketItems[indexOfProductToChange] = newBasketItem;

            return {
                ...state,
                basketItems: [...newBasketItems],
            };
        }
        case INCREASE_PRODUCT_QUANTITY: {
            const indexOfProductToChange = state
                .basketItems.findIndex(item => item.id === action.payload);

            const newBasketItem = {
                ...state.basketItems[indexOfProductToChange],
                quantity: state.basketItems[indexOfProductToChange].quantity + 1,
            };
            
            const newBasketItems = [...state.basketItems];
            newBasketItems[indexOfProductToChange] = newBasketItem;

            return {
                ...state,
                basketItems: [...newBasketItems],
            };
        }
        case DECREASE_PRODUCT_QUANTITY: {
            const indexOfProductToChange = state
                .basketItems.findIndex(item => item.id === action.payload);
            const prevItemQuantity = state.basketItems[indexOfProductToChange].quantity;

            if(prevItemQuantity <= 1) return state;

            const newBasketItem = {
                ...state.basketItems[indexOfProductToChange],
                quantity: prevItemQuantity - 1,
            };

            const newBasketItems = [...state.basketItems];
            newBasketItems[indexOfProductToChange] = newBasketItem;

            return {
                ...state,
                basketItems: [...newBasketItems],
            };
        }
        case GET_BASKET_FROM_LOCAL_STORAGE:
            return {
                ...state,
                basketItems: action.payload,
            };
        case CLEAR_BASKET: {
            return {
                ...state,
                basketItems: [],
            };
        }
        case ADD_MULTIPLE_PRODUCTS_BASKET: {
            return {
                ...state,
                basketItems: [...action.payload],
            };
        }
        default:
            return state;
    }
};

export default basketReducer;
