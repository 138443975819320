import React, {useEffect, useState} from 'react';

import './Scontrol.scss';
import {useTranslation} from 'react-i18next';
import {_image} from '../../API/apiConsts';
import {getProductBySlug} from '../../API/requests';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {ScontrolHeader} from "./ScontrolHeader";
import {ScontrolAdvantages} from "./ScontrolAdvantages";
import {ScontrolCompare} from "./ScontrolCompare";
import {ScontrolParallax} from "./ScontrolParallax";
import {ScontrolBuy} from "./ScontrolBuy";
import {ScontrolCarScroll} from "./ScontrolCarScroll";
import {OtherSolutions} from "../globalComponents/OtherSolutions";
import {connect} from "react-redux";

const ScontrolContainer = props => {
    const [t] = useTranslation('services');
    const [product, setProduct] = useState();
    const title = t('scontrol.title');

    useEffect(() => {
        getProductBySlug('112-personalnii-treker-scontrol').then(product => saveProduct(product));
    }, []);

    const saveProduct = product => {
        const productToSet = {
            ...product,
            images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
        };

        setProduct(productToSet);
    }

    return (
        <main className="scontrol">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={t('scontrol.subtitle')}
            />

            <div className="scontrol__top-wrap">
                <ScontrolHeader />

                <ScontrolAdvantages />
            </div>

            <ScontrolCarScroll />
            <ScontrolParallax />
            <ScontrolCompare />
            <ScontrolBuy product={product} />
            
            <section className="other-solutions">
                <div className="container">
                    <OtherSolutions
                        solutions={props.industrySolutions}
                        solutionsCategory={'industry'}
                        title={t('scontrol.industrySolutionsTitle')}
                    />
                </div>
            </section>
        </main>
    );
};

const mapStateToProps = state => {
    return {
        industrySolutions: state.solutionsState.industrySolutions,
    };
};

export const Scontrol = connect(mapStateToProps)(ScontrolContainer);
