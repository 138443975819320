import React, {useState, useEffect} from 'react';

import './UserAccount.scss';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useLocalizedPath from '../../customHooks/useLocalizedPath';
import {getUserInformation} from '../../redux/actionCreators/getActionCreators/userAccountActions';
import {toggleUserLogin} from '../../redux/actionCreators/userActions';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

import {UserAccountPersonal} from "./UserAccountPersonal";
import {UserAccountOrderHistory} from "./UserAccountOrderHistory/UserAccountOrderHistory";
import {UserAccountDocuments} from "./UserAccountDocuments";
import {UserAccountHeader} from "./UserAccountHeader";
import {getCurrentUser} from '../../API/requests';
import {useTranslation} from "react-i18next";

const UserAccountContainer = props => {
    const [t] = useTranslation('account');
    const localizedMainPath = useLocalizedPath('');

    const routerHistory = useHistory();
    const [activeTab, setActiveTab] = useState('personal');
    const changeActiveTab = tabType => {
        setActiveTab(tabType);
    };

    useEffect(() => {
        getCurrentUser().then(user => {
            if(user) {
                props.getUserInformation(user);
            } else {
                routerHistory.push(localizedMainPath);
                localStorage.removeItem('token');
                props.toggleUserLogin(false);
            }
        });
    }, []);


    return (
        <div className="container">
            <MetaTagsComponent
                title={`${props.user.name} ${props.user.last_name}`}
                setTitle={t('personal.personalDataTitle')}
            />

            <div className="user-account">
                <UserAccountHeader
                    activeTab={activeTab}
                    changeActiveTab={changeActiveTab}
                />

                <div className="user-account__body">
                    <UserAccountPersonal isActive={activeTab === 'personal'}/>
                    <UserAccountOrderHistory isActive={activeTab === 'orderHistory'}/>
                    <UserAccountDocuments isActive={activeTab === 'documents'}/>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        isLogged: store.userAccountState.isLogged,
        user: store.userAccountState.user,
    };
};

const mapDispatchToProps = {
    getUserInformation,
    toggleUserLogin,
};

export const UserAccount = connect(mapStateToProps, mapDispatchToProps)(UserAccountContainer);
