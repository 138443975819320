import React from 'react';
import {useTranslation} from 'react-i18next';

import closeButton from '../../../images/close-btn.svg';
import {connect} from "react-redux";
import {toggleModal, toggleSubscribe} from "../../../redux/actionCreators/modalActions";

const SubscribeContainer = props => {
    const [t] = useTranslation('common');

    const closeModals = () => {
        props.toggleSubscribe(false);
        props.toggleModal(false);
    };

    return (
        <div className={`alert-wrap alert-subs ${props.subscribe ? 'active' : ''}`}>
            <div className="modal__close" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>

            <div className="alert">
                <h5 className="alert__title">{t('subscribeModal.title')}</h5>
                <p className="alert__subtitle">
                    {t('subscribeModal.subtitle')}
                </p>
                <button className="alert__confirm-btn" onClick={closeModals}>
                    {t('submitButton')}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        subscribe: state.modalsState.subscribe,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleSubscribe,
};

export const Subscribe = connect(mapStateToProps, mapDispatchToProps)(SubscribeContainer);
