import React from 'react';

export const SliderPrevArrow = props => {
    return (
        <button onClick={props.onClick} className="slider__button-sec slider__prev-sec" aria-label="previous slide">
            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.82843 16L17.4142 3.41421C18.1953 2.63317 18.1953 1.36684 17.4142 0.585786C16.6332 -0.195262 15.3668 -0.195262 14.5858 0.585786L0.585787 14.5858C-0.195261 15.3668 -0.195261 16.6332 0.585787 17.4142L14.5858 31.4142C15.3668 32.1953 16.6332 32.1953 17.4142 31.4142C18.1953 30.6332 18.1953 29.3668 17.4142 28.5858L4.82843 16Z"
                    fill="#1A3B70"/>
            </svg>
        </button>
    );
};
