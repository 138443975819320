import React from 'react';
import {useTranslation} from 'react-i18next';

export const CasesArticleClientDetails = props => {
    const [t] = useTranslation('cases');

    return (
        <div className="cases-article__bottom-section">
            <div className="cases-article__advantages-box">
                {
                    props.benefits.map(benefit => (
                        <div key={benefit.title} className="cases-article__advantage">
                            <h5 className="cases-article__advantage-value">{benefit.value}</h5>
                            <h5 className="cases-article__advantage-description">{benefit.title}</h5>
                        </div>
                    ))
                }
                {/*<button className="cases-article__solution-btn btn-primary">Заказать решение</button>*/}
            </div>

            <div className="cases-article__review-box">
                <h3 className="cases-article__title">{t('feedback')}</h3>
                <article className="cases-article__review">
                    <div className="cases-article__review-text" dangerouslySetInnerHTML={{__html: props.feedback}}/>
                </article>
            </div>
        </div>
    );
};
