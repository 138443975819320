import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {setServiceForOrder} from '../../redux/actionCreators/globalActions';
import {toggleModal, toggleServiceOrder} from '../../redux/actionCreators/modalActions';

const OrderServiceButtonContainer = props => {
    const [t] = useTranslation('common');

    const openServiceOrderModal = () => {
        props.setServiceForOrder(props.service);
        props.toggleServiceOrder(true);
        props.toggleModal(true);
    };

    return (
        <button
            className="btn-accent"
            onClick={openServiceOrderModal}
        >
            {props.text ? props.text : t('orderServiceButton')}
        </button>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleServiceOrder,
    setServiceForOrder,
};

export const OrderServiceButton = connect(null, mapDispatchToProps)(OrderServiceButtonContainer);
