import React, {useEffect, useState} from 'react';

import './PersonalService.scss';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {_image} from '../../API/apiConsts';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PersonalServiceHeader} from "./PersonalServiceHeader";
import {PersonalServiceDescription} from "./PersonalServiceDescription";
import {ProductSlider} from "../globalComponents/ProductSlider";
import {CapabilitiesSlider} from "../globalComponents/CapabilitiesSlider";
import {PersonalServiceDescriptionSecondary} from "./PersonalServiceDescriptionSecondary";

import capabilitiesBg from '../../images/personal/capabilities-bg.webp';
import capabilityIco1 from '../../images/personal/capabilities-ico1.svg';
import capabilityIco2 from '../../images/personal/capabilities-ico2.svg';
import capabilityIco3 from '../../images/personal/capabilities-ico3.svg';
import capabilityIco4 from '../../images/personal/capabilities-ico4.svg';

import {getCatalogInformation, getStaticService} from '../../API/requests';

const PersonalServiceContainer = props => {
    const [t] = useTranslation('services');
    const [service, setService] = useState({});
    const [products, setProducts] = useState([]);

    const capabilities = [
        {
            description: t('personal.capabilities.routes'),
            icon: capabilityIco1,
        },
        {
            description: t('personal.capabilities.alert'),
            icon: capabilityIco2,
        },
        {
            description: t('personal.capabilities.geoZones'),
            icon: capabilityIco3,
        },
        {
            description: t('personal.capabilities.additionalInformation'),
            icon: capabilityIco4,
        },
    ];

    useEffect(() => {
        getCatalogInformation('personalni-trekeri-5').then(data => parseProducts(data.products.data))
    }, []);

    useEffect(() => {
        getStaticService(3).then(service => parseService(service.data, props.language));
    }, [props.language]);

    const parseService = (service, language) => {
        const parsedService = {
            ...service,
            title: service[`title_${language}`] || service['title_uk'],
            subtitle: service[`subtitle_${language}`] || service['subtitle_uk'],
            description_first: service[`description_first_${language}`] || service['description_first_uk'],
            description_second: service[`description_second_${language}`] || service['description_second_uk'],
            description_third: service[`description_third_${language}`] || service['description_first_uk'],
        };

        setService(parsedService);
    };

    const parseProducts = products => {
        setProducts(products.map(product => ({
            ...product,
            images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
        })));
    };

    return (
        <main className="personal">
            <MetaTagsComponent
                title={service.title}
                seoTitle={service.title}
                seoDescription={service.subtitle}
            />

            <PersonalServiceHeader service={service} />

            <PersonalServiceDescription service={service} />

            <CapabilitiesSlider
                title={t('personal.capabilitiesSliderTitle')}
                backgroundImage={capabilitiesBg}
                capabilities={capabilities}
            />


            <PersonalServiceDescriptionSecondary service={service}/>

            {
                (products.length > 0) && (
                    <div className="product-slider-wrap">
                        <div className="container">
                            <h3 className="product-slider-title">{t('personal.equipmentSliderTitle')}</h3>
                            <ProductSlider products={products}/>
                        </div>
                    </div>
                )
            }
        </main>
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
    };
};

export const PersonalService = connect(mapStateToProps)(PersonalServiceContainer);