import React from 'react';

import {ProductItem} from "./ProductItem";
import {connect} from "react-redux";

const CatalogBodyContainer = props => {
    return (
        <div className="catalog__body">
            {
                props.products.map(product => (
                    <ProductItem
                        key={product.id}
                        product={product}
                        products={props.products}
                        isInCompare={props.productsCompare.some(item => item.id === product.id)}
                        category={props.category}
                    />
                ))
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        productsCompare: state.compareState.products,
    };
};

export const CatalogBody = connect(mapStateToProps)(CatalogBodyContainer);
