import React from 'react';
import {useTranslation} from 'react-i18next';

export const CartDeletedProduct = props => {
    const [t] = useTranslation('cart');

    return (
        <div className="basket-page-product">
            <div className="basket-page-product__deleted">
                <div className="basket-page-product__deleted-title">{t('productDeleted')}</div>
                <button className="basket-page-product__cancel-btn" onClick={() => {
                    props.setActiveRemove(false)
                    props.removeDelete()
                }}>{t('cancelButton')}</button>
            </div>
        </div>
    );
};
