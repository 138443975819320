import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import checkmark from '../../../images/checkmark.svg';
import {applyCategoryFilter} from '../../../redux/actionCreators/catalogActions';

const CatalogFilterCategoryContainer = props => {
    const [selectedCategories, setSelectedCategories] = useState({});

    useEffect(() => {
        if(Object.keys(selectedCategories).length) {
            props.applyCategoryFilter(selectedCategories);
        }
    }, [selectedCategories]);

    const handleCategorySelectChange = event => {
        const categoryName = event.target.name;

        setSelectedCategories(prevState => {
            if(prevState[categoryName]) {
                return {
                    ...prevState,
                    [categoryName]: false,
                };
            } else {
                return {
                    ...prevState,
                    [categoryName]: true,
                };
            }
        });
    };

    return (
        <div className="filter__checkbox-section filter__container">
            <div className="filter__title">Категория</div>
            <form className="filter__form">
                {
                    Object.keys(props.categories).map(category => (
                        <label key={category} className="checkbox-wrap">
                            <input
                                type="checkbox"
                                checked={selectedCategories.category}
                                name={category}
                                onChange={handleCategorySelectChange}
                            />
                            <span className="checkbox">
                                <img src={checkmark} alt="checkmark" />
                                <span>{props.categories[category]}</span>
                            </span>
                        </label>
                    ))
                }
            </form>
        </div>
    );
};

const mapDispatchToProps = {
    applyCategoryFilter,
};

export const CatalogFilterCategory = connect(null, mapDispatchToProps)(CatalogFilterCategoryContainer);
