import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {sendPasswordRecoveryEmail} from '../../../API/requests';

import closeButton from '../../../images/close-btn.svg';
import {connect} from "react-redux";
import {toggleModal, togglePasswordRecovery} from "../../../redux/actionCreators/modalActions";
import {Input} from '../Input/Input';

const PasswordRecoveryModalContainer = props => {
    const [t] = useTranslation('common');
    const form = t('form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;

    const [email, setEmail] = useState({
        value: '',
        error: false,
        wasTouched: false,
        errorMessage,
    });

    const handleEmailChange = event => {
        setEmail(prevState => ({
            ...prevState,
            value: event.target.value,
            error: false,
        }));
    };

    const validateEmailField = value => {
        setEmail(prevState => ({
            ...prevState,
            wasTouched: true,
        }));

        if(!value.trim()) {
            setEmail(prevState => ({
                ...prevState,
                error: true,
                errorMessage,
            }));

            return false;
        }

        return true;
    };

    const handleSubmit = event => {
        event.preventDefault();

        let isFormValid = true;

        if(!validateEmailField(email.value)) {
            isFormValid = false;
        }

        if(isFormValid) {
            sendRecoveryRequest();
        }
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.togglePasswordRecovery(false);
    };

    const sendRecoveryRequest = () => {
        sendPasswordRecoveryEmail(email.value).then(result => {
            if(result) {
                closeModals();
            } else {
                setEmail(prevState => ({
                    ...prevState,
                    error: true,
                    errorMessage: 'Error',
                }));
            }
        });
    };

    // const applicationSuccessModal = () => {
    //     props.togglePasswordRecovery(false);
    // }

    return (
        <div className={`password-recovery-modal ${props.passwordRecovery ? 'active' : ''}`}>
            <div className="close-btn" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>

            <form className="password-recovery-modal__body" onSubmit={handleSubmit}>
                <h5 className="password-recovery-modal__title">{t('passwordRecoveryModal.title')}</h5>
                <p className="password-recovery-modal__subtitle">
                    {t('passwordRecoveryModal.subtitle')}
                </p>

                <label className="form__label">
                    <Input
                        className={`form__input ${email.error ? 'invalid' : ''} ${!email.error && email.wasTouched ? 'valid' : ''}`}
                        type="email"
                        name="email"
                        placeholder=" "
                        required
                        value={email.value}
                        handleChange={event => {
                            handleEmailChange(event)
                            validateEmailField(event.target.value)
                        }}
                        handleBlur={event => validateEmailField(event.target.value)}
                        title={form.email}
                        isErrorOccurred={email.error}
                        errorMessage={email.errorMessage}
                    />
                </label>

                <button
                    className="password-recovery-modal__send-btn btn-additional"
                    type="submit"
                >
                    {form.sendButton}
                </button>
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        passwordRecovery: state.modalsState.passwordRecovery,
    };
};

const mapDispatchToProps = {
    toggleModal,
    togglePasswordRecovery,
};

export const PasswordRecoveryModal = connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryModalContainer);
