import React from 'react';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {SupportInformationAdditional} from './SupportInformationAdditional';
import {SupportMain} from "./SupportMain";
import {SupportInformation} from "./SupportInformation";
import {SupportQuestions} from "./SupportQuestions";

import './TechnicalSupport.scss';

export const TechnicalSupport = props => {
    const [t] = useTranslation('support');
    const title = t('title');

    return (
        <div className="container">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={t('main.title')}
            />

            <main className="support">
                <h1 className="support__main-title">{title}</h1>
                <SupportMain />
                <SupportInformation />
                <SupportInformationAdditional />
                <SupportQuestions />
            </main>
        </div>
    );
};
