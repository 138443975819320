import React from 'react';
import {useTranslation} from 'react-i18next';
import {addProductToBasket} from "../../redux/actionCreators/basketActions";
import {toggleBasket, toggleModal} from "../../redux/actionCreators/modalActions";
import {connect} from "react-redux";

const AddToBasketButtonContainer = props => {
    const [t] = useTranslation('common');

    const addProductToBasket = product => {
        props.addProductToBasket({
            ...product,
            quantity: 1,
        });
        props.toggleModal(true);
        props.toggleBasket(true);
    };

    return (
        <button className="btn-accent" onClick={() => addProductToBasket(props.product)}>
            {t('addToBasketButton')}
        </button>
    );
};

const mapDispatchToProps = {
    addProductToBasket,
    toggleModal,
    toggleBasket,
};

export const AddToBasketButton = connect(null, mapDispatchToProps)(AddToBasketButtonContainer);
