import React from 'react';

import './Gsm.scss';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {GsmHeader} from "./GsmHeader";
import {GsmDescription} from "./GsmDescription";

import capabilitiesBg from '../../images/gsm/capababilities-bg.webp';
import {GsmMethods} from "./GsmMethods";
import {ProductSlider} from "../globalComponents/ProductSlider";
import {CapabilitiesSlider} from "../globalComponents/CapabilitiesSlider";
import capabilityIco1 from "../../images/gsm/capabilities/fuel-1.svg";
import capabilityIco2 from "../../images/gsm/capabilities/maps-and-location.svg";
import capabilityIco3 from "../../images/gsm/capabilities/presentation.svg";
import capabilityIco4 from "../../images/gsm/capabilities/graph-fuel.svg";
import capabilityIco5 from "../../images/gsm/capabilities/fuel-2.svg";
import capabilityIco6 from "../../images/gsm/capabilities/graph.svg";


const products = [];

export const Gsm = props => {
    const [t] = useTranslation('services');
    const title = t('gsm.title');
    const capabilitiesTranslation = t('gsm.capabilities', {returnObjects: true});
    const capabilities = [
        {
            description: capabilitiesTranslation.fuelLower,
            icon: capabilityIco1,
        },
        {
            description: capabilitiesTranslation.location,
            icon: capabilityIco2,
        },
        {
            description: capabilitiesTranslation.fuelHack,
            icon: capabilityIco3,
        },
        {
            description: capabilitiesTranslation.discipline,
            icon: capabilityIco4,
        },
        {
            description: capabilitiesTranslation.infoReal,
            icon: capabilityIco5,
        },
        {
            description: capabilitiesTranslation.reports,
            icon: capabilityIco6,
        },
    ];

    return (
        <main className="gsm">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={t('gsm.subtitle')}
            />

            <GsmHeader />

            <GsmDescription />

            <CapabilitiesSlider
                capabilities={capabilities}
                backgroundImage={capabilitiesBg}
                title={t('gsm.capabilitiesSliderTitle')}
            />

            <GsmMethods />

            {
                (products.length > 0) && (
                    <div className="product-slider-wrap">
                        <div className="container">
                            <h3 className="product-slider-title">{t('gsm.equipmentSliderTitle')}</h3>
                            <ProductSlider />
                        </div>
                    </div>
                )
            }

        </main>
    );
};
