import ReactGA from 'react-ga';

const trackingId = 'UA-19328898-3';

const initializeGoogleAnalytics = () => {
    ReactGA.initialize(trackingId, {debug: false});
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export default initializeGoogleAnalytics;
 
