export const createOrder = (orderInfo) => {
    return window.axios.post('', {
        params: {
            query: `
                mutation (
                    $external_order_id: String!
                    $delivery_method: Int!
                    $payment_method: Int!
                    $back_call: Boolean!
                    $delivery_price: Float
                    $installation: InstallationInput
                    $comment: String
                    $order_items: [OrderItemInput!]!
                    $bill_to: BillToInput!
                    $ship_to: ShipToInput
                    ${orderInfo.payment_method === 1 ? '$payment_payload: PaymentPayloadInput!' : ''}
                ) {
                    storeOrder(
                        external_order_id: $external_order_id
                        delivery_method: $delivery_method
                        payment_method: $payment_method
                        back_call: $back_call
                        delivery_price: $delivery_price
                        installation: $installation
                        comment: $comment
                        order_items: $order_items
                        bill_to: $bill_to
                        ship_to: $ship_to
                        ${orderInfo.payment_method === 1 ? 'payment_payload: $payment_payload' : ''}
                    ) {
                        external_order_id
                        id
                    }
                }
            `,
            variables: {
                ...orderInfo,
            }
        }
    })
        // .then(response => console.log(response))
        .catch(error => console.log(error))
};
