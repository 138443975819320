import React from 'react';
import MetaTags from 'react-meta-tags';

export const MetaTagsComponent = props => {
    return (
        <MetaTags>
            <title>{`Total Control - ${props.title}`}</title>
            <meta name="title" content={`${props.seoTitle}`} />
            <meta name="description" content={`${props.seoDescription}`} />
        </MetaTags>
    );
};
