import React from 'react';

import {connect} from "react-redux";
import LocalizedLink from '../../globalComponents/LocalizedLink';

export const SliderContentContainer = props => {

    return (
        <div className="main-slider__slide-wrap">
            <div className="main-slider__bg">
                <div className="main-slider__bg-gradient" />
                <img alt={props.title} className="main-slider__slide-bg" src={props.bg} />
            </div>

            <div className="main-slider__slide">
                <div className="main-slider__slide-content-wrap">
                    <div className="main-slider__slide-content">
                        {
                            (props.brand) && (
                                <div className="main-slider__brand-label">
                                    <img src={props.brand} alt="brand" />
                                </div>
                            )
                        }
                        <h2 className="main-slider__slide-title">{props.title}</h2>
                        <h3 className="main-slider__slide-subtitle" dangerouslySetInnerHTML={{__html: props.subtitle}} />
                        {props.vatTitle && <p className="main-slider__slide-description">{props.vatTitle}</p>}
                        <p className="main-slider__slide-description">{props.description}</p>
                        <LocalizedLink to={props.link} className="btn-accent">
                            {props.buttonTextTranslation}
                        </LocalizedLink>
                    </div>

                    {
                        (props.image) && (
                            <div className="main-slider__product-image-wrap">
                                {
                                    props.labels.map((label, index) => (
                                        <div
                                            key={index}
                                            className={`main-slider__label main-slider__label--${label.type} main-slider__label--${label.size}`}>
                                            <p className="main-slider__label-title">{label.title}</p>
                                            <p className="main-slider__label-price">{label.price}</p>
                                            <p className="main-slider__label-currency">{label.currency}</p>
                                        </div>
                                    ))
                                }

                                <img className="main-slider__product-image" src={props.image} alt={props.title} />
                            </div>
                        )
                    }
                </div>



                {
                    (props.companies.length > 1) && (
                        <div className="main-slider__bottom-box">
                            {
                                props.companies.map((company, index) => (
                                    <LocalizedLink to={`/catalog/brand/${company.name}`} key={index} className="main-slider__brand-logo-container">
                                        <div className="main-slider__brand-logo-wrap">
                                            <img alt={company.name} src={company.image} />
                                        </div>
                                    </LocalizedLink>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        companies: state.mainPageState.companies,
    };
};

export const SliderContent = connect(mapStateToProps)(SliderContentContainer);