import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import closeButton from '../../../images/close-btn.svg';
import {
    toggleModal,
    togglePasswordRecovery,
    togglePasswordResetError,
} from '../../../redux/actionCreators/modalActions';

const PasswordResetErrorModalContainer = props => {
    const [t] = useTranslation('common');

    const closeModals = () => {
        props.togglePasswordResetError(false);
        props.toggleModal(false);
    };

    const openPasswordRecoveryModal = () => {
        props.togglePasswordResetError(false);
        props.togglePasswordRecovery(true);
    };

    return (
        <div className={`alert-wrap alert-subs ${props.passwordResetError ? 'active' : ''}`}>
            <div className="modal__close" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>

            <div className="alert">
                <h5 className="alert__title">{t('resetPasswordModal.title')}</h5>
                <p className="alert__subtitle">
                    {t('resetPasswordModal.subtitle')}
                </p>

                <button className="alert__confirm-btn" onClick={openPasswordRecoveryModal}>
                    {t('resetPasswordModal.buttonText')}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        passwordResetError: store.modalsState.passwordResetError,
    };
};

const mapDispatchToProps = {
    toggleModal,
    togglePasswordResetError,
    togglePasswordRecovery,
};

export const PasswordResetErrorModal = connect(mapStateToProps, mapDispatchToProps)(PasswordResetErrorModalContainer);
