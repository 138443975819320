import {_image} from '../../API/apiConsts';
import {GET_USER_INFO, TOGGLE_USER_LOGIN} from '../actionConsts/actionConsts';

const initialAccountState = {
    isLogged: false,
    user: {
        id: 1,
        is_organization: false,
        bonus_points: 0,
        name: '',
        fathers_name: '',
        last_name: '',
        phone: '',
        email: '',
        orders: [
            {
                external_order_id: 0,
                created_date: new Date(),
                delivery_method: 0,
                delivery_method_human: '',
                payment_method: 0,
                payment_method_human: '',
                ship_to: [],
                installation: [],
                delivery_price: 0,
                products_amount: 0,
                total_amount: 0,
                order_items: [
                    {
                        quantity: 0,
                        total_price: 0,
                        product: {
                            id: 0,
                            name: '',
                            short_description: '',
                            price: 0,
                            discount: 0,
                            bonus_points: 0,
                            images: [],

                        }

                    },
                ],
            },
        ],
        documents: {
            format: [
                'Договор о присоединениии',
                'Заявление о присоединении',
            ],
            available: [
                'Прайс-лист на оборудование',
            ],
        },
    },
};

const userAccountReducer = (state = initialAccountState, action) => {
    switch (action.type) {
        case GET_USER_INFO: {
            const user = action.payload;

            const newUser = {
                ...user,
                orders: user.orders ? user.orders.map(order => ({
                    ...order,
                    order_items: order.order_items ? order.order_items.map(order_item => ({
                        ...order_item,
                        product: {
                            ...order_item.product,
                            images: order_item.product.images ? order_item.product.images.map(image => `${_image}/${image}`) : [],
                        }
                    })) : [],
                })) : [],
            };

            return {
                ...state,
                user: newUser,
            }
        }

        case TOGGLE_USER_LOGIN: {
            return {
                ...state,
                isLogged: action.payload,
            }
        }
        default:
            return state;
    }
};

export default userAccountReducer;
