import React from 'react';
import {useTranslation} from 'react-i18next';
// import bonusIco from '../../../images/bonus-ico.svg';
import {UserAccountExit} from '../UserAccountExit';

export const UserAccountBonus = props => {
    // const [t] = useTranslation('account');

    return (
        <div className="user-account__bonus-section">
            {/*<h3 className="user-account__personal-title">{t('personal.bonusesTitle')}</h3>*/}
            {/*<div className="user-account__bonus">*/}
            {/*    <div className="user-account__bonus-body">*/}
            {/*        <div className="user-account__bonus-ico">*/}
            {/*            <img src={bonusIco} alt="Бонус" />*/}
            {/*        </div>*/}

            {/*        <div className="user-account__bonus-info">*/}
            {/*            <div className="user-account__bonus-title">{t('personal.bonusesSubtitle')}</div>*/}
            {/*            <div className="user-account__bonus-text">*/}
            {/*                <span className="user-account__bonus-value">{props.bonusPoint.toLocaleString()}</span>*/}
            {/*                {t('personal.bonusesText')}*/}
            {/*            </div>*/}
            {/*            <a className="user-account__bonus-link" href="/">{t('personal.bonusesUseText')}</a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <UserAccountExit/>
        </div>
    );
};
