import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {userRegistration} from '../../../../API/requests';
import {getUserInformation} from '../../../../redux/actionCreators/getActionCreators/userAccountActions';
import {toggleUserLogin} from '../../../../redux/actionCreators/userActions';
import {Input} from '../../Input/Input';
import {createContact} from "../../../../API/birixApi/createContact";
import {createCompany} from "../../../../API/birixApi/createCompany";

const EntryModalRegistrationContainer = props => {
    const [t] = useTranslation('common');
    const form = t('form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;
    
    const formFields = [
        {
            name: "orgName",
            title: form.orgName,
            type: 'text',
        },
        {
            name: 'name',
            title: form.name,
            type: 'text',
        },
        {
            name: 'lastName',
            title: form.lastName,
            type: 'text',
        },
        {
            name: 'phone',
            title: form.phone,
            type: 'tel',
        },
        {
            name: 'email',
            title: form.email,
            type: 'email',
        },
        {
            name: 'password',
            title: form.password,
            type: 'password',
        },
        {
            name: 'password_confirmation',
            title: form.passwordConfirmation,
            type: 'password',
        },
    ];

    const [isOrganization, setIsOrganization] = useState(false);
    const [registrationFormValues, setRegistrationFormValues] = useState({
        orgName: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        name: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        lastName: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        phone: {
            value: '+380',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        email: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        password: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        password_confirmation: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
    });

    const registrationRequest = async () => {
        let userInfo = {
            orgName: registrationFormValues.orgName.value,
            name: registrationFormValues.name.value,
            lastName: registrationFormValues.lastName.value,
            phone: registrationFormValues.phone.value,
            email: registrationFormValues.email.value,
            password: registrationFormValues.password.value,
            passwordConfirmation: registrationFormValues.password_confirmation.value,
        };

        let companyId;
        let userId;

        if(isOrganization) {
             companyId = await createCompany(userInfo, props.googleId, props.utmParams);
             userId = await createContact(userInfo, companyId, props.googleId, props.utmParams);
        } else {
            userId = await createContact(userInfo, null, props.googleId, props.utmParams);
        }

        userInfo = {
            ...userInfo,
            companyId,
            userId,
        };

        userRegistration(userInfo, isOrganization)
            .then(data => {
                if(data ? data.data.register : false) {
                    props.openRegistrationSuccessModal();
                }

                if(data.errors) {
                    const validationErrors = window._.head(data.errors).extensions.validation;
                    if(validationErrors) {
                        Object.keys(validationErrors).forEach(field => {

                            switch (window._.head(validationErrors[field])) {
                                case 'validation.unique' : {
                                    setRegistrationFormValues(prevState => ({
                                        ...prevState,
                                        [field]: {
                                            ...prevState[field],
                                            error: true,
                                            errorMessage: form.userAlreadyExist,
                                        }
                                    }));
                                    break;
                                }
                                case 'validation.min.string': {
                                    setRegistrationFormValues(prevState => ({
                                        ...prevState,
                                        [field]: {
                                            ...prevState[field],
                                            error: true,
                                            errorMessage: form.minWidth,
                                        }
                                    }));
                                    break;
                                }
                                case 'validation.confirmed' : {
                                    setRegistrationFormValues(prevState => ({
                                        ...prevState,
                                        [field]: {
                                            ...prevState[field],
                                            error: true,
                                            errorMessage: form.passwordNotEqual,
                                        }
                                    }));
                                    break;
                                }
                                case 'validation.phone': {
                                    setRegistrationFormValues(prevState => ({
                                        ...prevState,
                                        phone: {
                                            ...prevState.phone,
                                            error: true,
                                            errorMessage: form.notValidFormat,
                                        }
                                    }));
                                }
                            }
                        });
                    }
                }
            });
    };

    const handleRegistrationFormChange = event => {
        const {name, value} = event.target;

        setRegistrationFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
                error: false,
            },
        }));
    };

    const handleRegistrationSubmit = event => {
        event.preventDefault();

        let isFormValid = true;
        Object.keys(registrationFormValues).forEach(field => {
            if(!validateRegistrationField(field, registrationFormValues[field].value)) {

                isFormValid = field === 'orgName' && !isOrganization;
            }
        });

        if(isFormValid) {
            registrationRequest();
        }
    };

    const validateRegistrationField = (name, value) => {
        setRegistrationFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                wasTouched: true,
            },
        }));

        if(!value.trim()) {
            setRegistrationFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    error: true,
                    errorMessage,
                },
            }));

            return false;
        }

        if(name === 'phone') {
            const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

            if(!regEx.test(value)) {
                setRegistrationFormValues(prevState => ({
                    ...prevState,
                    phone: {
                        ...prevState.phone,
                        error: true,
                        errorMessage: form.notValidFormat,
                    }
                }));

                return false;
            }
        }

        return true;
    };

    return (
        <div className="enter-modal__body enter-modal__body--registration">
            <div className="switcher-checkbox-wrap">
                <label className="switcher-checkbox">
                    <input
                        className="switcher-checkbox-hidden"
                        type="checkbox"
                        name="individual"
                        checked={isOrganization}
                        onChange={() => setIsOrganization(prevState => !prevState)}
                    />
                    <div className="switcher-checkbox__body">
                        <div className="switcher-checkbox__button" />
                    </div>
                    <div className="switcher-checkbox__label">{isOrganization ? form.entity : form.individual}</div>
                </label>
            </div>

            <form className="enter-modal__form" onSubmit={handleRegistrationSubmit}>
                {
                    formFields.map(field => (
                        (field.name === 'orgName' && !isOrganization)
                            ? (
                                <React.Fragment key={field.name} />
                            )
                            : (
                                <Input
                                    key={field.name}
                                    className={`form__input ${registrationFormValues[field.name].error ? 'invalid' : ''} ${!registrationFormValues[field.name].error && registrationFormValues[field.name].wasTouched ? 'valid' : ''}`}
                                    type={field.type}
                                    name={field.name}
                                    placeholder=""
                                    required
                                    value={registrationFormValues[field.name].value}
                                    handleChange={(event) => {
                                        handleRegistrationFormChange(event);
                                        validateRegistrationField(event.target.name, event.target.value);
                                    }}
                                    handleBlur={event =>validateRegistrationField(event.target.name, event.target.value)}
                                    title={field.title}
                                    isErrorOccurred={registrationFormValues[field.name].error && registrationFormValues[field.name].wasTouched}
                                    errorMessage={registrationFormValues[field.name].errorMessage}
                                />
                            )
                    ))
                }

                <div className="enter-modal__footer">
                    <button className="enter-modal__footer-btn enter-modal__footer-btn--reg btn-secondary" type="submit">
                        {form.registerButton}
                    </button>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        googleId: store.globalReducer.googleId,
        utmParams: store.globalReducer.utmParams,
    };
};

const mapDispatchToProps = {
    getUserInformation,
    toggleUserLogin,
};

export const EntryModalRegistration = connect(mapStateToProps, mapDispatchToProps)(EntryModalRegistrationContainer);
