import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SupportQuestion} from "./SupportQuestion";



export const SupportQuestions = props => {
    const [t] = useTranslation('support');
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
    const questions = t('questions.list', {returnObjects: true});

    const changeActiveQuestion = indexOfQuestion => {
        setActiveQuestionIndex(prevState => {
            if(prevState === indexOfQuestion) {
                return null;
            }

            return indexOfQuestion;
        });
    };

    return (
        <div className="support__tabs-wrap">
            <h2 className="support__section-title">{t('questions.title')}</h2>
            <div className="support__tabs">
                {
                    questions.map((question, index) => (
                        <SupportQuestion
                            key={index}
                            question={question}
                            changeActiveQuestion={changeActiveQuestion}
                            isActive={index === activeQuestionIndex}
                            questionIndex={index}
                        />
                    ))
                }
            </div>
        </div>
    );
};
