export const searchProducts = term => {
    return window.axios.get('', {
        params: {
            query: `
                query($term: String!, $limit: Int) {
                    search(term: $term, limit: $limit) {
                        id
                        name
                        slug
                        final_price
                    }
                }
            `,
            variables: {
                term,
                limit: 5
            },
        },
    }).then(response => response.data.data ? response.data.data.search : [])
        .catch(error => console.log(error));
};
