import React from 'react';
import {useTranslation} from 'react-i18next';

// import articleImg from '../../images/about-us/company.webp';
import advantagesImg from '../../images/about-us/advantages-bg.webp';

export const AboutCompanyDescription = props => {
    const [t] = useTranslation('aboutUs');
    const descriptionParagraphs = t('articles', {returnObjects: true});

    return (
        <>
            <div className="container">
            <article className="about-us__article">
                {/* <div className="about-us__article-img">
                    <img src={articleImg} alt="company" />
                </div> */}
                {
                    descriptionParagraphs.map((text, index) => (
                        <p key={index} className="about-us__article-p">
                            {text}
                        </p>
                    ))
                }
            </article>
        </div>

            <section className="about-us-adv">
                <div className="about-us-adv__bg">
                    <img src={advantagesImg} alt="advantages" />
                </div>

                <div className="about-us-adv__body container">
                    <div className="about-us-adv__item">
                        <div className="about-us-adv__title">10+</div>
                        <p className="about-us-adv__subtitle">{t('advantages.years')}</p>
                    </div>

                    <div className="about-us-adv__item">
                        <div className="about-us-adv__title">10000+</div>
                        <p className="about-us-adv__subtitle">{t('advantages.units')}</p>
                    </div>

                    <div className="about-us-adv__item">
                        <div className="about-us-adv__title">1000+</div>
                        <p className="about-us-adv__subtitle">{t('advantages.clients')}</p>
                    </div>
                </div>
            </section>
        </>
    );
};
