import React, {useState, useEffect} from 'react';

import './AdditionalServices.scss';
import {useTranslation} from 'react-i18next';
import {_image} from '../../API/apiConsts';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {AdditionalServicesHeader} from "./AdditionalServicesHeader";
import {AdditionalServicesTab} from "./AdditionalServicesTab";

import {ProductSlider} from "../globalComponents/ProductSlider";
import {connect} from "react-redux";
import {OtherServices} from "../globalComponents/OtherServices";
import {getRecommendedProducts, getServices} from '../../API/requests';
import {getAdditionalServices} from "../../redux/actionCreators/getActionCreators/servicesActions";

const AdditionalServicesContainer = props => {
    const [t] = useTranslation('services');
    const [activeServiceTab, setActiveServiceTab] = useState(0);
    const [products, setProducts] = useState([]);

    const changeActiveServiceTab = serviceTabIndex => {
        setActiveServiceTab(prevState => {
            if(serviceTabIndex === prevState) return -1
            return serviceTabIndex;
        });
    };

    useEffect(() => {
        saveAdditionalServices();
        saveRecommendedProducts();
    }, []);

    const saveAdditionalServices = () => {
        getServices().then(services => props.getAdditionalServices(services));
    };

    const saveRecommendedProducts = () => {
        getRecommendedProducts().then(products => {
            const productsToSet = products.map(product => ({
                ...product,
                images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
            }));

            setProducts(productsToSet);
        });
    };

    return (
        <main className="services">
            <MetaTagsComponent
                title={t('otherServicesTitle')}
                seoTitle={t('otherServicesTitle')}
                seoDescription={`${props.additionalServices.reduce((acc, service, index) => {
                    if(acc.length > 160) return acc;
                    return index === 0 ? service.name : `${acc}, ${service.name}`
                }, '')}`}
            />

            <AdditionalServicesHeader title={t('otherServicesTitle')}/>

            <div className="container">
                <div className="services__body">
                    <section className="services__body-content">
                        {
                            props.additionalServices.map((service, index) => (
                                <AdditionalServicesTab
                                    key={service.id}
                                    isActive={index === activeServiceTab}
                                    tabIndex={index}
                                    changeActiveServiceTab={changeActiveServiceTab}
                                    service={service}
                                />
                            ))
                        }
                    </section>
                </div>

                <OtherServices />

                {
                    (products.length > 0) && (
                        <div className="product-slider-wrap container">
                            <h3 className="product-slider-title">Рекомендовані товари</h3>
                            <ProductSlider products={products} />
                        </div>
                    )
                }
            </div>
        </main>
    );
};

const mapStateToProps = state => {
    return {
        additionalServices: state.additionalServicesState.additionalServices,
    };
};

const mapDispatchToProps = {
    getAdditionalServices,
};

export const AdditionalServices = connect(mapStateToProps, mapDispatchToProps)(AdditionalServicesContainer);
