import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import Slider from 'react-slick';
import {EmptyPage} from '../globalComponents/EmptyPage/EmptyPage';
import LocalizedLink from '../globalComponents/LocalizedLink';
import {SliderPrevArrow} from "../globalComponents/SliderPrevArrow";
import {SliderNextArrow} from "../globalComponents/SliderNextArrow";

import {connect} from "react-redux";
import {getCases} from "../../API/requests";
import {getCasesArticles} from "../../redux/actionCreators/getActionCreators/casesActions";

const settings = {
    className: 'about-us-cases__slider',
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 3000,
    arrows: true,
    dots: false,
    waitForAnimate: true,
    // dotsClass: 'main-sl-dots',
    adaptiveHeight: true,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    responsive: [
        {
            breakpoint: 1585,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 1245,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 880,
            settings: {
                slidesToShow: 1,
                dots: true,
                waitForAnimate: true,
                arrows: false,
                dotsClass: 'main-sl-dots',
            }
        },
        {
            breakpoint: 748,
            settings: {
                slidesToShow: 1,
                dots: true,
                waitForAnimate: true,
                arrows: false,
                dotsClass: 'main-sl-dots',
            }
        },
    ]
};

const AboutCompanyCasesContainer = ({getCasesArticles, cases}) => {
    const [t] = useTranslation('aboutUs');

    useEffect(() => {
        getCases().then(cases => getCasesArticles(cases));
    }, []);

    return (
        <section className="about-us-cases">
            <div className="container-about-us">
                <div className="about-us-cases__header">
                    <h3 className="about-us-cases__title">{t('cases.title')}</h3>
                </div>

                <div className="about-us-cases__slider-wrap container">
                    {
                        cases.length > 0
                            ? (
                                <Slider {...settings}>
                                    {
                                        cases.map(caseItem  => (
                                            <div key={caseItem.id} className="cases__item-wrap">
                                                <div className="cases__item-container">
                                                    <div className="cases__item-bg">
                                                        <img src={caseItem.image} alt={caseItem.name}/>
                                                    </div>

                                                    <article className="cases__item">
                                                        <div className="cases__item-title">{caseItem.name}</div>
                                                        <div className="cases__item-subtitle" dangerouslySetInnerHTML={{__html: caseItem.short_description}}/>
                                                        <LocalizedLink to={`/cases/article/${caseItem.id}`} className="cases__item-details">
                                                            {t('cases.detailsLink')}
                                                            <svg width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M1 4C0.723858 4 0.5 4.22386 0.5 4.5C0.5 4.77614 0.723858 5 1 5V4ZM23.3536 4.85355C23.5488 4.65829 23.5488 4.34171 23.3536 4.14645L20.1716 0.964466C19.9763 0.769204 19.6597 0.769204 19.4645 0.964466C19.2692 1.15973 19.2692 1.47631 19.4645 1.67157L22.2929 4.5L19.4645 7.32843C19.2692 7.52369 19.2692 7.84027 19.4645 8.03553C19.6597 8.2308 19.9763 8.2308 20.1716 8.03553L23.3536 4.85355ZM1 5H23V4H1V5Z"
                                                                    fill="white" />
                                                            </svg>
                                                        </LocalizedLink>
                                                    </article>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            )
                            : (
                                <EmptyPage />
                            )
                    }
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        cases: state.casesState.cases,
    };
};

const mapDispatchToProps = {
    getCasesArticles,
};

export const AboutCompanyCases = connect(mapStateToProps, mapDispatchToProps)(AboutCompanyCasesContainer);
