import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import LocalizedLink from '../globalComponents/LocalizedLink';

const CartEmptyContainer = props => {
    const [t] = useTranslation('cart');

    return (
        <div className="basket-page__basket basket-empty">
            <h3 className="basket-empty__title">{t('emptyCart.title')}</h3>
            <p className="basket-empty__subtitle">
                {t('emptyCart.subtitle')}
            </p>

            <LocalizedLink
                to={`/catalog/${props.currentCategory.slug}`}
                className="basket-page__back-btn btn-ghost-secondary">
                {t('emptyCart.catalogButton')}
            </LocalizedLink>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentCategory: state.catalogState.categoryDetails,
    };
};

export const CartEmpty = connect(mapStateToProps)(CartEmptyContainer);
