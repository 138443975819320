import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import sectionBg from '../../images/scontrol/buy-section-bg.jpg';
import scontrolLogo from '../../images/scontrol/scontrol-logo.jpg';
import {addProductToBasket} from '../../redux/actionCreators/basketActions';
import {toggleBasket, toggleModal} from '../../redux/actionCreators/modalActions';

const ScontrolBuyContainer = props => {
    const [t] = useTranslation('services');

    const handleBuyButtonClick = () => {
        props.toggleModal(true);
        props.toggleBasket(true);
        props.addProductToBasket({
            ...props.product,
            quantity: 1,
        });
    };

    return (
        <section className="scontrol-buy-section">
            <div className="scontrol-buy-section__bg">
                <img src={sectionBg} alt="map" />
            </div>
            <div className="container">
                <div className="scontrol-buy-section__logo">
                    <img src={scontrolLogo} alt="SControl" />
                </div>
                <h3 className="scontrol-buy-section__title">{t('scontrol.uniqueSearchSystem')}</h3>
                <button className="scontrol-buy-section__buy-btn btn-accent" onClick={handleBuyButtonClick}>{t('scontrol.buyButton')}</button>
            </div>
        </section>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleBasket,
    addProductToBasket,
};

export const ScontrolBuy = connect(null, mapDispatchToProps)(ScontrolBuyContainer);
