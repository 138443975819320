import React from 'react';

export const IndustrySolutionDescription = props => {
    return (
        <section className="personal__description">
            <div className="personal__description-text-wrap">
                <div className="personal__description-text"
                    dangerouslySetInnerHTML={{__html: props.description}}
                />
            </div>
        </section>
    );
};
