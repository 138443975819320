import React from 'react';

export const PrevLabel = props => {
    return (
        <div className="label-arrow prev-label-arrow">
            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.51008 4L5.23574 6.82269C5.49581 7.09201 5.49581 7.52868 5.23574 7.79801C4.97567 8.06733 4.55402 8.06733 4.29395 7.79801L1.0974 4.48766C0.837327 4.21833 0.837327 3.78167 1.0974 3.51234L4.29395 0.201995C4.55402 -0.0673318 4.97567 -0.0673318 5.23574 0.201995C5.49581 0.471322 5.49581 0.907988 5.23574 1.17732L2.51008 4Z" fill="#1A3B70"/>
            </svg>
        </div>
    );
};
