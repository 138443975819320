import React from 'react';

import './Cart.scss';
import {useTranslation} from 'react-i18next';

import {connect} from "react-redux";
import {
    decreaseProductQuantity,
    deleteProductFromBasket,
    increaseProductQuantity, toggleProductDeleteFlag
} from "../../redux/actionCreators/basketActions";
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {CartEmpty} from "./CartEmpty";
import {CartFooter} from "./CartFooter";
import {CartHeader} from "./CartHeader";
import CartItem from "./CartItem";

const CartContainer = props => {
    const [t] = useTranslation('cart');

    return (
        <div className="container">
            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={props.basketItems.reduce((acc, product, index) => {
                    if(acc.length > 160) return acc;
                    return index === 0 ? product.name : `${acc}, ${product.name}`
                }, '')}
            />

            <div className="basket-page">
                <h1 className="basket-page__title">{t('title')}</h1>
                <div className="basket-page__basket">
                    {
                        props.basketItems.length > 0
                            ? (
                                <>
                                    <CartHeader />

                                    <div className="basket-page__body">
                                        {
                                            props.basketItems.map(data => {
                                                return (
                                                    <CartItem
                                                        data={data}
                                                        key={data.id}
                                                        basketItems={props.basketItems}
                                                        increaseProductQuantity={props.increaseProductQuantity}
                                                        decreaseProductQuantity={props.decreaseProductQuantity}
                                                        deleteProductFromBasket={props.deleteProductFromBasket}
                                                    />
                                                )
                                            })
                                        }
                                    </div>

                                    <CartFooter basketItems={props.basketItems}/>
                                </>
                            )
                            : (
                                <CartEmpty />
                            )
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        basketItems: state.basketState.basketItems,
    };
};

const mapDispatchToProps = {
    deleteProductFromBasket,
    increaseProductQuantity,
    decreaseProductQuantity,
    toggleProductDeleteFlag,
};

export const Cart = connect(mapStateToProps, mapDispatchToProps)(CartContainer);
