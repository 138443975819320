import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import LocalizedLink from '../../globalComponents/LocalizedLink';

const CheckoutOrderResultContainer = props => {
    const [t] = useTranslation('checkout');

    const deliveryTypes = {
        'delivery_pickup': {
            title: t('delivery.ways.pickUp.title'),
            cost: 0,
        },
        'delivery_new_post_office': {
            title: t('delivery.ways.post.title'),
            cost: 0,
        },
        'delivery_new_post_courier': {
            title: t('delivery.ways.courier.title'),
            cost: 0,
        },
        'delivery_address': {
            title: t('delivery.ways.address.title'),
            cost: 100,
        },
    };

    return (
        <div className="basket-container__result">
            {
                (props.delivery.deliveryType) && (
                    <div className="basket-container__result-item">
                        <div className="basket-container__left-container">
                            <div
                                className="basket-container__result-title">{deliveryTypes[props.delivery.deliveryType].title}</div>
                            {/*<div className="basket-container__result-subtitle">*/}
                            {/*    Киев, ул. Васильковская, 35, кв. 5*/}
                            {/*</div>*/}
                        </div>

                        <div className="basket-container__delivery-price">
                            {`${deliveryTypes[props.delivery.deliveryType].cost} грн`}
                        </div>
                    </div>
                )
            }


            {/*<div className="basket-container__result-item">*/}
            {/*    <div className="basket-container__left-container">*/}
            {/*        <div className="basket-container__result-title">Установка оборудования</div>*/}
            {/*        <div className="basket-container__result-subtitle">*/}
            {/*            /!*{`${props.installation.city}, ${props.installation.address}, ${props.installation.date ? props.installation.date.toLocaleDateString() : ''}`}*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="basket-container__delivery-price">500 грн</div>*/}
            {/*</div>*/}

            <div className="basket-container__result-item basket-container__result-item--sum">
                <div className="basket-container__left-container">
                    <div className="basket-container__bonus">
                        <div className="basket-container__bonus-label">{`${t('order.youGet')} - ${props.discountAmount.toLocaleString()} грн`}</div>
                        {/*<a className="basket-container__bonus-value" href="/">{`+${props.totalBonuses} ${t('order.points')}`}</a>*/}
                    </div>
                </div>

                <div className="basket-container__sum">
                    <div className="basket-container__sum-label">{t('order.totalPrice')}</div>
                    <div className="basket-container__sum-val">
                        {`${props.totalPrice.toLocaleString()} грн`}
                    </div>
                </div>
            </div>

            <div className="basket-container__confirm-btn btn-accent" onClick={props.submitOrder}>
                {t('order.submitButton')}
            </div>
            <div className="basket-container__user-agreement">
                {t('order.agreementTitle')} <LocalizedLink to={`/for-clients`} target="_blank">{t('order.agreement')}</LocalizedLink>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        delivery: store.checkoutState.delivery,
        installation: store.checkoutState.installation,
        payment: store.globalReducer.payment,
    };
};

export const CheckoutOrderResult = connect(mapStateToProps)(CheckoutOrderResultContainer);
