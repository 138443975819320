import React from 'react';
import {useTranslation} from 'react-i18next';
import {ProductSlider} from "./ProductSlider";

export const Recommendations = props => {
    const [t] = useTranslation('common');

    return (
        <section className="recommended-slider">
                <div className="product-slider-wrap container">
                    <h3 className="product-slider-title">{t('recommendationsTitle')}</h3>
                    <div className="product-slider">
                        <ProductSlider products={props.recommendedProducts} />
                    </div>
                </div>
        </section>
    );
};
