import React from "react";
import LocalizedLink from '../LocalizedLink';



export const FooterSolutions = props => {
    const solutionsList = [
        {
            name: props.translation.solutionsList.agribusiness,
            slug: 'industry/agribusiness',
        },
        {
            name: props.translation.solutionsList.specialEquipment,
            slug: 'transport/special-equipment',
        },
        {
            name: props.translation.solutionsList.logistics,
            slug: 'industry/logistics',
        },
        {
            name: props.translation.solutionsList.publicTransport,
            slug: 'industry/public-transport',
        },
    ];

    return (
        <nav className="footer__section footer__section--list">
            <h4 onClick={props.toggleMenu} className="footer__list-title">
                {props.translation.title}
                <div className={`plus ${props.isActive? 'active': ''}`} />
            </h4>

            <ul className={`footer__list ${props.isActive ? 'active' : ''}`}>
                {
                    solutionsList.map(solution => (
                        <li key={solution.slug} className="footer__item"><LocalizedLink to={`/solution/${solution.slug}`}>{solution.name}</LocalizedLink></li>
                    ))
                }
            </ul>
        </nav>
    );
}