import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';

import './ProductImageSlider.scss';
import {SliderPrevArrow} from "../SliderPrevArrow";
import {SliderNextArrow} from "../SliderNextArrow";

export const ProductImageSlider = props => {
    const [mainSlider, setMainSlider] = useState(null);
    const [navSlider, setNavSlider] = useState(null);
    const [sliderCounter, setSliderCounter] = useState(1);

    let slider1 = []
    let slider2 = []

    useEffect(() => {
        setMainSlider(slider1);
        setNavSlider(slider2);
    }, []);

    const mainSliderSettings = {
        beforeChange: (_, newIndex) => {
            setSliderCounter(newIndex + 1);
        },
        autoplay: false,
        speed: 400,
        arrows: false,
        fade: true,
        asNavFor: navSlider,
        responsive: [
            {
                breakpoint: 792,
                settings: {
                    fade: false,
                    arrows: true,
                    prevArrow: <SliderPrevArrow />,
                    nextArrow: <SliderNextArrow />,
                }
            }
        ]
    };

    const navSliderSettings = {
        slidesToShow: 3,
        speed: 400,
        arrows: false,
        asNavFor: mainSlider,
        focusOnSelect: true,
        adaptiveHeight: true,
    }

    return (
        <div className="product-demo">
            <div className="product-demo__slider-wrap">
                <ul className="product-demo__main-slider">
                    <Slider {...mainSliderSettings} ref={slider => slider1 = slider} >

                        {
                            props.images.map((image, index) => (
                                <li key={index} className="product-demo__slide-item">
                                    <img
                                        className="product-demo__main-slider-img"
                                        src={image}
                                        alt="product"
                                    />
                                </li>
                            ))
                        }

                    </Slider>
                </ul>

                <ul className="product-demo__thumbnail-slider ">
                    <Slider {...navSliderSettings} ref={slider => (slider2 = slider)}>
                        {
                            props.images.map((image, index) => (
                                <li key={index} className="product-demo__slide-item">
                                    <img
                                        className="product-demo__main-slider-img"
                                        src={image}
                                        alt="product"
                                    />
                                </li>
                            ))
                        }
                    </Slider>
                </ul>

            </div>
            <div className="product-demo__slider-counter-wrap">
                <div className="product-demo__slider-line"/>
                <div className="product-demo__slider-counter"><span
                    className="product-demo__slider-current-slide">{sliderCounter}</span><span
                    className="product-demo__slider-total-amount">{`/${props.images.length}`}</span></div>
            </div>
        </div>
    );
};
