import {
    GET_COMPANIES,
} from "../actionConsts/actionConsts";

import {_image} from "../../API/apiConsts";

const initialMainPageValues = {
    companies: []
};

const mainPageReducer = (state = initialMainPageValues, action) => {
    switch (action.type) {
        case GET_COMPANIES: {
            if(!action.payload) return state;

            const companies = action.payload.map(company => ({
                ...company,
                image: `${_image}/${company.image}`,
            })).filter(company => company.name !== 'TECHNOTON' && company.name !== 'CARGO' );

            return  {
                ...state,
                companies: [...companies]
            };
        }
        default:
            return state;

    }
};

export default mainPageReducer;
