export const subscribeMailing = email => {
    window.axios.get('', {
        params: {
            query: `
                mutation($email: String!) {
                    subscribeMailing(email: $email) 
                }
            `,
            variables: {
                email
            },
        },
    }).then(response => console.log(response))
        .catch(error => console.log(error));
};
