export const _discount = 0.03;

const languageUk = {title: 'Укр', key: 'uk'};
const languageRu = {title: 'Рус', key: 'ru'};

export const _language = languageUk;
export const _languages = [
    languageUk,
    languageRu,
];

export const paymentMethods = [
    {
        slug: 'payment_on_delivery',
        identifier: 0,
        text: 'Замовити рахунок',
    },
    {
        slug: 'payment_by_card',
        identifier: 1,
        text: 'Карта Visa і MasterCard (WAYFORPAY)',
    },
    {
        slug: 'payment_by_invoice',
        identifier: 2,
        text: 'Рахунок-фактура',
    },
];

export const deliveryMethods = [
    {
        slug: 'delivery_pickup',
        identifier: 0,
        text: 'Самовивіз із офісу',
    },
    {
        slug: 'delivery_new_post_office',
        identifier: 1,
        text: '«Нова пошта» до відділення',
    },
    {
        slug: 'delivery_new_post_courier',
        identifier: 2,
        text: 'Кур\'єрська доставка «Нова пошта»',
    },
    {
        slug: 'delivery_address',
        identifier: 3,
        text: 'Адресна доставка',
    },
];

export const callbackFormFields = [
    {
        name: 'name',
        title: 'name',
        type: 'text',
    },
    {
        name: 'lastName',
        title: 'lastName',
        type: 'text',
    },
    {
        name: 'phone',
        title: 'phone',
        type: 'tel',
    },
    {
        name: 'comment',
        title: 'comment',
    }
];

export const commercialOfferFormFields = [
    {
        name: 'name',
        title: 'name',
        type: 'text',
    },
    {
        name: 'lastName',
        title: 'lastName',
        type: 'text',
    },
    {
        name: 'phone',
        title: 'phone',
        type: 'tel',
    },
    {
        name: 'email',
        title: 'email',
        type: 'email',
    },
    {
        name: 'comment',
        title: 'comment',
    },
];

export const orderServiceFormFields = [
    {
        name: 'service',
        title: 'service',
        type: 'text',
    },
    {
        name: 'name',
        title: 'name',
        type: 'text',
    },
    {
        name: 'phone',
        title: 'phone',
        type: 'tel',
    },
    {
        name: 'email',
        title: 'email',
        type: 'email',
    },
    {
        name: 'comment',
        title: 'comment',
    },
];

export const orderSolutionFormFields = [
    {
        name: 'solution',
        title: 'solution',
        type: 'text',
    },
    {
        name: 'name',
        title: 'name',
        type: 'text',
    },
    {
        name: 'phone',
        title: 'phone',
        type: 'tel',
    },
    {
        name: 'email',
        title: 'email',
        type: 'email',
    },
    {
        name: 'comment',
        title: 'comment',
    }
];

export const consultation = [
    {
        name: 'name',
        title: 'name',
        type: 'text',
    },
    {
        name: 'phone',
        title: 'phone',
        type: 'tel',
    },
    {
        name: 'email',
        title: 'email',
        type: 'email',
    },
];