import React from 'react';
import {useTranslation} from 'react-i18next';

export const ContactsTabs = props => {
    const [t] = useTranslation('contacts');

    return (
        <div className="contacts__tabs">
            <h2
                className={`contacts__tab-btn ${props.activeTab === 'branches' ? 'active' : ''}`}
                onClick={() => props.changeTab('branches')}
            >
                {t('branches.title')}
            </h2>

            <h2
                className={`contacts__tab-btn ${props.activeTab === 'support' ? 'active' : ''}`}
                onClick={() => props.changeTab('support')}
            >
                {t('support.title')}
            </h2>
        </div>
    );
};
