// const testEndpoint = 'https://sysale.bitrix24.ua/rest/1/r1wrcw2jukr6y911';
const productionEndpoint = 'https://crm.totalcontrol.ua/rest/78/ll3v2hn39a5lvjen';
// const oldProductionEndpoint = 'https://crm.totalcontrol.ua/rest/35/7kculvvplucv3rxo'

const endpoint = productionEndpoint;

// const testId = 354;
const productionId = 13;

export const categoryId = 3;
export const assignedById = productionId;
export const addLead = `${endpoint}/crm.lead.add.json`;
export const addDeal = `${endpoint}/crm.deal.add.json`;
export const addContacts = `${endpoint}/crm.contact.add.json`;
export const addCompany = `${endpoint}/crm.company.add.json`;
