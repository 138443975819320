import {_api} from '../apiConsts';

export const sendPasswordRecoveryEmail = email => {
    return window.axios.post(`${_api}/auth`, {
        params: {
            query: `
                mutation($email: String!) {
                    sendPasswordRecoveryEmail(email: $email)
                }
            `,
            variables: {
                email,
            },
        },
    }).then(response => window._.head(response.data).data.sendPasswordRecoveryEmail)
        .catch(error => console.log(error));
};
