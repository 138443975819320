import React, {useEffect, useState} from 'react';

import { connect } from 'react-redux';

import {getEquipment} from "../../../../redux/actionCreators/getActionCreators/equipmentActions";
import {getCategoryDetails} from "../../../../redux/actionCreators/getActionCreators/catalogActions";
import LocalizedLink from '../../LocalizedLink';
import {PreLoader} from '../../PreLoader';
import {getEquipmentCategories} from "../../../../API/requests";
import {DropMenuBanner} from "../../DropMenuBanner";

const EquipmentMenu = props => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        saveEquipmentToStore();
    }, []);

    const saveEquipmentToStore = async () => {
        setIsLoading(true);

        try {
            const equipment = await getEquipmentCategories();
            props.getEquipment(equipment);
            props.getCategoryDetails(equipment[0])
        } catch (error) {
            props.getEquipment([]);
        } finally {
            setIsLoading(false);
        }
    };

    const saveCurrentCategory = category => {
        props.getCategoryDetails(category);
    };

    return (
        <ul
            className={`header-bottom__drop-menu header-bottom__drop-menu--catalog ${props.isActive ? 'active' : ''}`}
        >
            <div className="header-bottom__catalog-drop">
            {
                isLoading
                    ? (
                        <PreLoader />
                    )
                    : (

                        props.equipment.map((item, index) => (
                            <li key={index} className="header-bottom__drop-menu-item">
                                <LocalizedLink
                                    to={`/catalog/${item.slug}`}
                                    onClick={() => {
                                        props.toggleMenu();
                                        props.closeSideMenu();
                                        saveCurrentCategory(item);
                                    }}
                                >
                                    <div className="ico-wrap">
                                        <img className="ico" src={item.image} alt={item.name} />
                                    </div>
                                    {item.name}
                                </LocalizedLink>
                            </li>
                        ))
                    )
            }
            </div>

            <DropMenuBanner toggleMenu={props.toggleMenu} />
        </ul>
    )
}

const mapStateToProps = state => {
    return {
        equipment: state.equipmentState.equipment,
    };
};

const mapDispatchToProps = {
    getEquipment,
    getCategoryDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentMenu);
