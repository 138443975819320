import {
    ADD_MULTIPLE_PRODUCTS_BASKET,
    ADD_PRODUCT_BASKET,
    CLEAR_BASKET,
    DECREASE_PRODUCT_QUANTITY,
    DELETE_PRODUCT_BASKET,
    INCREASE_PRODUCT_QUANTITY,
    TOGGLE_PRODUCT_DELETE_FLAG,
} from '../actionConsts/actionConsts';

export const addProductToBasket = payload => {
    return {
        type: ADD_PRODUCT_BASKET,
        payload,
    };
};

export const deleteProductFromBasket = payload => {
    return {
        type: DELETE_PRODUCT_BASKET,
        payload,
    };
};

export const toggleProductDeleteFlag = payload => {
    return {
        type: TOGGLE_PRODUCT_DELETE_FLAG,
        payload,
    };
};

export const increaseProductQuantity = payload => {
    return {
        type: INCREASE_PRODUCT_QUANTITY,
        payload,
    };
};

export const decreaseProductQuantity = payload => {
    return {
        type: DECREASE_PRODUCT_QUANTITY,
        payload,
    };
};

export const clearBasket = () => {
    return {
        type: CLEAR_BASKET,
    };
};

export const addMultipleProductsToBasket = payload => {
    return {
        type: ADD_MULTIPLE_PRODUCTS_BASKET,
        payload,
    };
};
