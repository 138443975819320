import React, {useState} from 'react';

import './Contacts.scss';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {ContactsMap} from "./ContactsMap";
import {ContactsAddresses} from "./ContactsAddresses";
import {ContactsTabs} from "./ContactsTabs";

export const Contacts = props => {
    const [t] = useTranslation('contacts');
    const locationsTranslation = t('locations', {returnObjects: true});

    const locations = {
        branches: [
            {
                id: 6,
                phones: ['+38 (044) 333-96-51'],
                address: {
                    title: locationsTranslation.kyiv.address,
                },
                hours: `${t('weekdays')}: 8:00-19:00`,
                location: {
                    lat: 50.39756426553329,
                    lng: 30.493865662690684,
                },
            },
            {
                id: 1,
                phones: ['(0342) 55 90 61'],
                address: {
                    title: locationsTranslation.ivanoFrankivsk.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 48.931888570807345,
                    lng: 24.708166816025045,
                },
            },
            {
                id: 2,
                phones: ['(0432) 50-85-35', '0 (800) 33 9651'],
                address: {
                    title: locationsTranslation.vinnutsa.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 49.23428958553928,
                    lng: 28.450053384747363,
                },
            },
            {
                id: 3,
                phones: ['+38 (056) 790-22-90', '+38 (056) 790-22-92'],
                address: {
                    title: locationsTranslation.dnipro.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 48.432382558311815,
                    lng: 35.047349433327,
                },
            },
            {
                id: 4,
                phones: ['(061) 289 08 88'],
                address: {
                    title: locationsTranslation.zaporizhiya.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 47.84831601650617,
                    lng: 35.13572665534619,
                },
            },
            {
                id: 5,
                phones: ['(052)35 4 01 33', '(052)35 7 28 80'],
                address: {
                    title: locationsTranslation.oleksandiya.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 48.663177872805264,
                    lng: 33.08326894240269,
                },
            },
            {
                id: 7,
                phones: ['(032) 245-40-61'],
                address: {
                    title: locationsTranslation.lviv.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 49.8112944224094,
                    lng: 24.06064941295602,
                },
            },
            {
                id: 8,
                phones: ['(0512) 58-07-85'],
                address: {
                    title: locationsTranslation.mykolaiv.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 46.96914247036956,
                    lng: 31.989730233269597,
                },
            },
            {
                id: 9,
                phones: ['(0532) 58-27-72'],
                address: {
                    title: locationsTranslation.poltava.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 49.58583562953026,
                    lng: 34.52819613841371,
                },
            },
            {
                id: 10,
                phones: ['(057) 728-00-41'],
                address: {
                    title: locationsTranslation.harkiv.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 49.97376326607298,
                    lng: 36.25770253348999,
                },
            },
            {
                id: 11,
                phones: ['(0552) 49-51-48'],
                address: {
                    title: locationsTranslation.herson.address,
                },
                hours: `${t('weekdays')}: 9:00-18:00`,
                location: {
                    lat: 46.63758436219319,
                    lng: 32.61228617794957,
                },
            },
        ],

        support: [
            {
                type: 'email',
                contact: 'support@totalcontrol.ua',
            },
            {
                type: 'email',
                contact: 'support@scontrol.ua',
            },

            {
                type: 'phone',
                contact: '+38 (044) 333-96-51',
            },

            {
                type: 'phone',
                contact: '+38 (099) 333-96-51',
            },

            {
                type: 'phone',
                contact: '+38 (067) 333-96-51',
            },
            {
                type: 'phone',
                contact: '+38 (093) 333-96-51',
            },
            {
                type: 'phone',
                contact: '0 800 33-96-51',
            },
        ],
    };
    const [activeContactsTab, setActiveContactsTab] = useState('branches');
    const [selectedLocation, setSelectedLocation] = useState({
        index: 0,
        type: 'branches',
    });

    const changeActiveContactsTab = tabName => {
        setActiveContactsTab(tabName);
        setSelectedLocation({
            index: 0,
            type: tabName,
        });
    };

    const selectLocation = location => {
        setSelectedLocation(location);
    };

    return (
        <main className="contacts">
            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={''}
            />

            <h1 className="contacts__title">{t('title')}</h1>

            <ContactsTabs activeTab={activeContactsTab} changeTab={changeActiveContactsTab} />

            <div className="contacts__page">
                <ContactsAddresses
                    activeTab={activeContactsTab}
                    locations={locations}
                    selectLocation={selectLocation}
                    selectedLocation={selectedLocation}
                />

                <ContactsMap
                    selectedLocation={selectedLocation}
                    locations={locations}
                />
            </div>
        </main>
    );
};
