import React from 'react';
import {useTranslation} from 'react-i18next';

import option1 from '../../images/rent/option1.svg';
import option2 from '../../images/rent/option2.svg';
import option3 from '../../images/rent/option3.svg';
import option4 from '../../images/rent/option4.svg';
import {ProductImageSlider} from "../globalComponents/ProductImageSlider";

import image1 from '../../images/rent/bi-868-trek.webp';
import image2 from '../../images/rent/bi-flsensor-v5-770.webp';


const images = [
    image1,
    image2,
];

export const RentProducts = props => {
    const [t] = useTranslation('services');
    const optionsTranslation = t('rent.options', {returnObjects: true});
    const text = t('rent.text', {returnObjects: true});
    const options = [
        {
            image: option1,
            title: optionsTranslation.block,
        },
        {
            image: option2,
            title: optionsTranslation.akb,
        },
        {
            image: option3,
            title: optionsTranslation.adapter,
        },
        {
            image: option4,
            title: optionsTranslation.blocking,
        },
    ];

    return (
        <div className="container">
            <section className="rent-product">
                <div className="rent-slider">
                    <ProductImageSlider images={images} />
                </div>


                <div className="rent-product__info">
                    {
                        text.map((p, index) => (
                            <p key={index} className="rent-product__info-p">
                                {p}
                            </p>
                        ))
                    }
                </div>

                <div className="rent-product__options">
                    <h4 className="rent-product__options-title">{t('rent.optionsTitle')}</h4>
                    {
                        options.map((option, index) => (
                            <div key={index} className="rent-product__option">
                                <div className="rent-product__option-ico">
                                    <img src={option.image} alt={option.title} />
                                </div>
                                <p className="rent-product__option-name">{option.title}</p>
                            </div>
                        ))
                    }
                </div>
            </section>
        </div>
    );
};
