export const getSolutionsByType = (type, withDetails = false) => {
    return window.axios.get('', {
        params: {
            query: `
                query($type: String) {
                    solutions(type: $type) {
                        type
                        name
                        ${(type === 'transport' && withDetails) ? `
                            opportunities {
                                title
                                image
                                description
                                solution_for
                                products {
                                    id
                                    name
                                    slug
                                    images
                                    final_price
                                    short_description
                                    bonus_points
                                    specifications
                                    category {
                                        slug
                                        name
                                    }
                                }
                            }
                        ` : ''}
                    }
                }
            `,
            variables: {
                type,
            },
        },
    }).then(response => response.data.data.solutions)
        .catch(error => console.log(error));
};
