import React, {useState, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import Slider from 'react-slick';

import ico1 from "../../images/gsm/method-ico1.svg";
import ico1Alt from "../../images/gsm/method-ico1-alt.svg";
import image1 from '../../images/gsm/ico1.png'
import ico2 from "../../images/gsm/method-ico2.svg";
import ico2Alt from "../../images/gsm/method-ico2-alt.svg";
import image2 from '../../images/gsm/ico2.png';
import ico3 from "../../images/gsm/method-ico3.svg";
import ico3Alt from "../../images/gsm/method-ico3-alt.svg";
import image3 from '../../images/gsm/ico3.png';
import ico4 from "../../images/gsm/method-ico4.svg";
import ico4Alt from "../../images/gsm/method-ico4-alt.svg";
import image4 from '../../images/gsm/ico4.png';
import ico5 from "../../images/gsm/method-ico5.svg";
import ico5Alt from "../../images/gsm/method-ico5-alt.svg";
import image5 from '../../images/gsm/ico5.png';
import {OrderServiceButton} from '../globalComponents/OrderServiceButton';



export const GsmMethods = props => {
    const [t] = useTranslation('services');
    const methodsTranslation = t('gsm.methods', {returnObjects: true});

    const [activeMethod, setActiveMethod] = useState(0);

    const methods = [
        {
            icons: [ico1, ico1Alt],
            image: image1,
            title: methodsTranslation.calculation.title,
            description: methodsTranslation.calculation.description,
            advantages: methodsTranslation.calculation.advantages,
            disadvantages: methodsTranslation.calculation.disadvantages,
            isRecommended: false,
        },
        {
            icons: [ico2, ico2Alt],
            image: image2,
            title: methodsTranslation.standard.title,
            description: methodsTranslation.standard.description,
            advantages: methodsTranslation.standard.advantages,
            disadvantages: methodsTranslation.standard.disadvantages,
            isRecommended: false,
        },
        {
            icons: [ico3, ico3Alt],
            image: image3,
            title: methodsTranslation.immersion.title,
            description: methodsTranslation.immersion.description,
            advantages: methodsTranslation.immersion.advantages,
            disadvantages: methodsTranslation.immersion.disadvantages,
            isRecommended: true,
        },
        {
            icons: [ico4, ico4Alt],
            image: image4,
            title: methodsTranslation.flow.title,
            description: methodsTranslation.flow.description,
            advantages: methodsTranslation.flow.advantages,
            disadvantages: methodsTranslation.flow.disadvantages,
            isRecommended: true,
        },
        {
            icons: [ico5, ico5Alt],
            image: image5,
            title: methodsTranslation.can.title,
            description: methodsTranslation.can.description,
            advantages: methodsTranslation.can.advantages,
            disadvantages: methodsTranslation.can.disadvantages,
            isRecommended: true,
        },
    ];
    const methodsSliderSettings = {
        className: 'gsm-methods__slider',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: false,
        dots: false,
        waitForAnimate: true,
        draggable: false,
        responsive: [
            {
                breakpoint: 630,
                settings: {
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            }
        ]
    };
    const sliderRef = useRef();

    const changeActiveMethod = indexOfMethod => {
        setActiveMethod(indexOfMethod);
        sliderRef.current.slickGoTo(indexOfMethod, true);
    };

    return (
        <div className="gsm-methods">
            <div className="container-gsm">
                <h2 className="gsm-methods__title">{t('gsm.methodsTitle')}</h2>
                <p className="gsm-methods__subtitle">
                    {t('gsm.methodsSubtitle')}
                </p>
                <div className="gsm-methods__tabs-wrap">
                    <div className="gsm-methods__tabs">
                        {
                            methods.map((method, index) => (
                                <div
                                    key={index}
                                    className={`gsm-methods__tab ${index === activeMethod ? 'active' : ''} `}
                                    onClick={() => changeActiveMethod(index)}
                                >
                                    <div className="gsm-methods__tab-ico">
                                        <img className="ico" src={method.icons[0]} alt={method.title} />
                                        <img className="ico-active" src={method.icons[1]} alt={method.title} />
                                    </div>
                                    <h4 className="gsm-methods__tab-title">{method.title}</h4>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="gsm-methods__slider-wrap">
                <Slider ref={sliderRef} {...methodsSliderSettings}>
                    {
                        methods.map((method, index) => (
                            <div key={index} className="method-wrap">
                                <div className="method">
                                    <div className="method__img">
                                        <img src={method.image} alt={method.title} />
                                    </div>
                                    <div className="method__body">
                                        <p className="method__paragraph">{method.description}</p>
                                        <div className="method__footer">
                                            <ul className="method__plus-list">
                                                {
                                                    method.advantages.map((advantage, index) => (
                                                        <li key={index} className="method__list-item">{advantage}</li>
                                                    ))
                                                }
                                            </ul>

                                            <ul className="method__minus-list">
                                                {
                                                    method.disadvantages.map((disadvantage, index) => (
                                                        <li key={index} className="method__list-item">{disadvantage}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="method-not-recommended">
                                    {
                                        method.isRecommended
                                            ? (
                                                <OrderServiceButton service={method.title} />
                                            )
                                            : (
                                                <span>{t('gsm.notRecommend')}</span>
                                            )
                                    }
                                </div>

                            </div>
                        ))
                    }
                </Slider>
            </div>
        </div>
    );
};
