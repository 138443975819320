import React from 'react';
import {useTranslation} from 'react-i18next';
import headerBg from '../../images/scontrol/header-bg.webp';
import './PayThankCard.scss';
import LocalizedLink from '../globalComponents/LocalizedLink';
import {useParams} from 'react-router-dom';

const PayThankCard = () => {
    const [t] = useTranslation('common');
    const {orderId} = useParams();

    return (
        <section className="pay-thank">
            <div className="pay-thank__bg">
                <img src={headerBg} alt="header" />
            </div>

            <div className="pay-thank__body">
                <h1 className="pay-thank__title">{t('orderMessageModal.title')}</h1>
                <h2 className="pay-thank__subtitle">
                    {t('orderMessageModal.subtitle')} <span>{orderId}</span>
                </h2>

                <LocalizedLink
                    to="/"
                    className="page404__back-link btn-secondary"
                >
                    {t('errorPage.onMainButton')}
                </LocalizedLink>
            </div>
        </section>
    );
};

export default PayThankCard;
