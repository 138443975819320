import React from 'react';

import './EmptyPage.scss';
import {useTranslation} from 'react-i18next';

export const EmptyPage = props => {
    const [t] = useTranslation('common');

    return (
        <div className="empty-page">
            <svg className="empty-page__icon" width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27.3646 30.8332C29.0675 30.8332 30.4479 29.4527 30.4479 27.7498C30.4479 26.047 29.0675 24.6665 27.3646 24.6665C25.6617 24.6665 24.2812 26.047 24.2812 27.7498C24.2812 29.4527 25.6617 30.8332 27.3646 30.8332Z" fill="#1A3B70"/>
                <path d="M46.6341 30.8332C48.337 30.8332 49.7175 29.4527 49.7175 27.7498C49.7175 26.047 48.337 24.6665 46.6341 24.6665C44.9312 24.6665 43.5508 26.047 43.5508 27.7498C43.5508 29.4527 44.9312 30.8332 46.6341 30.8332Z" fill="#1A3B70"/>
                <path d="M37.0005 40.0833C33.6984 40.0302 30.4591 40.9883 27.7172 42.8291C24.9753 44.6699 22.862 47.3053 21.6609 50.3817C21.5961 50.574 21.5699 50.7772 21.5836 50.9797C21.5974 51.1822 21.6509 51.38 21.7411 51.5618C21.8312 51.7436 21.9564 51.9059 22.1093 52.0393C22.2622 52.1728 22.4399 52.2748 22.6322 52.3396C22.8245 52.4044 23.0277 52.4306 23.2302 52.4169C23.4327 52.4031 23.6305 52.3496 23.8123 52.2595C23.9941 52.1693 24.1564 52.0442 24.2898 51.8913C24.4233 51.7384 24.5253 51.5607 24.5901 51.3683C25.5537 48.8837 27.2655 46.7598 29.4888 45.2905C31.7121 43.8212 34.337 43.079 37.0005 43.1667C39.6511 43.0952 42.2589 43.8449 44.4668 45.3132C46.6747 46.7814 48.3747 48.8964 49.3338 51.3683C49.4411 51.6874 49.6499 51.9626 49.9283 52.1519C50.2066 52.3412 50.5393 52.4342 50.8755 52.4167C51.0393 52.4378 51.2051 52.4378 51.3688 52.4167C51.5775 52.3615 51.7722 52.2632 51.9404 52.1281C52.1086 51.993 52.2466 51.824 52.3454 51.6322C52.4442 51.4404 52.5016 51.23 52.514 51.0146C52.5264 50.7991 52.4934 50.5835 52.4172 50.3817C51.2116 47.2928 49.0865 44.6486 46.3292 42.8068C43.572 40.965 40.3156 40.0143 37.0005 40.0833Z" fill="#1A3B70"/>
                <path d="M37 4.625C30.5968 4.625 24.3375 6.52376 19.0134 10.0812C13.6894 13.6386 9.5398 18.6949 7.08941 24.6106C4.63903 30.5264 3.99789 37.0359 5.24709 43.316C6.49629 49.5962 9.57971 55.3649 14.1074 59.8926C18.6352 64.4203 24.4038 67.5037 30.684 68.7529C36.9641 70.0021 43.4736 69.361 49.3894 66.9106C55.3051 64.4602 60.3614 60.3106 63.9188 54.9866C67.4763 49.6625 69.375 43.4032 69.375 37C69.375 28.4136 65.9641 20.1789 59.8926 14.1074C53.8211 8.03593 45.5864 4.625 37 4.625ZM37 66.2917C31.2067 66.2917 25.5434 64.5737 20.7264 61.3551C15.9094 58.1365 12.1551 53.5618 9.93804 48.2094C7.72103 42.8571 7.14095 36.9675 8.27118 31.2855C9.40141 25.6035 12.1912 20.3842 16.2877 16.2877C20.3842 12.1912 25.6035 9.40139 31.2855 8.27116C36.9675 7.14094 42.8571 7.72101 48.2094 9.93803C53.5618 12.155 58.1365 15.9094 61.3551 20.7264C64.5738 25.5434 66.2917 31.2067 66.2917 37C66.2917 44.7686 63.2056 52.2191 57.7123 57.7123C52.2191 63.2056 44.7687 66.2917 37 66.2917Z" fill="#1A3B70"/>
            </svg>

            <p className="empty-page__title">{props.message ? props.message : t('emptyPageText')}</p>
        </div>
    );
};
