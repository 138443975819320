import React from 'react';
import {useTranslation} from 'react-i18next';

import checkmark from '../../images/checkmark.svg';

export const SolutionsChoosingTask = props => {
    const [t] = useTranslation('solutionChoosing');

    return (
        <section className={`solution-choose__section active`}>
            <h1 className="solution-choose__title">{t('choosing.taskTitle')}</h1>
            <div className="solution-choose__body-wrap">
                <div className="solution-choose__body solution-choose__body--tasks">
                    {
                        props.opportunities.map((opportunity, index) => (
                            <div
                                key={index}
                                className="task"
                                onClick={() => props.changeSelectedTasks(opportunity.title)}
                            >
                                <div className="task__ico">
                                    <img src={opportunity.icon} alt={opportunity.title} />
                                </div>

                                <h2 className="task__name">{opportunity.title}</h2>

                                <label className="checkbox-wrap">
                                    <input
                                        type="checkbox"
                                        checked={!!props.selectedTasks[opportunity.title]}
                                        onChange={() => props.changeSelectedTasks(opportunity.title)}
                                    />
                                    <span className="checkbox">
                                        <img src={checkmark} alt="checkmark" />
                                    </span>
                                </label>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};
