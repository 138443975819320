import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useLocalizedPath from '../../../customHooks/useLocalizedPath';
import {_discount} from '../../../globalConsts';
import {addMultipleProductsToBasket, clearBasket} from '../../../redux/actionCreators/basketActions';
import {
    setDeliveryRepeat,
    setInstallationRepeat,
    setPaymentMethodRepeat,
} from "../../../redux/actionCreators/checkoutActions";



const UserAccountOrderHistoryFooterContainer = props => {
    const [t] = useTranslation(['account', 'checkout']);
    const localizedOrderPath = useLocalizedPath('order');
    const routerHistory = useHistory();

    const deliveryTypes = {
        0: {
            slug: 'delivery_pickup',
            title: t('checkout:delivery.ways.pickUp.title'),
        },
        1: {
            slug: 'delivery_new_post_office',
            title: t('checkout:delivery.ways.post.title'),
        },
        2: {
            slug: 'delivery_new_post_courier',
            title: t('checkout:delivery.ways.courier.title'),
        },
        3: {
            slug: 'delivery_address',
            title: t('checkout:delivery.ways.address.title'),
        },
    };

    const paymentMethods = {
        0: {
            slug: 'payment_on_delivery',
            title: t('checkout:payment.paymentOnDeliver'),
        },
        1: {
            slug: 'payment_by_card',
            title: t('checkout:payment.paymentByCard'),
        },
        2: {
            slug: 'payment_by_invoice',
            title: t('checkout:payment.factor'),
        },
    };

    const repeatOrder = () => {
        let installationInfo = null;
        let deliveryInfo = {
            deliveryType: deliveryTypes[props.order.delivery_method].slug,
        }
        if(props.order.installation) {
            installationInfo = {
                city: props.order.installation.city || '',
                address: props.order.installation.address || '',
            }
        }

        if(!Array.isArray(props.order.ship_to)) {
            deliveryInfo = {
                ...deliveryInfo,
                cityDelivery: props.order.ship_to.city || '',
                warehouse: props.order.ship_to.new_post_office || '',
                deliveryAddress: {
                    street: props.order.ship_to.street || '',
                    building: props.order.ship_to.building || '',
                    flat: props.order.ship_to.flat || '',
                }
            }
        }

        const paymentMethod = paymentMethods[props.order.payment_method].slug;

        props.setInstallationRepeat(installationInfo);
        props.setPaymentMethodRepeat(paymentMethod);
        props.setDeliveryRepeat(deliveryInfo);
        props.clearBasket();

        const productsToAdd = props.order.order_items.map(order_item => ({
            ...order_item.product,
            quantity: order_item.quantity,
        }));
        props.addMultipleProductsToBasket([...productsToAdd]);
        routerHistory.push(localizedOrderPath);
    }

    return (
        <div className="order-history-footer">
            <div className="order-history-footer__info-section">
                <div className="order-history-footer__info-title">{t('orders.deliveryInformation')}</div>
                <div className="order-history-footer__info">
                    {deliveryTypes[props.order.delivery_method]?.title}
                </div>
                {
                    !Array.isArray(props.order.ship_to) && (
                        <div className="order-history-footer__info">
                            {`${props.order.ship_to.city}, ${props.order.ship_to.new_post_office ? props.order.ship_to.new_post_office : ''} ${props.order.ship_to.street ? props.order.ship_to.street : ''} ${props.order.ship_to.building ? props.order.ship_to.building : ''} ${props.order.ship_to.flat ? `, ${props.order.ship_to.flat}` : ''}`}
                        </div>
                    )
                }
                <div className="order-history-footer__info">
                    {paymentMethods[props.order.payment_method]?.title}
                </div>


                {
                    (props.order.installation) && (
                        <>
                            <div className="order-history-footer__info-title">{t('orders.installationInformation')}</div>
                            <div className="order-history-footer__info">
                                {`${props.order.installation.city}, ${props.order.installation.address}`}
                            </div>

                            <div className="order-history-footer__info">
                                {props.order.installation.date}
                            </div>
                        </>
                    )
                }
            </div>

            <div className="order-history-footer__price-section">
                <div className="order-history-footer__price-section-body">
                    <div className="order-history-footer__price-item">
                        <div className="order-history-footer__price-label">{t('orders.totalPrice')}</div>
                        <div className="order-history-footer__price">
                            {`${(props.order.products_amount * (1 - _discount)).toLocaleString()} грн`}
                        </div>
                    </div>

                    <div className="order-history-footer__price-item">
                        <div className="order-history-footer__price-label">{t('orders.deliveryPrice')}</div>
                        <div className="order-history-footer__price">
                            {`${props.order.delivery_price.toLocaleString()} грн`}
                        </div>
                    </div>

                    <div className="order-history-footer__price-item order-history-footer__price-item--total">
                        <div className="order-history-footer__price-label">{t('orders.priceWithDelivery')}</div>
                        <div className="order-history-footer__price">
                            {`${(props.order.products_amount * (1 - _discount) + props.order.delivery_price).toLocaleString()} грн`}
                        </div>
                    </div>
                </div>

                <button className="order-history-footer__repeat-order-btn btn-accent" onClick={repeatOrder}>
                    {t('orders.repeatOrderButton')}
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    clearBasket,
    addMultipleProductsToBasket,
    setDeliveryRepeat,
    setInstallationRepeat,
    setPaymentMethodRepeat,
};

export const UserAccountOrderHistoryFooter = connect(null, mapDispatchToProps)(UserAccountOrderHistoryFooterContainer);
