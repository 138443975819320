import React from 'react';
import {useTranslation} from 'react-i18next';

export const SupportInformationAdditional = props => {
    const [t] = useTranslation('support');
    const additionalInformationTranslation = t('additionalInformation', {returnObjects: true});

    return (
        <div className="support__content-wrap">
            <div className="support__info">
                <h2 className="support__section-title">{additionalInformationTranslation.title}</h2>
                <p className="support__paragraph">
                    {additionalInformationTranslation.subtitle}
                </p>

                <p className="support__info-title"> {`${additionalInformationTranslation.supportDataTitle}:`}</p>
                <ul className="support__info-list">
                    {
                        additionalInformationTranslation.supportDataList.map((data, index) => (
                            <li key={index} className="support__info-li">
                                <div className="support__li-title">
                                    {data}
                                </div>
                            </li>
                        ))
                    }
                </ul>

                {
                    additionalInformationTranslation.text.map((data, index) => (
                        <p key={index} className="support__paragraph">
                            {data}
                        </p>
                    ))
                }

                <p className="support__paragraph">
                    {additionalInformationTranslation.complaints} <a className="support__li-link" href="mailto:glyanenko@scformula.ua">glyanenko@scformula.ua</a>.
                </p>
            </div>
        </div>
    );
};
