export const getVacancies = () => {
    return window.axios.get('', {
        params: {
            query: `
                query {
                    vacancies {
                        id
                        name
                        description
                    }
                }
            `
        },
    }).then(response => response.data.data.vacancies)
        .catch(error => console.log(error));
};
