import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import LocalizedLink from '../globalComponents/LocalizedLink';

const CompareEmptyContainer = props => {
    const [t] = useTranslation('compare');

    return (
        <div className="basket-page__basket basket-empty">
            <h3 className="basket-empty__title">{t('emptyCompare.title')}</h3>
            <p className="basket-empty__subtitle">
                {t('emptyCompare.recommendation')}
            </p>

            <LocalizedLink
                to={`/catalog/${props.currentCategory.slug}`}
                className="basket-page__back-btn btn-ghost-secondary"
            >
                {t('emptyCompare.moveToCatalogButton')}
            </LocalizedLink>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentCategory: state.catalogState.categoryDetails,
    };
};

export const CompareEmpty = connect(mapStateToProps)(CompareEmptyContainer);
