import {GET_CASE_ARTICLE, GET_CASES} from "../../actionConsts/actionConsts";

export const getCasesArticles = payload => {
    return {
        type: GET_CASES,
        payload,
    };
};

export const getCaseArticle = payload => {
    return {
        type: GET_CASE_ARTICLE,
        payload,
    };
};
