import React from 'react';
import {useTranslation} from 'react-i18next';

// import Slider from 'react-slick';
import team1 from '../../images/about-us/team/Sydorenko_Serhiy.webp';
import team2 from '../../images/about-us/team/Ulys_Larysa.webp';
import team3 from '../../images/about-us/team/Magalas_Oleh.webp';
import team4 from '../../images/about-us/team/Hlanenko_Vasyl.webp';
import team5 from '../../images/about-us/team/Zhurubeda_Roman.webp';
import team6 from '../../images/about-us/team/Labunska_Ludmyla.webp';
import team8 from '../../images/about-us/team/Pohoriliy Vitaliy.webp';
import team9 from '../../images/about-us/team/Pulupchuk_Oleh.webp';
import team10 from '../../images/about-us/team/Hanas_Oleksandr.webp';
import team11 from '../../images/about-us/team/Doroshenko_Oleksiy.webp';

import {SliderPrevArrow} from "../globalComponents/SliderPrevArrow";
import {SliderNextArrow} from "../globalComponents/SliderNextArrow";



export const AboutCompanyTeam = () => {
    const [t] = useTranslation('aboutUs');
    const settings = {
        className: 'about-us-team__slider',
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: true,
        dots: false,
        waitForAnimate: true,
        // dotsClass: 'main-sl-dots',
        prevArrow: <SliderPrevArrow />,
        nextArrow: <SliderNextArrow />,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1110,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 420,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };
    const teamOptions = t('team.teamOptions', {returnObjects: true});
    const teammatesTranslation = t('team.teammates', {returnObjects: true});
    const teammates = [
        {
            name: teammatesTranslation.Sidorenko.name,
            position: teammatesTranslation.Sidorenko.position,
            image: team1,
        },
        {
            name: teammatesTranslation.Ulys.name,
            position: teammatesTranslation.Ulys.position,
            image: team2,
        },
        {
            name: teammatesTranslation.Mahalas.name,
            position: teammatesTranslation.Mahalas.position,
            image: team3,
        },
        {
            name: teammatesTranslation.Zurybeda.name,
            position: teammatesTranslation.Zurybeda.position,
            image: team5,
        },
        {
            name: teammatesTranslation.Labunska.name,
            position: teammatesTranslation.Labunska.position,
            image: team6,
        },
        {
            name: teammatesTranslation.Hlanenko.name,
            position: teammatesTranslation.Hlanenko.position,
            image: team4,
        },
        {
            name: teammatesTranslation.Pogoriliy.name,
            position: teammatesTranslation.Pogoriliy.position,
            image: team8,
        },
        {
            name: teammatesTranslation.Pylypchuk.name,
            position: teammatesTranslation.Pylypchuk.position,
            image: team9,
        },
        {
            name: teammatesTranslation.Hanas.name,
            position: teammatesTranslation.Hanas.position,
            image: team10,
        },
        {
            name: teammatesTranslation.Doroshenko.name,
            position: teammatesTranslation.Doroshenko.position,
            image: team11,
        },
    ];

    return (
        <div className="container-about-us">
            <section className="about-us-team">
                <div className="about-us-team__header">
                    <h3 className="about-us-team__title">{t('team.title')}</h3>
                    {
                        teamOptions.map((option, index) => (
                            <p key={index} className="about-us-team__paragraph">{option}</p>
                        ))
                    }
                </div>

                {/* <div className="about-us-team__slider-wrap container">
                    <Slider {...settings}>
                        {
                            teammates.map((teammate, index) => (
                                <div  key={index} className="about-us-team__slider-item">
                                    <div className="about-us-team__slide">
                                        <div className="about-us-team__slide-img">
                                            <img src={teammate.image} alt={teammate.name} />
                                        </div>

                                        <div className="about-us-team__slide-title">{teammate.name}</div>
                                        <div className="about-us-team__slide-subtitle">{teammate.position}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div> */}
            </section>
        </div>
    );
};
