import {
    SET_CHECKOUT_ERRORS,
    SET_CHECKOUT_PERSONAL_INFORMATION,
    SET_DELIVERY_INFORMATION, SET_DELIVERY_REPEAT,
    SET_INSTALLATION_INFORMATION, SET_INSTALLATION_REPEAT,
    SET_PAYMENT_METHOD_REPEAT,
} from '../actionConsts/actionConsts';

export const setCheckoutPersonalInformation = payload => {
    return {
        type: SET_CHECKOUT_PERSONAL_INFORMATION,
        payload,
    };
};

export const setDeliveryInformation = payload => {
    return {
        type: SET_DELIVERY_INFORMATION,
        payload,
    };
};

export const setInstallationInformation = payload => {
    return {
        type: SET_INSTALLATION_INFORMATION,
        payload,
    };
};

export const setCheckoutErrors = payload => {
    return {
        type: SET_CHECKOUT_ERRORS,
        payload,
    };
};

export const setPaymentMethodRepeat = payload => {
    return {
        type: SET_PAYMENT_METHOD_REPEAT,
        payload,
    };
};

export const setDeliveryRepeat = payload => {
    return {
        type: SET_DELIVERY_REPEAT,
        payload,
    };
};

export const setInstallationRepeat = payload => {
    return {
        type: SET_INSTALLATION_REPEAT,
        payload,
    };
};

