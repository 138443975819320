export const getServices = () => {
    return window.axios.get('', {
        params: {
            query: `
                query {
                    services {
                        id
                        icon
                        image
                        name
                        description
                    }
                }
            `,
        },
    }).then(response => response.data.data.services)
        .catch(error => console.log(error));
};
