import {_api} from '../apiConsts';

export const changeUserPassword = userInfo => {
    return window.axios.post(`${_api}/auth`, {
        params: {
            query: `
                mutation(
                    $old_password: String!
                    $new_password: String!
                    $new_password_confirmation: String!
                ) {
                    changePassword(
                        old_password: $old_password
                        new_password: $new_password
                        new_password_confirmation: $new_password_confirmation
                    )
                }
            `,
            variables: {
                old_password: userInfo.old_password,
                new_password: userInfo.new_password,
                new_password_confirmation: userInfo.new_password_confirmation,
            },
        },
    }).then(response => window._.head(response.data))
        .catch(error => console.log(error));
};
