import {GET_CATEGORY_DETAILS, GET_FILTERS, GET_PRODUCTS} from "../../actionConsts/actionConsts";

export const getProducts = payload => {
    return {
        type: GET_PRODUCTS,
        payload,
    };
};

export const getFilters = payload => {
    return {
        type: GET_FILTERS,
        payload,
    };
};

export const getCategoryDetails = payload => {
    return {
        type: GET_CATEGORY_DETAILS,
        payload,
    };
};
