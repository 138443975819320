import React from 'react';
import {useTranslation} from 'react-i18next';

import {useParams} from 'react-router-dom';
import {CheckboxGroup} from '../../globalComponents/CheckboxGroup';
import {Toggler} from '../../globalComponents/Toggler';

export const CatalogSpecificationStaticFilters = props => {
    const [t] = useTranslation('catalog');
    const filtersTranslation = t('filters', {returnObjects: true});
    const params = useParams();

    const staticSpecifications = [
        {
            type: 'boolean',
            categories: ['avtomobilni-trekeri-1'],
            name: filtersTranslation.digitalFuelLevelSensor,
            value: '+',
        },
        {
            type: 'boolean',
            categories: ['avtomobilni-trekeri-1'],
            name: filtersTranslation.digitalTemperatureSensor,
            value: '+',
        },
        {
            type: 'multiple',
            categories: ['avtomobilni-trekeri-1'],
            name: filtersTranslation.digitalInterfaces,
            values: [
                {
                    name: filtersTranslation.canBusSupport,
                    title: filtersTranslation.builtInCanModule,
                    value: '+',
                },
                {
                    name: filtersTranslation.canAdapterSupport,
                    title: filtersTranslation.canAdapterSupport,
                    value: '+',
                },
                {
                    name: filtersTranslation.rs485Support,
                    title: 'RS-485',
                    value: '+',
                },
                {
                    name: filtersTranslation.oneWireSupport,
                    title: '1-wire',
                    value: '+',
                },
                {
                    name: filtersTranslation.obd2Support,
                    title: 'OBD II',
                    value: '+',
                },
            ],
        },
        {
            type: 'boolean',
            categories: ['avtomobilni-trekeri-1'],
            name: filtersTranslation.bluetoothTemperatureSensor,
            value: '+',
        },

        {
            type: 'boolean',
            categories: ['oxoronni-mayaki-7', 'personalni-trekeri-5', 'bezpeka-vantaziv-4', 'gps-mayaki-12'],
            name: filtersTranslation.bluetoothSupport,
            value: '+',
        }
    ];

    const renderStaticFilter = specification => {
        switch (specification.type) {
            case 'boolean': return (
                <Toggler
                    onChange={props.onChange}
                    specification={specification}
                    checked={props.selectedSpecifications ? props.selectedSpecifications[specification.name] ? !!props.selectedSpecifications[specification.name][specification.value] : false : false}
                />
            )
            case 'multiple': return (
                <CheckboxGroup
                    onChange={props.onChange}
                    specification={specification}
                    selectedSpecifications={props.selectedSpecifications}
                />
            )
            default: return <></>
        }
    };

    return (
        <>
            {
                staticSpecifications
                    .filter(specification => specification.categories.some(category => category === params.category))
                    .map(specification => (
                        <div key={specification.name} className={`filter__container ${specification.type === 'boolean' ? 'filter__container--boolean': ''}`}>
                            <div className="filter__title">{specification.name}</div>
                            {
                                renderStaticFilter(specification)
                            }
                        </div>
                    ))
            }
        </>
    );
};
