import React, {useEffect, useReducer} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {createLead} from '../../../API/birixApi/createLead';
import {commercialOfferFormFields} from '../../../globalConsts';

import closeButton from '../../../images/close-btn.svg';
import {setProductForOffer, setSolutionForOffer} from '../../../redux/actionCreators/globalActions';
import {toggleApplicationSuccess, toggleCommercialOffer, toggleModal} from '../../../redux/actionCreators/modalActions';
import createFormState from '../../../utils/stateReducers/createFormState';
import formReducer from '../../../utils/stateReducers/formReducer';
import Form from '../Form/Form';
import ReactGA from 'react-ga';

const CommercialOfferContainer = props => {
    const [t] = useTranslation('common');
    const [offerFormValues, dispatchOfferFormValues] = useReducer(formReducer, commercialOfferFormFields, createFormState);

    useEffect(() => {
        if(props.isLogged) {
            const formInfoToSet = {
                name: {
                    ...offerFormValues.name,
                    value: props.user.name,
                    wasTouched: true,
                },
                lastName: {
                    ...offerFormValues.lastName,
                    value: props.user.last_name,
                    wasTouched: true,
                },
                phone: {
                    ...offerFormValues.phone,
                    value: props.user.phone,
                    wasTouched: true,
                },
                email: {
                    ...offerFormValues.email,
                    value: props.user.email,
                    wasTouched: true,
                }
            }

            dispatchOfferFormValues({type: 'set_all_values', payload: formInfoToSet});
        }
    }, [props.user, props.isLogged]);

    const onOfferSubmit = () => {
        const userInfo = {
            name: `${offerFormValues.name.value} ${offerFormValues.lastName.value}`,
            phone: offerFormValues.phone.value,
            email: offerFormValues.email.value,
            comment: offerFormValues.comment.value,
            itemForOffer: props.productForOffer || `${props.solutionForOffer.solutionName}: ${props.solutionForOffer.taskName}`,
        }

        createLead(userInfo, 'commercialOffer', props.googleId, props.utmParams);
        openApplicationSuccessModal();
        ReactGA.event({
            category: 'Order',
            action: 'order-commercial-offer',
            label: 'Запрос комерческого предложения',
        });

        props.setSolutionForOffer(null);
        props.setProductForOffer(null);
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleCommercialOffer(false);
    };

    const openApplicationSuccessModal = () => {
        props.toggleCommercialOffer(false);
        props.toggleApplicationSuccess(true);
    };

    return (
        <div className={`form-modal form-modal--commercial modal-commercial ${props.commercialOffer ? 'active' : ''}`}>
            <div
                className="modal__close"
                onClick={closeModals}
            >
                <img src={closeButton} alt="close" />
            </div>

            <Form
                className="form-modal__body"
                formValues={offerFormValues}
                dispatchFormValues={dispatchOfferFormValues}
                formFields={commercialOfferFormFields}
                onSubmitSuccess={onOfferSubmit}
            >
                <h6 className="form-modal__title">{t('commercialOfferRequest')}</h6>
            </Form>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        commercialOffer: store.modalsState.commercialOffer,
        user: store.userAccountState.user,
        isLogged: store.userAccountState.isLogged,
        productForOffer: store.globalReducer.productForCommercialOffer,
        solutionForOffer: store.globalReducer.solutionForCommercialOffer,
        googleId: store.globalReducer.googleId,
        utmParams: store.globalReducer.utmParams,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleCommercialOffer,
    toggleApplicationSuccess,
    setProductForOffer,
    setSolutionForOffer,
};

export const CommercialOffer = connect(mapStateToProps, mapDispatchToProps)(CommercialOfferContainer);
