import React from 'react';
import {useTranslation} from 'react-i18next';
import {SupportForm} from "./SupportForm";
import viberQR from '../../../images/support/viberQR.png';
import telegramQR from '../../../images/support/telegramQR.png';

export const SupportInformation = props => {
    const [t] = useTranslation('support');
    const informationTranslation = t('information', {returnObjects: true})
    return (
        <div className="support__content-wrap">
            <div className="support__info-wrap">
                <h2 className="support__section-title">{informationTranslation.title}</h2>
                <article className="support__info">
                    <h3 className="support__info-title">
                        {`${informationTranslation.subtitle}:`}
                    </h3>

                    <ul className="support__info-list">
                        <li className="support__info-li">
                            <div className="support__li-title">
                                {`${informationTranslation.email}:`}
                                <p><a className="support__li-link" href="mailto:support@totalcontrol.ua?subject=Заявка на техподдержку">support@totalcontrol.ua</a></p>
                                <p><a className="support__li-link" href="mailto:support@scontrol.ua?subject=Заявка на техподдержку">support@scontrol.ua</a></p>
                            </div>

                        </li>

                        <li className="support__info-li">
                            <div className="support__li-title">
                                {`${informationTranslation.phone}:`}
                                <p><a className="support__li-link" href="tel:+380443339651">+38 (044) 333-96-51</a></p>
                                <p><a className="support__li-link" href="tel:+380993339651">+38 (099) 333-96-51</a></p>
                                <p><a className="support__li-link" href="tel:+380673339651">+38 (067) 333-96-51</a></p>
                                <p><a className="support__li-link" href="tel:+380933339651">+38 (093) 333-96-51</a></p>
                                <p><a className="support__li-link support__li-link--large" href="tel:0800339651">0 800 33-96-51</a></p>
                            </div>

                        </li>

                        <li className="support__info-li">
                            <div className="support__li-title">
                                {informationTranslation.app}
                            </div>
                        </li>

                        <li className="support__info-li">
                            <div className="support__li-title">
                                {informationTranslation.priorPhone.phone} <a className="support__li-link" href="tel:+380443541675">+38 (044) 354-16-75</a>  {informationTranslation.priorPhone.message}
                            </div>
                        </li>

                        <li className="support__info-li">
                            <div className="support__li-title">
                                {informationTranslation.messengers}

                                <div className="support__li-qr-codes">
                                    <div className="support__li-qr-code">
                                        <p><strong>Viber</strong></p>
                                        <img src={viberQR} alt="viber qr"/>
                                    </div>


                                    <div className="support__li-qr-code">
                                        <p><strong>Telegram</strong></p>
                                        <img src={telegramQR} alt="viber qr"/>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="support__info-li">
                            <div className="support__li-title">
                                {informationTranslation.time}
                            </div>
                        </li>
                    </ul>
                </article>
            </div>

            <SupportForm />
        </div>
    );
};
