import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {GpsMonitoringTab} from "./GpsMonitoringTab";
import {useLocation} from 'react-router-dom';

export const GpsMonitoringBody = props => {
    const [t] = useTranslation('services');
    const [activeTab, setActiveTab] = useState(-1);
    const servicesRef = useRef(false);
    const serviceTabRef = useRef(false);
    const location = useLocation();

    useEffect(() => {
        if(location.hash) {
            const indexFromHash = props.service.data.opportunities.findIndex(opportunity => opportunity.id === parseInt(location.hash.replace('#', '')));
            const idOfElement = props.service.data.opportunities[indexFromHash]?.id;
            const element = document.getElementById(idOfElement);

            if(element) {
                element.scrollIntoView();
                window.scrollBy(0, -115)
            }
            setActiveTab(indexFromHash);
        }
    }, [props.service, location.hash]);

    const changeActiveTab = indexOfTabToSet => {
        setActiveTab(prevState => {
            if(prevState === indexOfTabToSet) return -1;

            return indexOfTabToSet;
        });
    };

    return (
        <div className="services__body">
            <h2 className="services__title">{ props.title ? props.title : t('gps.opportunitiesTitle')}</h2>
            <section className="services__body-content" ref={servicesRef}>
                {
                    props.service.data.opportunities.map((opportunity, index) => (
                        <GpsMonitoringTab
                            key={index}
                            changeActiveTab={changeActiveTab}
                            opportunity={opportunity}
                            isActive={index === activeTab}
                            indexOfTab={index}
                            serviceTabRef={serviceTabRef}
                        />
                    ))
                }
            </section>
        </div>
    );
};
