import React from 'react';

import {addProductToCompare, deleteProductFromCompare} from "../../../redux/actionCreators/compareActions";
import {connect} from "react-redux";
import LocalizedLink from '../LocalizedLink';

export const ProductBlockContainer = props => {
    const addProductToCompare = () => {
        props.isInCompare
            ? props.deleteProductFromCompare(props.product.id)
            : props.addProductToCompare(props.product);
    };

    return (
        <div className="product-item-wrap product-item-wrap--slide">
            <div className="product-item">
                <button className={`product-item__compare-button ${props.isInCompare ? 'active' : ''}`} onClick={addProductToCompare}>
                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M27.4862 17.1504L23.9862 9.33006C23.8452 9.01517 23.5325 8.8125 23.1875 8.8125C23.1769 8.8125 23.1664 8.81327 23.1558 8.81365C23.1481 8.81343 23.1405 8.8125 23.1328 8.8125H14.875V6.49698C16.1386 6.11952 17.0625 4.94719 17.0625 3.5625C17.0625 1.87386 15.6887 0.5 14 0.5C12.3113 0.5 10.9375 1.87386 10.9375 3.5625C10.9375 4.94713 11.8614 6.11947 13.125 6.49698V8.8125H4.86719C4.85942 8.8125 4.85193 8.81348 4.84422 8.81365C4.83361 8.81327 4.82311 8.8125 4.8125 8.8125C4.46753 8.8125 4.15477 9.01517 4.01384 9.33006L0.513844 17.1504C0.45757 17.276 0.434438 17.4078 0.438813 17.5368C0.438539 17.5454 0.4375 17.5538 0.4375 17.5625V18.875C0.4375 21.2874 2.40013 23.25 4.8125 23.25C7.22487 23.25 9.1875 21.2874 9.1875 18.875V17.5625C9.1875 17.5538 9.18646 17.5454 9.18619 17.5368C9.19056 17.4078 9.16743 17.276 9.11116 17.1504L6.16279 10.5625H13.125V26.75H8.75C8.26673 26.75 7.875 27.1418 7.875 27.625C7.875 28.1082 8.26673 28.5 8.75 28.5H19.25C19.7333 28.5 20.125 28.1082 20.125 27.625C20.125 27.1418 19.7333 26.75 19.25 26.75H14.875V10.5625H21.8372L18.8888 17.1504C18.8325 17.276 18.8094 17.4078 18.8138 17.5368C18.8135 17.5454 18.8124 17.5538 18.8124 17.5625V18.875C18.8124 21.2874 20.7751 23.25 23.1874 23.25C25.5998 23.25 27.5624 21.2874 27.5624 18.875V17.5625C27.5624 17.5538 27.5614 17.5454 27.5611 17.5368C27.5656 17.4078 27.5424 17.276 27.4862 17.1504ZM12.6875 3.5625C12.6875 2.83877 13.2763 2.25 14 2.25C14.7237 2.25 15.3125 2.83877 15.3125 3.5625C15.3125 4.28623 14.7237 4.875 14 4.875C13.2763 4.875 12.6875 4.28623 12.6875 3.5625ZM4.8125 11.8294L6.98671 16.6875H2.63829L4.8125 11.8294ZM7.4375 18.875C7.4375 20.3224 6.25991 21.5 4.8125 21.5C3.36509 21.5 2.1875 20.3224 2.1875 18.875V18.4375H7.4375V18.875ZM25.3617 16.6875H21.0133L23.1875 11.8294L25.3617 16.6875ZM25.8125 18.875C25.8125 20.3224 24.6349 21.5 23.1875 21.5C21.7401 21.5 20.5625 20.3224 20.5625 18.875V18.4375H25.8125V18.875Z"
                            fill="#1A3B70"
                        />
                    </svg>
                </button>

                <LocalizedLink to={`/product/${props.product.slug}`}>
                    <div className="product-item__content">
                        <div className="product-item__img-wrap">
                            <img
                                alt={props.product.name}
                                className="product-item__img"
                                src={props.product.images[0]}
                            />
                        </div>
                    </div>

                    <div className="product-item__info">
                        <h5 className="product-item__title">{props.product.name}</h5>
                        <p className="product-item__subtitle" dangerouslySetInnerHTML={{__html: props.product.short_description}} />
                        <p className="product-item__price">{`${props.product.final_price.toLocaleString()} грн`}</p>
                    </div>
                </LocalizedLink>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        compareProductIds: state.compareState.compareProductIds,
    };
};

const mapDispatchToProps = {
    addProductToCompare,
    deleteProductFromCompare,
};

export const ProductBlock = connect(mapStateToProps, mapDispatchToProps)(ProductBlockContainer);
