import React from 'react';

export const NextArrow = props => {
    const {onClick} = props;

    return (
        <button onClick={onClick} className="slider__button slider__next">
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.385 11L0.450001 3.065C0.165 2.795 -8.39259e-08 2.42 -6.55671e-08 2C-2.95052e-08 1.175 0.675 0.5 1.5 0.5C1.92 0.5 2.295 0.665 2.565 0.935L11.565 9.935C11.835 10.205 12 10.58 12 11C12 11.42 11.835 11.795 11.565 12.065L2.565 21.065C2.295 21.335 1.92 21.5 1.5 21.5C0.674999 21.5 -8.88434e-07 20.825 -8.52372e-07 20C-8.34013e-07 19.58 0.164999 19.205 0.434999 18.935L8.385 11Z"
                      fill="white"
                />
            </svg>
        </button>
    )
}