import {parseProductEntities} from '../../utils/parsers/parsers';
import {
    APPLY_BRAND_FILTER,
    APPLY_CATEGORY_FILTER,
    APPLY_PRICE_FILTER,
    APPLY_SORT_PROPERTY,
    APPLY_SPECIFICATIONS_FILTER,
    GET_CATEGORY_DETAILS,
    GET_FILTERS,
    GET_PRODUCTS,
} from '../actionConsts/actionConsts';

const initialCatalogState = {
    appliedFilters: {
        prices: {
            min: 3050,
            max: 3050,
        },
        brands: [

        ],
        specifications: [

        ],
        categories: [

        ],
    },
    sortProperty: {
        column: '',
        direction: '',
    },
    categoryDetails: {
        name: '',
        slug: 'avtomobilni-trekeri-1',
        description: '',
        seo_title: '',
        seo_description: '',
        seo_keywords: '',
    },
    filterOptions: {
        prices: {
            min: 0,
            max: 0,
        },
        brands: [
        ],
        specifications: {},
        categories: [],
    },
    products: [

    ],
};

const catalogReducer = (state = initialCatalogState, action) => {
    switch (action.type) {
        case GET_PRODUCTS: {
            return {
                ...state,
                products: [...parseProductEntities(action.payload)],
            }
        }
        case GET_FILTERS: {
            const currentFilters = action.payload;

            const newFilters = {
                prices: currentFilters.prices,
                brands: currentFilters.brands ? currentFilters.brands : [],
                specifications: currentFilters.specifications ? currentFilters.specifications : [],
                categories: currentFilters.categories ? currentFilters.categories : [],
            }

            return {
                ...state,
                filterOptions: newFilters,
            };
        }
        case GET_CATEGORY_DETAILS: {
            return {
                ...state,
                categoryDetails: {...action.payload},
                appliedFilters: {
                    ...state.appliedFilters,
                    prices: action.payload.prices,
                },
            };
        }
        case APPLY_PRICE_FILTER: {
            const newPriceFilter = {
                min: !isNaN(action.payload[0]) ? action.payload[0] : state.filterOptions.prices.min,
                max: !isNaN(action.payload[1]) ? action.payload[1] : state.filterOptions.prices.max,
            };

            return {
                ...state,
                appliedFilters: {
                    ...state.appliedFilters,
                    prices: newPriceFilter,
                },
            };
        }
        case APPLY_BRAND_FILTER: {
            const brandsToApply = Object.keys(action.payload).filter(item => action.payload[item]);

            return {
                ...state,
                appliedFilters: {
                    ...state.appliedFilters,
                    brands: brandsToApply,
                },
            };
        }
        case APPLY_CATEGORY_FILTER: {
            const categoriesToApply = Object.keys(action.payload).filter(item => action.payload[item]).map(item => parseInt(item));

            return {
                ...state,
                appliedFilters: {
                    ...state.appliedFilters,
                    categories: categoriesToApply,
                },
            };
        }
        case APPLY_SPECIFICATIONS_FILTER: {
            const currentSpecifications = action.payload;

            const specificationsToApply = Object.keys(currentSpecifications)
                .reduce((acc, specification) => ([
                    ...acc,
                    {
                        name: specification,
                        values: Object.keys(currentSpecifications[specification]).reduce((acc, value) => {
                            return currentSpecifications[specification][value]? [...acc, value] : [...acc];
                        }, []),
                    },
                ]), [])
                .filter(specification => specification.values.length);

            return {
                ...state,
                appliedFilters: {
                    ...state.appliedFilters,
                    specifications: specificationsToApply,
                },
            };
        }
        case APPLY_SORT_PROPERTY: {
            return {
                ...state,
                sortProperty: action.payload,
            };
        }
        default:
            return state;
    }
};

export default catalogReducer;
