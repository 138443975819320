import React from 'react';
import closeButton from '../../images/close-btn.svg';
import LocalizedLink from '../globalComponents/LocalizedLink';

export const CartProduct = props => {
    return (
        <div key={props.basketItem.id} className="basket-page-product">
            <LocalizedLink to={`/product/${props.basketItem.slug}`} className="basket-page-product__link-wrap" href="/">
                <div className="basket-page-product__img">
                    <img src={props.basketItem.images[0]} alt={props.basketItem.name}/>
                </div>

                <div className="basket-page-product__info">
                    <div className="basket-page-product__name">{props.basketItem.name}</div>
                    <div className="basket-page-product__subtitle">{props.basketItem.name}</div>
                </div>
            </LocalizedLink>

            <div className="basket-page-product__right-section">
                <div className="basket-page-product__counter-wrap">
                    <div className="basket-counter">
                        <div className="basket-counter__dec"
                             onClick={() => props.decreaseQuantity(props.basketItem.id)}>-
                        </div>
                        <div className="basket-counter__quantity">
                            {props.basketItem.quantity}
                        </div>
                        <div className="basket-counter__inc"
                             onClick={() => props.increaseQuantity(props.basketItem.id)}>+
                        </div>
                    </div>
                </div>

                <div className="basket-page-product__price-wrap">
                    <div className="basket-page-product__price">{`${(props.basketItem.quantity * props.basketItem.final_price).toLocaleString()} грн`}</div>
                </div>

                <div className="close-btn">
                    <img
                        src={closeButton}
                        alt="delete"
                        onClick={() => {
                            props.deleteProduct(props.basketItem.id)
                            props.setActiveRemove(true)
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
