import {useSelector} from 'react-redux';
import {_language} from '../globalConsts';

const useLocalizedPath = path => {
    const language = useSelector(store => store.globalReducer.language);

    return language.key === _language.key ? `/${path}` : `/${language.key}/${path}`;
};

export default useLocalizedPath;
