import React from 'react';
import {ProductSlider} from "./ProductSlider";

export const SolutionEquipment = props => {
    return (
        <div className="product-slider-wrap">
            <h3 className="product-slider-title">Обладнання цього рішення</h3>
            <ProductSlider />
        </div>
    );
};
