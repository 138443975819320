import React, {useState} from 'react'
import {CartDeletedProduct} from './CartDeletedProduct';
import {CartProduct} from './CartProduct';

const CartItem = props => {
    const [activeRemove, setActiveRemove] = useState(false);
    const [timerId, setTimerId] = useState();

    const removeDelete = () => {
        clearTimeout(timerId)
        setActiveRemove(false)
    };

    const deleteProduct = () => {
        setActiveRemove(true)
        setTimerId(setTimeout(() => {
            props.deleteProductFromBasket(props.data.id)
            if (props.basketItems.length <= 1) {
                localStorage.setItem('basket', JSON.stringify([]))
            }
        }, 5000))
    };

    return (
        <>
            {
                activeRemove ? (
                    <CartDeletedProduct
                        basketItem={props.data}
                        removeDelete={removeDelete}
                        setActiveRemove={setActiveRemove}
                    />
                ) : (
                    <CartProduct
                        basketItem={props.data}
                        decreaseQuantity={props.decreaseProductQuantity}
                        increaseQuantity={props.increaseProductQuantity}
                        deleteProduct={deleteProduct}
                        setActiveRemove={setActiveRemove}
                    />
                )
            }
        </>
    );
};

export default CartItem;
