import React from 'react';

import videoHeader from '../../videos/personal.mp4';
import {OrderServiceButton} from '../globalComponents/OrderServiceButton';

export const PersonalServiceHeader = props => {
    return (
        <section className="personal-header">
            <div className="personal-header__bg">
                <video className="video-bg"  autoPlay loop muted>
                    <source src={videoHeader} type="video/mp4"/>
                </video>
            </div>

            <div className="personal-header-container">
                <div className="personal-header__body">
                    <h1 className="personal-header__title">{props.service.title}</h1>
                    <h2 className="personal-header__subtitle">{props.service.subtitle}</h2>
                    <OrderServiceButton service={props.service.title} />
                </div>
            </div>
        </section>
    );
};
