import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {resetPassword} from '../../../API/requests';
import useLocalizedPath from '../../../customHooks/useLocalizedPath';
import closeButton from '../../../images/close-btn.svg';
import {
    toggleModal,
    togglePasswordResetError,
    togglePasswordResetModal, togglePasswordResetSuccess,
} from '../../../redux/actionCreators/modalActions';
import {Input} from '../Input/Input';

const PasswordResetModalContainer = props => {
    const [t] = useTranslation('common');
    const localizedMainPath = useLocalizedPath('');
    const form = t('form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;

    const routerHistory = useHistory();
    const location = useLocation();

    const [passwordResetFormValues, setPasswordResetFormValues] = useState({
        email: {
            value: '',
            wasTouched: '',
            error: false,
            errorMessage,
        },
        password: {
            value: '',
            wasTouched: '',
            error: false,
            errorMessage,
        },
        password_confirmation: {
            value: '',
            wasTouched: '',
            error: false,
            errorMessage,
        }
    });

    const formFields = [
        {
            name: 'email',
            title: form.email,
            type: 'email',
        },
        {
            name: 'password',
            title: form.password,
            type: 'password',
        },
        {
            name: 'password_confirmation',
            title: form.passwordConfirmation,
            type: 'password',
        },
    ]

    const handlePasswordResetSubmit = event => {
        event.preventDefault();

        let isFormValid = true;
        Object.keys(passwordResetFormValues).forEach(field => {
            if(!validatePasswordResetField(field, passwordResetFormValues[field].value)) {
                isFormValid = false;
            }
        })

        if(isFormValid) {
            const userInfo = {
                email: passwordResetFormValues.email.value,
                password: passwordResetFormValues.password.value,
                passwordConfirmation: passwordResetFormValues.password_confirmation.value,
            }

            resetPassword(userInfo, location.search.split('=')[1]).then(data => {
                if(data.errors) {
                    const validationErrors = window._.head(data.errors).extensions.validation;

                    if(validationErrors) {
                        Object.keys(validationErrors).forEach(field => {

                            switch (window._.head(validationErrors[field])) {
                                case 'validation.confirmed': {
                                    setPasswordResetFormValues(prevState => ({
                                        ...prevState,
                                        [field]: {
                                            ...prevState[field],
                                            error: true,
                                            errorMessage: form.passwordNotEqual,
                                        }
                                    }));

                                    break;
                                }
                                case 'validation.min.string': {
                                    setPasswordResetFormValues(prevState => ({
                                        ...prevState,
                                        [field]: {
                                            ...prevState[field],
                                            error: true,
                                            errorMessage: form.minWidth,
                                        }
                                    }))

                                }

                            }
                        })
                    }
                } else if(data.data) {
                    data.data.resetPassword ? openSuccessModal() : openErrorModal();
                }
            });
        }
    };

    const handlePasswordResetFormChange = event => {
        const {name, value} = event.target;

        setPasswordResetFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
                error: false,
            },
        }));

        if((name === 'password' || name === 'password_confirmation') && passwordResetFormValues.password.errorMessage === form.passwordNotEqual) {
            setPasswordResetFormValues(prevState => ({
                ...prevState,
                password: {
                    ...prevState.password,
                    value,
                    error: false,
                }
            }))
        }
    };

    const validatePasswordResetField = (name, value) => {
        setPasswordResetFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                wasTouched: true,
            }
        }));

        if(!value.trim()) {
            setPasswordResetFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    error: true,
                    errorMessage,
                },
            }));

            return false;
        }

        return true;
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.togglePasswordResetModal(false);
        routerHistory.push(localizedMainPath);
    };

    const openSuccessModal = () => {
        props.togglePasswordResetSuccess(true);
        props.togglePasswordResetModal(false);
    };

    const openErrorModal = () => {
        props.togglePasswordResetModal(false);
        props.togglePasswordResetError(true);
    };

    return (
        <div className={`password-recovery-modal ${props.passwordReset ? 'active' : ''}`}>
            <div className="close-btn" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>

            <form className="password-recovery-modal__body" onSubmit={handlePasswordResetSubmit}>
                <h5 className="password-recovery-modal__title">{t('passwordResetModal.title')}</h5>
                <p className="password-recovery-modal__subtitle">
                    {t('passwordResetModal.subtitle')}
                </p>

                {
                    formFields.map(field => (
                        <Input
                            key={field.name}
                            className={`form__input ${passwordResetFormValues[field.name].error ? 'invalid' : ''} ${!passwordResetFormValues[field.name].error && passwordResetFormValues[field.name].wasTouched ? 'valid': ''}`}
                            type={field.type}
                            name={field.name}
                            required
                            value={passwordResetFormValues[field.name].value}
                            handleChange={(event) => {
                                handlePasswordResetFormChange(event);
                                validatePasswordResetField(event.target.name, event.target.value);
                            }}
                            handleBlur={event => validatePasswordResetField(event.target.name, event.target.value)}
                            title={field.title}
                            isErrorOccurred={passwordResetFormValues[field.name].error && passwordResetFormValues[field.name].wasTouched}
                            errorMessage={passwordResetFormValues[field.name].errorMessage}
                        />
                    ))
                }

                <button
                    className="password-recovery-modal__send-btn btn-additional"
                    type="submit"
                >
                    {form.sendButton}
                </button>
            </form>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        passwordReset: store.modalsState.passwordReset,
        token: store.globalReducer.resetPasswordToken,
    };
};

const mapDispatchToProps = {
    toggleModal,
    togglePasswordResetModal,
    togglePasswordResetError,
    togglePasswordResetSuccess,
};

export const PasswordResetModal = connect(mapStateToProps, mapDispatchToProps)(PasswordResetModalContainer);