import React, {useEffect, useState} from 'react';

import {useParams, useHistory} from 'react-router-dom';
import './TransportSolution.scss';
import '../SolutionChoosing/Solutions.scss';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PreLoader} from '../globalComponents/PreLoader';
import Page404 from '../Page404';
import {TransportSolutionHeader} from "./TransportSolutionHeader";
import {connect} from "react-redux";
import {OtherSolutions} from "../globalComponents/OtherSolutions";
import {CapabilitiesSlider} from "../globalComponents/CapabilitiesSlider";
import {SolutionOpportunitySelect} from "../globalComponents/SolutionOpportunitySelect";
import {SolutionEquipment} from "../globalComponents/SolutionEquipment";
import {getSolution} from "../../API/requests";
import {getTransportSolution} from "../../redux/actionCreators/getActionCreators/solutionsActions";

const TransportSolutionContainer = props => {
    const [t] = useTranslation('solutions');
    const [isErrorOccurred, setIsErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {solutionType} = useParams();
    const history = useHistory();

    useEffect(() => {
        if(solutionType) {
            saveSolution(solutionType);
        } else {
            history.replace('/solution/transport/freight-transport');
        }
    }, [solutionType]);

    const saveSolution = solutionType => {
        setIsErrorOccurred(false);
        setIsLoading(true);

        getSolution(solutionType)
            .then(solution => {
                props.getTransportSolution(solution);
                setIsLoading(false);
            })
            .catch(() => setIsErrorOccurred(true));
    };

    return (
        <>
            {
                isErrorOccurred
                    ? (
                        <Page404 />
                    )
                    : (
                        <main className="transport-solutions">
                            {
                                isLoading
                                    ? (
                                        <PreLoader />
                                    )
                                    : (
                                        <>
                                            <MetaTagsComponent
                                                title={props.solution.name}
                                                seoTitle={props.solution.name}
                                                seoDescription={props.solution.short_description}
                                            />

                                            <TransportSolutionHeader
                                                shortDescription={props.solution.short_description}
                                                name={props.solution.name}
                                                transportImage={props.solution.transportImage}
                                                backgroundImage={props.solution.backgroundImage}
                                                solutionType={props.match.params.solutionType}
                                                title={t('header.transportSolution')}
                                            />

                                            <div className="container">
                                                <SolutionOpportunitySelect
                                                    solution={props.solution}
                                                    title={t('solutionOpportunities')}
                                                />
                                            </div>

                                            <CapabilitiesSlider
                                                capabilities={props.solution.capabilities}
                                                title={t('capabilitiesSlider')}
                                            />

                                            <section className="personal__description">
                                                <div className="personal__description-text-wrap">
                                                    <div className="personal__description-text" dangerouslySetInnerHTML={{__html: props.solution.description}} />
                                                </div>
                                            </section>

                                            <div className="container">
                                                {
                                                    (props.solution.products.length > 0) && (
                                                        <SolutionEquipment />
                                                    )
                                                }

                                                <OtherSolutions
                                                    solutions={props.transportSolutions}
                                                    solutionsCategory={'transport'}
                                                    title={t('otherSolutions.transport')}
                                                />
                                            </div>
                                        </>
                                    )
                            }
                        </main>
                    )
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        solution: state.transportSolutionState.solution,
        transportSolutions: state.solutionsState.transportSolutions,
    };
};

const mapDispatchToProps = {
    getTransportSolution,
};

export const TransportSolution = connect(mapStateToProps, mapDispatchToProps)(TransportSolutionContainer);
