import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {AboutCompanyVacanciesForm} from "./AboutCompanyVacanciesForm";
import {AboutCompanyVacanciesTabs} from "./AboutCompanyVacanciesTabs";
import {getVacancies} from "../../API/requests";

export const AboutCompanyVacancies = props => {
    const [t] = useTranslation('aboutUs');
    const [vacancies, setVacancies] = useState([]);
    const [selectedVacancy, setSelectedVacancy] = useState(0);

    useEffect(() => {
        getVacancies().then(vacancies => {
            setVacancies(vacancies);
            setSelectedVacancy(vacancies[0]?.id)
        });
    }, []);

    return (
        <>
            {
                (vacancies ? vacancies.length > 0 : false)
                    ? (
                        <section className="vacancies">
                            <div className="vacancies-container">
                                <h3 className="vacancies__title">{t('vacancies.title')}</h3>
                                <div className="vacancies__body">
                                    {
                                        (vacancies ? vacancies.length > 0 : false) && (
                                            <>
                                                <AboutCompanyVacanciesTabs vacancies={vacancies} setSelectedVacancy={setSelectedVacancy}/>
                                                <AboutCompanyVacanciesForm selectedVacancy={selectedVacancy} />
                                            </>

                                        )
                                    }


                                </div>
                            </div>
                        </section>
                    )
                    : (
                        <></>
                    )
            }

        </>

    );
};
