import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';

import filterIcon from '../../images/catalog/filter-ico-mobile.svg';
import blackArrowDown from '../../images/ar-down--black.svg';
import {connect} from 'react-redux';
import {applySortProperty} from '../../redux/actionCreators/catalogActions';

const CatalogHeaderContainer = props => {
    const [t] = useTranslation('catalog');
    const [sortProperty, setSortProperty] = useState({
        column: '',
        direction: '',
    });
    const isMounted = useRef(false);
    const [isSortPropertiesMenuActive, setIsSortPropertiesMenuActive] = useState(false);

    useEffect(() => {
        if (isMounted.current) {
            props.applySortProperty(sortProperty);
        } else isMounted.current = true;
    }, [sortProperty]);

    const changeSortProperty = (column, direction, changeType = 'set') => {
        if (changeType === 'set') {
            setSortProperty({
                column,
                direction,
            });
        }

        if (changeType === 'toggle') {
            setSortProperty(prevState => ({
                column,
                direction: prevState.direction === 'desc' && prevState.column !== 'views' ? 'asc' : 'desc',
            }));
        }

        toggleSortPropertiesMenu();
    };

    const toggleSortPropertiesMenu = () => {
        setIsSortPropertiesMenuActive(prevState => !prevState);
    }

    return (
        <div className="catalog__heading">
            <div className="catalog-mobile__heading">
                <button className="catalog-mobile__filter-btn" onClick={props.toggleFilterMenu}>
                    <img src={filterIcon} alt="filter icon"/>
                    <span>{t('catalogHeader.filters')}</span>
                </button>

                <div className="catalog-mobile__filter-sort">
                    <div className="catalog-mobile__sort-title" onClick={toggleSortPropertiesMenu}>
                        {
                            sortProperty.column === 'views'
                                ? t('catalogHeader.sortProperties.views.mobileTitle')
                                : sortProperty.direction === 'desc'
                                ? t('catalogHeader.sortProperties.price.priceDesc')
                                : t('catalogHeader.sortProperties.price.priceAsc')
                        }
                    </div>

                    <div className={`catalog-mobile__sort-items ${isSortPropertiesMenuActive ? 'active' : ''}`}>
                        <div className="catalog-mobile__sort-item"
                             onClick={() => changeSortProperty('views', 'desc')}>{t('catalogHeader.sortProperties.views.mobileTitle')}</div>
                        <div className="catalog-mobile__sort-item"
                             onClick={() => changeSortProperty('final_price', 'asc')}>{t('catalogHeader.sortProperties.price.priceAsc')}</div>
                        <div className="catalog-mobile__sort-item"
                             onClick={() => changeSortProperty('final_price', 'desc')}>{t('catalogHeader.sortProperties.price.priceDesc')}</div>
                    </div>
                </div>
            </div>

            <h1 className="catalog__header">{props.categoryTitle}</h1>

            <div className="catalog__heading-filter">
                <div className="catalog__hfilter-title">{`${t('catalogHeader.sortTitle')}:`}</div>
                <div
                    onClick={() => changeSortProperty('views', 'desc')}
                    className={`catalog__hfilter catalog__hfilter--popular ${sortProperty.column === 'views' ? 'active' : ''}`}
                >
                    {t('catalogHeader.sortProperties.views.desktopTitle')}
                </div>

                <div
                    onClick={() => changeSortProperty('final_price', '', 'toggle')}
                    className={`catalog__hfilter catalog__hfilter--price ${sortProperty.column === 'final_price' ? 'active' : ''}`}
                >
                    {t('catalogHeader.sortProperties.price.title')}
                    <img
                        className={`catalog__hfilter-arrow arrow-down ${sortProperty.direction === 'asc' ? 'active' : ''} ${sortProperty === 'price' ? '' : 'hidden'}`}
                        alt="dropdown icon"
                        src={blackArrowDown}
                    />
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = state => {
    return {
        appliedFilters: state.catalogState.appliedFilters,
        sortProperty: state.catalogState.sortProperty,
    };
};

const mapDispatchToProps = {
    applySortProperty,
};

export const CatalogHeader = connect(mapStateToProps, mapDispatchToProps)(CatalogHeaderContainer)
