import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import useLocalizedPath from '../../customHooks/useLocalizedPath';
import bonusIco from '../../images/bonus-ico.svg';
import LocalizedLink from '../globalComponents/LocalizedLink';

const CartFooterContainer = props => {
    const [t] = useTranslation('cart');
    const localizedCatalogPath = useLocalizedPath(`catalog/${props.currentCategory.slug}`);
    const localizedOrderPath = useLocalizedPath('order')

    const history = useHistory();

    return (
        <div className="basket-page__footer">
            <div className="basket-page__footer-left-wrap">
                <LocalizedLink
                    to={localizedCatalogPath}
                    className="basket-page__back-btn btn-ghost-secondary"
                    href="/"
                >
                    {t('continueButton')}
                </LocalizedLink>
            </div>

            <div className="basket-page__footer-right-wrap">
                <div className="basket-page__bonus">
                    {/*<img src={bonusIco} alt="Бонус" />*/}
                    {/*<div className="basket-page__bonus-label">Ви отримаєте</div>*/}
                    {/*<a className="basket-page__bonus-value" href="/">+{props.basketItems.reduce((i, item) => {*/}
                    {/*    return (*/}
                    {/*        i + (item.bonus_points * item.quantity)*/}
                    {/*    )*/}
                    {/*}, 0)} балів</a>*/}
                </div>

                <div className="basket-page__total">{t('total')}</div>
                <div className="basket-page__total-price">
                    {
                        props.basketItems.reduce((i, item) => {
                            return (
                                i + (item.final_price * item.quantity)
                            )
                        }, 0).toLocaleString()
                    } грн
                </div>

                <button
                    className="basket-page__confirm-btn btn-accent"
                    onClick={() => {
                        history.push(localizedOrderPath)
                    }}
                >
                    {t('checkoutButton')}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        currentCategory: state.catalogState.categoryDetails,
    };
};

export const CartFooter = connect(mapStateToProps)(CartFooterContainer);
