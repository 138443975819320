import React, {useState} from "react";

import './Solutions.scss';
import solutionImg1 from '../../../images/main-page/solutions/solution-img1.webp';
import solutionImg2 from '../../../images/main-page/solutions/solution-img2.webp';
import solutionImg3 from '../../../images/main-page/solutions/solution-img3.webp';
import solutionImg4 from '../../../images/main-page/solutions/solution-img4.webp';
import solutionImg5 from '../../../images/main-page/solutions/solution-img5.webp';
import solutionImg6 from '../../../images/main-page/solutions/solution-img6.webp';
import solutionImg7 from '../../../images/main-page/solutions/solution-img7.webp';
import solutionImg8 from '../../../images/main-page/solutions/solution-img8.webp';
import LocalizedLink from '../../globalComponents/LocalizedLink';

export const Solution = props => {
    const solutions = [
        {
            title: props.translation.solutionsList.logistics,
            type: 'logistics',
            image: solutionImg1,
            id: 1,
        },
        {
            title: props.translation.solutionsList.deliveryServices,
            type: 'delivery-services',
            image: solutionImg2,
            id: 2,
        },
        {
            title: props.translation.solutionsList.agribusiness,
            type: 'agribusiness',
            image: solutionImg3,
            id: 3,
        },
        {
            title: props.translation.solutionsList.constructionMining,
            type: 'construction-mining',
            image: solutionImg4,
            id: 4,
        },
        {
            title: props.translation.solutionsList.carRental,
            type: 'car-rental',
            image: solutionImg5,
            id: 5,
        },
        {
            title: props.translation.solutionsList.insurance,
            type: 'insurance',
            image: solutionImg6,
            id: 6,
        },
        {
            title: props.translation.solutionsList.utilities,
            type: 'utilities',
            image: solutionImg7,
            id: 7,
        },
        {
            title: props.translation.solutionsList.publicTransport,
            type: 'public-transport',
            image: solutionImg8,
            id: 8,
        },
    ];
    const [activeSolution, setActiveSolution] = useState(solutions[0]);

    const handleSolutionChange = (index) => {
        setActiveSolution(solutions[index])
    };

    return (
        <section className="industry-solutions-wrap">
            <div className="industry-solutions">
                <div className="industry-solutions__content-box">
                    <div className="industry-solutions__content-img-box">
                        {
                            solutions.map(solution => (
                                <img
                                    className={`industry-solutions__content-img ${solution.id === activeSolution.id ? 'active' : ''}`}
                                    alt="solution"
                                    key={solution.title}
                                    src={solution.image}
                                />
                            ))
                        }
                    </div>

                    <div className="industry-solutions__content-selectors">
                        <ul className="industry-solutions__content-list">
                            <h2 className="industry-solutions__title">{props.translation.title}</h2>
                            {
                                solutions.map((solution, index) => (
                                    <li
                                        className="industry-solutions__content-list-item"
                                        onMouseOver={() => handleSolutionChange(index)}
                                        key={index}
                                    >
                                        <LocalizedLink to={`/solution/industry/${solution.type}`}>
                                            <span className="industry-solutions__content-list-text">- {solution.title}</span>
                                            <span className="industry-solutions__arrow">
                                                <svg className="is-ar1" width="71" height="8" viewBox="0 0 71 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M70.3536 4.35355C70.5488 4.15829 70.5488 3.84171 70.3536 3.64645L67.1716 0.464466C66.9763 0.269204 66.6597 0.269204 66.4645 0.464466C66.2692 0.659728 66.2692 0.976311 66.4645 1.17157L69.2929 4L66.4645 6.82843C66.2692 7.02369 66.2692 7.34027 66.4645 7.53553C66.6597 7.7308 66.9763 7.7308 67.1716 7.53553L70.3536 4.35355ZM0 4.5H70V3.5H0V4.5Z"
                                                        fill="#2C599D"
                                                    />
                                                </svg>

                                                <svg className="is-ar2" width="17" height="8" viewBox="0 0 17 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464467C12.9763 0.269205 12.6597 0.269205 12.4645 0.464467C12.2692 0.659729 12.2692 0.976312 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53554L16.3536 4.35355ZM-4.37114e-08 4.5L16 4.5L16 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="#2C599D"
                                                    />
                                                </svg>
                                            </span>
                                        </LocalizedLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};
