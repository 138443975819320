import {
    TOGGLE_BASKET_MODAL,
    TOGGLE_COMMERCIAL_OFFER_MODAL,
    TOGGLE_ENTRY_MODAL,
    TOGGLE_MODAL,
    TOGGLE_QUESTION_MODAL,
    TOGGLE_SUBSCRIBE_MODAL,
    TOGGLE_CALLBACK_MODAL,
    TOGGLE_PASSWORD_RECOVERY_MODAL,
    TOGGLE_EMPTY_BASKET_MODAL,
    TOGGLE_EMPTY_COMPARE_MODAL,
    TOGGLE_CITY_SELECT_MODAL,
    TOGGLE_REGISTRATION_SUCCESS_MODAL,
    TOGGLE_APPLICATION_SUCCESS_MODAL,
    TOGGLE_SERVICE_ORDER_MODAL,
    TOGGLE_SOLUTION_ORDER_MODAL,
    TOGGLE_PASSWORD_RESET_MODAL,
    TOGGLE_PASSWORD_RESET_SUCCESS_MODAL,
    TOGGLE_PASSWORD_RESET_ERROR_MODAL, TOGGLE_ORDER_ID_MODAL, TOGGLE_DEAL_SUCCESS,
} from '../actionConsts/actionConsts';

export const toggleModal = payload => {
    return {
        type: TOGGLE_MODAL,
        payload,
    }
};

export const toggleSubscribe = payload => {
    return {
        type: TOGGLE_SUBSCRIBE_MODAL,
        payload,
    }
};

export const toggleCommercialOffer = payload => {
    return {
        type: TOGGLE_COMMERCIAL_OFFER_MODAL,
        payload,
    }
};

export const toggleQuestion = payload => {
    return {
        type: TOGGLE_QUESTION_MODAL,
        payload,
    }
};

export const toggleBasket = payload => {
    return {
        type: TOGGLE_BASKET_MODAL,
        payload,
    };
};

export const toggleEntry = payload => {
    return {
        type: TOGGLE_ENTRY_MODAL,
        payload,
    };
};

export const toggleCallback = payload => {
    return {
        type: TOGGLE_CALLBACK_MODAL,
        payload,
    };
};

export const togglePasswordRecovery = payload => {
    return {
        type: TOGGLE_PASSWORD_RECOVERY_MODAL,
        payload,
    };
};

export const toggleEmptyBasket = payload => {
    return {
        type: TOGGLE_EMPTY_BASKET_MODAL,
        payload,
    };
};

export const toggleEmptyCompare = payload => {
    return {
        type: TOGGLE_EMPTY_COMPARE_MODAL,
        payload,
    };
};

export const toggleCitySelect = payload => {
    return {
        type: TOGGLE_CITY_SELECT_MODAL,
        payload,
    };
};

export const toggleRegistrationSuccess = payload => {
    return {
        type: TOGGLE_REGISTRATION_SUCCESS_MODAL,
        payload,
    };
};

export const toggleApplicationSuccess = payload => {
    return {
        type: TOGGLE_APPLICATION_SUCCESS_MODAL,
        payload,
    };
};

export const toggleServiceOrder = payload => {
    return {
        type: TOGGLE_SERVICE_ORDER_MODAL,
        payload,
    };
};

export const toggleSolutionOrder = payload => {
    return {
        type: TOGGLE_SOLUTION_ORDER_MODAL,
        payload,
    };
};

export const togglePasswordResetModal = payload => {
    return {
        type: TOGGLE_PASSWORD_RESET_MODAL,
        payload,
    };
};

export const togglePasswordResetSuccess = payload => {
    return {
        type: TOGGLE_PASSWORD_RESET_SUCCESS_MODAL,
        payload,
    };
};

export const togglePasswordResetError = payload => {
    return {
        type: TOGGLE_PASSWORD_RESET_ERROR_MODAL,
        payload,
    };
};

export const toggleOrderIdModal = payload => {
    return {
        type: TOGGLE_ORDER_ID_MODAL,
        payload,
    };
};

export const toggleDealSuccess = payload => {
    return {
        type: TOGGLE_DEAL_SUCCESS,
        payload,
    };
};
