import axios from "axios";
import lodash from "lodash";

const _apiNewPost = "https://api.novaposhta.ua/v2.0/json/";
const npKey = "4ef01d3b8cb8b07bb46b5068c4239ca2";

export const getCitiesFromNewPost = async (city) => {
  const response = await axios({
    method: "post",
    url: _apiNewPost,
    data: {
      modelName: "Address",
      calledMethod: "searchSettlements",
      methodProperties: {
        CityName: city,
        Limit: 10,
      },
      apiKey: npKey,
    },
  });

  const responseData = lodash.head(response.data.data);

  return responseData ? responseData.Addresses : [];
};

export const getWarehousesByCity = async (cityName, street) => {
  const response = await axios({
    method: "post",
    url: _apiNewPost,
    data: {
      modelName: "AddressGeneral",
      calledMethod: "getWarehouses",
      methodProperties: {
        CityName: cityName,
        FindByString: street,
        Limit: 15,
      },
      apiKey: npKey,
    },
  });

  return response.data.data;
};
