import React from 'react';
import {useTranslation} from 'react-i18next';
import dropImage from "../../images/header/drop-img.jpg";
import LocalizedLink from './LocalizedLink';

export const DropMenuBanner = props => {
    const [t] = useTranslation('common');

    return (
        <div className="header-bottom__drop-pic drop-pic">
            <div className="drop-pic">
                <div className="drop-pic__wrapper">
                    <div className="drop-pic__bg">
                        <div className="drop-pic__bg-gradient" />
                        <img
                            alt={t('dropMenuBanner.title')}
                            className="drop-pic__image-bg"
                            src={dropImage}
                        />
                    </div>


                    <div className="drop-pic__content-wrap">
                        <div className="drop-pic__content">
                            <h2 className="drop-pic__title">{t('dropMenuBanner.title')}</h2>
                            <h3 className="drop-pic__subtitle">{t('dropMenuBanner.subtitle')}</h3>
                            <LocalizedLink
                                to={`/services/gps`}
                                className="btn-ghost-secondary"
                                onClick={props.toggleMenu}
                            >
                                {t('dropMenuBanner.detailsButtonText')}
                            </LocalizedLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
