import React from 'react';

export const Toggler = props => {
    return (
        <div className="switcher-checkbox-wrap">
            <label className="switcher-checkbox">
                <input
                    className="switcher-checkbox-hidden"
                    type="checkbox"
                    name="individual"
                    checked={props.checked}
                    onChange={() => props.onChange(props.specification.name, props.specification.value)}
                />

                <div className="switcher-checkbox__body">
                    <div className="switcher-checkbox__button" />
                </div>
            </label>
        </div>
    );
};
