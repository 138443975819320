import React from 'react';

export const CasesArticleTask = props => {
    return (
        <div className="cases-article__text">
            <h3 className="cases-article__title">{props.title}</h3>
            <p className="cases-article__paragraph" dangerouslySetInnerHTML={{__html: props.problem}} />
        </div>
    );
};
