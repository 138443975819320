import {_api} from '../apiConsts';

export const changeUserData = userInfo => {
    return window.axios.post(`${_api}/auth`, {
        params: {
            query: `
                mutation(
                    $name: String!
                    $fathers_name: String
                    $last_name: String!
                    $email: String!
                    $phone: String!
                    $legal: LegalInput
                ) {
                    changeUserData(
                        name: $name
                        fathers_name: $fathers_name
                        last_name: $last_name
                        email: $email
                        phone: $phone
                        legal: $legal
                    )
                }
            `,
            variables: {
                name: userInfo.name,
                fathers_name: userInfo.fathers_name,
                last_name: userInfo.last_name,
                email: userInfo.email,
                phone: userInfo.phone,
                legal: userInfo.legal ? userInfo.legal : null,
            },
        },
    }).then(response => window._.head(response.data))
        .catch(error => console.log(error))
};
