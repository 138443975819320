import React, {useEffect, useState, useRef} from 'react';

import './Blog.scss';
import {useTranslation} from 'react-i18next';
import {getPosts} from '../../API/requests';
import {EmptyPage} from '../globalComponents/EmptyPage/EmptyPage';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {Pagination} from "../globalComponents/Pagination";
import {PreLoader} from '../globalComponents/PreLoader';
import {BlogFilters} from "./BlogFilters";
import {BlogItem} from "./BlogItem";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const Blog = props => {
    const [t] = useTranslation('blog');
    const [posts, setPosts] = useState([]);
    const [filters, setFilters] = useState({});
    const [activePage, setActivePage] = useState(1);
    const prevPage = usePrevious(activePage);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [selectedSection, setSelectedSection] = useState({id: null, name: ''});
    const [selectedTopic, setSelectedTopic] = useState({id: null, name: ''});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(activePage === prevPage) {
            setActivePage(1);
            getBlogPosts(1);
        } else {
            window.scrollTo(0, 0);
            getBlogPosts(activePage)
        }
    }, [activePage, selectedSection, selectedTopic]);
    
    const getBlogPosts = pageNumber => {
        setIsLoading(true);
        getPosts(selectedTopic.id, selectedSection.id, pageNumber).then(data => {
            if(data) {
                setPosts(data.posts.data);
                setNumberOfPages(data.posts.last_page);
                setFilters(data.postFilters);
            }
            setIsLoading(false);
        });
    };

    const selectTopic = topic => {
        setSelectedTopic(topic);
    };

    const selectSection = section => {
        setSelectedSection(section);
    };

    const changeActivePage = payload => {
        switch (payload) {
            case 'next': {
                setActivePage(prevState => {
                    if(prevState === numberOfPages) return prevState;

                    return prevState + 1;
                });
                break;
            }
            case 'prev': {
                setActivePage(prevState => {
                    if(prevState === 1) return prevState;

                    return prevState - 1;
                });
                break;
            }
            default: setActivePage(payload);
        }
    };

    return (
        <div className="container">
            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={
                    posts.reduce((acc, post, index) => {
                        if(acc.length > 160) return acc;
                        return  index === 0 ? post.title : `${acc}, ${post.title}`
                    }, '')
                }
            />

            <main className="blog">
                <h1 className="blog__title">{t('title')}</h1>
                <div className="blog__content-wrap">
                    {
                        isLoading
                            ? (
                                <PreLoader />
                            )
                            : (
                                <>
                                    <BlogFilters
                                        filters={filters}
                                        selectSection={selectSection}
                                        selectedSection={selectedSection}
                                        selectTopic={selectTopic}
                                        selectedTopic={selectedTopic}
                                    />


                                    {
                                        posts.length > 0
                                            ? (
                                                <div className="blog__content">
                                                    {
                                                        posts.map(post => (
                                                            <BlogItem key={post.id} article={post} />
                                                        ))
                                                    }
                                                </div>
                                            )
                                            : (
                                                <EmptyPage />
                                            )
                                    }

                                </>
                            )
                    }

                </div>

                {
                    (numberOfPages > 1) && (
                        <Pagination
                            activePage={activePage}
                            changeActivePage={changeActivePage}
                            numberOfPages={numberOfPages}
                        />
                    )
                }
            </main>
        </div>
    );
};
