import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {addProductToBasket} from "../../../redux/actionCreators/basketActions";
import {toggleBasket, toggleModal} from "../../../redux/actionCreators/modalActions";
import {deleteProductFromCompare} from "../../../redux/actionCreators/compareActions";
import LocalizedLink from '../../globalComponents/LocalizedLink';

const CompareProductCardContainer = props => {
    const [t] = useTranslation('common');

    const addProductToBasket = () => {
        props.addProductToBasket({
            ...props.product,
            quantity: 1,
        });
        props.toggleModal(true);
        props.toggleBasket(true);
    };

    return (
        <div className="product-item-wrap">
            <div className="product-item">
                <button className="product-item__compare-button close-btn" onClick={() => {
                    props.deleteProductFromCompare(props.product.id)
                }}>
                    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.29289 8.8925C7.90237 9.28303 7.90237 9.91619 8.29289 10.3067L13.5858 15.5996L8.29289 20.8925C7.90237 21.283 7.90237 21.9162 8.29289 22.3067C8.68342 22.6972 9.31658 22.6972 9.70711 22.3067L15 17.0138L20.2929 22.3067C20.6834 22.6972 21.3166 22.6972 21.7071 22.3067C22.0976 21.9162 22.0976 21.283 21.7071 20.8925L16.4142 15.5996L21.7071 10.3067C22.0976 9.91619 22.0976 9.28303 21.7071 8.8925C21.3166 8.50198 20.6834 8.50198 20.2929 8.8925L15 14.1854L9.70711 8.8925C9.31658 8.50198 8.68342 8.50198 8.29289 8.8925Z"
                              fill="#1A3B70"
                        />
                        <mask id="mask0" maskUnits="userSpaceOnUse" x="8" y="8" width="14"
                              height="15">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8.29289 8.8925C7.90237 9.28303 7.90237 9.91619 8.29289 10.3067L13.5858 15.5996L8.29289 20.8925C7.90237 21.283 7.90237 21.9162 8.29289 22.3067C8.68342 22.6972 9.31658 22.6972 9.70711 22.3067L15 17.0138L20.2929 22.3067C20.6834 22.6972 21.3166 22.6972 21.7071 22.3067C22.0976 21.9162 22.0976 21.283 21.7071 20.8925L16.4142 15.5996L21.7071 10.3067C22.0976 9.91619 22.0976 9.28303 21.7071 8.8925C21.3166 8.50198 20.6834 8.50198 20.2929 8.8925L15 14.1854L9.70711 8.8925C9.31658 8.50198 8.68342 8.50198 8.29289 8.8925Z"
                                  fill="white"
                            />
                        </mask>
                        <g mask="url(#mask0)">
                            <rect y="0.599609" width="30" height="30" fill="#1A3B70" />
                        </g>
                    </svg>
                </button>

                <LocalizedLink to={`/product/${props.product.slug}`} className="product-item__content">
                    <div className="product-item__img-wrap">
                        <img
                            alt={props.product.name}
                            className="product-item__img"
                            src={props.product.images[0]}
                        />
                    </div>
                </LocalizedLink>

                <div className="product-item__info">
                    <LocalizedLink to={`/product/${props.product.slug}`} className="product-item__content">
                        <h5 className="product-item__title">{props.product.name}</h5>
                    </LocalizedLink>

                    <div className="product-item__buy-box">
                        <p className="product-item__price">{`${props.product.final_price.toLocaleString()} грн`}</p>
                        <button className="btn-accent" onClick={addProductToBasket}>{t('addToBasketButton')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapDispatchToProps = {
    addProductToBasket,
    deleteProductFromCompare,
    toggleModal,
    toggleBasket,
};

export const CompareProductCard = connect(null, mapDispatchToProps)(CompareProductCardContainer);
