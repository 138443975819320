import React, {useState} from 'react';
import './Subscribe.scss';
import {useTranslation} from 'react-i18next';
import subscribeBg from '../../../images/main-page/subscription-bg.webp';
import validationFailIcon from '../../../images/main-page/mail-validation-fail.svg';
import mailIcon from '../../../images/main-page/mail-ico.svg';
import {connect} from 'react-redux';
import {toggleModal, toggleSubscribe} from '../../../redux/actionCreators/modalActions';
import {subscribeMailing} from '../../../API/requests';
import {createSubscriberContacts} from '../../../API/birixApi/createSubscriberContacts';
import ReactGA from 'react-ga';

const SubscribeContainer = props => {
    const [t] = useTranslation('common');
    const subscribe = t('subscribe', {returnObjects: true});

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);

    const handleSubmit = event => {
        event.preventDefault();

        if (!email.trim()) {
            setIsEmailValid(false);
        } else {
            setIsEmailValid(true);
            props.toggleModal(true);
            props.toggleSubscribe(true);
            subscribeMailing(email);
            createSubscriberContacts(email);
            ReactGA.event( {
                category: 'Email Subscription',
                action: 'Subscribe-to-Mailing',
                label: 'Подписка на рассылку',
            });
        }
    };

    const handleEmailChange = event => {
        setEmail(event.target.value)
    };

    return (
        <section className="subscription-wrap">
            <img
                alt="subscribe"
                className="subscription-bg"
                src={subscribeBg}
            />

            <div className="container">
                <div className="subscription">
                    <div className="subscription__title-wrap">
                        <div className="subscription__title">{subscribe.title}</div>
                        <div className="subscription__subtitle">
                            {subscribe.subtitle}
                        </div>
                    </div>

                    <form
                        className="subscription__form"
                        onSubmit={handleSubmit}
                    >
                        <div className="btn-accent__mail-ico">
                            {
                                (
                                    isEmailValid
                                        ? (
                                            <img
                                                alt="mail icon"
                                                className="subscription__mail-ico active"
                                                src={mailIcon}
                                            />
                                        )
                                        : (
                                            <img
                                                alt="mail validation fail"
                                                src={validationFailIcon}
                                                className="subscription__validation-ico--fai"
                                            />
                                        )
                                )
                            }
                        </div>

                        <input
                            className="subscription__email-input"
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder={subscribe.subscribePlaceholder}
                        />

                        <button
                            className="subscription__confirm-btn btn-accent"
                            type="submit"
                            name="mail-subs"
                        >
                            {subscribe.subscribeButtonText}
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleSubscribe,
};

export const Subscribe = connect(null, mapDispatchToProps)(SubscribeContainer);
