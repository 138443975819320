export const getBrands = () => {
    return window.axios.get('', {
        params: {
            query: `
                query {
                    brands {
                        id
                        name
                        image
                    }
                }
            `
        },
    }).then(response => response.data.data.brands)
        .catch(error => console.log(error));
};
