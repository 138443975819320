import React from 'react';

export const ProductCounter = props => {
    return (
        <div className="product__quantity">
            <div className="product__quantity-title">{props.quantityTranslation}</div>
            <div className="product__quantity-counter">
                <div onClick={props.decreaseCount} className="product__quantity-button product__quantity-button--dec">-</div>
                <div className="product__quantity-button product__quantity-value">{props.productCount}</div>
                <div onClick={props.increaseCount} className="product__quantity-button product__quantity-button--inc">+</div>
            </div>
        </div>
    );
};
