import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

export const BlogFilters = props => {
    const [t] = useTranslation('blog');
    const [isSectionSelectActive, setIsSectionSelectActive] = useState(false);
    const [isTopicSelectActive, setIsTopicSelectActive] = useState(false);

    const toggleSelect = selectType => {
        switch (selectType) {
            case 'section': {
                setIsSectionSelectActive(prevState => !prevState);
                break;
            }
            case 'topic': {
                setIsTopicSelectActive(prevState => !prevState);
                break;
            }
        }
    };

    return (
        <div className="blog__filter-wrap">
            <div className={`blog__filter blog__filter--sections ${isSectionSelectActive ? 'active' : ''}`} onClick={() => toggleSelect('section')}>
                <div className="blog__filter-head">
                    <span className="blog__filter-title">{`${t('sectionsTitle')}:`}</span>
                    <span className="blog__filter-selected">{`${props.selectedSection.name ? props.selectedSection.name : t('anySection')}`}</span>
                    <svg className="arrow-down" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.00039 9.27201L3.76839 5.04001C3.62439 4.88801 3.42439 4.80001 3.20039 4.80001C2.76039 4.80001 2.40039 5.16001 2.40039 5.60001C2.40039 5.82401 2.48839 6.02401 2.63239 6.16801L7.43239 10.968C7.57639 11.112 7.77639 11.2 8.00039 11.2C8.22439 11.2 8.42439 11.112 8.56839 10.968L13.3684 6.16801C13.5124 6.02401 13.6004 5.82401 13.6004 5.60001C13.6004 5.16001 13.2404 4.80001 12.8004 4.80001C12.5764 4.80001 12.3764 4.88801 12.2324 5.03201L8.00039 9.27201Z"
                              fill="#273043" />
                    </svg>
                </div>

                <ul className="blog__filter-drop-content">
                    <li className="blog__filter-drop-item" onClick={() => props.selectSection({id: null, name: ''})}>{t('anySection')}</li>

                    {
                        (props.filters.sections) && (
                            Object.keys(props.filters.sections).map(key => (
                                <li
                                    key={props.filters.sections[key]}
                                    className="blog__filter-drop-item"
                                    onClick={() => props.selectSection({id: parseInt(key), name: props.filters.sections[key]})}
                                >
                                    {props.filters.sections[key]}
                                </li>
                            ))
                        )
                    }
                </ul>
            </div>

            <div className={`blog__filter blog__filter--Topic ${isTopicSelectActive ? 'active' : ''}`} onClick={() => toggleSelect('topic')}>
                <div className="blog__filter-head" >
                    <span className="blog__filter-title">{`${t('topicTitle')}:`}</span>
                    <span className="blog__filter-selected">{`${props.selectedTopic.name ? props.selectedTopic.name : t('anyTopic')}`}</span>
                    <svg className="arrow-down" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.00039 9.27201L3.76839 5.04001C3.62439 4.88801 3.42439 4.80001 3.20039 4.80001C2.76039 4.80001 2.40039 5.16001 2.40039 5.60001C2.40039 5.82401 2.48839 6.02401 2.63239 6.16801L7.43239 10.968C7.57639 11.112 7.77639 11.2 8.00039 11.2C8.22439 11.2 8.42439 11.112 8.56839 10.968L13.3684 6.16801C13.5124 6.02401 13.6004 5.82401 13.6004 5.60001C13.6004 5.16001 13.2404 4.80001 12.8004 4.80001C12.5764 4.80001 12.3764 4.88801 12.2324 5.03201L8.00039 9.27201Z"
                              fill="#273043" />
                    </svg>
                </div>

                <ul className="blog__filter-drop-content">
                    <li className="blog__filter-drop-item" onClick={() => props.selectTopic({id: null, name: ''})}>{t('anyTopic')}</li>
                    {
                        (props.filters.topics) && (
                           Object.keys(props.filters.topics).map(key => (
                                <li
                                    key={props.filters.topics[key]}
                                    className="blog__filter-drop-item"
                                    onClick={() => props.selectTopic({id:parseInt(key), name: props.filters.topics[key]})}
                                >
                                    {props.filters.topics[key]}
                                </li>
                            ))
                        )
                    }
                </ul>
            </div>
        </div>
    );
};

