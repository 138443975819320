import React from 'react';
import {useTranslation} from 'react-i18next';

import headerBg from '../../images/scontrol/header-bg.webp';
import scontrol from '../../images/scontrol/header-img.png';
import {OrderServiceButton} from '../globalComponents/OrderServiceButton';

export const ScontrolHeader = props => {
    const [t] = useTranslation('services');

    return (
        <header className="scontrol-header">
            <div className="scontrol-header__bg">
                <img src={headerBg} alt="header" />
            </div>

            <div className="scontrol-header__body">
                <h1 className="scontrol-header__title">{t('scontrol.title')}</h1>
                <h2 className="scontrol-header__subtitle">
                    {
                        t('scontrol.subtitle')
                    }
                </h2>

                <div className="scontrol-header__images">
                    <div className="scontrol-header__img">
                        <img src={scontrol} alt="SControl" />
                    </div>
                </div>
                <OrderServiceButton text={t('scontrol.orderServiceButtonText')} service={t('scontrol.title')}/>
            </div>
        </header>
    );
};
