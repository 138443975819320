import React, {useEffect, useState} from 'react';

import './ProductCard.scss';
import Page404 from '../Page404';
import {useTranslation} from 'react-i18next';
import {getCategoryDetails} from '../../redux/actionCreators/getActionCreators/catalogActions';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PreLoader} from '../globalComponents/PreLoader';
import {ProductFeatures} from "./ProductFeatures";
import {ProductBuyInfo} from "./ProductBuyInfo";
import {ProductController} from "./ProductController";
import {ProductDescription} from "./ProductDescription";
import {ProductSpecifications} from "./ProductSpecifications";
import {ProductBuyWith} from "./ProductBuyWith";
import {ProductAlternative} from "./ProductAlternative";
import {ProductSolutions} from "./ProductSolutions";

import {BreadCrumbs} from "../globalComponents/BreadCrumbs";
import {ProductSet} from "./ProductSet/ProductSet";
import {connect} from "react-redux";
import {ProductImplement} from "./ProductImplement";
import {getProduct} from "../../redux/actionCreators/getActionCreators/productItemActions";
import {getProductBySlug} from "../../API/requests";
import {ProductImageSlider} from "../globalComponents/ProductImageSlider";

const ProductCardContainer = props => {
    const [t] = useTranslation('productCard')
    const [isLoading, setIsLoading] = useState(false);
    const [seoDescription, setSeoDescription] = useState('');
    const [isErrorOccurred, setIsErrorOccurred] = useState(false);

    useEffect(() => {
        setIsErrorOccurred(false);
        saveProduct(props.match.params.product).catch(() => setIsErrorOccurred(true));
    }, [props.match.params.product]);

    const saveProduct = async productSlug => {
        setIsLoading(true)
        const product = await getProductBySlug(productSlug);

        props.getProduct(product);
        props.getCategoryDetails(product.category);

        const seoDescription = Object.keys(product.specifications).reduce((acc, specification, index) => {
            if(acc.length > 160) return acc
            return index === 0 ?`${specification}: ${product.specifications[specification]}` : `${acc}, ${specification}: ${product.specifications[specification]}`
        }, '');
        setSeoDescription(seoDescription);

        setIsLoading(false);
    };

    return (
        <>
            {
                isErrorOccurred
                    ? (
                        <Page404 />
                    )
                    : (
                        <main className="product-page">
                            <MetaTagsComponent
                                title={props.product.name}
                                seoTitle={props.product.seo_keywords || props.product.name}
                                seoDescription={props.product.seo_description || seoDescription}
                            />

                            <div className="container">
                                <BreadCrumbs
                                    links={[
                                        {
                                            title: t('catalog'),
                                            link: `catalog/${props.categoryDetails.slug}`,
                                        },
                                        {
                                            title: props.categoryDetails.name,
                                            link: `catalog/${props.categoryDetails.slug}`,
                                        },
                                    ]}
                                />

                                {
                                    (isLoading)
                                        ? (
                                            <PreLoader />
                                        )
                                        : (
                                            <section className="product-wrap">
                                                <div className="product">
                                                    {
                                                        (props.product.images.length > 0) && (
                                                            <ProductImageSlider images={props.product.images} />
                                                        )
                                                    }
                                                    <ProductFeatures advantages={props.product.advantages} />
                                                    <ProductController product={props.product} />
                                                    <ProductBuyInfo productSlug={props.product.slug}/>
                                                </div>

                                                {/*{*/}
                                                {/*    (props.product.sets.length > 0) && (*/}
                                                {/*        <ProductSet product={props.product} currentProductSlug={props.match.params.product}/>*/}
                                                {/*    )*/}
                                                {/*}*/}

                                                <ProductDescription
                                                    description={props.product.description}
                                                    descriptionSecondary={props.product.description_secondary}
                                                    solutionsFor={props.product.solutions_for}
                                                />

                                                <ProductSpecifications
                                                    specifications={props.product.specifications}
                                                    documentation={props.product.documentation}
                                                />

                                                {
                                                    (props.product.implementations) && (
                                                        <ProductImplement implementations={props.product.implementations} />
                                                    )
                                                }
                                                {
                                                    (props.product.recommended_products.length > 0) && (
                                                        <ProductBuyWith recommendedProducts={props.product.recommended_products} />

                                                    )
                                                }
                                                {
                                                    (props.product.alternative_products.length > 0) && (
                                                        <ProductAlternative alternativeProducts={props.product.alternative_products} />
                                                    )
                                                }
                                                <ProductSolutions />
                                            </section>
                                        )
                                }
                            </div>
                        </main>

                    )
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        product: state.productItemState.product,
        categoryDetails: state.catalogState.categoryDetails,
    };
};

const mapDispatchToProps = {
    getProduct,
    getCategoryDetails,
};

export const ProductCard = connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
