import React from 'react';
import {useTranslation} from 'react-i18next';

import checkmark from '../../images/checkmark.svg';

export const SolutionChoosingTransport = props => {
    const [t] = useTranslation('solutionChoosing');

    return (
        <section className={`solution-choose__section active`}>
            <h1 className="solution-choose__title">{t('choosing.transportTitle')}</h1>
            <div className="solution-choose__body-wrap">
                <div className="solution-choose__body">
                    {
                        props.transportSolutions.map((solution, index) => (
                            <div
                                key={index}
                                className={`sc-transport-item ${props.selectedTypesOfTransport[solution.type] ? 'active' : ''}`}
                                onClick={() => props.changeSelectedTypesOfTransport(solution.type)}
                            >
                                <label className="checkbox-wrap">
                                    <input
                                        type="checkbox"
                                        onChange={() => props.changeSelectedTypesOfTransport(solution.type)}
                                        checked={!!props.selectedTypesOfTransport[solution.type]}
                                    />
                                    <span className="checkbox">
                                        <img src={checkmark} alt="checkmark" />
                                    </span>
                                </label>

                                <div className="sc-transport-item__img-section">
                                    <div className={`sc-transport-item__img-wrap sc-transport-item__img-wrap--${solution.type === 'stationary-objects' ? 'stationar' : solution.type === 'air-transport' ? 'plane' : 'standard'}`}>
                                        <img
                                            className="sc-transport-item__img"
                                            src={solution.image}
                                            alt={solution.name}
                                        />
                                    </div>

                                    {
                                        (solution.type !== 'stationary-objects') && (
                                            <div className={`sc-transport-item__img-wrap sc-transport-item__img-wrap--${solution.type === 'air-transport' ? 'plane' : 'standard'}-alt`}>
                                                <img
                                                    className="sc-transport-item__img"
                                                    src={solution.image}
                                                    alt={solution.name}
                                                />
                                            </div>
                                        )
                                    }

                                </div>
                                <h2 className="sc-transport-item__title">{solution.name}</h2>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};
