import React from 'react';
import {useTranslation} from 'react-i18next';
import {OrderServiceButton} from '../globalComponents/OrderServiceButton';

export const RentPrices = props => {
    const [t] = useTranslation('services');
    const prices = t('rent.prices', {returnObjects: true});

    return (
        <section className="rent-price">
            <div className="container">
                <h3 className="rent-price__title">{prices.title}</h3>
                <div className="rent-price__body">
                    <div className="rent-price__section">
                        <div className="rent-price__top-wrap">
                            <div className="rent-price__service">
                                <div className="rent-price__service-title">{prices.rentPriceTitle}</div>
                                <div className="rent-price__service-price">{prices.rentPrice}</div>
                            </div>
                            <div className="rent-price__plus-ico">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.46667 0.8C7.46667 0.358172 7.82484 0 8.26667 0C8.7085 0 9.06667 0.358172 9.06667 0.8V15.2C9.06667 15.6418 8.7085 16 8.26667 16C7.82484 16 7.46667 15.6418 7.46667 15.2V0.8Z"
                                        fill="#273043" />
                                    <path
                                        d="M15.2 7.46667C15.6418 7.46667 16 7.82484 16 8.26667C16 8.7085 15.6418 9.06667 15.2 9.06667H0.8C0.358172 9.06667 0 8.7085 0 8.26667C0 7.82484 0.358172 7.46667 0.8 7.46667H15.2Z"
                                        fill="#273043" />
                                </svg>
                            </div>
                            <div className="rent-price__service">
                                <div className="rent-price__service-title-tariff">{prices.tariffTitle}</div>
                                <div className="rent-price__service-label">{prices.tariffLabel}</div>
                                <div className="rent-price__service-price">{prices.tariffPrice}</div>
                            </div>
                        </div>
                        <div className="rent-price__bottom-wrap">
                            <p className="rent-price__descr">
                                {prices.installation}
                            </p>
                        </div>
                    </div>
                    <div className="rent-price__section rent-price__section--discount">
                        <div className="rent-price__discount-wrap" />
                        <h4 className="rent-price__discount-title">{prices.discounts.title}</h4>
                        <div className="rent-price__discount-body">
                            <div className="rent-price__term">
                                <div className="rent-price__term-item">{prices.discounts.three}</div>
                                <div className="rent-price__term-item">{prices.discounts.six}</div>
                                <div className="rent-price__term-item">{prices.discounts.twelve}</div>
                            </div>
                            <div className="rent-price__percentage">
                                <div className="rent-price__percentage-item">10%</div>
                                <div className="rent-price__percentage-item">15%</div>
                                <div className="rent-price__percentage-item">20%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="rent-price__order-btn">
                    <OrderServiceButton service={t('rent.title')}/>
                </div>
            </div>
        </section>
    );
};
