import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {setCheckoutErrors, setDeliveryInformation} from '../../redux/actionCreators/checkoutActions';
import {AddressForm} from "./AddressForm";
import {getCitiesFromNewPost, getWarehousesByCity} from "../../API/newPostApi";

const DeliveryFormContainer = props => {
    const [t] = useTranslation(['checkout', 'common']);

    const [deliveryFormValues, setDeliveryFormValues] = useState({
        cityDelivery: '',
        warehouse: '',
        deliveryType: '',
        deliveryAddress: {
            street: '',
            building: '',
            flat: '',
        },
    });
    const [listOfCities, setListOfCities] = useState([]);
    const [listOfWarehouses, setListOfWarehouses] = useState([]);

    const [isCitySelectActive, setIsCitySelectActive] = useState(false);
    const [selectedCity, setSelectedCity] = useState({});
    const [selectedWarehouse, setSelectedWarehouse] = useState({});
    const [isSelectWarehouseMenuActive, setIsSelectWarehouseMenuActive] = useState(true);

    useEffect(() => {
        setDeliveryFormValues(prevState => ({
            ...prevState,
            ...props.deliveryRepeat,
        }));
    }, []);

    useEffect(() => {
        props.setDeliveryInformation(deliveryFormValues);
        props.setCheckoutErrors({
            field: 'deliveryType',
            value: false,
        });

        props.setCheckoutErrors({
            field: 'deliveryInformation',
            value: false,
        });
    }, [deliveryFormValues]);

    const handleAddressChange = event => {
        const {name, value} = event.target;

        setDeliveryFormValues(prevState => ({
            ...prevState,
            deliveryAddress: {
                ...prevState.deliveryAddress,
                [name]: value,
            }
        }));
    };

    const handleChange = async event => {
        const {name, value} = event.target;

        setDeliveryFormValues(prevState => ({
            ...prevState,
            [name]: value,
        }));

        if(name === 'cityDelivery') {
            getCitiesFromNewPost(value).then(cities => setListOfCities(cities))
            setSelectedCity(prev => ({...prev, Present: ''}))
            setSelectedWarehouse({})
            setDeliveryFormValues(prev => ({
                ...prev,
                warehouse: '',
            }))
        }
        if (name === 'warehouse') {
            getWarehousesByCity(selectedCity.MainDescription, value).then(warehouses => setListOfWarehouses(warehouses))
        }
    };

    const toggleCitySelect = value => {
        setIsCitySelectActive(value);
    };

    const selectCity = city => {
        setSelectedCity(city);
        toggleCitySelect(false);

        setDeliveryFormValues(prevState => ({
            ...prevState,
            cityDelivery: city.Present,
        }));

        props.setCheckoutErrors({
            field: 'deliveryInformation',
            value: false,
        });
    };

    const handleDeliveryTypeChange = event => {
        setDeliveryFormValues(prevState => ({
            ...prevState,
            deliveryType: event.target.value,
        }));
    };

    const selectWarehouse = warehouse => {
        setSelectedWarehouse(warehouse);

        setDeliveryFormValues(prevState => ({
            ...prevState,
            warehouse: warehouse.Description,
        }));

        setIsSelectWarehouseMenuActive(false);
    };

    const getWarehouses = async () => {
        // if(listOfWarehouses.length > 0) return;

        const cityName = selectedCity.MainDescription;

        if(cityName) {
            const result = await getWarehousesByCity(cityName);
            setListOfWarehouses(result);
        }
    };
    useEffect(() => {
        setSelectedWarehouse({})
        setDeliveryFormValues(prev => ({
            ...prev,
            warehouse: '',
        }))
    },[selectedCity])

    return (
        <div className="basket-form__section">
            <h3 className="basket-form__section-title">{t('delivery.title')}</h3>
            <form className="basket-form__delivery-form form">
                <div
                    className={`basket-form__dropdown-selector basket-form__dropdown-selector--city ${isCitySelectActive ? 'active' : ''}`}
                    onMouseLeave={() => toggleCitySelect(false)}
                    onMouseEnter={() => {
                        if(!selectedCity.Present) toggleCitySelect(true);
                    }}
                >
                    <label className="form__label">
                        <input
                            className="form__input"
                            type="text"
                            placeholder=" "
                            required
                            name="cityDelivery"
                            value={deliveryFormValues.cityDelivery}
                            onChange={handleChange}
                            onFocus={() => toggleCitySelect(true)}
                            onClick={() => toggleCitySelect(true)}
                            autoComplete="chrome-off"
                        />
                        <span className="required field-title">{t('common:form.city')}</span>
                    </label>

                    <ul className={`basket-form__dropdown ${isCitySelectActive ? 'active' : ''}`}>
                        {
                            listOfCities.length > 0
                                ? (
                                    listOfCities.map((city, index) => (
                                        <li
                                            key={index}
                                            className="basket-form__dropdown-item"
                                            onClick={() => selectCity(city)}
                                        >
                                            {city.Present}
                                        </li>
                                    ))
                                )
                                : (
                                    <li className="basket-form__dropdown-item">{t('delivery.startTyping')}</li>
                                )
                        }
                    </ul>
                </div>

                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="post"
                        value="delivery_address"
                        checked={deliveryFormValues.deliveryType === 'delivery_address'}
                        onChange={handleDeliveryTypeChange}
                    />
                    <span className="radio">{t('delivery.ways.address.title')}<span className="days">{t('delivery.ways.address.time')} </span>- 100 грн</span>
                </label>

                <AddressForm
                    isActive={deliveryFormValues.deliveryType === 'delivery_address'}
                    handleChange={handleAddressChange}
                    address={deliveryFormValues.deliveryAddress}
                />

                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="post"
                        value="delivery_new_post_office"
                        checked={deliveryFormValues.deliveryType === 'delivery_new_post_office'}
                        onChange={handleDeliveryTypeChange}
                    />
                    <span className="radio">{t('delivery.ways.post.title')}  <span className="days">{t('delivery.ways.post.time')}</span>- {t('delivery.ways.address.price')}</span>
                </label>

                <div
                    className={`basket-form__dropdown-selector ${deliveryFormValues.deliveryType === 'delivery_new_post_office' && !isCitySelectActive ? 'active' : ''}`}
                    onMouseLeave={() => setIsSelectWarehouseMenuActive(false)}
                    onMouseEnter={() => {
                        if(!deliveryFormValues.warehouse) setIsSelectWarehouseMenuActive(true);
                    }}
                >
                    <label className="form__label">
                        <input
                            className="form__input"
                            type="text"
                            placeholder=" "
                            required
                            name="warehouse"
                            value={deliveryFormValues.warehouse}
                            onChange={handleChange}
                            onFocus={() => {
                                getWarehouses();
                                setIsSelectWarehouseMenuActive(true);
                            }}
                            onClick={() => {
                                setIsSelectWarehouseMenuActive(true);
                            }}
                            autoComplete="off"
                        />
                        <span className="standart field-title">{t('common:form.post')}</span>
                    </label>

                    <ul className={`basket-form__dropdown ${isSelectWarehouseMenuActive && !isCitySelectActive? 'active' : ''}`}>
                        {
                            listOfWarehouses.map((warehouse, index) => (
                                <li
                                    key={index}
                                    className="basket-form__dropdown-item"
                                    onClick={() => selectWarehouse(warehouse)}
                                >
                                    {warehouse.Description}
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="post"
                        value="delivery_new_post_courier"
                        checked={deliveryFormValues.deliveryType === 'delivery_new_post_courier'}
                        onChange={handleDeliveryTypeChange}
                    />
                    <span className="radio">{t('delivery.ways.courier.title')} <span className="days">{t('delivery.ways.courier.time')} </span>- {t('delivery.ways.courier.price')}</span>
                </label>

                <AddressForm
                    isActive={deliveryFormValues.deliveryType === 'delivery_new_post_courier'}
                    handleChange={handleAddressChange}
                    address={deliveryFormValues.deliveryAddress}
                />

                <label className="radio-wrap">
                    <input
                        type="radio"
                        name="post"
                        value="delivery_pickup"
                        checked={deliveryFormValues.deliveryType === 'delivery_pickup'}
                        onChange={handleDeliveryTypeChange}
                    />
                    <span className="radio">{t('delivery.ways.pickUp.title')} - {t('delivery.ways.pickUp.price')}</span>
                </label>
            </form>
            <p className={`enter-modal__login-error ${props.deliveryTypeError ? 'active' : ''}`}>{t('delivery.chooseDeliveryType')}</p>
            <p className={`enter-modal__login-error ${props.deliveryInformationError ? 'active' : ''}`} >{t('delivery.typeDeliveryInfo')}</p>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        deliveryTypeError: store.checkoutState.errors.deliveryType,
        deliveryInformationError: store.checkoutState.errors.deliveryInformation,
        deliveryRepeat: store.checkoutState.deliveryRepeat,
    };
};

const mapDispatchToProps = {
    setDeliveryInformation,
    setCheckoutErrors,
};

export const DeliveryForm = connect(mapStateToProps, mapDispatchToProps)(DeliveryFormContainer);
