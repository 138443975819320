import React, {useRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import section1 from '../../images/scontrol/section-bg1.webp';
import section2 from '../../images/scontrol/section-bg2.webp';
import quote1Uk from '../../images/scontrol/quote1.png';
import quote2Uk from '../../images/scontrol/quote2.png';
import quote1Ru from '../../images/scontrol/quote1ru.png';
import quote2Ru from '../../images/scontrol/quote2ru.png';
import mobileImg1 from '../../images/scontrol/mobile-img1.png';
import mobileImg2 from '../../images/scontrol/mobile-img2.png';
import adv1 from '../../images/scontrol/px-adv-ico1.svg';
import adv2 from '../../images/scontrol/px-adv-ico2.svg';
import adv3 from '../../images/scontrol/px-adv-ico3.svg';
import adv4 from '../../images/scontrol/px-adv-ico4.svg';
import adv5 from '../../images/scontrol/px-adv-ico5.svg';
import adv6 from '../../images/scontrol/px-adv-ico6.svg';
import adv7 from '../../images/scontrol/px-adv-ico7.svg';
import adv8 from '../../images/scontrol/px-adv-ico8.svg';
import adv9 from '../../images/scontrol/px-adv-ico9.svg';

import slide1 from '../../images/scontrol/parallax/slide1.webp';
import slide2 from '../../images/scontrol/parallax/slide2.webp';
import slide3 from '../../images/scontrol/parallax/slide3.webp';
import slide4 from '../../images/scontrol/parallax/slide4.webp';
import slide5 from '../../images/scontrol/parallax/slide5.webp';
import slide6_1 from '../../images/scontrol/parallax/slide6-1.webp';
import slide6_2 from '../../images/scontrol/parallax/slide6-2.webp';
import slide7 from '../../images/scontrol/parallax/slide7.webp';
import slide8 from '../../images/scontrol/parallax/slide8.webp';
import slide9 from '../../images/scontrol/parallax/slide9.webp';
import slide10 from '../../images/scontrol/parallax/slide10.webp';
import slide11 from '../../images/scontrol/parallax/slide11.webp';
import slide12 from '../../images/scontrol/parallax/slide12.webp';
import slide13 from '../../images/scontrol/parallax/slide13.webp';
import slide14 from '../../images/scontrol/parallax/slide14.webp';

import {OrderServiceButton} from '../globalComponents/OrderServiceButton';


const imagesToUse = {
    1: {
        top: null,
        bottom: slide1,
    },
    2: {
        top: null,
        bottom: slide2,
    },
    3: {
        top: null,
        bottom: slide3,
    },
    4: {
        top: null,
        bottom: slide4,
    },
    5: {
        top: null,
        bottom: slide5,
    },
    6: {
        top: slide6_2,
        bottom: slide6_1,
    },
    7: {
        top: slide6_2,
        bottom: slide7,
    },
    8: {
        top: slide6_2,
        bottom: slide8,
    },
    9: {
        top: slide6_2,
        bottom: slide9,
    },
    10: {
        top: slide6_2,
        bottom: slide10,
    },
    11: {
        top: slide6_2,
        bottom: slide11,
    },
    12: {
        top: slide6_2,
        bottom: slide12,
    },
    13: {
        top: slide6_2,
        bottom: slide13,
    },
    14: {
        top: slide6_2,
        bottom: slide14,
    },
    15: {
        top: slide6_2,
        bottom: slide13,
    },
    16: {
        top: slide6_2,
        bottom: slide12,
    },
    17: {
        top: slide6_2,
        bottom: slide11,
    },
    18: {
        top: slide6_2,
        bottom: slide10,
    },
    19: {
        top: slide6_2,
        bottom: slide9,
    },
    20: {
        top: slide6_2,
        bottom: slide8,
    },
    21: {
        top: slide6_2,
        bottom: slide7,
    },
    22: {
        top: slide6_2,
        bottom: slide6_1,
    },
    23: {
        top: null,
        bottom: slide5,
    },
    24: {
        top: null,
        bottom: slide4,
    },
    25: {
        top: null,
        bottom: slide3,
    },
    26: {
        top: null,
        bottom: slide2,
    },
    27: {
        top: null,
        bottom: slide1,
    },
}

const quote1 = {
    uk: quote1Uk,
    ru: quote1Ru,
};

const quote2 = {
    uk: quote2Uk,
    ru: quote2Ru,
};

const ScontrolParallaxContainer = props => {
    const [t] = useTranslation('services');
    const opportunities = t('scontrol.opportunitiesOfScontrol', {returnObjects: true});
    const characterisctic = t('scontrol.characteristic', {returnObjects: true});
    const advantages = opportunities.advantages;
    const tracker = useRef(false);
    const [indexOfImage, setIndexOfImage] = useState(1);


    const advantagesFirstList = [
        {
            image: adv1,
            text: advantages.accum,
        },
        {
            image: adv2,
            text: advantages.server,
        },
        {
            image: adv3,
            text: advantages.regimes,
        },
        {
            image: adv4,
            text: advantages.hidden,
        },
    ];

    const advantagesSecondList = [
        {
            image: adv5,
            text: advantages.options,
        },
        {
            image: adv6,
            text: advantages.tariffs,
        },
        {
            image: adv7,
            text: advantages.sim,
        },
        {
            image: adv8,
            text: advantages.systems,
        },
    ];

    const handleScroll = () => {
        const y = tracker.current.offsetTop;
        const label = Math.min(Math.floor(y/50) + 1, 27);

        setIndexOfImage(label);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <div className="scontrol__parallax-wrap" >
            <div className="scontrol__parallax-item-container-wrap">
                <div className="scontrol__parallax-item-container">
                    <div className="scontrol__parallax-item-wrap" ref={tracker}>
                        <div className={`scontrol__parallax-item scontrol__parallax-item--${indexOfImage} active`}>
                            {
                                (imagesToUse[indexOfImage].top) && (
                                    <img className="px-item-top" src={imagesToUse[indexOfImage].top} alt="parallax top image" />
                                )
                            }
                            <img className="px-item" src={imagesToUse[indexOfImage].bottom} alt="parallax bottom image" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="scontrol-px-section scontrol-px-section--top">
                <div className="scontrol-px-section__pic-section">
                    <div className="scontrol-px-section__pic-section-bg">
                        <img src={section1} alt="section" />
                    </div>

                    <div className="scontrol-px-section__pic-section-quote">
                        <img src={quote1[props.language.key]} alt="quote" />
                    </div>
                </div>

                <div className="scontrol-px-section__info-section">
                    <div className="scontrol-px-section__info-section-body">
                        <div className="scontrol-px-section__mobile-img-top">
                            <img src={mobileImg1} alt="mobile image" />
                        </div>
                        <h3 className="scontrol-px-section__title">{opportunities.title}</h3>
                        <ul className="scontrol-px-section__list-check">
                            <li className="scontrol-px-section__list-item">{opportunities.list.first}</li>
                            <li className="scontrol-px-section__list-item">{opportunities.list.second}</li>
                            <li className="scontrol-px-section__list-item">{opportunities.list.third}</li>
                            <li className="scontrol-px-section__list-item">{opportunities.list.fourth}</li>
                        </ul>
                        <OrderServiceButton text={t('scontrol.orderServiceButtonText')} />
                    </div>
                </div>
            </div>

            <section className="scontrol-px-adv">
                <div className="container scontrol-px-adv__wrap">
                    <div className="scontrol-px-adv__section">
                        {
                            advantagesFirstList.map((advantage, index) => (
                                <div key={index} className="scontrol-px-adv__item">
                                    <div className="scontrol-px-adv__item-ico">
                                        <img src={advantage.image} alt="advantage" />
                                    </div>
                                    <div className="scontrol-px-adv__text">{advantage.text}</div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="scontrol-px-adv__section">
                        {
                            advantagesSecondList.map((advantage, index) => (
                                <div key={index} className="scontrol-px-adv__item">
                                    <div className="scontrol-px-adv__item-ico">
                                        <img src={advantage.image} alt="advantage" />
                                    </div>
                                    <div className="scontrol-px-adv__text">{advantage.text}</div>
                                </div>
                            ))
                        }

                        <div className="scontrol-px-adv__item">
                            <div className="scontrol-px-adv__item-ico"><img
                                src={adv9} alt="advantage" /></div>
                            <div className="scontrol-px-adv__text scontrol-px-adv__text--link">
                                {advantages.service}
                                <a href="tel:+0800339651">0 (800) 33 9651</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="scontrol-px-section scontrol-px-section--bottom">
                <div className="scontrol-px-section__info-section">
                    <div className="scontrol-px-section__info-section-body">
                        <div className="scontrol-px-section__mobile-img-bottom">
                            <img src={mobileImg2} alt="mobile image" />
                        </div>

                        <h3 className="scontrol-px-section__title scontrol-px-section__title--highlight">
                            {characterisctic.title} <span>SControl</span>
                        </h3>

                        <div className="scontrol-px-section__paragraph">
                            {characterisctic.subtitle}
                        </div>

                        <ul className="scontrol-px-section__list-square">
                            <li className="scontrol-px-section__list-item" dangerouslySetInnerHTML={{__html: characterisctic.list.standard}}/>
                            <li className="scontrol-px-section__list-item" dangerouslySetInnerHTML={{__html: characterisctic.list.navigation}}/>
                            <li className="scontrol-px-section__list-item" dangerouslySetInnerHTML={{__html: characterisctic.list.battery}}/>
                            <li className="scontrol-px-section__list-item" dangerouslySetInnerHTML={{__html: characterisctic.list.workTime}}/>
                            <li className="scontrol-px-section__list-item" dangerouslySetInnerHTML={{__html: characterisctic.list.size}}/>
                            <li className="scontrol-px-section__list-item" dangerouslySetInnerHTML={{__html: characterisctic.list.warranty}}/>
                        </ul>
                    </div>
                </div>

                <div className="scontrol-px-section__pic-section">
                    <div className="scontrol-px-section__pic-section-bg">
                        <img src={section2} alt="section" />
                    </div>

                    <div className="scontrol-px-section__pic-section-quote">
                        <img src={quote2[props.language.key]} alt="quote" />
                    </div>
                </div>
            </section>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
    };
};

export const ScontrolParallax = connect(mapStateToProps)(ScontrolParallaxContainer);
