import React from "react";

import check from '../../images/check.svg';
import {connect} from "react-redux";
import {EmptyPage} from '../globalComponents/EmptyPage/EmptyPage';

export const UserAccountDocumentsContainer = props => {

    return (
        <section className={`user-documents ${props.isActive ? 'active' : ''}`}>
            <div className="user-documents__body">
                {
                    (props.documents)
                        ? (
                            <>
                                <div className="user-documents__section">
                                    <h3 className="user-documents__section-title">Оформленные договора</h3>
                                    <div className="user-documents__documents">
                                        {
                                            props.documents.format.map((document, index) => (
                                                <div key={index} className="user-documents__document">
                                                    <img src={check} alt="checkmark" />
                                                    <div className="user-documents__document-name">{document}</div>
                                                    <svg className="download-btn" width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M4 0H14V2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V24C2 24.5304 2.21071 25.0391 2.58579 25.4142C2.96086 25.7893 3.46957 26 4 26H20C20.5304 26 21.0391 25.7893 21.4142 25.4142C21.7893 25.0391 22 24.5304 22 24V10H24V24C24 25.0609 23.5786 26.0783 22.8284 26.8284C22.0783 27.5786 21.0609 28 20 28H4C2.93913 28 1.92172 27.5786 1.17157 26.8284C0.421427 26.0783 0 25.0609 0 24V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0Z"
                                                            fill="#273043"
                                                        />
                                                        <path
                                                            d="M14 7V0L24 10H17C16.2044 10 15.4413 9.68393 14.8787 9.12132C14.3161 8.55871 14 7.79565 14 7Z"
                                                            fill="#273043"
                                                        />
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M7.29183 16.2921C7.38473 16.199 7.49508 16.1251 7.61657 16.0747C7.73806 16.0242 7.8683 15.9983 7.99983 15.9983C8.13137 15.9983 8.26161 16.0242 8.3831 16.0747C8.50459 16.1251 8.61494 16.199 8.70783 16.2921L11.9998 19.5861L15.2918 16.2921C15.4796 16.1043 15.7343 15.9988 15.9998 15.9988C16.2654 15.9988 16.5201 16.1043 16.7078 16.2921C16.8956 16.4799 17.0011 16.7345 17.0011 17.0001C17.0011 17.2656 16.8956 17.5203 16.7078 17.7081L12.7078 21.7081C12.6149 21.8012 12.5046 21.8751 12.3831 21.9255C12.2616 21.9759 12.1314 22.0019 11.9998 22.0019C11.8683 22.0019 11.7381 21.9759 11.6166 21.9255C11.4951 21.8751 11.3847 21.8012 11.2918 21.7081L7.29183 17.7081C7.19871 17.6152 7.12482 17.5048 7.07441 17.3833C7.024 17.2619 6.99805 17.1316 6.99805 17.0001C6.99805 16.8685 7.024 16.7383 7.07441 16.6168C7.12482 16.4953 7.19871 16.385 7.29183 16.2921Z"
                                                              fill="#273043"
                                                        />
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11V19C13 19.2652 12.8946 19.5196 12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071C11.1054 19.5196 11 19.2652 11 19V11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10Z"
                                                              fill="#273043"
                                                        />
                                                    </svg>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="user-documents__section">
                                    <h3 className="user-documents__section-title">Доступные документы</h3>
                                    <div className="user-documents__documents">
                                        {
                                            props.documents.available.map((document, index) => (
                                                <div key={index} className="user-documents__document">
                                                    <img src={check} alt="document icon" />
                                                    <div className="user-documents__document-name">{document}</div>
                                                    <svg className="download-btn" width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M4 0H14V2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V24C2 24.5304 2.21071 25.0391 2.58579 25.4142C2.96086 25.7893 3.46957 26 4 26H20C20.5304 26 21.0391 25.7893 21.4142 25.4142C21.7893 25.0391 22 24.5304 22 24V10H24V24C24 25.0609 23.5786 26.0783 22.8284 26.8284C22.0783 27.5786 21.0609 28 20 28H4C2.93913 28 1.92172 27.5786 1.17157 26.8284C0.421427 26.0783 0 25.0609 0 24V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0Z"
                                                            fill="#273043"
                                                        />
                                                        <path
                                                            d="M14 7V0L24 10H17C16.2044 10 15.4413 9.68393 14.8787 9.12132C14.3161 8.55871 14 7.79565 14 7Z"
                                                            fill="#273043"
                                                        />
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M7.29183 16.2921C7.38473 16.199 7.49508 16.1251 7.61657 16.0747C7.73806 16.0242 7.8683 15.9983 7.99983 15.9983C8.13137 15.9983 8.26161 16.0242 8.3831 16.0747C8.50459 16.1251 8.61494 16.199 8.70783 16.2921L11.9998 19.5861L15.2918 16.2921C15.4796 16.1043 15.7343 15.9988 15.9998 15.9988C16.2654 15.9988 16.5201 16.1043 16.7078 16.2921C16.8956 16.4799 17.0011 16.7345 17.0011 17.0001C17.0011 17.2656 16.8956 17.5203 16.7078 17.7081L12.7078 21.7081C12.6149 21.8012 12.5046 21.8751 12.3831 21.9255C12.2616 21.9759 12.1314 22.0019 11.9998 22.0019C11.8683 22.0019 11.7381 21.9759 11.6166 21.9255C11.4951 21.8751 11.3847 21.8012 11.2918 21.7081L7.29183 17.7081C7.19871 17.6152 7.12482 17.5048 7.07441 17.3833C7.024 17.2619 6.99805 17.1316 6.99805 17.0001C6.99805 16.8685 7.024 16.7383 7.07441 16.6168C7.12482 16.4953 7.19871 16.385 7.29183 16.2921Z"
                                                              fill="#273043"
                                                        />
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M12 10C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11V19C13 19.2652 12.8946 19.5196 12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20C11.7348 20 11.4804 19.8946 11.2929 19.7071C11.1054 19.5196 11 19.2652 11 19V11C11 10.7348 11.1054 10.4804 11.2929 10.2929C11.4804 10.1054 11.7348 10 12 10Z"
                                                              fill="#273043"
                                                        />
                                                    </svg>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </>
                        )
                        : (
                            <EmptyPage />
                        )
                }
            </div>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        documents: state.userAccountState.user.documents,
    };
};

export const UserAccountDocuments = connect(mapStateToProps)(UserAccountDocumentsContainer);
