import {
    CHANGE_LANGUAGE,
    CHANGE_USER_LOCATION, SET_GOOGLE_ID,
    SET_PRODUCT_FOR_OFFER,
    SET_SERVICE_FOR_ORDER,
    SET_SOLUTION_FOR_OFFER, SET_SOLUTION_FOR_ORDER, SET_UTM_PARAMETERS,
} from '../actionConsts/actionConsts';

export const changeLanguage = payload => {
    return {
        type: CHANGE_LANGUAGE,
        payload,
    };
};

export const changeUserLocation = payload => {
    return {
        type: CHANGE_USER_LOCATION,
        payload,
    };
};

export const setProductForOffer = payload => {
    return {
        type: SET_PRODUCT_FOR_OFFER,
        payload,
    };
};

export const setSolutionForOffer = payload => {
    return {
        type: SET_SOLUTION_FOR_OFFER,
        payload,
    };
};

export const setServiceForOrder = payload => {
    return {
        type: SET_SERVICE_FOR_ORDER,
        payload,
    };
};

export const setSolutionForOrder = payload => {
    return {
        type: SET_SOLUTION_FOR_ORDER,
        payload,
    };
};

export const setGoogleId = payload => {
    return {
        type: SET_GOOGLE_ID,
        payload,
    };
};

export const setUtmParams = payload => {
    return {
        type: SET_UTM_PARAMETERS,
        payload,
    };
};
