import React from 'react';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';

import './MainSlider.scss'
import sliderImage1 from '../../../images/main-page/slider-img1.webp';
import sliderImage2 from '../../../images/main-page/slider-img2.webp';
import sliderImage3 from '../../../images/main-page/slider-img3.webp';
import sliderImage4 from '../../../images/main-page/slider-img4.webp';
import sliderImage5 from '../../../images/main-page/complex.webp';
import sliderImage6 from '../../../images/main-page/together.webp';
import sliderImage7 from '../../../images/main-page/discount.webp';
import sliderImage8 from '../../../images/main-page/map-vector.svg';

import teltonikaLogo from '../../../images/main-page/logo-teltonika.png';

import teltonikaBanner from '../../../images/main-page/teltonika-banner.webp';
import doubleScontorlBanner from '../../../images/main-page/double-sctronrol-banner.webp';
import scontrolBanner from '../../../images/main-page/scontrol-banner.webp';
import teltonikaStraight from '../../../images/main-page/teltonika-straight-banner.webp';

import {NextArrow} from './NextArrow';
import {PrevArrow} from './PrevArrow';
import {SliderContent} from './SliderContent';

export const MainSlider = props => {
    const [t] = useTranslation('common');

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        waitForAnimate: true,
        autoplay: true,
        autoPlaySpeed: 8000,
        arrows: true,
        dots: true,
        dotsClass: 'main-sl-dots',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    const slides = [
        {
            bg: sliderImage1,
            title: props.translation.title,
            subtitle: props.translation.slides.firstSlide.text,
            link: '/services/gps'
        },
        {
            bg: sliderImage5,
            image: teltonikaBanner,
            brand: teltonikaLogo,
            title: props.translation.slides.fifthSlide.title,
            subtitle: props.translation.slides.fifthSlide.text,
            description: props.translation.slides.fifthSlide.description,
            link: '/blog/article/14',
            labels: [
                {
                    size: 'large',
                    type: 'right',
                    title: props.translation.slides.fifthSlide.labelTitle,
                    price: '1499',
                    currency: `грн (${t('withoutVat')})`,
                }
            ]
        },
        {
            bg: sliderImage6,
            image: doubleScontorlBanner,
            title: props.translation.slides.sixthSlide.title,
            subtitle: props.translation.slides.sixthSlide.text,
            link: '/blog/article/15',
            labels: [
                {
                    size: 'small',
                    type: 'double-right',
                    price: '1470',
                    currency: 'грн',
                },
                {
                    size: 'small',
                    type: 'double-left',
                    price: '1470',
                    currency: 'грн',
                }
            ]
        },
        {
            bg: sliderImage7,
            image: scontrolBanner,
            brand: teltonikaLogo,
            title: props.translation.slides.seventhSlide.title,
            subtitle: props.translation.slides.seventhSlide.text,
            link: '/blog/article/13',
            labels: [
                {
                    size: 'small',
                    type: 'right-top',
                    price: '-12%',
                }
            ]
        },
        {
            bg: sliderImage8,
            image: teltonikaStraight,
            brand: teltonikaLogo,
            title: props.translation.slides.eighthSlide.title,
            subtitle: `${props.translation.slides.eighthSlide.text}*`,
            vatTitle: `*${t('withoutVat')}`,
            link: '/blog/article/12',
            labels: [
                {
                    size: 'large',
                    type: 'right-top',
                    price: '-25%',
                }
            ]
        },
        {
            bg: sliderImage2,
            title: props.translation.title,
            subtitle: props.translation.slides.secondSlide.text,
            link: '/services/other-services'
        },
        {
            bg: sliderImage3,
            title: props.translation.title,
            subtitle: props.translation.slides.thirdSlide.text,
            link: '/services/rent'
        },
        {
            bg: sliderImage4,
            title: props.translation.title,
            subtitle: props.translation.slides.fourthSlide.title,
            link: '/services/other-services'
        },

    ];

    return (
        <section className="main-slider-wrap">
            <div
                className="main-slider"
            >
                <Slider {...settings}>
                    {
                        slides.map((slide, index) => (
                            <SliderContent
                                key={index}
                                {...slide}
                                buttonTextTranslation={props.translation.buttonText}
                            />
                        ))
                    }
                </Slider>
            </div>
        </section>
    );
};
