import React from 'react';

export const PrevArrow = props => {
    const {onClick} = props;

    return (
        <button onClick={onClick} className="slider__button slider__prev">
            <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M3.615 11L11.55 18.935C11.835 19.205 12 19.58 12 20C12 20.825 11.325 21.5 10.5 21.5C10.08 21.5 9.705 21.335 9.435 21.065L0.435 12.065C0.165 11.795 4.40611e-07 11.42 4.5897e-07 11C4.77328e-07 10.58 0.165 10.205 0.435 9.935L9.435 0.935002C9.705 0.665002 10.08 0.5 10.5 0.5C11.325 0.5 12 1.175 12 2C12 2.42 11.835 2.795 11.565 3.065L3.615 11Z"
                      fill="white"
                />
            </svg>
        </button>
    )
}