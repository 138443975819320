import {_language} from '../globalConsts';

export const languageInitialization = (language, currentLocation, currentHistory) => {
    let finalPathname;

    const isLanguageMain = _language.key === language.key;
    finalPathname = isLanguageMain
        ? currentLocation.pathname.replace(`/ru`, '')
        : `/${language.key}${currentLocation.pathname.replace(`/ru`, '')}`

    currentHistory.replace(finalPathname);
};
