import React from 'react';

export const CasesArticleClient = props => {
    return (
        <div className="cases-article__content-section cases-article__content-section--with-img">
            {/*<div className="cases-article__image-box">*/}
            {/*    <img src={props.client_logo} alt="client" />*/}
            {/*</div>*/}

            <div className="cases-article__text">
                <h3 className="cases-article__title">{props.title}</h3>
                <div className="cases-article__paragraph" dangerouslySetInnerHTML={{__html: props.client_description}}/>
            </div>
        </div>
    );
};
