import React from 'react';
import {useTranslation} from 'react-i18next';

import car from '../../images/scontrol/car.svg';
import ico1 from '../../images/scontrol/adv-ico-px1.svg';
import ico2 from '../../images/scontrol/adv-ico-px2.svg';
import ico3 from '../../images/scontrol/adv-ico-px3.svg';

export const ScontrolCarScroll = props => {
    const [t] = useTranslation('services');
    const advantages = t('scontrol.betterThenOthers.listOfAdvantages', {returnObjects: true});

    return (
        <div className="scontrol-adv">
            <h2 className="scontrol-adv__title">{`${t('scontrol.betterThenOthers.title')}:`}</h2>
            <div className="container scontrol-adv__body">
                <div className="scontrol-adv__roadmap-wrap">
                    <div className="scontrol-adv__circle scontrol-adv__circle--signalization">
                        <div className="scontrol-adv__item active">
                            <div className="scontrol-adv__item-content">
                                <div className="scontrol-adv__item-ico">
                                    <img src={ico1} alt="icon" />
                                </div>

                                <div className="scontrol-adv__item-list-wrap">
                                    <ul className="scontrol-adv__list">
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--plus">
                                            {advantages.signalization.first}
                                        </li>
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--minus">
                                            {advantages.signalization.second}
                                        </li>
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--minus">
                                            {advantages.signalization.third}
                                        </li>
                                    </ul>
                                    <h3 className="scontrol-adv__item-title">{advantages.signalization.title}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="scontrol-adv__circle scontrol-adv__circle--casco">
                        <div className="scontrol-adv__item scontrol-adv__item--right active">
                            <div className="scontrol-adv__item-content">
                                <div className="scontrol-adv__item-list-wrap">
                                    <h3 className="scontrol-adv__item-title">{advantages.casco.title}</h3>
                                    <ul className="scontrol-adv__list">
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--plus">
                                            {advantages.casco.first}
                                        </li>
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--minus">
                                            {advantages.casco.second}
                                        </li>
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--minus">
                                            {advantages.casco.third}
                                        </li>
                                    </ul>
                                </div>

                                <div className="scontrol-adv__item-ico">
                                    <img src={ico2} alt="icon" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="scontrol-adv__circle scontrol-adv__circle--gps">
                        <div className="scontrol-adv__item active">
                            <div className="scontrol-adv__item-content">
                                <div className="scontrol-adv__item-ico"><img
                                    src={ico3} alt="icon" /></div>
                                <div className="scontrol-adv__item-list-wrap">
                                    <ul className="scontrol-adv__list">
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--plus">
                                            {advantages.gps.first}
                                        </li>
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--minus">
                                            {advantages.gps.second}
                                        </li>
                                        <li className="scontrol-adv__list-item scontrol-adv__list-item--minus">
                                            {advantages.gps.third}
                                        </li>
                                    </ul>
                                    <h3 className="scontrol-adv__item-title">{advantages.gps.title}</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="scontrol-adv__roadmap">
                        <svg width="61" height="680" viewBox="0 0 61 680" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="29.5" cy="15.5" r="15.5" fill="#F88125"/>
                            <line x1="29.5" y1="14.5" x2="29.5" y2="634.5" strokeWidth="2" stroke="#F88125"
                                  strokeLinecap="round" strokeDasharray="2 5"/>
                            <circle cx="29.5" cy="664.5" r="14.5" fill="white" stroke="#F88125"
                                    strokeWidth="2"/>
                            <circle cx="29.5" cy="664.5" r="8.5" fill="#F88125" stroke="#F88125"
                                    strokeWidth="2"/>
                        </svg>
                    </div>
                    <div className="scontrol-adv__car">
                        <img src={car} alt="scontrol car" />
                    </div>
                </div>
            </div>
        </div>
    );
};
