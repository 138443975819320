import React, {useEffect} from 'react';

const JsonLd = ({data}) => {
    useEffect(() => {
        const script = document.createElement('script');
    }, [data]);

    return (
        <>
            {
                data && (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{__html: JSON.stringify(data)}}
                    />
                )
            }
        </>
    );
};

export default JsonLd;