import React from 'react';
import {useTranslation} from 'react-i18next';
import LocalizedLink from './LocalizedLink';

export const SuitableTabs = props => {
    const [t] = useTranslation('common');
    const solutionsTranslation = t('solutions', {returnObjects: true});

    return (
        <div className="suitable-tabs">
            {
                (props.solutionsFor) && (
                    <>
                        <h4 className="suitable-tabs__title">{`${t('suitableTabs.title')} :`}</h4>
                        <div className="suitable-tabs__tabs">
                            {
                                props.solutionsFor.map((solution, index) => (
                                    <LocalizedLink to={solution.link} target="_blank" key={index} className="suitable-tabs__tab">
                                        <div className="suitable-tabs__tab-ico">
                                            {solution.icon}
                                        </div>
                                        <div className="suitable-tabs__tab-name">{solutionsTranslation[solution.slug]}</div>
                                    </LocalizedLink>
                                ))
                            }
                        </div>

                        <div className="suitable-tabs__list">
                            {
                                props.solutionsFor.map((solution, index) => (
                                    <LocalizedLink to={solution.link} target="_blank" key={index} className="suitable-tabs__list-item">{solutionsTranslation[solution.slug]}</LocalizedLink>
                                ))
                            }
                        </div>
                    </>
                )
            }
        </div>
    );
};
