import React from 'react';

import './Page404.scss';
import {useTranslation} from 'react-i18next';

import errorImage from '../../images/page404/page404bg.webp';
import LocalizedLink from '../globalComponents/LocalizedLink';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

export const Page404 = props => {
    const [t] = useTranslation('common');

    return  (
        <main className="page404">
            <MetaTagsComponent
                title={t('errorPage.title')}
                seoTitle={t('errorPage.title')}
            />

            <div className="page404__bg">
                <img src={errorImage} alt="404" />
            </div>

            <div className="container">
                <div className="page404__body">
                    <h1 className="page404__title">{t('errorPage.title')}</h1>
                    <p className="page404__subtitle">{t('errorPage.subtitle')}</p>
                    <LocalizedLink to="/" className="page404__back-link btn-secondary" href="">{t('errorPage.onMainButton')}</LocalizedLink>
                </div>
            </div>
        </main>
    );
};
