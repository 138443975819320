import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {createLead} from '../../../API/birixApi/createLead';
import {toggleApplicationSuccess, toggleModal, toggleQuestion} from '../../../redux/actionCreators/modalActions';
import {connect} from "react-redux";

import closeButton from "../../../images/close-btn.svg";
import {Input} from "../Input/Input";

const QuestionContainer = props => {
    const [t] = useTranslation('common');
    const form = t('form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;
    
    const formFields = [
        {
            name: 'name',
            title: form.name,
            type: 'text',
        },
        {
            name: 'lastName',
            title: form.lastName,
            type: 'text',
        },
        {
            name: 'phone',
            title: form.phone,
            type: 'tel',
        },
        {
            name: 'email',
            title: form.email,
            type: 'email',
        },
        {
            name: 'question',
            title: form.question,
        },
    ];
    
    const [questionFormValues, setQuestionFormValues] = useState({
        name: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage: errorMessage,
        },
        lastName: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage: errorMessage,
        },
        phone: {
            value: '+380',
            error: false,
            wasTouched: false,
            errorMessage: errorMessage,
        },
        email: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage: errorMessage,
        },
        question: '',
    });

    useEffect(() => {
        if(props.isLogged) {
            const formInfoToSet = {
                name: {
                    ...questionFormValues.name,
                    value: props.user.name,
                    wasTouched: true,
                },
                lastName: {
                    ...questionFormValues.lastName,
                    value: props.user.last_name,
                    wasTouched: true,
                },
                phone: {
                    ...questionFormValues.phone,
                    value: props.user.phone,
                    wasTouched: true,
                },
                email: {
                    ...questionFormValues.email,
                    value: props.user.email,
                    wasTouched: true,
                }
            }

            setQuestionFormValues(prevState => ({
                ...prevState,
                ...formInfoToSet,
            }));
        }
    }, [props.user, props.isLogged]);

    const handleQuestionFormChange = event => {
        const {name, value} = event.target;

        if(name === 'question') {
            setQuestionFormValues(prevState => ({
                ...prevState,
                [name]: value,
            }))
        } else {
            setQuestionFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value,
                    error: false,
                },
            }));
        }
    };

    const validateQuestionField = (name, value) => {
        if(name !== 'question') {
            setQuestionFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    wasTouched: true,
                },
            }));

            if(!value.trim()) {
                setQuestionFormValues(prevState => ({
                    ...prevState,
                    [name]: {
                        ...prevState[name],
                        error: true,
                    },
                }));

                return false;
            }
        }

        if(name === 'phone') {
            const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

            if(!regEx.test(value)) {
                setQuestionFormValues(prevState => ({
                    ...prevState,
                    phone: {
                        ...prevState.phone,
                        error: true,
                        errorMessage: form.notValidFormat
                    }
                }));

                return false;
            }
        }

        return true;
    };

    const handleSubmit = event => {
        event.preventDefault();

        let isFormValid = true;
        Object.keys(questionFormValues).forEach(field => {
            if(!validateQuestionField(field, questionFormValues[field].value)) {
                isFormValid = false;
            }
        });

        if(isFormValid) {
            openApplicationSuccessModal();

            const userInfo = {
                name: `${questionFormValues.name.value} ${questionFormValues.lastName.value}`,
                phone: questionFormValues.phone.value,
                email: questionFormValues.email.value,
                question: questionFormValues.question,
            };

            createLead(userInfo, 'question', props.googleId, props.utmParams);
        }
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleQuestion(false);
    };

    const openApplicationSuccessModal = () => {
        props.toggleQuestion(false);
        props.toggleApplicationSuccess(true);
    };

    return (
        <div className={`form-modal form-modal--question modal-question ${props.question ? 'active' : ''}`}>
            <div className="modal__close" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>

            <form className="form-modal__body" onSubmit={handleSubmit}>
                <h6 className="form-modal__title">{t('questionRequest')}</h6>
                {
                    formFields.map(field => (
                        <React.Fragment key={field.name}>
                            {
                                (field.name === 'question')
                                    ? (
                                        <textarea
                                            className="form-modal__input form-modal__input--textarea"
                                            name={field.name}
                                            value={questionFormValues[field.name]}
                                            onChange={handleQuestionFormChange}
                                            cols="30"
                                            rows="7"
                                            placeholder={field.title}
                                        />
                                ) : (
                                        <Input
                                            className={`form__input ${questionFormValues[field.name].error ? 'invalid' : ''} ${!questionFormValues[field.name].error && questionFormValues[field.name].wasTouched ? 'valid' : ''}`}
                                            type={field.type}
                                            name={field.name}
                                            placeholder=" "
                                            required
                                            value={questionFormValues[field.name].value}
                                            handleChange={event => {
                                                handleQuestionFormChange(event);
                                                validateQuestionField(event.target.name, event.target.value);
                                            }}
                                            handleBlur={event => validateQuestionField(event.target.name, event.target.value)}
                                            title={field.title}
                                            isErrorOccurred={questionFormValues[field.name].error}
                                            errorMessage={questionFormValues[field.name].errorMessage}
                                        />
                                    )
                            }
                        </React.Fragment>
                    ))
                }

                <input
                    className="form-modal__submit-btn btn-secondary"
                    type="submit"
                    value={form.sendButton}
                />
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        question: state.modalsState.question,
        isLogged: state.userAccountState.isLogged,
        user: state.userAccountState.user,
        googleId: state.globalReducer.googleId,
        utmParams: state.globalReducer.utmParams,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleQuestion,
    toggleApplicationSuccess,
};

export const Question = connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);
