import React from 'react'

import mobileImg from '../../images/personal/mobile.webp';
import worksImg2 from '../../images/personal/how-it-works-img1.webp';
import worksImg1 from '../../images/personal/how-it-works-img2.webp';

export const PersonalServiceDescription = props => {
    return (
        <div className="personal-hiw-container">
            <section className="personal__hiw">
                <div className="personal__center-img">
                    <img src={mobileImg} alt="mobile" />
                </div>

                <article className="personal__hiw-article">
                    <div className="personal__hiw-text">
                        <div className="personal__hiw-text-container" dangerouslySetInnerHTML={{__html: props.service.description_first}}/>
                    </div>
                    <div className="personal__hiw-img">
                        <img src={worksImg1} alt="people" />
                    </div>
                </article>

                <article className="personal__hiw-article">
                    <div className="personal__hiw-img">
                        <img src={worksImg2} alt="cat" />
                    </div>

                    <div className="personal__hiw-text">
                        <div className="personal__hiw-text-container" dangerouslySetInnerHTML={{__html: props.service.description_second}}/>
                    </div>
                </article>
            </section>
        </div>
    );
};
