import React from 'react';
import {connect} from 'react-redux';

import Slider from 'react-slick';
import {ProductBlock} from "./ProductBlock";
import {SliderNextArrow} from "../SliderNextArrow";
import {SliderPrevArrow} from "../SliderPrevArrow";

const ProductSliderContainer = props => {
    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 2,
        speed: 500,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />,
        responsive: [
            {
                breakpoint: 1110,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
        ]
    }

    return (
        <div className="product-slider">
            {
                (props.products) && (
                        <Slider {...settings}>
                            {
                                props.products.map(product => (
                                    <ProductBlock
                                        key={product.id}
                                        product={product}
                                        isInCompare={props.productsCompare.some(item => item.id === product.id)}
                                    />
                                ))
                            }
                        </Slider>
                    )
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        productsCompare: state.compareState.products,
    };
};

export const ProductSlider = connect(mapStateToProps)(ProductSliderContainer);
