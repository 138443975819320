import React from 'react';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import certificateBitrek from '../../images/for-clients/certificate-bitrek.webp';
import certificateTeltonika from '../../images/for-clients/certificate-teltonika.webp';
import {SliderPrevArrow} from "../globalComponents/SliderPrevArrow";
import {SliderNextArrow} from "../globalComponents/SliderNextArrow";

const certificates = [
    certificateTeltonika,
    certificateBitrek,
];

export const ForClientsCertificates = props => {
    const [t] = useTranslation('forClients');

    const settings = {
        className: 'for-clients__slider',
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        prevArrow: <SliderPrevArrow />,
        nextArrow: <SliderNextArrow />,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 790,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
            {
                breakpoint: 510,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
        ]
    };

    return (
        <>
            <h2 className="for-clients__section-title">{t('certificatesTitle')}</h2>

            <div className="for-clients__slider-wrap for-clients__slider">
                <Slider {...settings}>
                    {
                        certificates.map((certificate, index) => (
                            <div key={index} className="for-clients__slider-item" >
                                <img src={certificate} alt="certificate" />
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </>
    );
};
