import axios from 'axios';
import {_discount} from '../../globalConsts';
import {addDeal, assignedById, categoryId} from './bitrixAPIConsts';
import {createCompany} from './createCompany';
import {createContact} from './createContact';

export const createDeal = async (
    products,
    totalPrice,
    userInfo,
    orderInfo,
    isLogged,
    loggedUser,
    activeTypeOfUser,
    googleId,
    utmParams,
) => {
    const date = new Date();

    let deliveryInformation = '';
    let installationInformation = '';
    if (orderInfo.deliveryAddress) {
        const {
            city,
            street,
            building,
            flat,
            new_post_office,
        } = orderInfo.deliveryAddress;

        deliveryInformation = `Доставка: ${orderInfo.deliveryMethod} ${city ? `Адреса доставки:\nМісто: ${city}\n` : ''}${street ? `Вулиця: ${street}, ${building}\n` : ''} ${flat ? `Квартира: ${flat}\n` : ''}${new_post_office ? `Відділення: ${new_post_office}\n` : ''}`;
    }

    if (orderInfo.installation) {
        const {
            city: cityInstallation,
            date: dateInstallation,
            comment: commentInstallation,
            address,
        } = orderInfo.installation;

        installationInformation = `Установка обладання: \n Місто: ${cityInstallation} \n Адреса: ${address} \n Дата: ${dateInstallation} \n Коментар: ${commentInstallation}`;
    }

    const productsToSend = products.reduce((acc, product) => (
        `${acc} Назва: ${product.name}, вартість: ${product.quantity}x${(product.final_price * (1 - _discount)).toLocaleString()} = ${product.final_price * product.quantity * (1 - _discount)};\n`), ``,
    );

    let organizationInfo = '';
    if (activeTypeOfUser === 'entity') {
        organizationInfo = `Назва організації: ${userInfo.org_name} \n Код ЄДРПОУ: ${userInfo.EDRPOU}`
    }

    const paymentInformation = `Спосіб оплати: ${orderInfo.paymentMethod}`;
    let sourceDescription = `
    Товари: 
    ${productsToSend}
    ${deliveryInformation}
    ${organizationInfo}
    ${paymentInformation}
    ${installationInformation}
    ${orderInfo.comment ? `Коментар до замовлення: ${orderInfo.comment}` : ''}
        
    Дзвонити для підтвердження замовлення: ${orderInfo.backCall ? 'Так' : 'Ні'}
    `;

    if (googleId) {
        sourceDescription += `\n googleId: ${googleId}`;
    }

    let fields = {
        TITLE: 'Замовлення обладнання',
        STATUS_ID: 'NEW',
        OPENED: 'Y',
        CATEGORY_ID: categoryId,
        DATE_CREATE: date,
        OPPORTUNITY: totalPrice,
        SOURCE_DESCRIPTION: sourceDescription,
        SOURCE_ID: 'STORE',
        ASSIGNED_BY_ID: assignedById,
    };

    if (utmParams) {
        for (const paramKey in utmParams) {
            if (utmParams.hasOwnProperty(paramKey)) {
                fields = {
                    ...fields,
                    [paramKey]: utmParams[paramKey]
                }
            }
        }
    }

    const userInfoForContactCreating = {
        orgName: userInfo.org_name,
        name: userInfo.name,
        lastName: userInfo.last_name,
        email: userInfo.email,
        phone: userInfo.phone,
    };

    if (isLogged) {
        if (loggedUser.is_organization) {
            fields.COMPANY_ID = loggedUser.bitrix_company_id;
            fields.CONTACT_ID = loggedUser.bitrix_user_id;
        } else {
            fields.CONTACT_ID = loggedUser.bitrix_user_id;
        }
    } else {
        if (activeTypeOfUser === 'entity') {
            const createdCompanyId = await createCompany(userInfoForContactCreating, googleId, utmParams);

            fields.COMPANY_ID = createdCompanyId;
            fields.CONTACT_ID = await createContact(userInfoForContactCreating, createdCompanyId);
        } else {
            fields.CONTACT_ID = await createContact(userInfoForContactCreating, null, googleId, utmParams)
        }
    }

    const response = await axios.post(addDeal, {
        fields,
        params: {},
    });

    return response.data.result;
};
