import {
    APPLY_BRAND_FILTER,
    APPLY_CATEGORY_FILTER,
    APPLY_PRICE_FILTER,
    APPLY_SORT_PROPERTY,
    APPLY_SPECIFICATIONS_FILTER,
} from '../actionConsts/actionConsts';

export const applyPriceFilter = payload => {
    return {
        type: APPLY_PRICE_FILTER,
        payload,
    };
};

export const applyBrandFilter = payload => {
    return {
        type: APPLY_BRAND_FILTER,
        payload,
    };
};

export const applySpecificationsFilter = payload => {
    return {
        type: APPLY_SPECIFICATIONS_FILTER,
        payload,
    };
};

export const applySortProperty = payload => {
    return {
        type: APPLY_SORT_PROPERTY,
        payload,
    };
};

export const applyCategoryFilter = payload => {
    return {
        type: APPLY_CATEGORY_FILTER,
        payload,
    };
};
