import standardHeaderBg from '../../images/transport-solutions/header-bg.webp';
import waterHeaderBg from '../../images/transport-solutions/header-water-bg.webp';
import airHeaderBg from '../../images/transport-solutions/header-air-bg.webp';
import stationaryBg from '../../images/transport-solutions/stationar-bg.webp';

import carImg from '../../images/transport-solutions/car.webp';
import truckImg from '../../images/transport-solutions/truck.webp';
import busImg from '../../images/transport-solutions/bus.webp';
import motorcycleImg from '../../images/transport-solutions/motorcycle.webp';
import railwayImg from '../../images/transport-solutions/railway.webp';
import waterTransportImg from '../../images/transport-solutions/water-transport.webp';
import airTransportImg from '../../images/transport-solutions/air-transport.webp';
import stationaryImg from '../../images/transport-solutions/stationar.webp';
import specialImg from '../../images/transport-solutions/special.webp';
import {parseImage, parseProductEntities, parseSolutionsFor} from '../../utils/parsers/parsers';

import {GET_TRANSPORT_SOLUTION} from "../actionConsts/actionConsts";
import {_image} from "../../API/apiConsts";

const imagesForSolutions = {
    'cars': {
        backgroundImage: standardHeaderBg,
        transportImage: carImg,
    },
    'freight-transport': {
        backgroundImage: standardHeaderBg,
        transportImage: truckImg,
    },
    'special-equipment': {
        backgroundImage: standardHeaderBg,
        transportImage: specialImg,
    },
    'buses': {
        backgroundImage: standardHeaderBg,
        transportImage: busImg,
    },
    'motorcycles': {
        backgroundImage: standardHeaderBg,
        transportImage: motorcycleImg,
    },
    'stationary-objects': {
        backgroundImage: stationaryBg,
        transportImage: stationaryImg
    },
    'railway': {
        backgroundImage: standardHeaderBg,
        transportImage: railwayImg,
    },
    'water-transport': {
        backgroundImage: waterHeaderBg,
        transportImage: waterTransportImg,
    },
    'air-transport': {
        backgroundImage: airHeaderBg,
        transportImage: airTransportImg,
    },
};

const initialState = {
    solution: {
        title: 'Грузовой транспорт',

        backgroundImage: '',
        transportImage: '',
        description: '',
        title_image: truckImg,
        short_description: '',
        opportunities: [
            {
                title: '',
                icon: '',
                description: '',
                products: [],
            },
        ],
        capabilities: [],
        products: [],
    },
};

const transportSolutionReducer = (state = initialState, action) =>{
    switch (action.type) {
        case GET_TRANSPORT_SOLUTION: {
            const solution = action.payload;

            const newSolutions = {
                ...solution,
                backgroundImage: imagesForSolutions[solution.type].backgroundImage,
                transportImage: imagesForSolutions[solution.type].transportImage,
                capabilities: solution.capabilities ? solution.capabilities.map(capability => ({
                    ...capability,
                    icon: parseImage(capability.icon),
                })) : [],
                opportunities: solution.opportunities ? solution.opportunities.map(opportunity => ({
                    ...opportunity,
                    solution_for: opportunity.solution_for ? parseSolutionsFor(opportunity.solution_for) : [],
                    icon: parseImage(opportunity.icon),
                    products: opportunity.products ? parseProductEntities(opportunity.products) : [],
                })) : [],
                products: solution.products ? solution.products.map(product => ({
                    ...product,
                    images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
                })) : [],
            };

            return {
                ...state,
                solution: newSolutions,
            }
        }
        default: return state;
    }
};

export default transportSolutionReducer;
