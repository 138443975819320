import axios from "axios";
import {addContacts, assignedById} from "./bitrixAPIConsts";

export const createContact = async (userInfo, companyId, googleId, utmParams) => {
    let sourceDescription = '';

    if(googleId) {
        sourceDescription += `\n googleId: ${googleId}`;
    }

    let requestFields = {
        NAME: userInfo.name,
        LAST_NAME: userInfo.lastName,
        OPENED: "Y",
        ASSIGNED_BY_ID: assignedById,
        SOURCE_DESCRIPTION: sourceDescription,
        PHONE: [
            {
                VALUE: userInfo.phone,
            },
        ],
        EMAIL: [
            {
                VALUE: userInfo.email,
            }
        ],
    };

    if (utmParams) {
        for (const paramKey in utmParams) {
            if (utmParams.hasOwnProperty(paramKey)) {
                requestFields = {
                    ...requestFields,
                    [paramKey]: utmParams[paramKey]
                }
            }
        }
    }

    if(companyId) {
        requestFields.COMPANY_ID = companyId;
    }

    const response = await axios.post(addContacts, {
        fields: {...requestFields},
    });

    return response.data.result;
};
