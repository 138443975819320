import React, {useEffect, useState} from 'react';

import './MainPage.scss';

import {useLocation, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {_image} from '../../API/apiConsts';
import {connect} from 'react-redux';
import {getBrands} from '../../API/requests';
import useLocalizedPath from '../../customHooks/useLocalizedPath';
import {getCompanies} from '../../redux/actionCreators/getActionCreators/mainPageActions';
import {getRecommendedProducts} from '../../API/requests';
import {
    toggleEntry,
    toggleModal,
    toggleOrderIdModal,
    togglePasswordResetModal,
} from '../../redux/actionCreators/modalActions';

import MainSlider from './MainSlider';
import {Advantages} from './Advantages';
import {Production} from './Production';
import {Solution} from './MainSolutions';
import {Subscribe} from '../globalComponents/Subscribe';
import {Recommendations} from '../globalComponents/Recommendations';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

export const MainPageContainer = props => {
    const [t] = useTranslation(['mainPage', 'aboutUs']);
    const localizedUserPath = useLocalizedPath('user');
    const localizedMainPath = useLocalizedPath('');

    const [recommendedProducts, setRecommendedProducts] = useState([]);
    const location = useLocation();
    const routerHistory = useHistory();

    useEffect(() => {
        saveBrands();
        saveRecommendedProducts();
        if (location.search.includes('token') && !location.search.includes('reg_token')) {
            props.toggleModal(true);
            props.togglePasswordResetModal(true);
        }

        if (location.search.includes('order_id')) {
            props.toggleModal(true);
            props.toggleOrderIdModal(true);
        }
    }, []);

    useEffect(() => {
        if (location.search.includes('reg_token')) {
            if (props.isLogged) {
                routerHistory.push(localizedUserPath)
            } else {
                props.toggleModal(true);
                props.toggleEntry(true);
                routerHistory.push(localizedMainPath);
            }
        }
    }, [props.isLogged]);

    const saveBrands = () => {
        getBrands().then(companies => props.getCompanies(companies))
    };

    const saveRecommendedProducts = async () => {
        const products = await getRecommendedProducts();
        const productsToSet = products ? products.map(product => ({
            ...product,
            images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
        })) : [];

        setRecommendedProducts(productsToSet);
    };

    return (
        <div className="main-page">
            <h1 className="main-page__hidden-title">Total Control</h1>

            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={t('aboutUs:articles', {returnObjects: true})[2]}
            />

            <MainSlider translation={t('mainSlider', {returnObjects: true})}/>
            <Advantages advantages={props.advantages} translation={t('advantages', {returnObjects: true})}/>
            <Production productions={props.productions} translation={t('production', {returnObjects: true})}/>
            <Solution solutions={props.solutions} translation={t('solution', {returnObjects: true})}/>
            {
                (recommendedProducts.length > 0) && (
                    <Recommendations recommendedProducts={recommendedProducts}/>
                )
            }
            <Subscribe/>
        </div>
);
};

const mapStateToProps = state =>
    {
        return {
            productions: state.mainPageState.productions,
            solutions: state.mainPageState.solutions,
            isLogged: state.userAccountState.isLogged,
        };
    }
;

const mapDispatchToProps =
    {
        getCompanies,
            togglePasswordResetModal,
            toggleModal,
            toggleEntry,
            toggleOrderIdModal,
    }
;

export const MainPage = connect(mapStateToProps, mapDispatchToProps)(MainPageContainer);
