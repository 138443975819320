export const getEquipmentCategories = async () => {
    return window.axios.get('',{
        params: {
            query: `
                query {
                    categories {
                        name
                        slug
                    }
                }
            `
        },
    }).then(response => response.data.data.categories)
        .catch(error => console.log(error));
};
