import axios from "axios";
import {addContacts, assignedById} from "./bitrixAPIConsts";

export const createSubscriberContacts = async (email, googleId, utmParams) => {
    let sourceDescription = '';

    if(googleId) {
        sourceDescription += `\n googleId: ${googleId}`;
    }

    if(utmParams) {
        for (const paramKey in utmParams) {
            if (utmParams.hasOwnProperty(paramKey) && utmParams[paramKey]) {
                sourceDescription += `\n ${paramKey}: ${utmParams[paramKey]}`
            }
        }
    }

    return await axios.post(addContacts, {
        fields: {
            NAME: 'Підписка на сайт',
            OPENED: "Y",
            SOURCE_ID: "STORE",
            ASSIGNED_BY_ID: assignedById,
            SOURCE_DESCRIPTION: sourceDescription,
            EMAIL: [
                {
                    VALUE: email,
                },
            ],
        },
    });
};
