import React, {useEffect, useReducer} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {createLead} from '../../../API/birixApi/createLead';
import {orderServiceFormFields} from '../../../globalConsts';
import ReactGA from 'react-ga';
import closeBtn from '../../../images/close-btn.svg';
import {toggleApplicationSuccess, toggleModal, toggleServiceOrder} from '../../../redux/actionCreators/modalActions';
import createFormState from '../../../utils/stateReducers/createFormState';
import formReducer from '../../../utils/stateReducers/formReducer';
import Form from '../Form/Form';

const OrderServiceModalContainer = props => {
    const [t] = useTranslation('common');
    const [orderServiceFormValues, dispatchOrderServiceFormValues] = useReducer(formReducer, orderServiceFormFields, createFormState);

    useEffect(() => {
        if(props.service) dispatchOrderServiceFormValues({type: 'set_value', payload: {name: 'service', value: props.service}});

        if(props.isLogged) {
            const formInfoToSet = {
                name: {
                    ...orderServiceFormValues.name,
                    value: props.user.name,
                    wasTouched: true,
                },
                phone: {
                    ...orderServiceFormValues.phone,
                    value: props.user.phone,
                    wasTouched: true,
                },
                email: {
                    ...orderServiceFormValues.email,
                    value: props.user.email,
                    wasTouched: true,
                },
            }

            dispatchOrderServiceFormValues({type: 'set_all_values', payload: formInfoToSet});
        }
    }, [props.user, props.isLogged, props.service]);

    const onOrderServiceSubmit = () => {
        const userInfo = {
            service: orderServiceFormValues.service.value,
            name: orderServiceFormValues.name.value,
            phone: orderServiceFormValues.phone.value,
            email: orderServiceFormValues.email.value,
            comment: orderServiceFormValues.comment.value,
        }

        createLead(userInfo, 'serviceOrder', props.googleId, props.utmParams);
        applicationSuccessModal();
        ReactGA.event({
            category: 'Order',
            action: 'order-service',
            label: 'Заказ услуги',
        });
    };

    const applicationSuccessModal = () => {
        props.toggleApplicationSuccess(true);
        props.toggleServiceOrder(false);
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleServiceOrder(false);
    };

    return (
        <div className={`form-modal form-modal--service ${props.serviceOrder ? 'active' : ''}`}>
            <div className="modal__close">
                <img
                    src={closeBtn}
                    alt="close"
                    onClick={closeModals}
                />
            </div>

            <Form
                className="form-modal__body"
                formValues={orderServiceFormValues}
                dispatchFormValues={dispatchOrderServiceFormValues}
                formFields={orderServiceFormFields}
                onSubmitSuccess={onOrderServiceSubmit}
            >
                <h6 className="form-modal__title">{t('serviceRequest')}</h6>
            </Form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        serviceOrder: state.modalsState.serviceOrder,
        isLogged: state.userAccountState.isLogged,
        user: state.userAccountState.user,
        service: state.globalReducer.serviceForOrder,
        googleId: state.globalReducer.googleId,
        utmParams: state.globalReducer.utmParams,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleServiceOrder,
    toggleApplicationSuccess,
}

export const OrderServiceModal = connect(mapStateToProps, mapDispatchToProps)(OrderServiceModalContainer);
