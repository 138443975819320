const createFormState = formFields => {
    return  formFields.reduce((finalState, currentField) => {
        return {
            ...finalState,
            [currentField.name] : {
                value: currentField.type === 'tel' ? '+380' : '',
                error: false,
                wasTouched: false,
                errorMessage: '',
            },
        };
    }, {});
};

export default createFormState;
