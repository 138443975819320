import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {changeUserData} from '../../../API/requests';

export const UserAccountPersonalFormIndividual = props => {
    const [t] = useTranslation('account');
    const individualFormTitles = t('personal.individual', {returnObjects: true});

    const formFields = {
        name: {
            title: individualFormTitles.name,
        },
        fathers_name: {
            title: individualFormTitles.fathersName,
        },
        last_name: {
            title: individualFormTitles.lastName,
        },
        phone: {
            title: individualFormTitles.phone,
        },
        email: {
            title: individualFormTitles.email,
        },
    };

    const [personalDataFormValues, setPersonalDataFormValues] = useState({
        name: '',
        fathers_name: '',
        last_name: '',
        phone: '',
        email: '',
    });
    const [dataChangeRequest, setDataChangeRequest] = useState({
        isRequested: false,
        isSuccess: false,
    });

    useEffect(() => {
        const {
            name,
            fathers_name,
            last_name,
            phone,
            email,
        } = props.user;
        
        setPersonalDataFormValues({
           name: name ? name : '',
           fathers_name: fathers_name ? fathers_name : '',
           last_name: last_name ? last_name : '',
           phone: phone ? phone : '',
           email: email ? email : '',
        });
    }, [props.user]);

    const handlePersonalInfoChange = event => {
        const {name, value} = event.target;

        setPersonalDataFormValues(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setDataChangeRequest({
            isRequested: false,
            isSuccess: false,
        });
    };


    const submitPersonalDataChanges = () => {
        changeUserData(personalDataFormValues).then(data => {
            if(data.data) {
                setDataChangeRequest({
                    isRequested: true,
                    isSuccess: data.data.changeUserData,
                });
            } else {
                setDataChangeRequest({
                    isRequested: true,
                    isSuccess: false,
                });
            }
        });
    };

    return (
        <div className="user-account__personal-form">
            <h3 className="user-account__personal-title">{t('personal.personalDataTitle')}</h3>
            {
                Object.keys(formFields).map(fieldName => (
                    <label key={fieldName} className="form__label">
                        {
                            <input
                                className="form__input"
                                name={fieldName}
                                value={personalDataFormValues[fieldName]}
                                onChange={handlePersonalInfoChange}
                            />
                        }
                        <span className="standart field-title">{formFields[fieldName].title}</span>
                    </label>
                ))
            }

            {
                (dataChangeRequest.isRequested && dataChangeRequest.isSuccess) && (
                    <p className="message-label message-label--success">{t('personal.successMessage')}</p>
                )
            }

            {
                (dataChangeRequest.isRequested && !dataChangeRequest.isSuccess) && (
                    <p className="message-label message-label--error">{t('personal.errorMessage')}</p>
                )
            }

            <button className="user-account__edit-btn btn-ghost-secondary" onClick={submitPersonalDataChanges}>
                {t('personal.redactButton')}
            </button>
        </div>
    );
};
