import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {setProductForOffer} from '../../../redux/actionCreators/globalActions';

import {ProductCounter} from "./ProductCounter";
import {toggleBasket, toggleCommercialOffer, toggleModal} from "../../../redux/actionCreators/modalActions";
import {connect} from "react-redux";
import {addProductToBasket} from "../../../redux/actionCreators/basketActions";
import {addProductToCompare, deleteProductFromCompare} from "../../../redux/actionCreators/compareActions";

const ProductControllerContainer = props => {
    const [t] = useTranslation(['productCard', 'common']);

    const [productCount, setProductCount] = useState(1);
    const isInCompare = props.productsCompare.some(item => item.id === props.product.id);

    const increaseCount = () => {
        setProductCount(prevState => {
            return prevState ? prevState + 1 : 1;
        });
    };

    const decreaseCount = () => {
        setProductCount(prevState => {
            if (prevState === 1) {
                return prevState
            }

            return prevState - 1;
        });
    };

    const addProductToBasket = () => {
        const quantityToSet = productCount ? productCount : 1;

        props.addProductToBasket({
            ...props.product,
            quantity: quantityToSet,
        });
    };

    const addProductToCompare = () => {
        isInCompare
            ? props.deleteProductFromCompare(props.product.id)
            : props.addProductToCompare({
                ...props.product,
                id: props.product.id,
            });
    };

    return (
        <div className="product__info-card">
            <h1 className="product__info-card-title">
                {props.product.name}
            </h1>
            <div className="product__info-card-subtitle" dangerouslySetInnerHTML={{__html: props.product.short_description}} />

            <div className="product__middle-wrap">
                <div className="product__price-wrap">
                    <div className="product__price-value">
                        <span className="product__price">
                            {props.product.final_price.toLocaleString()}
                        </span>
                        <span className="product__currency"> грн</span>
                    </div>
                    {/*<div className="product__bonus-value">{`+${props.product.bonus_points} ${t('bonusPoints')}`}</div>*/}
                </div>

                <ProductCounter
                    productCount={productCount}
                    increaseCount={increaseCount}
                    decreaseCount={decreaseCount}
                    quantityTranslation={t('quantity')}
                />
            </div>

            <div className="product__bottom-wrap">
                <div className="product__btn-wrap">
                    <button
                        className="btn-accent product__buy-btn"
                        onClick={() => {
                            addProductToBasket()
                            props.toggleModal(true)
                            props.toggleBasket(true)
                        }}
                    >
                        {t('buyButton')}
                    </button>

                    <button
                        className={`product__compare-btn ${isInCompare ? 'active' : ''} `}
                        onClick={addProductToCompare}
                    >
                        <div className="product__compare-ico">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M27.7108 16.6504L24.2108 8.83006C24.0698 8.51517 23.7571 8.3125 23.4121 8.3125C23.4015 8.3125 23.391 8.31327 23.3804 8.31365C23.3727 8.31343 23.3651 8.3125 23.3574 8.3125H15.0996V5.99698C16.3632 5.61952 17.2871 4.44719 17.2871 3.0625C17.2871 1.37386 15.9133 0 14.2246 0C12.5359 0 11.1621 1.37386 11.1621 3.0625C11.1621 4.44713 12.0861 5.61947 13.3496 5.99698V8.3125H5.0918C5.08403 8.3125 5.07654 8.31348 5.06883 8.31365C5.05822 8.31327 5.04772 8.3125 5.03711 8.3125C4.69214 8.3125 4.37938 8.51517 4.23845 8.83006L0.738453 16.6504C0.68218 16.776 0.659047 16.9078 0.663422 17.0368C0.663148 17.0454 0.662109 17.0538 0.662109 17.0625V18.375C0.662109 20.7874 2.62473 22.75 5.03711 22.75C7.44948 22.75 9.41211 20.7874 9.41211 18.375V17.0625C9.41211 17.0538 9.41107 17.0454 9.4108 17.0368C9.41517 16.9078 9.39204 16.776 9.33577 16.6504L6.3874 10.0625H13.3496V26.25H8.97461C8.49134 26.25 8.09961 26.6418 8.09961 27.125C8.09961 27.6082 8.49134 28 8.97461 28H19.4746C19.9579 28 20.3496 27.6082 20.3496 27.125C20.3496 26.6418 19.9579 26.25 19.4746 26.25H15.0996V10.0625H22.0618L19.1134 16.6504C19.0571 16.776 19.034 16.9078 19.0384 17.0368C19.0381 17.0454 19.0371 17.0538 19.0371 17.0625V18.375C19.0371 20.7874 20.9997 22.75 23.4121 22.75C25.8244 22.75 27.7871 20.7874 27.7871 18.375V17.0625C27.7871 17.0538 27.786 17.0454 27.7857 17.0368C27.7902 16.9078 27.767 16.776 27.7108 16.6504ZM12.9121 3.0625C12.9121 2.33877 13.5009 1.75 14.2246 1.75C14.9483 1.75 15.5371 2.33877 15.5371 3.0625C15.5371 3.78623 14.9483 4.375 14.2246 4.375C13.5009 4.375 12.9121 3.78623 12.9121 3.0625ZM5.03711 11.3294L7.21132 16.1875H2.8629L5.03711 11.3294ZM7.66211 18.375C7.66211 19.8224 6.48452 21 5.03711 21C3.5897 21 2.41211 19.8224 2.41211 18.375V17.9375H7.66211V18.375ZM25.5863 16.1875H21.2379L23.4121 11.3294L25.5863 16.1875ZM26.0371 18.375C26.0371 19.8224 24.8595 21 23.4121 21C21.9647 21 20.7871 19.8224 20.7871 18.375V17.9375H26.0371V18.375Z"
                                    fill="#1A3B70"
                                />
                            </svg>
                        </div>
                        <div className="product__compare-text">{t('compareButton')}</div>
                    </button>
                </div>

                <button
                    className="btn-ghost-accent product__proposal-request-btn commercial"
                    onClick={
                        () => {
                            props.toggleModal(true);
                            props.toggleCommercialOffer(true);
                            props.setProductForOffer(props.product.name);
                        }
                    }
                >
                    {t('common:commercialOfferRequest')}
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        productsCompare: state.compareState.products,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleCommercialOffer,
    toggleBasket,
    addProductToBasket,
    addProductToCompare,
    deleteProductFromCompare,
    setProductForOffer,
};

export const ProductController = connect(mapStateToProps, mapDispatchToProps)(ProductControllerContainer);
