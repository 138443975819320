export const getPost = id => {
    return window.axios.get('', {
        params: {
            query: `
                query($id: ID!) {
                    post(id: $id) {
                        id
                        title
                        image
                        short_description
                        section {
                            name
                        }
                        topic {
                            name
                        }
                        text
                        products {
                            id
                            name
                            slug
                            images
                            final_price
                            short_description
                            category {
                                slug
                                name
                            }
                        }
                    }
                }
            `,
            variables: {
                id,
            },
        }
    }).then(response => response.data.data.post)
        .catch(error => console.log(error));
};
