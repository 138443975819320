import { combineReducers } from 'redux';
import choosingSolutionReducer from './choosingSolutionReducer';
import equipmentReducer from "./equipmentReducer";
import productItemReducer from "./productItemReducer";
import solutionsReducer from "./solutionsReducer";
import mainPageReducer from "./mainPageReducer";
import modalsReducer from "./modalsReducer";
import compareReducer from "./compareReducer";
import casesReducer from "./casesReducer";
import basketReducer from "./basketReducer";
import catalogReducer from "./catalogReducer";
import userAccountReducer from "./userAccountReducer";
import industrySolutionReducer from "./industrySolutionReducer";
import transportSolutionReducer from "./transportSolutionReducer";
import caseReducer from "./caseReducer";
import additionalServicesReducer from "./additionalServicesReducer";
import globalReducer from "./globalReducer";
import checkoutReducer from "./checkoutReducer";

const rootReducer = combineReducers({
    catalogState: catalogReducer,
    equipmentState: equipmentReducer,
    productItemState: productItemReducer,
    solutionsState: solutionsReducer,
    mainPageState: mainPageReducer,
    modalsState: modalsReducer,
    compareState: compareReducer,
    basketState: basketReducer,
    casesState: casesReducer,
    caseState: caseReducer,
    userAccountState: userAccountReducer,
    industrySolutionState: industrySolutionReducer,
    transportSolutionState: transportSolutionReducer,
    additionalServicesState: additionalServicesReducer,
    choosingSolutionState: choosingSolutionReducer,
    checkoutState: checkoutReducer,
    globalReducer,
});

export default rootReducer;
