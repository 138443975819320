import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {createLead} from '../../../../API/birixApi/createLead';
import {connect} from "react-redux";
import {toggleApplicationSuccess, toggleModal} from "../../../../redux/actionCreators/modalActions";

const PhoneMenuContainer = props => {
    const [t] = useTranslation('header');
    const phoneMenu = t('headerTopMenu.phoneMenu', {returnObjects: true});
    const [phone, setPhone] = useState('+380');

    const handleCallSubmit = () => {

        const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

        if(!regEx.test(phone)) {
            return;
        }

        if(phone.trim()) {
            const userInfo = {
                name: 'Невизначений',
                email: '',
                phone,
            };
            createLead(userInfo, 'callback');

            props.toggleModal(true);
            props.toggleApplicationSuccess(true);
        }
    };

    return (
        <div className={`header-top__phone-dropdown ${props.isActive ? 'active' : ''}`}>
            {/*{*/}
            {/*    props.mobileContacts.map(contact => (*/}
            {/*        <div key={contact.name} className="header-top__phone-cellphone">*/}
            {/*            <img src={contact.icon} alt={contact.name}/>*/}
            {/*            <a href={`tel:+38${contact.number}`}>{contact.number}</a>*/}
            {/*        </div>*/}
            {/*    ))*/}
            {/*}*/}

            <div className="header-top__phone-schedule">
                <div className="header-top__phone-schedule-item">
                    <span className="header-top__phone-days">{`${phoneMenu.weekdays}:`}</span>
                    <span className="header-top__phone-time">08:00 – 19:00</span>
                </div>
                <div className="header-top__phone-schedule-item">
                    <span className="header-top__phone-days">{`${phoneMenu.weekends}:`}</span>
                    <span className="header-top__phone-time">{phoneMenu.weekendsTime}</span>
                </div>
            </div>
            <label className="header-top__phone-input-label" htmlFor="user-phone">{phoneMenu.callMeTitle}
                <input
                    className="header-top__phone-input"
                    type="tel"
                    name="user-phone"
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                    autoComplete="chrome-off off"
                />
                <input
                    className="header-top__call-btn"
                    type="submit"
                    value={phoneMenu.callMeButtonValue}
                    onClick={handleCallSubmit}
                />
            </label>
        </div>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleApplicationSuccess,
};

export const PhoneMenu = connect(null, mapDispatchToProps)(PhoneMenuContainer);
