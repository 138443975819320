import React from 'react';
import {useTranslation} from 'react-i18next';
import LocalizedLink from '../../LocalizedLink';

export const HeaderTopNav = props => {
    const [t] = useTranslation('header');
    const navItemsTranslation = t('navItems', {returnObjects: true});
    const navItems = [
        {
            name: navItemsTranslation.discount,
            link: 'catalog/discount-products',
        },
        {
            name: navItemsTranslation.cases,
            link: 'cases',
        },
        {
            name: navItemsTranslation.tariffs,
            link: 'tariffs',
        },
        {
            name: navItemsTranslation.support,
            link: 'support',
        },
        {
            name: navItemsTranslation.pay,
            link: 'pay',
        },
        {
            name: navItemsTranslation.blog,
            link: 'blog',
        },
        {
            name: navItemsTranslation.aboutUs,
            link: 'about-us',
        },
        {
            name: navItemsTranslation.forClients,
            link: 'for-clients',
        },
        {
            name: navItemsTranslation.contacts,
            link: 'contacts',
        },
    ];

    return (
        <ul className="header-top__nav">
            {
                navItems.map((item, index) => (
                    <li
                        key={index}
                        className="header-top__nav-item"
                    >
                        <LocalizedLink to={`/${item.link}`} type={'nav_link'}>{item.name}</LocalizedLink>
                    </li>
                ))
            }
        </ul>
    );
};
