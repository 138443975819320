import React from 'react';
import {useTranslation} from 'react-i18next';

import mark from '../../images/mark-orange.svg';

export const ContactsAddresses = props => {
    const [t] = useTranslation('contacts');

    return (
        <div className="container container-addresses">
            <div className={`contacts__addresses-wrap contacts__addresses-wrap--branches ${props.activeTab === 'branches' ? 'active' : ''}`}>
                {
                    props.locations.branches.map((branch, index) => {
                        const isValidLocation = props.selectedLocation.type === 'branches'
                            && props.selectedLocation.index === index;

                        return (
                            <div
                                key={index}
                                className={`contacts__address-item ${isValidLocation ? 'active' : ''}`}
                                onClick={() => props.selectLocation({index, type: 'branches'})}
                            >
                                <div className="contacts__address-mark">
                                    <img src={mark} alt="mark" />
                                </div>
                                <p className="contacts__address">{branch.address.title}</p>
                                <p className="contacts__phone">{`тел: ${branch.phones}`}</p>
                            </div>
                        );
                    })
                }
            </div>

            <div className={`contacts__addresses-wrap contacts__addresses-wrap--tech ${props.activeTab === 'support' ? 'active' : ''}`}>
                <article className="support__info">
                    <h6 className="support__info-title">
                        {`${t('support.subtitle')}:`}
                    </h6>
                    <ul className="support__info-list">
                        <li className="support__info-li">
                            <div className="support__li-title">
                                {`${t('support.email')}:`}
                                <p><a className="support__li-link" href="mailto:support@totalcontrol.ua?subject=Заявка на техподдержку">support@totalcontrol.ua</a></p>
                                <p><a className="support__li-link" href="mailto:support@scontrol.ua?subject=Заявка на техподдержку">support@scontrol.ua</a></p>
                            </div>
                        </li>
                        <li className="support__info-li">
                            <div className="support__li-title">
                                {`${t('support.phones')}:`}
                                <p><a className="support__li-link" href="tel:+380443339651">+38 (044) 333-96-51</a></p>
                                <p><a className="support__li-link" href="tel:+380993339651">+38 (099) 333-96-51</a></p>
                                <p><a className="support__li-link" href="tel:+380673339651">+38 (067) 333-96-51</a></p>
                                <p><a className="support__li-link" href="tel:+380933339651">+38 (093) 333-96-51</a></p>
                                <p><a className="support__li-link support__li-link--large" href="tel:0800339651">0 800 33-96-51</a></p>
                                <p><a className="support__li-link" href="tel:+380443541675">+38 (044) 354-16-75</a>{` ${t('support.priority')}`}</p>
                            </div>
                        </li>
                    </ul>
                </article>
            </div>
        </div>
    );
};
