import React from 'react';
import ReactGA from 'react-ga';

const LoginMenu = ({toggleLoginMenu, isActive}) => {
    const handleLinkClick = () => {
        toggleLoginMenu();
        ReactGA.event({
            category: 'Open GPS server',
            action: 'open-gps-server',
            label: 'Зашли на сервер gps мониторинга',
        });
    };

    return (
        <ul className={`system-login ${isActive ? 'active' : ''}`}>
            <li className="system-login__item">
                <a
                    href="https://gps.totalcontrol.ua/login.aspx"
                    target='_blank'
                    rel="noreferrer"
                    onClick={handleLinkClick}
                >
                    gps.totalcontrol.ua
                </a>
            </li>

            <li className="system-login__item">
                <a
                    href="https://gps2.totalcontrol.ua/login.aspx"
                    target='_blank'
                    rel="noreferrer"
                    onClick={handleLinkClick}
                >
                    gps2.totalcontrol.ua
                </a>
            </li>

            <li className="system-login__item">
                <a
                    href="https://gps.scontrol.ua/"
                    target='_blank'
                    rel="noreferrer"
                    onClick={handleLinkClick}
                >
                    gps.scontrol.ua
                </a>
            </li>
        </ul>
    );
};


export default LoginMenu