import React, {useState, useEffect} from 'react';
import './Compare.scss';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {getCompareFromLocalStorage} from "../../redux/actionCreators/getActionCreators/compareAction";
import {addProductToBasket} from "../../redux/actionCreators/basketActions";
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {CompareProductCards} from "./CompareProductCards";
import {CompareTable} from "./CompareTable";
import {CompareFilter} from "./CompareFilter";
import {CompareEmpty} from "./CompareEmpty";

const CompareContainer = props => {
    const [t] = useTranslation('compare');
    const categories = [];

    const [reverse, setReverse] = useState(0);

    // 0 - all
    // 1 - different
    // 2 - same

    const [filterViewValue, setFilterViewValue] = useState('all');

    useEffect(() => {
        const compareFromLocaleStorage = localStorage.getItem('compare');
        if(compareFromLocaleStorage) {
            const parsedCompareFromStorage = JSON.parse(compareFromLocaleStorage);
            if(parsedCompareFromStorage) {
                props.getCompareFromLocalStorage(parsedCompareFromStorage);
                chooseCategory(parsedCompareFromStorage[0].category.slug);
            }
        }
    }, []);



    const changeFilterView = viewType => {
        setFilterViewValue(viewType);
    };

    const selectCategory = category => {
        setSelectedCategory(category);
    }

    props.products.map(data => {
        if(categories.includes(data.category.slug)) {
            return categories
        } else {
            categories.push(data.category.slug)
        }
    })

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const chooseCategory = data => {
        setSelectedCategory(data)
    }

    const productsCompare = []
    props.products.map(data => {
        if(data.category.slug === selectedCategory) {
            productsCompare.push(data)
        }
    })
    const specificationList = []
    productsCompare.map(data => {
        Object.keys(data.specifications).map(item => {
            if(specificationList.includes(item)) {
                return specificationList
            } else if(data.category.slug === selectedCategory){
                specificationList.push(item)
            }
        })
    })

    return (
        <div className="container compare-wrap">
            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={t('title')}
            />

            <div className="compare">
                <h3 className="compare__title">{t('title')}</h3>
                {
                    props.products.length
                        ? (
                            <>
                                <div className="compare__top-section">
                                    <CompareFilter
                                        categories={categories}
                                        changeFilterView={changeFilterView}
                                        filterViewValue={filterViewValue}
                                        selectedCategory={selectedCategory}
                                        selectCategory={selectCategory}
                                        productsCompare={productsCompare}
                                        products={props.products}
                                        setReverse={setReverse}
                                        reverse={reverse}
                                    />
                                    <CompareProductCards productsCompare={productsCompare} />
                                </div>

                                <CompareTable
                                    productsCompare={productsCompare}
                                    specificationList={specificationList}
                                    reverse={reverse}
                                />
                            </>
                        )
                        : (<CompareEmpty />)
                }
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        products: state.compareState.products,
    };
};

const mapDispatchToProps = {
    addProductToBasket,
    getCompareFromLocalStorage,
};

export const Compare = connect(mapStateToProps, mapDispatchToProps)(CompareContainer);
