import React, { useEffect, useState } from "react";

import "./App.scss";
import axios from "axios";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { getCurrentUser } from "./API/requests";
import Routes from "./components/globalComponents/Routes/Routes";
import initializeGoogleAnalytics from "./utils/googleAnalytics/initializeGoogleAnalytics";
import { getUserInformation } from "./redux/actionCreators/getActionCreators/userAccountActions";
import {
  changeUserLocation,
  changeLanguage,
  setGoogleId,
  setUtmParams,
} from "./redux/actionCreators/globalActions";
import { getBasketFromLocalStorage } from "./redux/actionCreators/getActionCreators/basketActions";
import { getCompareFromLocalStorage } from "./redux/actionCreators/getActionCreators/compareAction";
import { toggleUserLogin } from "./redux/actionCreators/userActions";

import { Header } from "./components/globalComponents/Header";
import { Footer } from "./components/globalComponents/Footer";
import { Modal } from "./components/globalComponents/Modal";
import { languageInitialization } from "./utils/languageInitialization";
import {
  getBasketItemsFromLocalStorage,
  getCompareItemsFromLocalStorage,
  getTokenFormLocalStorage,
} from "./utils/localStorageHandles";
import "./utils/googleAnalytics/initializeGoogleAnalytics";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://652792d6c8e244c3b21e0447d0b765a0@o1081602.ingest.sentry.io/6089307",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const App = (props) => {
  const { changeLanguage, changeUserLocation } = props;
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [, i18n] = useTranslation();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getItemsFromLocalStorage();
    detectLanguage();
    getCookiesInfo();
    getUtmParams();
    initializeGoogleAnalytics();
  }, []);

  useEffect(() => {
    if (lat && lng) {
      axios
        .get(
          `https://us1.locationiq.com/v1/reverse.php?key=pk.8948194835f78dfbb82e29da2089e28f&lat=${lat}&lon=${lng}&format=json`
        )
        .then((res) => {
          const location = res.data.address.state;
          if (location) {
            changeUserLocation(res.data.address.state);
            localStorage.setItem(
              "userLocation",
              JSON.stringify(res.data.address.state)
            );
          }
        })
        .catch(() =>
          localStorage.setItem("userLocation", JSON.stringify("Київ"))
        );
    }
  }, [lng, lat]);

  const detectLanguage = () => {
    const languageFromLocalStorage = localStorage.getItem("language");

    if (languageFromLocalStorage) {
      const language = JSON.parse(languageFromLocalStorage);
      changeLanguage(language);
      i18n.changeLanguage(language.key);
      languageInitialization(language, location, history);
    }
  };

  const getCookiesInfo = () => {
    const userId = Cookies.get("_ga")?.replace("GA1.1.", "");

    props.setGoogleId(userId);
  };

  const getUtmParams = () => {
    const searchParams = new URLSearchParams(location.search);

    const utmParams = {
      "UTM-метки": location.search,
      UTM_SOURCE: searchParams.get("utm_source"),
      UTM_CONTENT: searchParams.get("utm_content"),
      UTM_MEDIUM: searchParams.get("utm_medium"),
      UTM_TERM: searchParams.get("utm_term"),
      UTM_CAMPAIGN: searchParams.get("utm_campaign"),
    };

    props.setUtmParams(utmParams);
  };

  const getItemsFromLocalStorage = () => {
    if (getTokenFormLocalStorage()) {
      getCurrentUser()
        .then((user) => {
          if (user) {
            props.toggleUserLogin(true);
            props.getUserInformation(user);
          } else {
            localStorage.removeItem("token");
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
        });
    }

    props.getBasketFromLocalStorage(getBasketItemsFromLocalStorage());
    props.getCompareFromLocalStorage(getCompareItemsFromLocalStorage());

    const userLocation = localStorage.getItem("userLocation");

    if (userLocation !== "undefined" && userLocation) {
      props.changeUserLocation(JSON.parse(userLocation));
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    }
  };

  return (
    <div className="App">
      <Header />
      <Modal />

      <div className="content-wrap">
        <div className="content">
          <Routes />
        </div>
      </div>

      <Footer />
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    isLogged: store.userAccountState.isLogged,
    language: store.globalReducer.language,
  };
};

const mapDispatchToProps = {
  changeUserLocation,
  changeLanguage,
  getBasketFromLocalStorage,
  getCompareFromLocalStorage,
  toggleUserLogin,
  getUserInformation,
  setGoogleId,
  setUtmParams,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
