import React from 'react';

export const ProductFeatures = props => {
    return (
        <div className="product__features">
            {
                props.advantages.map((advantage, index) => (
                    <div key={index} className="product__feature-item-wrap">
                        <div className="product__feature-item">
                            <div className="product__feature-ico">
                                <img className="product__feature-image" src={advantage.icon} alt="icon" />
                            </div>

                            <div className="product__feature-body">
                                <p className="product__feature-text">{advantage.text}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};
