import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import './Solutions.scss';
import './SolutionChoosing.scss';
import {getAllOpportunities, getSolutionsByType} from '../../API/requests';
import useLocalizedPath from '../../customHooks/useLocalizedPath';
import {
    setSelectedOpportunities,
    setSelectedTransportTypes,
} from '../../redux/actionCreators/chosenSolutionActions';
import {getOpportunities, getTransportSolutions} from '../../redux/actionCreators/getActionCreators/solutionsActions';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PreLoader} from '../globalComponents/PreLoader';

import {SolutionChoosingTransport} from "./SolutionChoosingTransport";
import {SolutionsChoosingTask} from "./SolutionsChoosingTask";

const SolutionChoosingContainer = props => {
    const [t] = useTranslation('solutionChoosing');
    const localizedSolutionPath = useLocalizedPath('chosen-solutions')

    const [isNextButtonActive, setIsNextButtonActive] = useState(false);
    const [isFinalButtonActive, setIsFinalButtonActive] = useState(false);
    const [activeSolutionTab, setActiveSolutionTab] = useState('transport');
    const [selectedTypesOfTransport, setSelectedTypesOfTransport] = useState({});
    const [selectedTasks, setSelectedTasks] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const routerHistory = useHistory();

    useEffect(() => {
        setInfoForChoose();
    }, []);

    const setInfoForChoose = () => {
        setIsLoading(true);
        getSolutionsByType('transport', true).then(solutions => props.getTransportSolutions(solutions)).then(() => setIsLoading(false));
        getAllOpportunities().then(opportunities => props.getOpportunities(opportunities));
    }

    useEffect(() => {
        Object.keys(selectedTypesOfTransport).some(key => selectedTypesOfTransport[key])
            ? setIsNextButtonActive(true)
            : setIsNextButtonActive(false)

        props.setSelectedTransportTypes(selectedTypesOfTransport);
    }, [selectedTypesOfTransport]);

    useEffect(() => {
        Object.keys(selectedTasks).some(key => selectedTasks[key])
            ? setIsFinalButtonActive(true)
            : setIsFinalButtonActive(false)

        props.setSelectedOpportunities(selectedTasks);
    }, [selectedTasks]);

    const changeSelectedTypesOfTransport = transportType => {
        setSelectedTypesOfTransport(prevState => {
            return prevState[transportType]
                ? {
                    ...prevState,
                    [transportType]: false,
                }
                : {
                    ...prevState,
                    [transportType]: true,
                }
        });
    };

    const changeSelectedTasks = taskName => {
        setSelectedTasks(prevState => {
            return prevState[taskName]
                ? {
                    ...prevState,
                    [taskName]: false,
                }
                : {
                    ...prevState,
                    [taskName]: true,
                }
        });
    };

    return (
        <main className="solution-choose">
            <MetaTagsComponent
                title={t('choosing.title')}
                seoTitle={t('choosing.title')}
                seoDescription={t('choosing.title')}
            />

            {
                isLoading
                    ? (
                        <PreLoader />
                    )
                    : (
                        <div className="container">
                            <div className="solution-choose__sections-wrap">
                                {
                                    (activeSolutionTab === 'transport')
                                        ? (
                                            <>
                                                <SolutionChoosingTransport
                                                    selectedTypesOfTransport={selectedTypesOfTransport}
                                                    changeSelectedTypesOfTransport={changeSelectedTypesOfTransport}
                                                    transportSolutions={props.transportSolutions}
                                                />
                                                <button
                                                    className="solution-choose__next-btn btn-ghost-secondary"
                                                    onClick={() => {
                                                        setActiveSolutionTab('task');
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    disabled={!isNextButtonActive}
                                                >
                                                    {t('choosing.nextButton')}
                                                </button>
                                            </>
                                        )
                                        : (
                                            <>
                                                <SolutionsChoosingTask
                                                    selectedTasks={selectedTasks}
                                                    changeSelectedTasks={changeSelectedTasks}
                                                    opportunities={props.opportunities}
                                                />
                                                <button
                                                    className="solution-choose__submit-btn btn-ghost-secondary"
                                                    onClick={() => routerHistory.push(localizedSolutionPath)}
                                                    disabled={!isFinalButtonActive}
                                                >
                                                    {t('choosing.finalButton')}
                                                </button>
                                            </>
                                        )
                                }
                            </div>
                        </div>
                    )
            }
        </main>
    );
};

const mapStateToProps = store => {
    return {
        transportSolutions: store.solutionsState.transportSolutions,
        opportunities: store.solutionsState.opportunities,
    };
};

const mapDispatchToProps = {
    setSelectedTransportTypes,
    setSelectedOpportunities,
    getOpportunities,
    getTransportSolutions,
};

export const SolutionChoosing = connect(mapStateToProps, mapDispatchToProps)(SolutionChoosingContainer);
