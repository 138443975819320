import {_image} from '../../API/apiConsts';
import {solutionTypes} from './solutionTypes';

const parseSetEntity = set => {
    return {
        ...set,
        products: set.products ? parseProductEntities(set.products) : [],
    }
}

const parseSolutionFor = solution => solutionTypes[solution];
export const parseImage = image => `${_image}/${image}`;

const parseDocument = document => {
    return {
        file: `${_image}/${document.file}`,
        text: document.text,
    };
};

const parseAdvantageEntity = advantage => {
    return {
        icon: advantage.icon ? parseImage(advantage.icon) : '',
        text: advantage.text,
    };
};

const parseCapabilityEntity = capability => {
    return {
        ...capability,
        icon: parseImage(capability.icon),
    };
};

const parseOpportunityEntity = opportunity => {
    return {
        ...opportunity,
        image: parseImage(opportunity.image),
        icon: parseImage(opportunity.icon),
        solution_for: opportunity.solution_for ? parseSolutionsFor(opportunity.solution_for) : [],
        products: opportunity.products ? parseProductEntities(opportunity.products) : [],
    };
};

export const parseProductEntity = product => {
    return {
        ...product,
        sets: product.sets ? parseSetEntities(product.sets) : [],
        images: product.images ? parseImages(product.images) : [],
        advantages: product.advantages ? parseAdvantageEntities(product.advantages) : [],
        solutions_for: product.solutions_for ? parseSolutionsFor(product.solutions_for): [],
        documentation: product.documentation ? parseDocumentation(product.documentation): [],
        alternative_products: product.alternative_products ? parseProductEntities(product.alternative_products): [],
        recommended_products: product.recommended_products ? parseProductEntities(product.recommended_products): []
    };
};

export const parseCaseEntity = caseItem => {
    return {
        ...caseItem,
        image: caseItem.image ? parseImage(caseItem.image) : '',
        client_logo: caseItem.client_logo ? parseImage(caseItem.client_logo) : '',
        products: caseItem.products ? parseProductEntities(caseItem.products): [],
    };
};

export const parseAdditionalServiceEntity = service => {
    return {
        ...service,
        icon: service.icon ? parseImage(service.icon) : '',
        image: service.image ? parseImage(service.image) : '',
    };
};

const parseSetEntities = sets => sets.map(set => parseSetEntity(set));
const parseDocumentation = documentation => documentation.map(document => parseDocument(document));

export const parseOpportunityEntities = opportunities => opportunities.map(opportunity => parseOpportunityEntity(opportunity));
export const parseCapabilityEntities = capabilities => capabilities.map(capability => parseCapabilityEntity(capability));
export const parseSolutionsFor = solutions => solutions.map(solution => parseSolutionFor(solution));
export const parseProductEntities = products => products.map(product => parseProductEntity(product));
export const parseAdvantageEntities = advantages => advantages.map(advantage => parseAdvantageEntity(advantage));
export const parseImages = images => images.map(image => parseImage(image));
export const parseAdditionalServiceEntities = services => services.map(service => parseAdditionalServiceEntity(service));
export const parseCaseEntities = cases => cases.map(caseItem => parseCaseEntity(caseItem));