import React from 'react';

import './Production.scss';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useLocalizedPath from '../../../customHooks/useLocalizedPath';
import production1 from '../../../images/main-page/production/production-bg1.webp';
import production2 from '../../../images/main-page/production/production-bg2.webp';
import production3 from '../../../images/main-page/production/production-bg3.webp';
import production4 from '../../../images/main-page/production/production-bg4.webp';
import production5 from '../../../images/main-page/production/production-bg5.webp';
import production6 from '../../../images/main-page/production/production-bg6.webp';
import LocalizedLink from '../../globalComponents/LocalizedLink';

const ProductionContainer = props => {
    const routerHistory = useHistory();
    const localizedCatalogPath = useLocalizedPath(`catalog/${props.currentCategory.slug}`);
    const productions = [
        {
            image: production1,
            title: props.translation.productionList.vehicleTrackers.title,
            subtitle: props.translation.productionList.vehicleTrackers.subtitle,
            slug: 'avtomobilni-trekeri-1',
        },
        {
            image: production2,
            title: props.translation.productionList.fuelLevelSensors.title,
            subtitle: props.translation.productionList.fuelLevelSensors.subtitle,
            slug: 'datciki-rivnya-palnogo-2',
        },
        {
            image: production3,
            title: props.translation.productionList.videoRecordersForTransport.title,
            subtitle: props.translation.productionList.videoRecordersForTransport.subtitle,
            slug: 'videoreyestratori-dlya-transportu-3',
        },
        {
            image: production4,
            title: props.translation.productionList.cargoSecurity.title,
            subtitle: props.translation.productionList.cargoSecurity.subtitle,
            slug: 'bezpeka-vantaziv-4',
        },
        {
            image: production5,
            title: props.translation.productionList.personalTrackers.title,
            subtitle: props.translation.productionList.personalTrackers.subtitle,
            slug: 'personalni-trekeri-5',
        },
        {
            image: production6,
            title: props.translation.productionList.canBus.title,
            subtitle: props.translation.productionList.canBus.subtitle,
            slug: 'can-sina-6',
        },
    ];

    return (
        <section className="production-wrap">
            <div className="production">
                <h2 className="production__title">{props.translation.title}</h2>
                <div className="production__wrapper">
                    <div className="production__items">
                        {
                            productions.map((production, index) => (
                                <LocalizedLink
                                    to={`/catalog/${production.slug}`}
                                    key={index}
                                    className="production__item"
                                >
                                    <div className="production__item-block">
                                        <img
                                            className="production__item-image"
                                            src={production.image}
                                            alt={production.title}
                                        />

                                        <div className="production__item-body">
                                            <h3 className="production__item-title">{production.title}</h3>
                                            <div className="production__item-subtitle-wrap">
                                                <p className="production__item-subtitle">{production.subtitle}</p>
                                            </div>
                                            <button className="production__item-button btn-accent">{props.translation.detailsButtonText}</button>
                                        </div>
                                    </div>
                                </LocalizedLink>
                            ))
                        }
                    </div>
                </div>
            </div>

            <button
                className="production-btn btn-ghost-secondary"
                onClick={() => {
                    routerHistory.push(localizedCatalogPath)
                }}
            >
                {props.translation.catalogButtonText}
            </button>
        </section>
    );
};

const mapStateToProps = state => {
    return {
        currentCategory: state.catalogState.categoryDetails,
    };
};

export const Production = connect(mapStateToProps)(ProductionContainer);
