import React, {useEffect, useState} from 'react';

import './BlogArticle.scss';
import {useTranslation} from 'react-i18next';
import {_image} from '../../API/apiConsts';
import {getPost} from '../../API/requests';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PreLoader} from '../globalComponents/PreLoader';
import {Subscribe} from "../globalComponents/Subscribe";
import {ProductSlider} from "../globalComponents/ProductSlider";
import {BreadCrumbs} from "../globalComponents/BreadCrumbs";
import {BlogArticleHeader} from './BlogArticleHeader';

export const BlogArticle = props => {
    const [t] = useTranslation('blog');
    const sections = t('sections', {returnObjects: true});
    const [post, setPost] = useState({
        products: [],
        section: {},
        topic: {},
    });
    const [articleClass, setArticleClass] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getPost(props.match.params.article).then(post => parsePost(post));
    }, [props.match.params.article]);

    useEffect(() => {
        setClassForArticle();
    }, [post]);

    const parsePost = post => {
        setIsLoading(true);

        if(post) {
            const parsedPost = {
                ...post,
                image: `${_image}/${post.image}`,
                products: post.products ? post.products.map(product => ({
                    ...product,
                    images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
                })) : [],
            };

            setPost(parsedPost);
            setIsLoading(false);
        }
    };

    const setClassForArticle = () => {
        switch (post.section.name) {
            case sections.news:
                setArticleClass('news');
                break;
            case sections.useful:
                setArticleClass('useful');
                break;
            case sections.recc:
                setArticleClass('recc');
                break;
            case sections.cases:
                setArticleClass('cases');
                break;
            default: setArticleClass('news')
        }
    };

    return (
        <>
            <MetaTagsComponent
                title={post.title}
                seoTitle={post.title}
                seoDescription={post.short_description}
            />

                    {
                        isLoading
                            ? (
                                <PreLoader />
                            )
                            : (
                                <>
                                    <div className="container blog-container">
                                        <BreadCrumbs links={[
                                            {
                                                title: t('title'),
                                                link: 'blog',
                                            },
                                            {
                                                title: post.title,
                                                link: `blog/article/${post.id}`,
                                            },
                                        ]} />
                                    </div>

                                    <BlogArticleHeader
                                        articleClass={articleClass}
                                        section={post.section.name}
                                        topic={post.topic.name}
                                        title={post.title}
                                        image={post.image}
                                    />

                                    <div className="container blog-container">
                                        <main className="blog-article-wrap">
                                            <article className="blog-article">
                                                <div className="blog-article__top-article" dangerouslySetInnerHTML={{__html: post.text}}/>
                                            </article>
                                        </main>

                                        {
                                            (post.products.length > 0) && (
                                                <div className="product-slider-wrap container">
                                                    <h3 className="product-slider-title">Товари по темі</h3>
                                                    <ProductSlider products={post.products} />
                                                </div>
                                            )
                                        }
                                    </div>
                                </>
                            )
                    }
            <Subscribe />
        </>
    );
};
