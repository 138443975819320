import React from 'react';
import {connect} from 'react-redux';
import {_language} from '../../../globalConsts';
import routes from '../../../routes/routes';
import Page404 from '../../Page404';

import {Route, Switch} from 'react-router-dom';

const Routes = ({language}) => {
    const localizeRoutePath = path => language.key === _language.key ? `/${path}` : `/${language.key}/${path}`

    return (
        <Switch>
            {
                routes.map(route => (
                    <Route
                        key={route.path}
                        exact
                        path={localizeRoutePath(route.path)}
                        component={route.component}
                    />
                ))
            }

            <Route component={Page404} />
        </Switch>
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
    };
};

export default connect(mapStateToProps)(Routes);
