import React from 'react';


import './BreadCrumbs.scss';
import {useTranslation} from 'react-i18next';
import JsonLd from '../JsonLd';
import LocalizedLink from '../LocalizedLink';

export const BreadCrumbs = ({links}) => {
    const [t] = useTranslation('common');

    console.log(links)

    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': links?.map((link, index) => ({
            '@type': 'ListItem',
            'position': index + 1,
            'item': {
                '@id': `https://totalcontrol.ua/${link.link}`,
                'name': link.title,
            }
        }))
    };

    return (
        <div className="bread-crumbs">
            <JsonLd data={jsonLd} />
            <LocalizedLink to={`/`} className="bread-crumbs__item">{t('breadCrumbsMain')}</LocalizedLink>
            {
                links.map((link, index) => (
                    <LocalizedLink to={`/${link.link}`} key={index} className="bread-crumbs__item">{link.title}</LocalizedLink>
                ))
            }
        </div>
    );
};
