import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {changeUserData} from '../../../API/requests';

export const UserAccountPersonalFormEntity = props => {
    const [t] = useTranslation('account');
    const entityFormFieldTitles = t('personal.entity', {returnObjects: true});
    const formFields = {
        org_name: {
            title: entityFormFieldTitles.orgName,
        },
        EDRPOU: {
            title: entityFormFieldTitles.EDRPOU,
        },
        INN: {
            title: entityFormFieldTitles.INN,
        },
        NDS: {
            title: entityFormFieldTitles.NDS,
        },
        org_phone: {
            title: entityFormFieldTitles.orgPhone,
        },
        postal_address: {
            title: entityFormFieldTitles.postalAddress,
        },
        legal_address: {
            title: entityFormFieldTitles.legalAddress,
        },
        IBAN: {
            title: entityFormFieldTitles.IBAN,
        },
        name: {
            title: entityFormFieldTitles.name,
        },
        last_name: {
            title: entityFormFieldTitles.lastName,
        },
        email: {
            title: entityFormFieldTitles.email,
        },
        phone: {
            title: entityFormFieldTitles.phone,
        },
        management_system: {
            title: entityFormFieldTitles.managementSystem,
        },
    };

    const [personalDataFormValues, setPersonalDataFormValues] = useState({
        org_name: '',
        EDRPOU: '',
        INN: '',
        NDS: '',
        org_phone: '',
        postal_address: '',
        legal_address: '',
        IBAN: '',
        name: '',
        last_name: '',
        email: '',
        phone: '',
        management_system: '',
    });

    const [dataChangeRequest, setDataChangeRequest] = useState({
        isRequested: false,
        isSuccess: false,
    });

    useEffect(() => {
        const {
            name,
            last_name,
            phone,
            email,
            legal,
        } = props.user;

        setPersonalDataFormValues({
            ...legal,
            name,
            last_name,
            phone,
            email,
        })
    }, [props.user]);

    const handlePersonalInfoChange = event => {
        const {name, value} = event.target;

        setPersonalDataFormValues(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setDataChangeRequest({
            isRequested: false,
            isSuccess: false,
        });
    };

    const submitPersonalDataChanges = () => {
        const newUserInfo = {
            name: personalDataFormValues.name,
            last_name: personalDataFormValues.last_name,
            email: personalDataFormValues.email,
            phone: personalDataFormValues.phone,
            legal: {
                org_name: personalDataFormValues.org_name,
                org_phone: personalDataFormValues.org_phone,
                EDRPOU: personalDataFormValues.EDRPOU,
                INN: personalDataFormValues.INN,
                NDS: personalDataFormValues.NDS,
                postal_address: personalDataFormValues.postal_address,
                legal_address: personalDataFormValues.legal_address,
                IBAN: personalDataFormValues.IBAN,
                management_system: personalDataFormValues.management_system,
            },
        }

        changeUserData(newUserInfo).then(data => {
            if(data.data) {
                setDataChangeRequest({
                    isRequested: true,
                    isSuccess: data.data.changeUserData,
                });
            }

            if(data.errors) {
                setDataChangeRequest({
                    isRequested: true,
                    isSuccess: false,
                });
            }
        });
    };

    return (
        <div className="user-account__personal-form">
            <h3 className="user-account__personal-title">{t('personal.personalDataTitle')}</h3>
            {
                Object.keys(formFields).map(fieldName => (
                    <label key={fieldName} className="form__label">
                        <input
                            className="form__input"
                            name={fieldName}
                            value={personalDataFormValues[fieldName]}
                            onChange={handlePersonalInfoChange}
                        />
                        <span className="standart field-title">{formFields[fieldName].title}</span>
                    </label>
                ))
            }

            {
                (dataChangeRequest.isRequested && dataChangeRequest.isSuccess) && (
                    <p className="message-label message-label--success">{t('personal.successMessage')}</p>
                )
            }

            {
                (dataChangeRequest.isRequested && !dataChangeRequest.isSuccess) && (
                    <p className="message-label message-label--error">{t('personal.errorMessage')}</p>
                )
            }
            <button className="user-account__edit-btn btn-ghost-secondary" onClick={submitPersonalDataChanges}>
                {t('personal.redactButton')}
            </button>
        </div>
    );
};
