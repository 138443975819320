import React from 'react';
import {useTranslation} from 'react-i18next';
import {EmptyPage} from '../globalComponents/EmptyPage/EmptyPage';
import {Pagination} from '../globalComponents/Pagination';
import {CatalogBody} from './CatalogBody';

export const CatalogMain = props => {
    const [t] = useTranslation('catalog');

    return (
        <div className="catalog__main">
            {
                props.products.length > 0
                    ? (
                        <>
                            <CatalogBody products={props.products} category={props.category}/>
                            {
                                props.numberOfPages > 1 ? (
                                    <Pagination
                                        activePage={props.activePage}
                                        changeActivePage={props.changeActivePage}
                                        numberOfPages={props.numberOfPages}
                                    />
                                ) : ''
                            }
                        </>
                    )
                    : (
                        <EmptyPage message={t('emptyPageMessage')}/>
                    )
            }
        </div>
    );
};
