import React from 'react';
import {connect} from "react-redux";
import LocalizedLink from '../LocalizedLink';

const FooterEquipmentContainer = props => {

    return (
        <nav className="footer__section footer__section--list">
            <h4 onClick={props.toggleMenu} className="footer__list-title">
                {props.translation.title}
                <div className={`plus ${props.isActive? 'active': ''}`} />
            </h4>

            <ul className={`footer__list ${props.isActive? 'active': ''}`}>
                {
                    props.equipment.map(item => (
                        <li key={item.name} className="footer__item">
                            <LocalizedLink to={`/catalog/${item.slug}`}>{item.name}</LocalizedLink>
                        </li>
                    ))
                }
            </ul>
        </nav>
    );
};

const mapStateToProps = state => {
    return {
        equipment: state.equipmentState.equipment,
    }
};

export const FooterEquipment = connect(mapStateToProps)(FooterEquipmentContainer);
