import React from 'react';
import {useTranslation} from 'react-i18next';

export const TariffsTables = props => {
    const [t] = useTranslation('tariffs');
    const tableTranslation = t('tariffsTable', {returnObjects: true});
    const common = t('common', {returnObjects: true});
    const categoryTitles = t('tariffsTable.categoryTitles', {returnObjects: true});
    const subCategoryTitles = t('tariffsTable.subCategoryTitles', {returnObjects: true})
    const tariffsTitles = {
        minimum: tableTranslation.tariffsTitles.minimum,
        minimumPlus: tableTranslation.tariffsTitles.minimumPlus,
        professional: tableTranslation.tariffsTitles.professional,
        professionalPlus: tableTranslation.tariffsTitles.professionalPlus,
        international: tableTranslation.tariffsTitles.international,
    }
    const tariffs = {
        cartographyGSM: {
            detailedMaps: {
                minimum: common.yes,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            simUkraine: {
                minimum: common.yes,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            simForeign: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.no,
                professionalPlus: common.no,
                international: common.yes
            }
        },
        accountsControl: {
            accountObjects: {
                minimum: 1,
                minimumPlus: common.unlimited,
                professional: common.unlimited,
                professionalPlus: common.unlimited,
                international: common.unlimited
            },
            vehicleMileageReport: {
                minimum: common.yes,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            userAccess: {
                minimum: 1,
                minimumPlus: common.unlimited,
                professional: common.unlimited,
                professionalPlus: common.unlimited,
                international: common.unlimited
            },
            informationStoring: {
                minimum: `1 ${common.oneMonth}`,
                minimumPlus: `2 ${common.twoMonths}`,
                professional: `12 ${common.multipleMonths}`,
                professionalPlus: `12 ${common.multipleMonths}`,
                international: `12 ${common.multipleMonths}`
            }
        },
        notifications: {
            eventLog: {
                minimum: common.yes,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            automaticNotification: {
                minimum: common.no,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            smsNotification: {
                minimum: common.no,
                minimumPlus: `25 SMS/${common.monthShort}`,
                professional: `60 SMS/${common.monthShort}`,
                professionalPlus: `60 SMS/${common.monthShort}`,
                international: `60 SMS/${common.monthShort}`
            }
        },
        businessTools: {
            trafficPackage: {
                minimum: common.no,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            fuelPackage: {
                minimum: common.no,
                minimumPlus: common.rateReport,
                professional: common.rateReport,
                professionalPlus: common.yes,
                international: common.yes
            },
            geofencingPackage: {
                minimum: common.no,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            sensorPackage: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            agroSolution: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            remoteEngineBlocking: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            serviceReportsPackage: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            reportDesigner: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            automaticEmailReports: {
                minimum: common.no,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            apiAccess: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            customSoftwareModules: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            periodicEventsModule: {
                minimum: common.no,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            }
        },
        support: {
            messengers: {
                minimum: common.yes,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            phone: {
                minimum: common.no,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            remoteApp: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            prioritySupport: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.no,
                professionalPlus: common.yes,
                international: common.yes
            },
            slaTechnicLevel: {
                minimum: 32,
                minimumPlus: 16,
                professional: 8,
                professionalPlus: 4,
                international: 4
            },
            slaServiceLevel: {
                minimum: 80,
                minimumPlus: 64,
                professional: 24,
                professionalPlus: 24,
                international: 24
            },
            virtualDispatcher: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            }
        },
        financeCirculation: {
            deferredSubscriptionPayment: {
                minimum: common.no,
                minimumPlus: common.no,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            electronicDocumentManagement: {
                minimum: common.yes,
                minimumPlus: common.yes,
                professional: common.yes,
                professionalPlus: common.yes,
                international: common.yes
            },
            temporarySuspensionService: {
                minimum: 1,
                minimumPlus: 3,
                professional: 6,
                professionalPlus: 6,
                international: 6
            }
        }
    }
    const tariffsCost = {
        minimum: 69,
        minimumPlus: 99,
        professional: 129,
        professionalPlus: 149,
        international: 199,
    }

    return (
        <div className="tariffs__table-wrap-overflow">
            <div className="tariffs__table-wrap">
                <h3 className="tariffs__table-title-main">{tableTranslation.title}</h3>
                <div className="tariffs__table-header table-header">
                    <div className="table-header__left-section">{tableTranslation.packageTitle}</div>
                    <div className="table-header__right-section">
                        <div className="table-header__cell-fill">{tableTranslation.tariffsTitle}</div>
                        <div className="table-header__cell-row">
                            {
                                Object.keys(tariffsTitles).map((key) => (
                                    <div key={key} className="table-header__cell">{tableTranslation.forClient}
                                        <p>{`“${tariffsTitles[key]}”`}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                    {
                        Object.keys(tariffs).map((key) => (
                            <div key={key} className="tariffs__table">
                                <div className="tariffs__table-title">{categoryTitles[key]}</div>
                                {
                                    Object.keys(tariffs[key]).map((subKey, indexSubCategory) => (
                                        <div key={subKey} className="tariffs__table-row">
                                            <div className="tariffs__table-cell-main">{`${indexSubCategory + 1}. ${subCategoryTitles[subKey]}`}</div>
                                            <div className="tariffs__table-cell-right">
                                            {
                                                Object.keys(tariffs[key][subKey]).map((valueKey) => {
                                                    const isDisables = tariffs[key][subKey][valueKey] === common.no;

                                                    return (
                                                        <div key={valueKey} className={`tariffs__table-cell ${isDisables? 'tariffs__table-cell--disabled' : ''}`}>
                                                            {tariffs[key][subKey][valueKey]}
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }

                <div className="tariffs__table">
                    <div className="tariffs__table-row tariffs__table-row--additional">
                        <div className="tariffs__table-cell-main">{tableTranslation.costTitle}</div>
                        <div className="tariffs__table-cell-right">
                            {
                                Object.keys(tariffsCost).map((key) => (
                                    <div key={key} className="tariffs__table-cell">{tariffsCost[key]}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
