import React, {useEffect, useState} from 'react';

import './IndustrySolution.scss';

import {useParams, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PreLoader} from '../globalComponents/PreLoader';
import Page404 from '../Page404';
import {IndustrySolutionHeader} from "./IndustrySolutionHeader";
import {IndustrySolutionDescription} from "./IndustrySolutionDescription";
import {connect} from "react-redux";
import {CapabilitiesSlider} from "../globalComponents/CapabilitiesSlider";
import {OtherSolutions} from "../globalComponents/OtherSolutions";
import {SolutionOpportunitySelect} from "../globalComponents/SolutionOpportunitySelect";
import {getSolution} from "../../API/requests";
import {getIndustrySolution} from "../../redux/actionCreators/getActionCreators/solutionsActions";
import {SolutionEquipment} from "../globalComponents/SolutionEquipment";

export const IndustrySolutionContainer = props => {
    const [t] = useTranslation('solutions');
    const {solutionType} = useParams();
    const history = useHistory();

    const [isErrorOccurred, setIsErrorOccurred] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(solutionType) {
            saveSolution(solutionType);
        } else {
            history.replace('/solution/industry/agribusiness');
        }
    }, [solutionType]);

    const saveSolution = solutionType => {
        setIsErrorOccurred(false);
        setIsLoading(true);

        getSolution(solutionType)
            .then(solution => {
                props.getIndustrySolution(solution);
                setIsLoading(false);
            })
            .catch(() => setIsErrorOccurred(true));
    };

    return (
        <>
            {
                isErrorOccurred
                    ? (
                        <Page404 />
                    )
                    : (
                        <main className="industry-solutions">
                            <MetaTagsComponent
                                title={props.solution.name}
                                seoTitle={props.solution.name}
                                seoDescription={props.solution.short_description}
                            />

                            {
                                isLoading
                                    ? (
                                        <PreLoader />
                                    )
                                    : (
                                        <>

                                            <IndustrySolutionHeader
                                                solution={props.solution}
                                                title={t('header.industrySolution')}
                                            />

                                            <div className="container">
                                                <SolutionOpportunitySelect
                                                    solution={props.solution}
                                                    title={t('solutionOpportunities')}
                                                />
                                            </div>

                                            <CapabilitiesSlider
                                                capabilities={props.solution.capabilities}
                                                title={t('capabilitiesSlider')}
                                            />

                                            <IndustrySolutionDescription description={props.solution.description} />

                                            <div className="container">
                                                {
                                                    (props.solution.products.length > 0) && (
                                                        <SolutionEquipment />
                                                    )
                                                }

                                                <OtherSolutions
                                                    solutions={props.industrySolutions}
                                                    solutionsCategory={'industry'}
                                                    title={t('otherSolutions.industry')}
                                                />
                                            </div>
                                        </>
                                    )
                            }
                        </main>
                    )
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        solution: state.industrySolutionState.solution,
        industrySolutions: state.solutionsState.industrySolutions,
    };
};

const mapDispatchToProps = {
    getIndustrySolution,
};

export const IndustrySolution = connect(mapStateToProps, mapDispatchToProps)(IndustrySolutionContainer);
