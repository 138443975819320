import iSolution1 from '../../images/solutions-catalog/industry/agro.webp';
import iSolution2 from '../../images/solutions-catalog/industry/transporting.webp';
import iSolution3 from '../../images/solutions-catalog/industry/delivery.webp';
import iSolution4 from '../../images/solutions-catalog/industry/const-ind.webp';
import iSolution5 from '../../images/solutions-catalog/industry/rental.webp';
import iSolution6 from '../../images/solutions-catalog/industry/lizing.webp';
import iSolution7 from '../../images/solutions-catalog/industry/comun.webp';
import iSolution8 from '../../images/solutions-catalog/industry/public-transport.webp';

import lightTransport from "../../images/solutions-catalog/transport/light-transport.svg";
import trucks from '../../images/solutions-catalog/transport/trucks.svg';
import spec from '../../images/solutions-catalog/transport/spec.svg';
import bus from '../../images/solutions-catalog/transport/bus.svg';
import motorcycle from '../../images/solutions-catalog/transport/motorcycle.svg';
import stationary from '../../images/solutions-catalog/transport/stationar.svg';
import train from '../../images/solutions-catalog/transport/train.svg';
import yacht from '../../images/solutions-catalog/transport/yacht.svg';
import plane from '../../images/solutions-catalog/transport/plane.svg';

import solIcon1 from '../../images/header/drop-ico/sol-ico1.svg';
import solIcon2 from '../../images/header/drop-ico/sol-ico2.svg';
import solIcon3 from '../../images/header/drop-ico/sol-ico3.svg';
import solIcon4 from '../../images/header/drop-ico/sol-ico4.svg';
import solIcon5 from '../../images/header/drop-ico/sol-ico5.svg';
import solIcon6 from '../../images/header/drop-ico/sol-ico6.svg';
import solIcon7 from '../../images/header/drop-ico/sol-ico7.svg';
import solIcon8 from '../../images/header/drop-ico/sol-ico8.svg';
import solIcon9 from '../../images/header/drop-ico/sol-ico9.svg';
import solIcon10 from '../../images/header/drop-ico/sol-ico10.svg';
import solIcon11 from '../../images/header/drop-ico/sol-ico11.svg';
import solIcon12 from '../../images/header/drop-ico/sol-ico12.svg';
import solIcon13 from '../../images/header/drop-ico/sol-ico13.svg';
import solIcon14 from '../../images/header/drop-ico/sol-ico14.svg';
import solIcon15 from '../../images/header/drop-ico/sol-ico15.svg';
import solIcon16 from '../../images/header/drop-ico/sol-ico16.svg';
import solIcon17 from '../../images/header/drop-ico/sol-ico17.svg';
import {parseImage, parseOpportunityEntities} from '../../utils/parsers/parsers';

import {
    GET_INDUSTRY_SOLUTIONS, GET_OPPORTUNITIES,
    GET_TRANSPORT_SOLUTIONS,
} from '../actionConsts/actionConsts';

const industrySolutionsData = {
    'agribusiness': {
        image: iSolution1,
        icon: solIcon6,
    },
    'logistics': {
        image: iSolution2,
        icon: solIcon1,
    },
    'delivery-services': {
        image: iSolution3,
        icon: solIcon7,
    },
    'construction-mining': {
        image: iSolution4,
        icon: solIcon8,
    },
    'car-rental': {
        image: iSolution5,
        icon: solIcon4,
    },
    'insurance': {
        image: iSolution6,
        icon: solIcon5,
    },
    'utilities': {
        image: iSolution7,
        icon: solIcon3,
    },
    'public-transport': {
        image: iSolution8,
        icon: solIcon2,
    },
};

const transportSolutionsData = {
    'cars': {
        image: lightTransport,
        icon: solIcon9,
    },
    'freight-transport': {
        image: trucks,
        icon: solIcon10,
    },
    'special-equipment': {
        image: spec,
        icon: solIcon11,
    },
    'buses': {
        image: bus,
        icon: solIcon12,
    },
    'motorcycles': {
        image: motorcycle,
        icon: solIcon13,
    },
    'stationary-objects': {
        image: stationary,
        icon: solIcon14,
    },
    'railway': {
        image: train,
        icon: solIcon15,
    },
    'water-transport': {
        image: yacht,
        icon: solIcon16,
    },
    'air-transport': {
        image: plane,
        icon: solIcon17,
    },
};

const initialSolutions = {
    industrySolutions: [
        {
            name: '',
            image: '',
            type: '',
            icon: '',
        },
    ],
    transportSolutions: [
        {
            name: '',
            image: '',
            type: '',
            icon: '',
        },
    ],
    opportunities: [
        {
            title: '',
            icon: '',
        },
    ],
}

const solutionsReducer = (state = initialSolutions, action) => {
    switch (action.type) {
        case GET_INDUSTRY_SOLUTIONS: {
            const currentSolutions = action.payload || [];

            const industrySolutions = currentSolutions.map(solution => ({
                ...solution,
                image: industrySolutionsData[solution.type].image,
                icon: industrySolutionsData[solution.type].icon,
            }));

            return {
                ...state,
                industrySolutions,
            }
        }
        case GET_TRANSPORT_SOLUTIONS: {
            const currentSolutions = action.payload || [];

            const transportSolutions = currentSolutions.map(solution => ({
                ...solution,
                image:  transportSolutionsData[solution.type].image,
                icon: transportSolutionsData[solution.type].icon,
                opportunities: solution.opportunities ? parseOpportunityEntities(solution.opportunities) : [],
            }));

            return {
                ...state,
                transportSolutions,
            }
        }
        case GET_OPPORTUNITIES: {
            const currentOpportunities = action.payload || [];

            const opportunities = currentOpportunities.map(opportunity => ({
                ...opportunity,
                icon: parseImage(opportunity.icon),
            }));

            return {
                ...state,
                opportunities,
            }
        }
        default:
            return state;
    }
};

export default solutionsReducer;
