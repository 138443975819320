import React from 'react';

import './Tariffs.scss';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {TariffsTables} from "./TariffsTables";

export const Tariffs = props => {
    const [t] = useTranslation('tariffs');
    const title = t('title');
    const features = t('features', {returnObjects: true});
    const pricePolicy = t('pricePolicy', {returnObjects: true});
    const virtualDispatcher = t('virtualDispatcher', {returnObjects: true});

    return (
        <div className="container">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={`${t('descriptionFirst')}`}
            />

            <main className="tariffs">
                <h1 className="tariffs__main-title">{title}</h1>
                <p className="tariffs__paragraph">
                    {t('descriptionFirst')}
                </p>

                <p className="tariffs__paragraph">
                    {t('descriptionSecond')}
                </p>

                <h2 className="tariffs__title">{features.title}</h2>

                <ul className="tariffs__list">
                    {
                        features.options.map((option, index) => (
                            <li key={index} className="tariffs__list-item">{option}</li>
                        ))
                    }
                </ul>

                <h2 className="tariffs__title">{pricePolicy.title}</h2>

                <ul className="tariffs__list">
                    {
                        pricePolicy.options.map((option, index) => (
                            <li key={index} className="tariffs__list-item">{option}</li>
                        ))
                    }
                </ul>

                <TariffsTables />

                <div className="tariffs__star-descr-wrap">
                    <div className="tariffs__star-descr">* {t('details.one')}</div>
                    <div className="tariffs__star-descr">** {t('details.two')}</div>
                    <div className="tariffs__star-descr">*** {t('details.three')}</div>
                    <div className="tariffs__star-descr">**** {t('details.four')}</div>
                </div>

                <div className="tariffs__service">
                    <h2 className="tariffs__title">{virtualDispatcher.title}</h2>
                    <div className="tariffs__service-p">{virtualDispatcher.subtitle}:</div>
                    {
                        virtualDispatcher.options.map((option, index) => (
                            <div key={index} className="tariffs__service-p">
                                — {option}
                            </div>
                        ))
                    }
                </div>
            </main>
        </div>
    );
};
