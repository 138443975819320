import React from 'react';

import './ForClients.scss';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

import {ForClientsDocuments} from "./ForClientsDocuments";
import {ForClientsCertificates} from "./ForClientsCertificates";

export const ForClients = props => {
    const [t] = useTranslation('forClients');
    const title = t('title');

    return (
        <main className="for-clients">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={t('certificatesTitle')}
            />

            <h1 className="for-clients__title">{title}</h1>
            <ForClientsCertificates />
            
            <ForClientsDocuments />
        </main>
    );
};
