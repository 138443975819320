import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import image1 from '../../images/product-card/is-img-1.webp';
import image2 from '../../images/product-card/is-img-2.webp';
import image3 from '../../images/product-card/is-img-3.webp';
import LocalizedLink from '../globalComponents/LocalizedLink';

export const ProductSolutions = props => {
    const [t] = useTranslation('productCard');
    const [solutions, setSolutions] = useState([
        {
            name: 'Легковий транспорт',
            description: 'GPS-моніторинг легкових автомобілів - найпоширеніше рішення, яким користуються приватні особи і компанії. Під ним мається на увазі повний контроль за транспортними засобами. Після впровадження системи зменшуються витрати на утримання автопарку і підвищується безпека. У кожному разі розробляється індивідуальне рішення, засноване на особливостях бізнесу і на побажаннях клієнтів.',
            image: image1,
            link: 'solution/transport/cars',
        },
        {
            name: 'Лізинг, банки, страхування',
            description: 'GPS-моніторинг - ідеальний варіант для страхових і лізингових компаній, для банків. Під ним мається на увазі віддалений контроль за транспортними засобами і за працівниками. При використанні системи моніторингу оптимізуються маршрути, економиться паливо, підвищується дисципліна співробітників компаній. Для машин інкасації таке рішення є обов\'язковим. З його допомогою підвищується безпека при перевезенні грошових коштів, цінних вантажів.',
            image: image2,
            link: 'solution/industry/insurance',
        },
        {
            name: 'Вантажний транспорт',
            description: 'GPS-моніторинг вантажного транспорту - одне з джерел для економії грошей. З такою системою виключається нецільове використання транспортних засобів і зменшуються витрати на ПММ. Також підвищується дисципліна водіїв, збільшується прибуток автопарку і продовжується період експлуатації вантажних автомобілів.',
            image: image3,
            link: 'solution/transport/freight-transport',
        },
    ]);

    return (
        <section className="product-is">
            <h3 className="product-is__title">{t('industrySolutionTitle')}</h3>
            <div className="product-is__body">
                {
                    solutions.map((solution, index) => (
                        <div key={index} className="product-is__item">
                            <div className="product-is__img-wrap">
                                <img src={solution.image} alt={solution.name} />
                            </div>

                            <div className="product-is__item-title">{solution.name}</div>
                            <div className="product-is__item-subtitle">{solution.description}</div>
                            <LocalizedLink to={`/${solution.link}`} className="product-is__item-link" >
                                Детальніше про рішення
                                <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5V3.5ZM23.3536 4.35355C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464466C19.9763 0.269204 19.6597 0.269204 19.4645 0.464466C19.2692 0.659728 19.2692 0.976311 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53553C19.6597 7.7308 19.9763 7.7308 20.1716 7.53553L23.3536 4.35355ZM1 4.5H23V3.5H1V4.5Z"
                                        fill="#5C83C4"
                                    />
                                </svg>
                            </LocalizedLink>
                        </div>
                    ))
                }
            </div>
        </section>
    );
};
