import React from 'react';
import {useTranslation} from 'react-i18next';
import {Input} from '../Input/Input';

const Form = ({children, className, formFields, formValues, onSubmitSuccess, dispatchFormValues, btnStyle}) => {
    const [t] = useTranslation('common');
    const form = t('form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;

    const handleSubmit = event => {
        event.preventDefault();

        let isFormValid = true;
        Object.keys(formValues).forEach(field => {
            if(!validateField(field, formValues[field].value)) {
                isFormValid = false;
            }
        });

        if(isFormValid) {
            onSubmitSuccess();
        }
    };
    
    const handleChange = event => {
        const {name, value} = event.target;

        dispatchFormValues({type: 'set_value', payload: {name, value}})
    };

    const validateField = (name, value) => {
        if(name !== 'comment') {
            dispatchFormValues({type: 'set_touch_state', payload: {name}});


            if(!value.trim()) {
                dispatchFormValues({type: 'set_error', payload: {errorMessage, name}})

                return false;
            }
        }



        if(name === 'phone') {
            const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

            if(!regEx.test(value)) {
                dispatchFormValues({type: 'set_error', payload: {errorMessage: form.notValidFormat, name}});

                return false;
            }
        }

        return true;
    };
    
    return (
        <form className={className} onSubmit={handleSubmit}>
            <>{children}</>

            {
                formFields.map(field => (
                    (field.name === 'comment')
                        ? (
                            <textarea
                                key={field.name}
                                className="form__input form__input--textarea"
                                cols="30"
                                rows="7"
                                name={field.name}
                                placeholder={form[field.title]}
                                value={formValues[field.name].value}
                                onChange={handleChange}
                            />
                        ) : (
                            <Input
                                key={field.name}
                                className={`form__input ${formValues[field.name].error ? 'invalid' : ''} ${!formValues[field.name].error && formValues[field.name].wasTouched ? 'valid' : ''}`}
                                type={field.type}
                                name={field.name}
                                placeholder=" "
                                required
                                value={formValues[field.name].value}
                                handleChange={event => {
                                    handleChange(event);
                                    validateField(event.target.name, event.target.value);
                                }}
                                handleBlur={event => validateField(event.target.name, event.target.value)}
                                title={form[field.title]}
                                isErrorOccurred={formValues[field.name].error}
                                errorMessage={formValues[field.name].errorMessage}
                            />
                        )
                ))
            }

            <input
                className={`form-modal__submit-btn btn-secondary`}
                style={{ backgroundColor: `${btnStyle ? 'rgba(251, 155, 81, 1)' : ''}`}}
                type="submit"
                value={form.sendButton}
            />
        </form>
    );
};

export default Form;
