import React from 'react';

import {useLocation} from 'react-router-dom';
import {connect} from "react-redux";
import {toggleModal, toggleOrderIdModal} from "../../../redux/actionCreators/modalActions";
import closeBtn from "../../../images/close-btn.svg";
import {useTranslation} from "react-i18next";

const OrderMessageModalContainer = props => {
    const [t] = useTranslation('common');
    const location = useLocation();

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleOrderIdModal(false);
    };

    return (
        <div className={`alert-wrap alert-registration ${props.orderIdModal ? 'active' : ''}`}>
            <div className="modal__close">
                <img
                    src={closeBtn}
                    alt="close"
                    onClick={closeModals}
                />
            </div>

            <div className="alert">
                <h5 className="alert__title">
                    {t('orderMessageModal.title')}!
                </h5>
                <p className="alert__subtitle">

                    {`${t('orderMessageModal.subtitle')}: ${location.search.split('=')[1]}`}
                </p>
                <button className="alert__confirm-btn" onClick={closeModals}>{t('submitButton')}</button>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        orderIdModal: store.modalsState.orderId,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleOrderIdModal,
};

export const OrderMessageModal = connect(mapStateToProps, mapDispatchToProps)(OrderMessageModalContainer);
