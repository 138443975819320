import React from 'react';
import {useTranslation} from 'react-i18next';

import {ProductSlider} from "../globalComponents/ProductSlider";

export const ProductAlternative = props => {
    const [t] = useTranslation('productCard');

    return (
        <section className="alternative-slider">
            <div className="product-slider-wrap">
                <h3 className="product-slider-title section-title">{t('alternativeTitle')}</h3>
                <div className="product-slider">
                    <ProductSlider products={props.alternativeProducts} />
                </div>
            </div>
        </section>
    );
}