import {
    TOGGLE_APPLICATION_SUCCESS_MODAL,
    TOGGLE_BASKET_MODAL,
    TOGGLE_CALLBACK_MODAL,
    TOGGLE_CITY_SELECT_MODAL,
    TOGGLE_COMMERCIAL_OFFER_MODAL, TOGGLE_DEAL_SUCCESS,
    TOGGLE_EMPTY_BASKET_MODAL,
    TOGGLE_EMPTY_COMPARE_MODAL,
    TOGGLE_ENTRY_MODAL,
    TOGGLE_MODAL, TOGGLE_ORDER_ID_MODAL,
    TOGGLE_PASSWORD_RECOVERY_MODAL,
    TOGGLE_PASSWORD_RESET_ERROR_MODAL,
    TOGGLE_PASSWORD_RESET_MODAL,
    TOGGLE_PASSWORD_RESET_SUCCESS_MODAL,
    TOGGLE_QUESTION_MODAL,
    TOGGLE_REGISTRATION_SUCCESS_MODAL,
    TOGGLE_SERVICE_ORDER_MODAL,
    TOGGLE_SOLUTION_ORDER_MODAL,
    TOGGLE_SUBSCRIBE_MODAL,
} from '../actionConsts/actionConsts';

const initialModals = {
    modal: false,
    subscribe: false,
    commercialOffer: false,
    question: false,
    basket: false,
    entry: false,
    callback: false,
    passwordRecovery: false,
    emptyBasket: false,
    emptyCompare: false,
    citySelect: false,
    registrationSuccess: false,
    applicationSuccess: false,
    solutionOrder: false,
    serviceOrder: false,
    passwordReset: false,
    passwordResetSuccess: false,
    passwordResetError: false,
    orderId: false,
    dealSuccess: {
        isActive: false,
        isCallbackRequested: false,
    },
};

const modalsReducer = (state = initialModals, action) => {
    switch (action.type) {
        case TOGGLE_MODAL: {
            return {
                ...state,
                modal: action.payload,
            };
        }
        case TOGGLE_SUBSCRIBE_MODAL: {
            return {
                ...state,
                subscribe: action.payload,
            };
        }
        case TOGGLE_COMMERCIAL_OFFER_MODAL: {
            return  {
                ...state,
                commercialOffer: action.payload,
            };
        }
        case TOGGLE_QUESTION_MODAL: {
            return {
                ...state,
                question: action.payload,
            };
        }
        case TOGGLE_BASKET_MODAL: {
            return {
                ...state,
                basket: action.payload,
            };
        }
        case TOGGLE_ENTRY_MODAL: {
            return {
                ...state,
                entry: action.payload,
            };
        }
        case TOGGLE_CALLBACK_MODAL: {
            return {
                ...state,
                callback: action.payload,
            };
        }
        case TOGGLE_PASSWORD_RECOVERY_MODAL: {
            return {
                ...state,
                passwordRecovery: action.payload,
            };
        }
        case TOGGLE_EMPTY_BASKET_MODAL: {
            return {
                ...state,
                emptyBasket: action.payload,
            };
        }
        case TOGGLE_EMPTY_COMPARE_MODAL: {
            return {
                ...state,
                emptyCompare: action.payload,
            };
        }
        case TOGGLE_CITY_SELECT_MODAL: {
            return {
                ...state,
                citySelect: action.payload,
            }
        }
        case TOGGLE_REGISTRATION_SUCCESS_MODAL: {
            return {
                ...state,
                registrationSuccess: action.payload,
            };
        }
        case TOGGLE_APPLICATION_SUCCESS_MODAL: {
            return {
                ...state,
                applicationSuccess: action.payload,
            };
        }
        case TOGGLE_SOLUTION_ORDER_MODAL: {
            return {
                ...state,
                solutionOrder: action.payload,
            };
        }
        case TOGGLE_SERVICE_ORDER_MODAL: {
            return {
                ...state,
                serviceOrder: action.payload,
            };
        }
        case TOGGLE_PASSWORD_RESET_MODAL: {
            return {
                ...state,
                passwordReset: action.payload,
            };
        }
        case TOGGLE_PASSWORD_RESET_SUCCESS_MODAL: {
            return {
                ...state,
                passwordResetSuccess: action.payload,
            };
        }
        case TOGGLE_PASSWORD_RESET_ERROR_MODAL: {
            return {
                ...state,
                passwordResetError: action.payload,
            };
        }
        case TOGGLE_ORDER_ID_MODAL: {
            return {
                ...state,
                orderId: action.payload,
            };
        }
        case TOGGLE_DEAL_SUCCESS: {
            return {
                ...state,
                dealSuccess: {
                    ...action.payload,
                },
            };
        }
        default:
            return state;
    }
};

export default modalsReducer;
