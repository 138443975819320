import React, {useState, useEffect} from 'react';
import checkmark from "../../../images/checkmark.svg";
import {connect} from "react-redux";
import {applySpecificationsFilter} from "../../../redux/actionCreators/catalogActions";
import {CatalogSpecificationStaticFilters} from './CatalogSpecificationStaticFilters';

const CatalogSpecificationFiltersContainer = props => {
    const [selectedSpecifications, setSelectedSpecifications] = useState({});

    useEffect(() => {
        if(Object.keys(selectedSpecifications).length) {
            props.applySpecificationsFilter(selectedSpecifications);
        }
    }, [selectedSpecifications]);

    const handleSpecificationSelectChange = (specification, name) => {
        setSelectedSpecifications(prevState => {
            if(!prevState[specification]) {
                return {
                    ...prevState,
                    [specification]: {
                        [name]: true,
                    },
                };
            } else {
                if(prevState[specification][name]) {
                    return {
                        ...prevState,
                        [specification]: {
                            ...prevState[specification],
                            [name]: false,
                        }
                    };
                } else {
                    return  {
                        ...prevState,
                        [specification]: {
                            ...prevState[specification],
                            [name]: true,
                        }
                    };
                }
            }
        });
    };

    return (
        <>
            <CatalogSpecificationStaticFilters
                onChange={handleSpecificationSelectChange}
                selectedSpecifications={selectedSpecifications}
            />

            {
                Object.keys(props.specifications)
                    .filter(specification => props.specifications[specification].length > 1)
                    .map(specification => (
                    <div key={specification} className="filter__checkbox-section filter__container">
                        <div className="filter__title">{specification}</div>
                        <form className="filter__form">
                            {
                                props.specifications[specification].map(name => (
                                    <label
                                        key={name}
                                        className="checkbox-wrap"
                                    >
                                        <input
                                            type="checkbox"
                                            name={name}
                                            checked={selectedSpecifications? selectedSpecifications[specification] ? !!selectedSpecifications[specification][name] : false : false}
                                            onChange = {() => handleSpecificationSelectChange(specification, name)}
                                        />

                                        <span className="checkbox">
                                            <img src={checkmark} alt="checkmark" />
                                            <span>{name}</span>
                                        </span>
                                    </label>
                                ))
                            }
                        </form>
                    </div>
                ))
            }
        </>
    );
};

const mapStateToProps = state => {
    return {
        appliedSpecifications: state.catalogState.appliedFilters.specifications,
    };
};

const mapDispatchToProps = {
    applySpecificationsFilter,
};

export const CatalogSpecificationFilters = connect(mapStateToProps, mapDispatchToProps)(CatalogSpecificationFiltersContainer);
