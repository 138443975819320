import React from 'react';
import {useTranslation} from 'react-i18next';

export const CompareTable = props => {
    const [t] = useTranslation('compare');
    const newList = []

    if (props.reverse === 0) {
        props.specificationList.forEach(item => {
            newList.push(item)
        })
    } else if (props.reverse === 1) {
        props.specificationList.forEach(item => {
            let i = 0
            props.productsCompare.forEach(data => {
                if(data.specifications[item] === props.productsCompare[0].specifications[item]) {
                    i++
                }
            })
            if(i !== props.productsCompare.length) {
                newList.push(item)
            }
        })
    } else if (props.reverse === 2) {
        props.specificationList.forEach(item => {
            let i = 0
            props.productsCompare.forEach(data => {
                if(data.specifications[item] === props.productsCompare[0].specifications[item]) {
                    i++
                }
            })
            if(i === props.productsCompare.length) {
                newList.push(item)
            }
        })
    }

    return (
        <div className="compare__table">
            {
                props.productsCompare.length < 2
                    ? (
                        <div className="compare__table-empty">
                            <p className="compare__table-empty-text">
                                {t('notEnoughProducts')}
                            </p>
                        </div>
                    )
                    : (
                        newList.map(characteristic => (
                            <div key={characteristic} className="compare__table-row">
                                <div className="compare__table-title">{characteristic}</div>
                                {
                                    props.productsCompare.map((product, index) => {
                                        const productCharacteristic = product.specifications[characteristic];
                                        const characteristicValue = productCharacteristic ? productCharacteristic : '—';

                                        return Array.isArray(characteristicValue)
                                            ? (
                                                <div key={index} className="compare__table-item">
                                                    <ul>
                                                        {
                                                            characteristicValue.map((option, index) => (
                                                                <li key={index}>{option}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            ) :
                                            (
                                                <div key={index} className="compare__table-item">
                                                    {characteristicValue}
                                                </div>
                                            )
                                    })
                                }
                            </div>
                        ))
                    )
            }
        </div>
    );
};
