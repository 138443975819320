import {connect} from "react-redux";
import {Recommendations} from "../globalComponents/Recommendations";
import {getRecommendedProducts} from "../../API/requests";
import {_image} from "../../API/apiConsts";
import {useEffect, useReducer, useState} from "react";
import {OtherSolutions} from "../globalComponents/OtherSolutions";
import {consultation} from "../../globalConsts";
import formReducer from "../../utils/stateReducers/formReducer";
import createFormState from "../../utils/stateReducers/createFormState";
import Form from "../globalComponents/Form/Form";
import {createLead} from "../../API/birixApi/createLead";
import ReactGA from "react-ga";
import {
    toggleApplicationSuccess,
    toggleCallback,
    toggleCommercialOffer,
    toggleSolutionOrder
} from "../../redux/actionCreators/modalActions";
import tablet from '../../images/partnership/tablet.png'
import sertificate from '../../images/partnership/sertificate.jpg'
import roat from '../../images/partnership/roat.jpg'
import logo1 from '../../images/partnership/logo1.svg'
import logo2 from '../../images/partnership/logo2.svg'
import logoTotal from '../../images/partnership/logoTotal.svg'
import logoWialon from '../../images/partnership/logoWialon.svg'
import hrest from '../../images/partnership/hrest.svg'
import radiusru from '../../images/partnership/radiusRu.svg'
import radiusua from '../../images/partnership/radiusUa.svg'


import q from '../../images/partnership/icons/1.svg'
import w from '../../images/partnership/icons/2.svg'
import e from '../../images/partnership/icons/3.svg'
import r from '../../images/partnership/icons/4.svg'
import t from '../../images/partnership/icons/5.svg'
import y from '../../images/partnership/icons/6.svg'
import u from '../../images/partnership/icons/7.svg'
import i from '../../images/partnership/icons/8.svg'
import a from '../../images/partnership/tools/map.jpg'
import s from '../../images/partnership/tools/2.jpg'
import d from '../../images/partnership/tools/3.jpg'
import f from '../../images/partnership/tools/4.jpg'
import g from '../../images/partnership/tools/5.jpg'
import h from '../../images/partnership/tools/6.jpg'
import j from '../../images/partnership/tools/7.jpg'
import k from '../../images/partnership/tools/8.jpg'
import './partnership.scss'
import {setSolutionForOrder} from "../../redux/actionCreators/globalActions";
import {useTranslation} from "react-i18next";

const data = [
    {
        name: 'opportunities.1.title',
        ico: q,
        info: {
            h1: 'opportunities.1.title',
            p: 'opportunities.1.text',
            img: a,
        }
    },
    {
        name: 'opportunities.2.title',
        ico: w,
        info: {
            h1: 'opportunities.2.title',
            p: 'opportunities.2.text',
            img: s,
        }
    },
    {
        name: 'opportunities.3.title',
        ico: e,
        info: {
            h1: 'opportunities.3.title',
            p: 'opportunities.3.text',
            img: d,
        }
    },
    {
        name: 'opportunities.4.title',
        ico: r,
        info: {
            h1: 'opportunities.4.title',
            p: 'opportunities.4.text',
            img: f,
        }
    },
    {
        name: 'opportunities.5.title',
        ico: t,
        info: {
            h1: 'opportunities.5.title',
            p: 'opportunities.5.text',
            img: g,
        }
    },
    {
        name: 'opportunities.6.title',
        ico:y,
        info: {
            h1: 'opportunities.6.title',
            p: 'opportunities.6.text',
            img: h,
        }
    },
    {
        name: 'opportunities.7.title',
        ico: u,
        info: {
            h1: 'opportunities.7.title',
            p: 'opportunities.7.text',
            img: j,
        }
    },
    {
        name: 'opportunities.8.title',
        ico: i,
        info: {
            h1: 'opportunities.8.title',
            p: 'opportunities.8.text',
            img: k,
        }
    },
]

const Partnership = (props) => {
    const [recommendedProducts, setRecommendedProducts] = useState([]);
    const [callbackFormValues, dispatchCallbackFormValues] = useReducer(formReducer, consultation, createFormState);
    const [t] = useTranslation('partnership')

    useEffect(() => {
        saveRecommendedProducts();
    },[])
    const saveRecommendedProducts = async () => {
        const products = await getRecommendedProducts();
        const productsToSet = products ? products.map(product => ({
            ...product,
            images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
        })) : [];

        setRecommendedProducts(productsToSet);
    };
    const onCallSubmit = () => {
        const userInfo = {
            name: callbackFormValues.name.value,
            phone: callbackFormValues.phone.value,
            email: callbackFormValues.email.value,
        };

        createLead(userInfo, 'callback', props.googleId, props.utmParams);
        ReactGA.event({
            category: 'Order',
            action: 'callback-order',
            label: 'Заказ обратного звонка',
        });
        openApplicationSuccessModal();
    };
    const openApplicationSuccessModal = () => {
        props.toggleApplicationSuccess(true);
    };

    const [selectedServices, setSelectedServices]  = useState(window.innerWidth > 1100 && 'Онлайн GPS/ГЛОНАСС мониторинг')
    return (
        <div className="partnership__page">
            <div className="partnership__intro">
                <h1 className={'partnership__introTitle'}><p><span>[ </span>PARTNERSHIP<span>]</span></p></h1>
                <div className={'partnership__logos'}>
                    <div>
                    <img src={logoTotal} alt=""/>
                    </div>
                        <img class={'hrest'} src={hrest} alt=""/>
                    <div>
                    <img src={logoWialon} alt=""/>
                </div>

                    </div>
                <div className={"partnership__introBanner"}>
                    <div className='partnership__introBannerInfo'>
                        <h2>{t("introBanner.title")}</h2>
                        <p>{t("introBanner.text")}</p>
                        <button className='btn-secondary banner__btn' onClick={() => {
                            props.setSolutionForOrder('Wialon 99грн за 1 месяц')
                            props.toggleSolutionOrder(true)
                            }}>{t("introBanner.button")}</button>
                    </div>
                </div>
            </div>
            <div className="partnership__montoring">
                    <h2 className={'partnership__officialTitle'}>{t("wialonBlock.title")}</h2>
                <p className={'partnership__monitoringText'}>{t("wialonBlock.one")}</p>
                <p className={'partnership__monitoringText'}>{t("wialonBlock.two")}</p>
                <p className={'partnership__monitoringText'}>{t("wialonBlock.three")}</p>
                <div className={'partnership__montoringCards'} >
                    <div  className={'partnership__montoringCard light'}>
                        <img src={logo1} alt=""/>
                        <p className={'partnership__montoringCardText'}>{t("wialonBlock.white.text")}</p>
                        <ul className={'partnership__montoringCardList'}>
                            <li>{t("wialonBlock.white.list.1")}</li>
                            <li>{t("wialonBlock.white.list.2")}</li>
                            <li>{t("wialonBlock.white.list.3")}</li>
                            <li>{t("wialonBlock.white.list.4")}</li>
                            <li>{t("wialonBlock.white.list.5")}</li>
                        </ul>
                        <button className='btn-secondary banner__btn' onClick={() => {
                            props.setSolutionForOrder('Wialon Hosting')
                            props.toggleSolutionOrder(true)
                        }}>{t("wialonBlock.white.list.button")}</button>
                    </div>
                    <div  className={'partnership__montoringCard dark'}>
                        <img src={logo2} alt=""/>
                        <p className={'partnership__montoringCardText '}>{t("wialonBlock.blue.text")}</p>
                        <ul className={'partnership__montoringCardList'}>
                            <li>{t("wialonBlock.blue.list.1")}</li>
                            <li>{t("wialonBlock.blue.list.2")}</li>
                            <li>{t("wialonBlock.blue.list.3")}</li>
                            <li>{t("wialonBlock.blue.list.4")}</li>
                            <li>{t("wialonBlock.blue.list.5")}</li>
                        </ul>
                        <button className='btn-secondary banner__btn' onClick={() => {
                            props.setSolutionForOrder('Wialon Local')
                            props.toggleSolutionOrder(true)
                        }}>{t("wialonBlock.blue.list.button")}</button>
                    </div>
                </div>
            </div>
            <div className={'partnership__wialon--wrapper'}>
            <div className="partnership__wialon">
                <div className="partnership__list">
                    <h3>{t('wialonHelp.title')}</h3>
                    <p>{t('wialonHelp.text')}</p>
                </div>
                <div className='partnership__wialon__radius'>
                    <p>{t('wialonHelp.total')}</p>
                    <img src={props.language.key === 'uk' ? radiusua : radiusru} alt=""/>
                </div>

            </div>
            </div>
            <div className="partnership__skills" >
                <h2 className={'skills__title'}>{t("opportunities.title")}</h2>
                <div className={'skill__list'}>
                    {
                        data.map((el, i) => (
                            <div key={i} className={'skill__block'}>
                                 <div className={`skill__item ${selectedServices === el.name && 'active'}`} onClick={()=> selectedServices === el.name ? setSelectedServices('') :setSelectedServices(el.name)}>
                                     <img src={el.ico} alt=""/>
                                     <h3>{t(el.name)}</h3>
                                 </div>
                                <div className={`skill__info ${selectedServices === el.name && 'active'}`} style={{opacity: selectedServices === el.name && '1'}}>
                                    <h3>{t(el.info.h1)}</h3>
                                    <p>{t(el.info.p)}</p>
                                    <img src={el.info.img} alt=""/>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="partnership__banner">
                <div className="partnership__banerInfo">
                    <h2 className="partnership__banerInfo--title">{t("banner.title")}</h2>
                    <p className="partnership__banerInfo--text">{t("banner.text")}</p>
                    <button className='btn-secondary banner__btn' onClick={() => {
                        props.setSolutionForOrder('Wialon Hosting')
                        props.toggleSolutionOrder(true)
                    }} >
                        {t("banner.button")}
                    </button>
                </div>
                <img src={roat} alt=""/>
            </div>
            <div className="partnership__official">
                <h2 className="partnership__officialTitle">{t("partner.title")}</h2>
                    <div className="partnership__officialFlex">
                        <img src={sertificate} alt=""/>
                        <div className={'partnership__officialText'}>
                        <p>
                            {t("partner.textOne")}
                        </p>
                        <p>
                            {t("partner.textTwo")}
                        </p>
                        </div>
                    </div>
            </div>
            <div className={'partnership__formBlock'}>
                <div>
                    <img src={tablet} alt=""/>
                </div>
                <div className={'partnership__form'} >
                    <h3 className={'partnership__formTitle'}>{t(('formTitle'))}</h3>
                <Form
                    btnStyle
                    formValues={callbackFormValues}
                    dispatchFormValues={dispatchCallbackFormValues}
                    formFields={consultation}
                    onSubmitSuccess={onCallSubmit}
                />
                </div>
            </div>
            <div className="container" style={{padding: '0px, 15px'}} >
                <OtherSolutions
                    solutions={props.industrySolutions}
                    solutionsCategory={'industry'}
                    title={t("solution")}
                />
            </div>
            {
                (recommendedProducts.length > 0) && (
                    <Recommendations recommendedProducts={recommendedProducts}/>
                )
            }
        </div>
    )
}

    const mapDispatchToProps = {
    toggleApplicationSuccess,
    toggleCallback,
    toggleSolutionOrder,
    setSolutionForOrder

    };

        const mapStateToProps = state => {
            return {
                productions: state.mainPageState.productions,
                industrySolutions: state.solutionsState.industrySolutions,
                language: state.globalReducer.language,
            };
        }
;
export default connect(mapStateToProps, mapDispatchToProps)(Partnership);