import React from 'react';
import {useTranslation} from 'react-i18next';
import service1 from "../../images/services/service1.webp";
import service2 from "../../images/services/service2.webp";
import service3 from "../../images/services/service3.webp";
import service4 from "../../images/services/service4.webp";
import LocalizedLink from './LocalizedLink';

export const OtherServices = props => {
    const [t] = useTranslation('common');
    const servicesTranslation = t('otherServicesList', {returnObjects: true});

    const services = [
        {
            name: servicesTranslation.list.scontrol,
            slug: 'scontrol',
            image: service1,
        },
        {
            name: servicesTranslation.list.personal,
            slug: 'personal',
            image: service2,
        },
        {
            name: servicesTranslation.list.gsm,
            slug: 'gsm',
            image: service3,
        },
        {
            name: servicesTranslation.list.otherServices,
            slug: 'other-services',
            image: service4,
        },
    ];

    return (
        <section className="other-services container">
            <h2 className="other-services__title">{servicesTranslation.title}</h2>
            <div className="other-services__body">
                {
                    services.map((service, index) => (
                        <LocalizedLink to={`/services/${service.slug}`} key={index} className="other-services__item">
                            <div className="other-services__bg">
                                <img src={service.image} alt={service.name} />
                            </div>
                            <h4 className="other-services__card-title">{service.name}</h4>
                        </LocalizedLink>
                    ))
                }
            </div>
        </section>
    );
};
