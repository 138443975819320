import {
    ADD_PRODUCT_COMPARE,
    DELETE_PRODUCT_COMPARE,
    DELETE_PRODUCTS_BY_CATEGORY_COMPARE,
} from '../actionConsts/actionConsts';

export const addProductToCompare = payload => {
    return {
        type: ADD_PRODUCT_COMPARE,
        payload,
    }
};

export const deleteProductFromCompare = payload => {
    return {
        type: DELETE_PRODUCT_COMPARE,
        payload,
    }
};

export const deleteProductsByCategoryFromCompare = payload => {
    return {
        type: DELETE_PRODUCTS_BY_CATEGORY_COMPARE,
        payload,
    };
};
