import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {_languages} from '../../../../globalConsts';

import newLogo from '../../../../images/new-logo.svg';
import logo from '../../../../images/logo.svg';
import LocalizedLink from '../../LocalizedLink';
import {HeaderBottomMenu} from "./HeaderBottomMenu";
import {HeaderBottomNav} from "./HeaderBottomNav";
import {toggleCitySelect, toggleModal} from "../../../../redux/actionCreators/modalActions";
import {connect} from "react-redux";

const HeaderBottomContainer = props => {
    const [t] = useTranslation('header');
    const navItemsTranslation = t('navItems', {returnObjects: true});
    const overflowRef = useRef(false);

    const navItems = [
        {
            name: navItemsTranslation.discount,
            link: 'catalog/discount-products',
        },
        {
            name: navItemsTranslation.cases,
            link: 'cases',
        },
        {
            name: navItemsTranslation.tariffs,
            link: 'tariffs',
        },
        {
            name: navItemsTranslation.support,
            link: 'support',
        },
        {
            name: navItemsTranslation.pay,
            link: 'pay',
        },
        {
            name: navItemsTranslation.blog,
            link: 'blog',
        },
        {
            name: navItemsTranslation.aboutUs,
            link: 'about-us',
        },
        {
            name: navItemsTranslation.forClients,
            link: 'for-clients',
        },
        {
            name: navItemsTranslation.contacts,
            link: 'contacts',
        },
    ];

    const changeLanguage = language => {
        localStorage.setItem('language', JSON.stringify(language));
        window.location.reload(false);
    };

    return (
        <div ref={props.sidebarRef} className={`header-bottom ${props.isSidebarActive? 'active': ''}`}>
            <div className="container-header">
                <div className="header-bottom__sidebar-header">
                    <LocalizedLink to={`/`} className="header-bottom__sidebar-logo-wrap">
                        <img
                            alt="Total Control"
                            src={logo}
                        />
                    </LocalizedLink>

                    <div className="header-bottom__sidebar-lang-selector">
                        {
                            _languages.map(language => (
                                <span
                                    key={language.key}
                                    className={`header-bottom__sidebar-lang-item ${language.key === props.language.key ? 'active' : ''}`}
                                    onClick={() => changeLanguage(language)}
                                >
                                    {language.title}
                                </span>
                            ))
                        }
                    </div>
                </div>

                <div className="header-bottom__sidebar-overflow-wrap" ref={overflowRef}>
                    <div className="header-bottom__content-wrap">
                        <LocalizedLink to={`/`} className="header-bottom__logo-wrap" >
                            <img
                                alt="Total Control"
                                src={newLogo}
                            />
                        </LocalizedLink>

                        <HeaderBottomNav
                            toggleSideMenu={props.toggleSideMenu}
                            closeSideMenu={props.closeSideMenu}
                            overflowRef={overflowRef}
                        />

                        <LocalizedLink to={`/choose-solution`} className="header-bottom__btn-selection btn-ghost-secondary" onClick={props.toggleSideMenu}>
                            {t('solutionChoosing')}
                        </LocalizedLink>
                    </div>

                    <HeaderBottomMenu toggleSideMenu={props.toggleSideMenu} closeSideMenu={props.closeSideMenu} />

                    <div className="mobile-sidebar__nav-wrap">
                        <ul className="mobile-sidebar__nav">
                            {
                                navItems.map((item, index) => (
                                    <li
                                        key={index}
                                        className="mobile-sidebar__nav-item"
                                        onClick={props.toggleSideMenu}
                                    >
                                        <LocalizedLink to={`/${item.link}`}>{item.name}</LocalizedLink>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>

                    <div className="mobile-sidebar__location" onClick={props.openModals}>
                        <span className="mobile-sidebar__title">{`${t('locationTitle')}:`}</span>
                        <span className="mobile-sidebar__value"> {props.userLocation}</span>
                    </div>
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
        userLocation: store.globalReducer.userLocation,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleCitySelect,
};

export const HeaderBottom = connect(mapStateToProps, mapDispatchToProps)(HeaderBottomContainer);
