import {parseAdditionalServiceEntities} from '../../utils/parsers/parsers';
import {GET_ADDITIONAL_SERVICES} from "../actionConsts/actionConsts";

const initialState = {
    additionalServices: [
        {
            id: 1,
            name: '',
            description: '',
            image: '',
            icon: '',
        },
    ],
};

const additionalServicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ADDITIONAL_SERVICES: {
            const services = action.payload;

            return {
                ...state,
                additionalServices: services ? parseAdditionalServiceEntities(services) : []
            };
        }
        default: return state
    }
};

export default additionalServicesReducer;
