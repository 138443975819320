import React from 'react';
import videoHeader from "../../videos/gps-monitoring.mp4";

export const GpsMonitoringHeader = props => {
    return (
        <div className="services__header">
            <div className="services__header-bg">
                <video className="video-bg"  autoPlay loop muted>
                    <source src={videoHeader} type="video/mp4"/>
                </video>
            </div>

            <article className="services__header-body">
                <div className="services__header-title-wrap">
                    <h1 className="services__header-title">{props.service.data.title}</h1>
                    <p className="services__header-title-price">{props.service.data.subtitle}</p>
                </div>

                <p className="services__header-subtitle">{props.service.data.short_description}</p>
            </article>
        </div>
    );
};
