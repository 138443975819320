import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {changeUserPassword} from '../../../API/requests';

export const UserAccountPersonalPasswordForm = props => {
    const [t] = useTranslation('account');
    const passwordTranslation = t('personal.password', {returnObjects: true});

    const [passwordFormValues, setPasswordFormValues] = useState({
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
    });
    const [dataChangeRequest, setDataChangeRequest] = useState({
        isRequested: false,
        isSuccess: false,
    });

    const handlePasswordValuesChange = event => {
        const {name, value} = event.target;

        setPasswordFormValues(prevState => ({
            ...prevState,
            [name]: value,
        }));

        setDataChangeRequest({
            isRequested: false,
            isSuccess: false,
        });
    };

    const submitPasswordChange = () => {
        changeUserPassword(passwordFormValues).then(data => {

            if(data.data) {
                setDataChangeRequest({
                    isRequested: true,
                    isSuccess: data.data.changePassword,
                })
            } else {
                setDataChangeRequest({
                    isRequested: true,
                    isSuccess: false,
                });
            }
        });
    };

    return (
        <div className="user-account__personal-form user-account__personal-form--password">
            <h3 className="user-account__personal-title">{passwordTranslation.title}</h3>
            <label className="form__label">
                <input
                    className="form__input input-with-label"
                    type="password"
                    name="old_password"
                    placeholder="⚹⚹⚹⚹⚹⚹⚹⚹"
                    required
                    value={passwordFormValues.old_password}
                    onChange={handlePasswordValuesChange}
                />
                <span className="standart field-title">{passwordTranslation.oldPassword}</span>
                <p className="password-forgot">{passwordTranslation.forgotPassword}</p>
            </label>

            <label className="form__label">
                <input
                    className={`form__input input-with-label`}
                    type="password"
                    name="new_password"
                    placeholder="⚹⚹⚹⚹⚹⚹⚹⚹"
                    required
                    value={passwordFormValues.new_password}
                    onChange={handlePasswordValuesChange}
                />
                <span className="standart field-title">
                    {passwordTranslation.newPassword}
                </span>
                <p className="password-label">{passwordTranslation.passwordLengthMessage}</p>
            </label>

            <label className="form__label input-with-label">
                <input
                    className={`form__input input-with-label`}
                    type="password"
                    name="new_password_confirmation"
                    placeholder="⚹⚹⚹⚹⚹⚹⚹⚹"
                    required
                    value={passwordFormValues.new_password_confirmation}
                    onChange={handlePasswordValuesChange}
                />
                <span className="standart field-title">
                    {passwordTranslation.repeatNewPassword}
                </span>
            </label>

            {
                (dataChangeRequest.isRequested && dataChangeRequest.isSuccess) && (
                    <p className="message-label message-label--success">{t('personal.successMessage')}</p>
                )
            }

            {
                (dataChangeRequest.isRequested && !dataChangeRequest.isSuccess) && (
                    <p className="message-label message-label--error">{t('personal.errorMessage')}</p>
                )
            }

            <button className="user-account__edit-btn btn-ghost-secondary" onClick={submitPasswordChange}>
                {t('personal.redactButton')}
            </button>
        </div>
    );
};
