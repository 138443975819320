import React, {useState, useRef} from 'react';

import './Header.scss';
import {connect} from 'react-redux';
import {toggleCitySelect, toggleModal} from '../../../redux/actionCreators/modalActions';

import { HeaderBottom } from './HeaderBottom';
import { HeaderTop } from './HeaderTop';

const HeaderContainer = props => {
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [isPhoneMenuActive, setIsPhoneMenuActive] = useState(false);
    const [searchMenu, setSearchMenu] = useState({
       isActive: false,
       value: '',
       products: [],
    });
    const sidebarRef = useRef(false);

    const toggleSearchInput = newStateValue => {

        if(!searchMenu.value.trim()) {
            setSearchMenu(prevState => ({
                ...prevState,
                isActive: newStateValue,
            }));
        }
    };

    const openModals = () => {
        props.toggleModal(true);
        props.toggleCitySelect(true);
    };

    const toggleSideMenu = () => {
        setIsSidebarActive(prevState => !prevState);
    };

    const closeSideMenu = () => {
        setIsSidebarActive(false);
    };

    const togglePhoneMenu = () => {
        setIsPhoneMenuActive(prevState => !prevState);
    };

    const closePhoneMenu = () => {
        setIsPhoneMenuActive(false);
    };

    return (
        <header
            className="header"
            onMouseLeave={() => {
                closePhoneMenu();
                toggleSearchInput(false);
            }}
        >
            <div onClick={toggleSideMenu} className={`header-mobile__menu-btn-wrap ${isSidebarActive ? 'active' : ''}`}>
                <div className={`header-mobile__menu-btn ${isSidebarActive ? 'active' : ''}`}>
                    <span /><span /><span />
                </div>
            </div>

          <HeaderTop
              phoneMenuState={{
                  isPhoneMenuActive,
                  togglePhoneMenu,
                  closePhoneMenu,
              }}
              searchInputState={{
                  searchMenu,
                  setSearchMenu,
              }}
          />
          <HeaderBottom
              isSidebarActive={isSidebarActive}
              toggleSideMenu={toggleSideMenu}
              closeSideMenu={closeSideMenu}
              openModals={openModals}
              sidebarRef={sidebarRef}
          />
        </header>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleCitySelect,
};

export const Header = connect(null, mapDispatchToProps)(HeaderContainer);
