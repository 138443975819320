import React from 'react';
import {OrderServiceButton} from '../globalComponents/OrderServiceButton';

export const AdditionalServicesTab = props => {
    return (
        <div className="services__tab-wrap">
            <div className={`services__tab ${props.isActive ? 'active' : ''}`} onClick={() => props.changeActiveServiceTab(props.tabIndex)}>
                <div className="services__tab-ico">
                    <img
                        className="active"
                        src={props.service.icon}
                        alt="icon"
                    />
                </div>

                <p className="services__tab-title">{props.service.name}</p>
                <div className="services__tab-arrow">
                    <svg width="31" height="15" viewBox="0 0 31 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.0838 13.7644L1.21883 1.82467C0.764827 1.43348 0.76559 0.800134 1.22118 0.409599C1.67671 0.0193659 2.41463 0.0203743 2.86898 0.411616L15.9087 11.6406L28.9484 0.411213C29.4028 0.0200222 30.1403 0.0190138 30.5958 0.409196C30.8242 0.604968 30.9384 0.861443 30.9384 1.11792C30.9384 1.37374 30.825 1.6292 30.5982 1.82462L16.7336 13.7644C16.5153 13.9528 16.2182 14.0585 15.9087 14.0585C15.5992 14.0585 15.3025 13.9525 15.0838 13.7644Z"
                            fill="#1A3B70" />
                    </svg>
                </div>
            </div>

            <div className="services__description-wrap">
                <div className="services__description">
                    <div className="services__description-img">
                        <img src={props.service.image} alt={props.service.name} />
                    </div>

                    <div className="services__description-body">
                        <div dangerouslySetInnerHTML={{__html: props.service.description}}/>
                        <div className="services__btns-wrap">
                            <OrderServiceButton service={props.service.name} />
                            {/*<button className="services__details-btn btn-ghost-secondary">*/}
                            {/*    Подробнее*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
