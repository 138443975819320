import React from 'react';

import './DeliveryPay.scss';
import {useTranslation} from 'react-i18next';

import newPost from '../../images/delivery/np.png';
import Visa from '../../images/delivery/visa.png';
import masterCard from '../../images/delivery/mc.png';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

export const DeliveryPay = () => {
    const [t] = useTranslation('pay');
    const title = t('title');
    const deliveryTranslation = t('delivery', {returnObjects: true});
    const payTranslation = t('pay', {returnObjects: true});

    const deliveryOptions = [
        {
            title: deliveryTranslation.options.newPostDelivery.title,
            description: deliveryTranslation.options.newPostDelivery.description,
        },
        {
            title: deliveryTranslation.options.courierDelivery.title,
            description: deliveryTranslation.options.courierDelivery.description,
        },
        {
            title: deliveryTranslation.options.pickup.title,
            description: deliveryTranslation.options.pickup.description,
        },
        {
            title: deliveryTranslation.options.deliveryWithInstallation.title,
            description: deliveryTranslation.options.deliveryWithInstallation.description,
        },
    ];

    const payOptions = [
        {
            title: payTranslation.options.cashless.title,
            description: payTranslation.options.cashless.description
        },
        {
            title: payTranslation.options.card.title,
            description: payTranslation.options.card.description
        },
    ];

    return (
        <div className="container">
            <MetaTagsComponent
                title={title}
                seoTitle={title}
                seoDescription={`${deliveryOptions.reduce((acc,option, index) => {
                    return index === 0 ? option.title : `${acc}, ${option.title}`
                }, '')}`}
            />

            <main className="delivery-pay">
                <h1 className="delivery-pay__title">{title}</h1>
                <div className="delivery-pay__body">
                    <div className="delivery-pay__section-wrap delivery-pay__section-wrap--left">
                        <div className="delivery-pay__section">
                            <h2 className="delivery-pay__section-title">{deliveryTranslation.title}</h2>
                            {
                                deliveryOptions.map((option, index) => (
                                    <div key={index} className="delivery-pay__article">
                                        <h3 className="delivery-pay__article-title">{option.title}</h3>
                                        {
                                            option.description.map((text, index) => (
                                                <p key={index} className="delivery-pay__article-subtitle">— {text}</p>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>

                        <div className="delivery-pay__logo-box">
                            <div className="delivery-pay__logo-wrap">
                                <img src={newPost} alt="new post"/>
                            </div>
                        </div>
                    </div>

                    <div className="delivery-pay__section-wrap delivery-pay__section-wrap--rigth">
                        <div className="delivery-pay__section">
                            <h2 className="delivery-pay__section-title">{payTranslation.title}</h2>
                            {
                                payOptions.map((option, index) => (
                                    <div key={index} className="delivery-pay__article">
                                        <h4 className="delivery-pay__article-title">{option.title}</h4>
                                        {
                                            option.description.map((text, index) => (
                                                <p key={index} className="delivery-pay__article-subtitle">
                                                    — {text}
                                                </p>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>

                        <div className="delivery-pay__logo-box">
                            <div className="delivery-pay__logo-wrap">
                                <img src={Visa} alt="visa"/>
                            </div>

                            <div className="delivery-pay__logo-wrap">
                                <img src={masterCard} alt="mastercard"/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};
