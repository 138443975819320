import {SET_SELECTED_OPPORTUNITIES, SET_SELECTED_TRANSPORT_TYPES} from '../actionConsts/actionConsts';

const initialState = {
    selectedTypesOfTransport: [],
    selectedOpportunities: [],
};

const choosingSolutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_TRANSPORT_TYPES : {
            return {
                ...state,
                selectedTypesOfTransport: [...Object.keys(action.payload)],
            };
        }
        case SET_SELECTED_OPPORTUNITIES: {
            return {
                ...state,
                selectedOpportunities: [...Object.keys(action.payload)],
            };
        }
        default: return state;
    }
};

export default choosingSolutionReducer;
