import React from 'react';
import {AddToBasketButton} from '../globalComponents/AddToBasketButton';
import LocalizedLink from '../globalComponents/LocalizedLink';

export const CasesArticleEquipment = props => {
    return (
        <>
            <h3 className="cases-article__title">{props.title}</h3>
            <div className="cases-article__equipment">
                {
                    props.products.map(product => (
                        <div key={product.id} className="product-item-wrap product-item-wrap--blog">
                            <div className="product-item">
                                <LocalizedLink to={`/product/${product.slug}`}>
                                    <div className="product-item__content">
                                        <div className="product-item__img-wrap">
                                            <img
                                                className="product-item__img"
                                                src={product.images[0]}
                                                alt={product.name}
                                            />
                                        </div>
                                    </div>

                                    <div className="product-item__info">
                                        <h5 className="product-item__title">{product.name}</h5>
                                        <div className="product-item__subtitle"
                                             dangerouslySetInnerHTML={{__html: product.short_description}}/>
                                    </div>
                                </LocalizedLink>


                                <div className="product-item__buy-box">
                                    <p className="product-item__price">{`${product.final_price.toLocaleString()} грн`}</p>
                                    <AddToBasketButton product={product}/>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
};
