import React from 'react';

import './AboutCompany.scss';
import {useTranslation} from 'react-i18next';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {AboutCompanyHeader} from "./AboutCompanyHeader";
import {AboutCompanyDescription} from "./AboutCompanyDescription";
import {AboutCompanyVacancies} from "./AboutCompanyVacancies";
import {AboutCompanyClients} from "./AboutCompanyClients";
import {AboutCompanyCases} from "./AboutCompanyCases";
import {AboutCompanyTeam} from "./AboutCompanyTeam";

export const AboutCompany = props => {
    const [t] = useTranslation('aboutUs');

    return (
        <main className="about-us">
            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={t('articles', {returnObjects: true})[2]}
            />

            <AboutCompanyHeader />
            <AboutCompanyDescription />
            <AboutCompanyClients />
            <AboutCompanyCases />
            <AboutCompanyTeam />
            <AboutCompanyVacancies />
        </main>
    );
};
