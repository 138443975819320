import {parseCaseEntity} from '../../utils/parsers/parsers';
import {GET_CASE_ARTICLE} from "../actionConsts/actionConsts";

const initialState = {
    case: {
        id: 0,
        name: '',
        image: '',
        short_description: '',
        client_logo: '',
        client_description: '',
        problem: '',
        solution: '',
        result: '',
        feedback: '',
        products: [],
        benefits: [],
    },
};

const caseReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CASE_ARTICLE: {
            const caseArticle = action.payload;

            return {
                ...state,
                case: parseCaseEntity(caseArticle),
            };
        }
        default: return state;
    }
};

export default caseReducer;
