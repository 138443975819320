export const getAllOpportunities = () => {
    return window.axios.get('', {
        params: {
            query: `
                query {
                    opportunities{
                        title
                        image
                        icon
                    }
                }
            `
        },
    }).then(response => response.data.data.opportunities)
        .catch(error => console.log(error));
};
