import React from 'react';

export const CapabilitiesSliderNextArrow = props => {
    return (
        <div onClick={props.onClick} className="slider__button-sec slider__next-sec capabilities-next-button">
            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.277 15.1219L2.47604 0.361867C1.9911 -0.121444 1.20598 -0.120631 0.721859 0.364367C0.238111 0.849302 0.239361 1.63486 0.72436 2.11855L14.6443 16.0001L0.723859 29.8815C0.238924 30.3652 0.237674 31.1503 0.721359 31.6353C0.964046 31.8784 1.28198 32 1.59992 32C1.91704 32 2.23373 31.8792 2.47598 31.6378L17.277 16.8782C17.5105 16.6458 17.6416 16.3296 17.6416 16.0001C17.6416 15.6706 17.5102 15.3547 17.277 15.1219Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
