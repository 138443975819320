export const paymentForm = order_id => {
    return window.axios.get('', {
        params: {
            query: `
                query(
                    $order_id: String!
                    $btn_text: String!
                    $btn_class: String!
                ) {
                    paymentForm(
                        order_id: $order_id
                        btn_text: $btn_text
                        btn_class: $btn_class
                    )
                }
            `,
            variables: {
                order_id,
                btn_text: '',
                btn_class: '',
            }
        }
    }).then(response => response.data.data.paymentForm)
};
