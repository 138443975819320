import React from 'react';
import {useTranslation} from 'react-i18next';

import icon1 from '../../images/scontrol/adv-ico1.svg';
import icon2 from '../../images/scontrol/adv-ico2.svg';
import icon3 from '../../images/scontrol/adv-ico3.svg';
import icon4 from '../../images/scontrol/adv-ico4.svg';
import icon5 from '../../images/scontrol/adv-ico5.svg';
import icon6 from '../../images/scontrol/adv-ico6.svg';
import icon7 from '../../images/scontrol/adv-ico7.svg';
import icon8 from '../../images/scontrol/adv-ico8.svg';
import icon9 from '../../images/scontrol/adv-ico9.svg';

export const ScontrolAdvantages = props => {
    const [t] = useTranslation('services');
    const advantagesTranslation = t('scontrol.advantages', {returnObjects: true});
    const advantages = [
        {
            text: advantagesTranslation.position,
            image: icon1,
        },
        {
            text: advantagesTranslation.danger,
            image: icon2,
        },
        {
            text: advantagesTranslation.battery,
            image: icon3,
        },
        {
            text: advantagesTranslation.unseen,
            image: icon4,
        },
        {
            text: advantagesTranslation.tariffs,
            image: icon5,
        },
        {
            text: advantagesTranslation.corpus,
            image: icon6,
        },
        {
            text: advantagesTranslation.cabinet,
            image: icon7,
        },
        {
            text: advantagesTranslation.warranty,
            image: icon8,
        },
        {
            text: advantagesTranslation.mobile,
            image: icon9,
        },
    ];

    return (
        <div className="scontrol__advantages">
            <div className="container">
                <div className="scontrol__advantages-body">
                    {
                        advantages.map((advantage, index) => (
                            <div key={index} className="scontrol__advantage">
                                <div className="scontrol__advantage-ico">
                                    <img src={advantage.image} alt="advantage" />
                                </div>
                                <p className="scontrol__advantage-text">{advantage.text}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};
