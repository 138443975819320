import React from 'react';
import {CompareProductCard} from "./CompareProductCard";

export const CompareProductCards = props => {
    return (
        <div className="compare__product-cards">
            {
                props.productsCompare.map(product => (
                    <CompareProductCard key={product.id} product={product} productsCompare={props.productsCompare}/>
                ))
            }
        </div>
    )
};