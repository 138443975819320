import React from 'react';

export const BlogArticleHeader = props => {
    return (
        <section className="blog-article-header">
            <div className="blog-article-header__bg">
                <img
                    src={props.image}
                    alt={props.title}
                />
            </div>

            <div className="blog-article-header__wrap">
                <div className="blog-article-header__category-wrap">
                    <div className={`blog-article-header__category-section blog-article-header__category-section--${props.articleClass}`}>{props.section}</div>
                    <div className="blog-article-header__subject">{props.topic}</div>
                </div>

                <h1 className="blog-article-header__title">{props.title}</h1>
            </div>
        </section>
    );
};
