import React from 'react';
import {useTranslation} from 'react-i18next';
import {setSolutionForOffer} from '../../redux/actionCreators/globalActions';
import {EmptyPage} from '../globalComponents/EmptyPage/EmptyPage';

import {SuitableTabs} from '../globalComponents/SuitableTabs';
import {ProductSlider} from '../globalComponents/ProductSlider';
import {connect} from 'react-redux';
import {toggleCommercialOffer, toggleModal} from '../../redux/actionCreators/modalActions';

const ChosenSolutionPageContainer = props => {
    const [t] = useTranslation('solutionChoosing');

    const openModals = () => {
        props.toggleModal(true);
        props.toggleCommercialOffer(true);
    };

    return (
        <div className={`solution-choose__page ${props.isActive ? 'active' : ''}`}>
            {
                (props.transportType.opportunities.length > 0)
                    ? (
                        props.transportType.opportunities.map(opportunity => (
                            <div key={opportunity.title}>
                                <div className="container">
                                    <article className="solution-article">
                                        <div className="solution-article__photo">
                                            <img src={opportunity.image} alt={opportunity.title}/>
                                        </div>
                                        <div className="solution-article__section">
                                            <h3 className="solution-article__title">{opportunity.title}</h3>
                                            <div
                                                className="solution-article__description"
                                                dangerouslySetInnerHTML={{__html: opportunity.description}}
                                            />

                                            <SuitableTabs solutionsFor={opportunity.solution_for}/>

                                            <div className="solution-article__download-btn" onClick={() => {
                                                openModals();
                                                props.setSolutionForOffer({
                                                    solutionName: props.transportType.name,
                                                    taskName: opportunity.title,
                                                });
                                            }}>
                                                <svg width="19" height="25" viewBox="0 0 19 25" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M18.7224 5.36094L13.5141 0.152588C13.4164 0.0549316 13.2842 0 13.1458 0H2.2083C1.05933 0 0.125 0.934326 0.125 2.08335V22.9167C0.125 24.0657 1.05933 25 2.20835 25H16.7917C17.9407 25 18.875 24.0657 18.875 22.9167V5.72915C18.875 5.59082 18.8201 5.45859 18.7224 5.36094ZM13.6667 1.77817L17.0968 5.20835H14.7083C14.1341 5.20835 13.6667 4.74092 13.6667 4.1667V1.77817ZM17.8333 22.9167C17.8333 23.4909 17.3659 23.9583 16.7917 23.9583H2.20835C1.63413 23.9583 1.1667 23.4909 1.1667 22.9167V2.08335C1.1667 1.50913 1.63413 1.0417 2.20835 1.0417H12.625V4.1667C12.625 5.31567 13.5593 6.25 14.7083 6.25H17.8333V22.9167Z"
                                                        fill="#2C599D"/>
                                                    <path
                                                        d="M12.1555 15.2895C11.6733 14.9101 11.2151 14.52 10.9099 14.2148C10.5132 13.8181 10.1597 13.4335 9.85249 13.0673C10.3316 11.5867 10.5417 10.8233 10.5417 10.4164C10.5417 8.68755 9.91709 8.33301 8.9792 8.33301C8.2666 8.33301 7.4167 8.70327 7.4167 10.4662C7.4167 11.2434 7.84243 12.1869 8.68623 13.2835C8.47974 13.9137 8.23711 14.6405 7.9645 15.4599C7.83325 15.8531 7.69087 16.2172 7.54028 16.554C7.41773 16.6084 7.29868 16.6638 7.18374 16.7213C6.76973 16.9283 6.37656 17.1145 6.01187 17.2874C4.34863 18.0748 3.25 18.5956 3.25 19.624C3.25 20.3707 4.06128 20.833 4.8125 20.833C5.78091 20.833 7.24321 19.5395 8.31133 17.3606C9.42012 16.9232 10.7985 16.5992 11.8865 16.3962C12.7583 17.0666 13.7211 17.708 14.1875 17.708C15.4789 17.708 15.75 16.9613 15.75 16.3352C15.75 15.1038 14.3431 15.1038 13.6667 15.1038C13.4566 15.1039 12.8931 15.1659 12.1555 15.2895ZM4.8125 19.7914C4.51494 19.7914 4.31353 19.651 4.29165 19.624C4.29165 19.2547 5.39282 18.7329 6.45791 18.2284C6.52554 18.1963 6.59424 18.1643 6.66392 18.1312C5.88164 19.2655 5.10801 19.7914 4.8125 19.7914ZM8.45835 10.4662C8.45835 9.37471 8.79712 9.37471 8.9792 9.37471C9.34746 9.37471 9.50005 9.37471 9.50005 10.4164C9.50005 10.6361 9.35356 11.1854 9.0855 12.043C8.67651 11.4133 8.45835 10.8736 8.45835 10.4662ZM8.85762 16.0642C8.89019 15.9736 8.92173 15.8821 8.95225 15.7895C9.14551 15.2097 9.31948 14.6888 9.47461 14.2199C9.69077 14.4579 9.92373 14.7016 10.1735 14.9513C10.2711 15.0489 10.5132 15.2687 10.8357 15.5438C10.1937 15.6837 9.51069 15.8571 8.85762 16.0642ZM14.7083 16.3353C14.7083 16.5692 14.7083 16.6664 14.2251 16.6694C14.0833 16.6389 13.7552 16.4457 13.3503 16.17C13.4973 16.1537 13.6056 16.1456 13.6667 16.1456C14.4362 16.1456 14.6544 16.2208 14.7083 16.3353Z"
                                                        fill="#2C599D"/>
                                                </svg>
                                                {t('chosen.applicationRequestButton')}
                                            </div>
                                        </div>
                                    </article>
                                </div>


                                {
                                    (opportunity.products.length > 0) && (
                                        <div className="product-slider-wrap container">
                                            <h3 className="product-slider-title">{t('chosen.equipmentSliderTitle')}</h3>
                                            <ProductSlider products={opportunity.products}/>
                                        </div>
                                    )
                                }
                            </div>
                        ))
                    )
                    : (
                        <div className="solution-article--empty">
                            <EmptyPage message={t('chosen.emptyPageMessage')}/>
                        </div>

                    )
            }

            {/*<CapabilitiesSlider capabilities={solution.capabilities} />*/}
        </div>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleCommercialOffer,
    setSolutionForOffer,
};

export const ChosenSolutionPage = connect(null, mapDispatchToProps)(ChosenSolutionPageContainer);
