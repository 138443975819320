export const getCases = () => {
    return window.axios.get('', {
        params: {
            query: `
                query {
                    cases {
                        id
                        name
                        image
                        short_description
                    }
                }
            `,
        },
    }).then(response => response.data.data.cases)
        .catch(error => console.log(error));
};
