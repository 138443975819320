//Catalog
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_FILTERS = 'GET_FILTERS';
export const GET_CATEGORY_DETAILS = 'GET_CATEGORY_DETAILS';
export const APPLY_PRICE_FILTER = 'APPLY_PRICE_FILTER';
export const APPLY_BRAND_FILTER = 'APPLY_BRAND_FILTER';
export const APPLY_SPECIFICATIONS_FILTER = 'APPLY_SPECIFICATIONS_FILTER';
export const APPLY_SORT_PROPERTY = 'APPLY_SORT_PROPERTY';
export const APPLY_CATEGORY_FILTER = 'APPLY_CATEGORY_FILTER';

//Equipment
export const GET_EQUIPMENT = 'GET_EQUIPMENT';

//product item
export const GET_PRODUCT = 'GET_PRODUCT';

//solutions
export const GET_INDUSTRY_SOLUTIONS = 'GET_INDUSTRY_SOLUTIONS';
export const GET_TRANSPORT_SOLUTIONS = 'GET_TRANSPORT_SOLUTIONS';
export const GET_INDUSTRY_SOLUTION = 'GET_INDUSTRY_SOLUTION';
export const GET_TRANSPORT_SOLUTION = 'GET_TRANSPORT_SOLUTION';
export const GET_OPPORTUNITIES = 'GET_OPPORTUNITIES';

//main page
export const GET_COMPANIES = 'GET_COMPANIES';

//modals
export const TOGGLE_MODAL='TOGGLE_MODAL';
export const TOGGLE_SUBSCRIBE_MODAL='TOGGLE_SUBSCRIBE_MODAL';
export const TOGGLE_COMMERCIAL_OFFER_MODAL = 'TOGGLE_COMMERCIAL_OFFER_MODAL';
export const TOGGLE_QUESTION_MODAL = 'TOGGLE_QUESTION_MODAL';
export const TOGGLE_BASKET_MODAL = 'TOGGLE_BASKET_MODAL';
export const TOGGLE_ENTRY_MODAL = 'TOGGLE_ENTRY_MODAL';
export const TOGGLE_CALLBACK_MODAL = 'TOGGLE_CALLBACK_MODAL';
export const TOGGLE_PASSWORD_RECOVERY_MODAL = 'TOGGLE_PASSWORD_RECOVERY_MODAL';
export const TOGGLE_EMPTY_BASKET_MODAL = 'TOGGLE_EMPTY_BASKET_MODAL';
export const TOGGLE_EMPTY_COMPARE_MODAL = 'TOGGLE_EMPTY_COMPARE_MODAL';
export const TOGGLE_CITY_SELECT_MODAL = 'TOGGLE_CITY_SELECT_MODAL';
export const TOGGLE_REGISTRATION_SUCCESS_MODAL = 'TOGGLE_REGISTRATION_SUCCESS_MODAL';
export const TOGGLE_APPLICATION_SUCCESS_MODAL = 'TOGGLE_APPLICATION_SUCCESS_MODAL';
export const TOGGLE_SOLUTION_ORDER_MODAL = 'TOGGLE_SOLUTION_ORDER_MODAL';
export const TOGGLE_SERVICE_ORDER_MODAL = 'TOGGLE_SERVICE_ORDER_MODAL';
export const TOGGLE_PASSWORD_RESET_MODAL = 'TOGGLE_PASSWORD_RESET_MODAL';
export const TOGGLE_PASSWORD_RESET_SUCCESS_MODAL = 'TOGGLE_PASSWORD_RESET_SUCCESS_MODAL';
export const TOGGLE_PASSWORD_RESET_ERROR_MODAL = 'TOGGLE_PASSWORD_RESET_ERROR_MODAL';
export const TOGGLE_ORDER_ID_MODAL = 'TOGGLE_ORDER_ID_MODAL';
export const TOGGLE_DEAL_SUCCESS = 'TOGGLE_DEAL_SUCCESS';

//compare
export const ADD_PRODUCT_COMPARE = 'ADD_PRODUCT_COMPARE';
export const DELETE_PRODUCT_COMPARE = 'DELETE_PRODUCT_COMPARE';
export const DELETE_PRODUCTS_BY_CATEGORY_COMPARE = 'DELETE_PRODUCTS_BY_CATEGORY_COMPARE';
export const GET_COMPARE_FROM_LOCAL_STORAGE = 'GET_COMPARE_FROM_LOCAL_STORAGE'

//basket
export const ADD_PRODUCT_BASKET = 'ADD_PRODUCT_BASKET';
export const DELETE_PRODUCT_BASKET = 'DELETE_PRODUCT_BASKET';
export const TOGGLE_PRODUCT_DELETE_FLAG = 'TOGGLE_PRODUCT_DELETE_FLAG';
export const INCREASE_PRODUCT_QUANTITY = 'INCREASE_PRODUCT_QUANTITY';
export const DECREASE_PRODUCT_QUANTITY = 'DECREASE_PRODUCT_QUANTITY';
export const GET_BASKET_FROM_LOCAL_STORAGE = 'GET_BASKET_FROM_LOCAL_STORAGE';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const ADD_MULTIPLE_PRODUCTS_BASKET = 'ADD_MULTIPLE_PRODUCTS_BASKET';

//cases
export const GET_CASES = 'GET_CASES';
export const GET_CASE_ARTICLE = 'GET_CASE_ARTICLE';

//user account
export const GET_USER_INFO = 'GET_USER_INFO';
export const TOGGLE_USER_LOGIN = 'TOGGLE_USER_LOGIN';

//global actions
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_USER_LOCATION = 'CHANGE_USER_LOCATION';
export const SET_PRODUCT_FOR_OFFER = 'SET_PRODUCT_FOR_OFFER';
export const SET_SOLUTION_FOR_OFFER = 'SET_SOLUTION_FOR_OFFER';
export const SET_SERVICE_FOR_ORDER = 'SET_SERVICE_FOR_ORDER';
export const SET_SOLUTION_FOR_ORDER = 'SET_SOLUTION_FOR_ORDER';
export const SET_GOOGLE_ID = 'SET_GOOGLE_ID';
export const SET_UTM_PARAMETERS = 'SET_UTM_PARAMETERS';

//services
export const GET_ADDITIONAL_SERVICES = 'GET_ADDITIONAL_SERVICES';

//checkout
export const SET_CHECKOUT_PERSONAL_INFORMATION = 'SET_CHECKOUT_PERSONAL_INFORMATION';
export const SET_DELIVERY_INFORMATION = 'SET_DELIVERY_INFORMATION';
export const SET_INSTALLATION_INFORMATION = 'SET_INSTALLATION_INFORMATION';
export const SET_CHECKOUT_ERRORS = 'SET_CHECKOUT_ERRORS';
export const SET_INSTALLATION_REPEAT = 'SET_INSTALLATION_REPEAT';
export const SET_DELIVERY_REPEAT = 'SET_DELIVERY_REPEAT';
export const SET_PAYMENT_METHOD_REPEAT = 'SET_PAYMENT_METHOD_REPEAT';

//choosing solution
export const SET_SELECTED_TRANSPORT_TYPES = 'SET_SELECTED_TRANSPORT_TYPES';
export const SET_SELECTED_OPPORTUNITIES = 'SET_SELECTED_OPPORTUNITIES';
