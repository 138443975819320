import React from 'react';
import {useTranslation} from 'react-i18next';

import headerImg from '../../images/about-us/header-bg.webp'

export const AboutCompanyHeader = props => {
    const [t] = useTranslation('aboutUs');

    return (
        <section className="about-us-header">
            <div className="about-us-header__bg">
                <img src={headerImg} alt="about us" />
            </div>

            <div className="about-us-header__body">
                <h1 className="about-us-header__title">{t('title')}</h1>
                <h2 className="about-us-header__subtitle">
                    {t('subtitle')}
                </h2>
            </div>
        </section>
    );
};
