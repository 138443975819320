import React from 'react';
import {_discount} from '../../../globalConsts';
import LocalizedLink from '../../globalComponents/LocalizedLink';

export  const UserAccountOrderHistoryOrder = props => {
    return (
        <div className="order-history__product">
            <LocalizedLink to={`/product/${props.orderItem.product.slug}`} className="order-history__product-body">
                <div className="order-history__product-img">
                    <img src={window._.head(props.orderItem.product.images)} alt={props.orderItem.product.name} />
                </div>

                <div className="order-history__product-info">
                    <div className="order-history__product-name">{props.orderItem.product.name}</div>
                    <div className="order-history__product-model">{props.orderItem.product.name}</div>
                </div>
            </LocalizedLink>

            <div className="order-history__right-wrap">
                <div className="order-history__product-quantity">{props.orderItem.quantity}</div>
                <div className="order-history__product-price">{`${(props.orderItem.total_price * (1 - _discount)).toLocaleString()} грн`}</div>
            </div>
        </div>
    );
};
