import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";

import {getSolutionsByType} from '../../../../API/requests';
import {
    getIndustrySolutions, getTransportSolutions,
} from '../../../../redux/actionCreators/getActionCreators/solutionsActions';
import {DropMenuBanner} from "../../DropMenuBanner";
import LocalizedLink from '../../LocalizedLink';

const SolutionsMenuContainer = props => {
    const [t] = useTranslation('header');
    const solutionTitles = t('headerBottomNav.menuComponents.solutions', {returnObjects: true});
    useEffect(() => {
        getSolutionsByType('transport').then(solutions => props.getTransportSolutions(solutions));
        getSolutionsByType('industry').then(solutions => props.getIndustrySolutions(solutions));
    }, []);

    return (
        <div className={`header-bottom__drop-menu header-bottom__drop-menu--solutions ${props.isActive ? 'active' : ''}`}>
            <div className="header-bottom__list-wrap">
                <h3 className="header-bottom__list-title">{solutionTitles.industrySolutionsTitle}</h3>
                <ul className="header-bottom__catalog-drop">
                    {
                        props.industrySolutions.map((solution, index) => (
                            <li
                                key={index}
                                className="header-bottom__drop-menu-item"
                                onClick={() => {
                                    props.toggleMenu();
                                    props.closeSideMenu();
                                }}
                            >
                                <LocalizedLink to={`/solution/industry/${solution.type}`}>
                                    <div className="ico-wrap">
                                        <img
                                            className="ico"
                                            src={solution.icon}
                                            alt={solution.name}
                                        />
                                    </div>
                                    {solution.name}
                                </LocalizedLink>
                            </li>
                        ))
                    }
                </ul>
            </div>

            <div className="header-bottom__list-wrap">
                <h3 className="header-bottom__list-title">{solutionTitles.transportSolutionsTitle}</h3>
                <ul className="header-bottom__catalog-drop">
                    {
                        props.transportSolutions.map((solution, index) => (
                            <li
                                key={index}
                                className="header-bottom__drop-menu-item"
                                onClick={() => {
                                    props.toggleMenu();
                                    props.closeSideMenu();
                                }}
                            >
                                <LocalizedLink to={`/solution/transport/${solution.type}`}>
                                    <div className="ico-wrap">
                                        <img
                                            className="ico"
                                            src={solution.icon}
                                            alt={solution.name}
                                        />
                                    </div>
                                    {solution.name}
                                </LocalizedLink>
                            </li>
                        ))
                    }
                </ul>
            </div>

            <DropMenuBanner toggleMenu={props.toggleMenu} />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        industrySolutions: state.solutionsState.industrySolutions,
        transportSolutions: state.solutionsState.transportSolutions,
    };
};

const mapDispatchToProps = {
    getTransportSolutions,
    getIndustrySolutions,
};

export const SolutionsMenu = connect(mapStateToProps, mapDispatchToProps)(SolutionsMenuContainer);
