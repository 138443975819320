import React from 'react';

export const CapabilitiesSliderPrevArrow = props => {
    return (
        <div onClick={props.onClick} className="slider__button-sec slider__prev-sec capabilities-prev-button">
            <svg width="18" height="32" viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.723019 15.1219L15.524 0.361867C16.0089 -0.121444 16.794 -0.120631 17.2781 0.364367C17.7619 0.849302 17.7606 1.63486 17.2756 2.11855L3.3557 16.0001L17.2761 29.8815C17.7611 30.3652 17.7623 31.1503 17.2786 31.6353C17.036 31.8784 16.718 32 16.4001 32C16.083 32 15.7663 31.8792 15.524 31.6378L0.723019 16.8782C0.489458 16.6458 0.358395 16.3296 0.358395 16.0001C0.358395 15.6706 0.489834 15.3547 0.723019 15.1219Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
