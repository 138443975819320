import {_api} from "../apiConsts";

export const loginUser = loginInfo => {
    return window.axios.post(`${_api}/auth`, {
        params: {
            query: `
                mutation (
                    $email: String!
                    $password: String!
                ) {
                    login (
                        email: $email
                        password: $password
                    ) {
                        token
                        token_type
                        expires_in
                    }
                }
            `,
            variables: {
                email: loginInfo.email,
                password: loginInfo.password,
            },
        },
    }).then(response => {
        return window._.head(response.data).data.login
    })
        .catch(error => console.log(error));
};
