import React from 'react';

import headerBg from '../../images/other-services/header-bg.webp';

export const AdditionalServicesHeader = props => {
    return (
        <div className="services__header services__header--other">
            <div className="services__header-bg">
                <img src={headerBg} alt="additional services" />
            </div>
            <article className="services__header-body">
                <h1 className="services__header-title">{props.title}</h1>
            </article>
        </div>
    );
};
