import React from 'react';
import {OrderSolutionButton} from '../globalComponents/OrderSolutionButton';

import rails from '../../images/transport-solutions/rails.webp';

export const TransportSolutionHeader = props => {
    return (
        <section className="transport-solutions__header">
            <div className="transport-solutions__header-bg">
                <img src={props.backgroundImage} alt={props.name} />
            </div>

            {
                (props.transportImage) && (
                    <div className="transport-solutions__header-bg-item">

                        <img
                            className="transport-solutions__header-train"
                            src={props.transportImage}
                            alt={props.name}
                        />
                    </div>
                )
            }

            {
                (props.solutionType === 'railway') && (<img className="transport-solutions__header-rails" src={rails} alt="rails" />)
            }

            <div className="container">
                <div className="transport-solutions__header-body">
                    <h1 className="transport-solutions__header-title">{`${props.title}:`}</h1>
                    <h2 className="transport-solutions__header-subtitle">{props.name}</h2>
                    <h3 className="transport-solutions__header-descr">{props.shortDescription}</h3>
                    <OrderSolutionButton solution={props.name}/>
                </div>
            </div>
        </section>
    );
};
