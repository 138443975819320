import {
    parseCapabilityEntities,
    parseOpportunityEntities,
    parseProductEntities,
} from '../../utils/parsers/parsers';
import {GET_INDUSTRY_SOLUTION} from "../actionConsts/actionConsts";

import agribusiness from '../../videos/agribusiness.mp4';
import logistics from '../../videos/logistics.mp4';
import deliveryServices from '../../videos/delivery-services.mp4';
import constructionMining from '../../videos/construction-mining.mp4';
import carRental from '../../videos/car-rental.mp4';
import insurance from '../../videos/insurance.mp4';
import utilities from '../../videos/utilities.mp4';
import publicTransport from '../../videos/public-transport.mp4';

const solutionsMedia = {
    'agribusiness': agribusiness,
    'logistics': logistics,
    'delivery-services': deliveryServices,
    'construction-mining': constructionMining,
    'car-rental': carRental,
    'insurance': insurance,
    'utilities': utilities,
    'public-transport': publicTransport,
};

const initialState = {
    solution: {
        name: '',
        description: '',
        short_description: '',
        opportunities: [
            {
                title: '',
                icon: '',
                description: '',
                products: [],
            },
        ],
        capabilities: [
        ],
        products: [],
    },
};

const industrySolutionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INDUSTRY_SOLUTION: {
            const solution = action.payload;

            const newSolution = {
                ...solution,
                video: solutionsMedia[solution.type],
                capabilities: solution.capabilities ? parseCapabilityEntities(solution.capabilities) : [],
                opportunities: solution.opportunities ? parseOpportunityEntities(solution.opportunities): [],
                products: solution.products ? parseProductEntities(solution.products) : [],
            };

            return {
                ...state,
                solution: newSolution,
            }
        }
        default:
            return state;
    }
};

export default industrySolutionReducer;
