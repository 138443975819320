import React from 'react';

import './ProductSpecifications.scss';
import {useTranslation} from 'react-i18next';

import fileIcon from '../../../images/file-ico.svg'

export const ProductSpecifications = props => {
    const [t] = useTranslation('productCard');

    return (
        <article className="product-specifications">
            <div className="product-specifications__body">
                <h5 className="product-specifications__title section-title">{t('specificationsTitle')}</h5>
                {

                    Object.keys(props.specifications).map((characteristic, index) => {
                        const characteristicName = characteristic;
                        const characteristicValue = props.specifications[characteristicName];

                        return (
                            <div key={index} className="product-specifications__spec-item">
                                <div className="product-specifications__spec-title">{characteristicName}</div>
                                <div className="product-specifications__spec-text-sec">
                                    {
                                        Array.isArray(characteristicValue)
                                            ? (
                                                characteristicValue.map((option, index) => (
                                                    <div key={index} className="product-specifications__spec-text">
                                                        {option}
                                                    </div>
                                                ))
                                            )
                                            : (
                                                <div className="product-specifications__spec-text">
                                                    {characteristicValue}
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="product-specifications__tecdoc">
                <h5 className="product-specifications__title section-title">{t('documentationTitle')}</h5>
                {
                    props.documentation.map((document, index) => (
                        <div key={index} className="product-specifications__tecdoc-item">
                            <div className="product-specifications__tecdoc-text">
                                {document.text}
                            </div>

                            <div className="product-specifications__tecdoc-line" />

                            <a className="product-specifications__tecdoc-link" href={document.file} target="_blank">
                                <img src={fileIcon} alt={document.text} />
                            </a>
                        </div>
                    ))
                }
            </div>
        </article>
    );
};
