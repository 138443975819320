import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import scontrol from '../../images/scontrol/product-img1.jpg';
import scontolPlus from '../../images/scontrol/product-img2.jpg';
import scontrol2 from '../../images/scontrol/header-img-2.png';
import {toggleCallback, toggleModal} from '../../redux/actionCreators/modalActions';

const ScontrolCompareContainer = props => {
    const [t] = useTranslation(['services', 'common']);
    const tableTranslation = t('scontrol.table', {returnObjects: true});

    const openModals = () => {
        props.toggleModal(true);
        props.toggleCallback(true);
    };

    const table = {
        purpose: {
            title: tableTranslation.purpose,
            scontrol: tableTranslation.lighthouseBookmark,
            scontrolPlus: tableTranslation.trackerAndLighthouse,
            scontrolV2: tableTranslation.lighthouseBookmark,
        },

        modes: {
            title: tableTranslation.modes,
            scontrol: 'GPS/LBS',
            scontrolPlus: 'GLONASS/GPS/LBS',
            scontrolV2: 'GPS/LBS',
        },
        motionSensor: {
            title: tableTranslation.motionSensor,
            scontrol: tableTranslation.withoutAccelerometer,
            scontrolPlus: tableTranslation.withAccelerometer,
            scontrolV2: tableTranslation.withoutAccelerometer,
        },
        batteryCapacity: {
            title: tableTranslation.batteryCapacity,
            scontrol: `3200 ${tableTranslation.batteryCapacityEnd}`,
            scontrolPlus: `3200 ${tableTranslation.batteryCapacityEnd}`,
            scontrolV2: `1400 ${tableTranslation.batteryCapacityEnd}`,
        },
        powerSupply: {
            title: tableTranslation.powerSupply,
            scontrol: tableTranslation.unableToConnect,
            scontrolPlus: tableTranslation.ableToConnect,
            scontrolV2: tableTranslation.unableToConnect,
        },
        monitoring: {
            title: tableTranslation.monitoring,
            scontrol: tableTranslation.noMonitoringRegime,
            scontrolPlus: tableTranslation.sensorInKit,
            scontrolV2: tableTranslation.noMonitoringRegime,
        },
        autonomousTracking: {
            title: tableTranslation.autonomousTracking,
            scontrol: tableTranslation.noAutonomousTracking,
            scontrolPlus: tableTranslation.noAutonomousTracking,
            scontrolV2: tableTranslation.noAutonomousTracking,
        },
        outdoorInstallation: {
            title: tableTranslation.outdoorInstallation,
            scontrol: tableTranslation.noAirtight,
            scontrolPlus: tableTranslation.noAirtight,
            scontrolV2: tableTranslation.noAirtight,
        },
        reporting: {
            title: tableTranslation.reporting,
            scontrol: tableTranslation.noAvailable,
            scontrolPlus: tableTranslation.trackingOperationReports,
            scontrolV2: tableTranslation.noAvailable,
        },
        recharging: {
            title: tableTranslation.recharging,
            scontrol: tableTranslation.noAvailable,
            scontrolPlus: tableTranslation.noAvailable,
            scontrolV2: tableTranslation.noAvailable,
        },
        tariff: {
            title: tableTranslation.tariff,
            scontrol: `30 грн/${t('common:monthShort')}`,
            scontrolPlus: `69 грн/${t('common:monthShort')}`,
            scontrolV2: `${t('common:from')} 30 грн./${t('common:monthShort')}`,
        },
    };

    return (
        <section className="scontrol-compare">
            <h2 className="scontrol-compare__title">{tableTranslation.title}<span>SControl</span></h2>
            <div className="scontrol-compare__body">
                <div className="scontrol-compare__header">
                    <div className="scontrol-compare__header-cell">
                        <div className="scontrol-compare__product-img">
                            <img src={scontrol} alt="SControl" />
                        </div>
                    </div>

                    <div className="scontrol-compare__header-cell">
                        <div className="scontrol-compare__product-img">
                            <img src={scontrol2} alt="SControl v2" />
                        </div>
                    </div>

                    <div className="scontrol-compare__header-cell">
                        <div className="scontrol-compare__product-img">
                            <img src={scontolPlus} alt="SControl++" />
                        </div>
                    </div>
                </div>

                <div className="scontrol-compare-table">
                    {
                        Object.keys(table).map(key => (
                            <div key={key} className="scontrol-compare-table__item">
                                <div className="scontrol-compare-table__cell scontrol-compare-table__cell--title">
                                    {
                                        table[key].title
                                    }
                                </div>

                                <div className="scontrol-compare-table__cell">
                                    <p className="scontrol-compare-table__cell-name">SControl</p>
                                    <p className="scontrol-compare-table__cell-val">{table[key].scontrol}</p>
                                </div>

                                <div className="scontrol-compare-table__cell">
                                    <p className="scontrol-compare-table__cell-name">SControl v2</p>
                                    <p className="scontrol-compare-table__cell-val">{table[key].scontrolV2}</p>
                                </div>

                                <div className="scontrol-compare-table__cell">
                                    <p className="scontrol-compare-table__cell-name">SControl++</p>
                                    <p className="scontrol-compare-table__cell-val">{table[key].scontrolPlus}</p>
                                </div>
                            </div>
                        ))
                    }

                    <div className="scontrol-compare-table__item">
                        <div className="scontrol-compare-table__cell scontrol-compare-table__cell--title">
                            {tableTranslation.cost}
                        </div>
                        <div className="scontrol-compare-table__cell scontrol-compare-table__cell--link">
                            <p className="scontrol-compare-table__cell-name">SControl</p>
                            <p className="scontrol-compare-table__cell-val" onClick={openModals}>{tableTranslation.findOutTheCost}</p>
                        </div>

                        <div className="scontrol-compare-table__cell scontrol-compare-table__cell--link">
                            <p className="scontrol-compare-table__cell-name">SControl v2</p>
                            <p className="scontrol-compare-table__cell-val" onClick={openModals}>{tableTranslation.findOutTheCost}</p>
                        </div>

                        <div className="scontrol-compare-table__cell scontrol-compare-table__cell--link">
                            <p className="scontrol-compare-table__cell-name">SControl++</p>
                            <p className="scontrol-compare-table__cell-val" onClick={openModals}>{tableTranslation.findOutTheCost}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleCallback,
}

export const ScontrolCompare = connect(null, mapDispatchToProps)(ScontrolCompareContainer);
