import React from 'react';
import {useTranslation} from 'react-i18next';

import videoHeader from '../../videos/gsm.mp4';
import {OrderServiceButton} from '../globalComponents/OrderServiceButton';

export const GsmHeader = props => {
    const [t] = useTranslation('services');

    return (
        <section className="services__header">
            <div className="services__header-bg">
                <video className="video-bg"  autoPlay loop muted>
                    <source src={videoHeader} type="video/mp4"/>
                </video>
            </div>

            <div className="gsm-header-container">
                <div className="gsm-header__body">
                    <h1 className="gsm-header__title">{t('gsm.title')}</h1>
                    <h2 className="gsm-header__subtitle">
                        {t('gsm.subtitle')}
                    </h2>
                    <OrderServiceButton service={t('gsm.title')}/>
                </div>
            </div>
        </section>
    );
};
