import React, {useReducer, useEffect} from 'react';

import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {createLead} from '../../../API/birixApi/createLead';
import {orderSolutionFormFields} from '../../../globalConsts';
import closeBtn from '../../../images/close-btn.svg';
import {
    toggleApplicationSuccess,
    toggleModal,
    toggleSolutionOrder,
} from '../../../redux/actionCreators/modalActions';
import createFormState from '../../../utils/stateReducers/createFormState';
import formReducer from '../../../utils/stateReducers/formReducer';
import Form from '../Form/Form';
import ReactGA from "react-ga";

const OrderSolutionModalContainer = props => {
    const [t] = useTranslation('common');
    const [orderSolutionFormValues, dispatchOrderSolutionFormValues] = useReducer(formReducer, orderSolutionFormFields, createFormState);

    useEffect(() => {
        if(props.solution) dispatchOrderSolutionFormValues({type: 'set_value', payload: {name: 'solution', value: props.solution}});

        if(props.isLogged) {
            const formInfoToSet = {
                name: {
                    ...orderSolutionFormValues.name,
                    value: props.user.name,
                    wasTouched: true,
                },
                phone: {
                    ...orderSolutionFormValues.phone,
                    value: props.user.phone,
                    wasTouched: true,
                },
                email: {
                    ...orderSolutionFormValues.email,
                    value: props.user.email,
                    wasTouched: true,
                },
            }

            dispatchOrderSolutionFormValues({type: 'set_all_values', payload: formInfoToSet});
        }
    }, [props.user, props.isLogged, props.solution]);

    const onOrderSubmit = () => {
        const userInfo = {
            solution: orderSolutionFormValues.solution.value,
            name: orderSolutionFormValues.name.value,
            phone: orderSolutionFormValues.phone.value,
            email: orderSolutionFormValues.email.value,
            comment: orderSolutionFormValues.comment.value,
        };

        createLead(userInfo, 'solutionOrder', props.googleId, props.utmParams);
        applicationSuccessModal();
        ReactGA.event({
            category: 'Order',
            action: 'order-solution',
            label: 'Запрос решения',
        });
    };

    const applicationSuccessModal = () => {
        props.toggleApplicationSuccess(true);
        props.toggleSolutionOrder(false);
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleSolutionOrder(false);
    };

    return (
        <div className={`form-modal form-modal--service ${props.solutionOrder ? 'active' : ''}`}>
            <div className="modal__close">
                <img
                    src={closeBtn}
                    alt="close"
                    onClick={closeModals}
                />
            </div>

            <Form
                className="form-modal__body"
                formValues={orderSolutionFormValues}
                dispatchFormValues={dispatchOrderSolutionFormValues}
                formFields={orderSolutionFormFields}
                onSubmitSuccess={onOrderSubmit}
            >
                <h6 className="form-modal__title">{t('solutionRequest')}</h6>
            </Form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isLogged: state.userAccountState.isLogged,
        user: state.userAccountState.user,
        solutionOrder: state.modalsState.solutionOrder,
        solution: state.globalReducer.solutionForOrder,
        googleId: state.globalReducer.googleId,
        utmParams: state.globalReducer.utmParams,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleSolutionOrder,
    toggleApplicationSuccess,
};

export const OrderSolutionModal = connect(mapStateToProps, mapDispatchToProps)(OrderSolutionModalContainer);
