import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";

import closeButton from '../../../../images/close-btn.svg';
import {EntryModalLogin} from "./EntryModalLogin";
import {EntryModalRegistration} from "./EntryModalRegistration";
import {
    toggleEntry,
    toggleModal,
    togglePasswordRecovery,
    toggleRegistrationSuccess
} from "../../../../redux/actionCreators/modalActions";

const EntryModalContainer = props => {
    const [t] = useTranslation('common');

    const [entryType, setEntryType] = useState('login');

    const closeModals = () => {
        props.toggleEntry(false);
        props.toggleModal(false);
    };

    const changeEntryType = entryTypeToSet => {
        setEntryType(entryTypeToSet);
    };

    const openPasswordRecoveryModal = () => {
        props.toggleEntry(false);
        props.togglePasswordRecovery(true);
    };

    const openRegistrationSuccessModal = () => {
        props.toggleEntry(false);
        props.toggleRegistrationSuccess(true);
    }

    return (
        <div className={`enter-modal ${props.entry ? 'active' : ''}`}>
            <div className="close-btn">
                <img
                    src={closeButton}
                    alt="close"
                    onClick={closeModals}
                />
            </div>

            <div className="user-type-selector">
                <div className={`user-type-selector__btn ${entryType === 'login' ? 'active' : ''}`} onClick={() => changeEntryType('login')}>{t('form.loginTitle')}</div>
                <div className={`user-type-selector__btn ${entryType === 'registration' ? 'active' : ''}`} onClick={() => changeEntryType('registration')}>{t('form.registrationTitle')}</div>
            </div>

            <div className="enter-modal__tabs-wrap">
                <div className="enter-modal__tabs">
                    {
                        entryType === 'login'
                            ? (<EntryModalLogin openPasswordRecoveryModal={openPasswordRecoveryModal} closeModals={closeModals}/>)
                            : (<EntryModalRegistration openRegistrationSuccessModal={openRegistrationSuccessModal} />)
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        entry: state.modalsState.entry,
    };
};

const mapDispatchToProps = {
    toggleEntry,
    toggleModal,
    togglePasswordRecovery,
    toggleRegistrationSuccess,
}

export const EntryModal = connect(mapStateToProps, mapDispatchToProps)(EntryModalContainer);
