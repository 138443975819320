import React from 'react';

import './Advantages.scss';

import bgImage from '../../../images/main-page/advantages-bg-item.svg';
import capabilities from '../../../images/main-page/advantages-icons/capabilities.svg';
import flexibility from '../../../images/main-page/advantages-icons/flexibility.svg';
import guarantee from '../../../images/main-page/advantages-icons/guarantee.svg';
import network from '../../../images/main-page/advantages-icons/network.svg';
import quality from '../../../images/main-page/advantages-icons/quality.svg';
import support from '../../../images/main-page/advantages-icons/support.svg';

export const Advantages = props => {
    const advantages = [
        {
            title: props.translation.advantagesList.quality.title,
            image: quality,
            description: props.translation.advantagesList.quality.description
        },
        {
            title: props.translation.advantagesList.flexibility.title,
            image: flexibility,
            description: props.translation.advantagesList.flexibility.description,
        },
        {
            title: props.translation.advantagesList.support.title,
            image: support,
            description: props.translation.advantagesList.support.description,
        },
        {
            title: props.translation.advantagesList.capabilities.title,
            image: capabilities,
            description: props.translation.advantagesList.capabilities.description,
        },
        {
            title: props.translation.advantagesList.network.title,
            image: network,
            description: props.translation.advantagesList.network.description,
        },
        {
            title: props.translation.advantagesList.guarantee.title,
            image: guarantee,
            description: props.translation.advantagesList.guarantee.description,
        },
    ];

    return (
        <section className="advantages-wrap">
            <img
                alt="advantages"
                className="advantages-bg-item"
                src={bgImage}
            />
            <h2 className="advantages-title">{props.translation.title}</h2>
            <div className="advantages container">
                {
                    advantages.map(advantage => (
                        <div
                            key={advantage.title}
                            className="advantages__item-wrap"
                        >
                            <div className="advantages__item">
                                <div className="advantages__ico-wrap">
                                    <img
                                        alt={advantage.title}
                                        src={advantage.image}
                                    />
                                </div>
                                <h4 className="advantages__title">{advantage.title}</h4>
                                <p className="advantages__subtitle">{advantage.description}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    );
};
