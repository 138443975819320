import React from 'react';

export const CasesArticleSolution = props => {
    return (
        <div className="cases-article__text">
            <h3 className="cases-article__title">{props.title}</h3>
            <div className="cases-article__paragraph" dangerouslySetInnerHTML={{__html: props.solution}}/>
        </div>
    );
};
