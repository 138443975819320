import React from 'react';

import GoogleMapReact from 'google-map-react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {toggleCallback, toggleModal} from '../../../redux/actionCreators/modalActions';
import {MapMarker} from "./MapMarker";
import {MapMarkerActive} from "./MapMarkerActive";

const ContactsMapContainer = props => {
    const currentLocation = props.locations.branches[props.selectedLocation.index];
    const [t] = useTranslation('contacts');
    const getMapOptions = () => {
        return {
            disableDefaultUI: true,
            styles:[
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#6195a0"
                        }
                    ]
                },
                {
                    "featureType": "administrative.province",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "lightness": "0"
                        },
                        {
                            "saturation": "0"
                        },
                        {
                            "color": "#f5f5f2"
                        },
                        {
                            "gamma": "1"
                        }
                    ]
                },
                {
                    "featureType": "landscape.man_made",
                    "elementType": "all",
                    "stylers": [
                        {
                            "lightness": "-3"
                        },
                        {
                            "gamma": "1.00"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural.terrain",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#bae5ce"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fac9a9"
                        },
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "color": "#4e4e4e"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#787878"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.airport",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "hue": "#0a00ff"
                        },
                        {
                            "saturation": "-77"
                        },
                        {
                            "gamma": "0.57"
                        },
                        {
                            "lightness": "0"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.rail",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#43321e"
                        }
                    ]
                },
                {
                    "featureType": "transit.station.rail",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "hue": "#ff6c00"
                        },
                        {
                            "lightness": "4"
                        },
                        {
                            "gamma": "0.75"
                        },
                        {
                            "saturation": "-68"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#eaf6f8"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#c7eced"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "lightness": "-49"
                        },
                        {
                            "saturation": "-53"
                        },
                        {
                            "gamma": "0.79"
                        }
                    ]
                }
            ],
        }
    }

    const openCallbackModal = () => {
        props.toggleModal(true);
        props.toggleCallback(true);
    };

    const redirectToMapDirection = () => {
        window.location.href = `https://www.google.com/maps/dir/?api=1&destination=${currentLocation.location.lat},${currentLocation.location.lng}`;
    };

    return (
        <div className="contacts__map-wrap">
            <div id="map-branches" style={{height: '420px'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: 'AIzaSyC85SAhQ4B8eacPCfL6QUBLnf6KoRjtrBo'}}
                    center={{lat: 50.4501, lng: 30.5234}}
                    zoom={5.8}
                    options={getMapOptions}
                >
                    {
                        props.locations.branches.map((item, index) => {
                            return index === props.selectedLocation.index
                                ? <MapMarkerActive key={index} {...currentLocation.location} />
                                : <MapMarker key={index} {...item.location} />
                        })
                    }
                </GoogleMapReact>
            </div>

            <div className="contacts__mark-info">
                <a className="contacts__mark-phone" href={`tel:${currentLocation.phones[0]}`}>
                    {currentLocation.phones[0]}
                </a>

                <p className="contacts__mark-address">{currentLocation.address.title}</p>

                <div className="contacts__mark-info-box">
                    <p className="contacts__mark-title">{`${t('contactTab.graphic')}:`}</p>
                    <p className="contacts__mark-subtitle">{currentLocation.hours}</p>
                </div>

                <div className="contacts__mark-info-box">
                    <p className="contacts__mark-title">{t('contactTab.support')}</p>
                    <p className="contacts__mark-subtitle">
                        <a href="mailto:support@totalcontrol.ua?subject=Вопрос в техподдержку">support@totalcontrol.ua</a>
                    </p>
                </div>

                <button
                    className="contacts__mark-btn btn-secondary"
                    onClick={openCallbackModal}
                >
                    {t('contactTab.backCall')}
                </button>

                <button
                    className="contacts__mark-btn btn-ghost-secondary"
                    onClick={redirectToMapDirection}
                >
                    {t('contactTab.mapDirection')}
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleCallback,
};

export const ContactsMap = connect(null, mapDispatchToProps)(ContactsMapContainer);
