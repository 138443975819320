export const getSolution = type => {
    return  window.axios.get('', {
        params: {
            query: `
                query($type: String!) {
                    solution(type: $type) {
                        type
                        name
                        description
                        short_description
                        capabilities
                        opportunities {
                            title
                            icon
                            solution_for
                            description
                            products {
                                id
                                slug
                                name
                                short_description
                                bonus_points
                                images
                                final_price
                                category {
                                    slug
                                    name
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                type,
            },
        },
    }).then(response => response.data.data.solution)
        .catch(error => console.log(error));
};
