import {
    SET_CHECKOUT_ERRORS,
    SET_CHECKOUT_PERSONAL_INFORMATION,
    SET_DELIVERY_INFORMATION, SET_DELIVERY_REPEAT,
    SET_INSTALLATION_INFORMATION, SET_INSTALLATION_REPEAT, SET_PAYMENT_METHOD_REPEAT,
} from '../actionConsts/actionConsts';

const initialState = {
    personalInformation : {},
    delivery: {},
    installation: {},
    errors: {
        deliveryType: false,
        paymentMethod: false,
        personalInformation: false,
        deliveryInformation: false,
    },
    deliveryRepeat: {
        cityDelivery: '',
        warehouse: '',
        deliveryType: '',
        deliveryAddress: {
            street: '',
            building: '',
            flat: '',
        },
    },
    installationRepeat: {

    },
    paymentMethodRepeat: 0
};

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHECKOUT_PERSONAL_INFORMATION: {
            const contactDetails = Object
                .keys(action.payload)
                .reduce((info, key) => {
                    return {
                        ...info,
                        [key]: action.payload[key].value,
                    }
                }, {});
            return {
                ...state,
                personalInformation: {...contactDetails}
            }
        }
        case SET_DELIVERY_INFORMATION: {
            return {
                ...state,
                delivery: {...action.payload}
            }
        }
        case SET_INSTALLATION_INFORMATION: {
            return {
                ...state,
                installation: {...action.payload}
            }
        }
        case SET_CHECKOUT_ERRORS: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.field]: action.payload.value,
                }
            }
        }
        case SET_DELIVERY_REPEAT : {
            return {
                ...state,
                deliveryRepeat: action.payload,
            }
        }

        case SET_INSTALLATION_REPEAT : {
            return {
                ...state,
                installationRepeat: action.payload,
            }
        }

        case SET_PAYMENT_METHOD_REPEAT : {
            return {
                ...state,
                paymentMethodRepeat: action.payload,
            }
        }
        default:
            return state;
    }
};

export default checkoutReducer;
