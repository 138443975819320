import React from 'react';

import {OrderSolutionButton} from '../globalComponents/OrderSolutionButton';

export const IndustrySolutionHeader = props => {
    return (
        <section className="industry-solutions-header">
            <div className="industry-solutions-header__bg">
                <video
                    className="video-bg"
                    src={props.solution.video}
                    autoPlay
                    loop
                    muted
                    type="video/mp4"
                />
            </div>
            <div className="industry-solutions-header__container">
                <div className="industry-solutions-header__body">
                    <h1 className="industry-solutions-header__title">{`${props.title}:`}</h1>
                    <h2 className="industry-solutions-header__subtitle">{props.solution.name}</h2>
                    <h3 className="industry-solutions-header__paragraph">{props.solution.short_description}</h3>
                    <OrderSolutionButton solution={props.solution.name}/>
                </div>
            </div>
        </section>
    );
};
