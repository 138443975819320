import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {setInstallationInformation} from '../../redux/actionCreators/checkoutActions';
import {CalendarControl} from "../globalComponents/CalendarControl";
import {Input} from "../globalComponents/Input/Input";

const EquipmentInstallationFormContainer = props => {
    const [t] = useTranslation(['checkout', 'common']);

    const [equipmentInstallationOrder, setEquipmentInstallationOrder] = useState({
        city: '',
        address: '',
        date: new Date(),
        comment: '',
    });

    useEffect(() => {
        if(props.installationRepeat) {
            setEquipmentInstallationOrder(prevState =>({
                ...prevState,
                ...props.installationRepeat
            }));
        }
    }, []);

    useEffect(() => {
        props.setInstallationInformation(equipmentInstallationOrder);
    }, [equipmentInstallationOrder]);

    const changeEquipmentInstallation = event => {
        if (event instanceof Date) {
            setEquipmentInstallationOrder(prevState => ({
                ...prevState,
                date: event,
            }));

            return;
        }

        const {name, value} = event.target;

        setEquipmentInstallationOrder(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <>

            <Input
                value={equipmentInstallationOrder.city}
                name="city"
                handleChange={changeEquipmentInstallation}
                title={t('installation.city')}
            />

            <Input
                value={equipmentInstallationOrder.address}
                name="address"
                handleChange={changeEquipmentInstallation}
                title={t('installation.address')}
            />

            <div className="basket-form__calendar-section">
                <h5 className="basket-form__calendar-title">{t('installation.dataTitle')}</h5>
                <CalendarControl
                    value={equipmentInstallationOrder.date}
                    handleChange={changeEquipmentInstallation}
                />
            </div>

            <label className="textarea-wrap">
                <textarea
                    className="form__input form__textarea"
                    name="comment"
                    value={equipmentInstallationOrder.comment}
                    onChange={changeEquipmentInstallation}
                    cols="30" rows="4"
                />
                <span>{t('installation.comment')}</span>
            </label>
        </>
    );
};

const mapStateToProps = store => {
    return {
        installationRepeat: store.checkoutState.installationRepeat,
    };
};

const mapDispatchToProps = {
    setInstallationInformation,
};

export const EquipmentInstallationForm = connect(mapStateToProps, mapDispatchToProps)(EquipmentInstallationFormContainer);

