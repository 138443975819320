import React from 'react';
import {useTranslation} from 'react-i18next';

export const CartHeader = props => {
    const [t] = useTranslation('cart');

    return (
        <div className="basket-page__header">
            <div className="basket-page__header-container">
                <div className="basket-page__header-item">{t('quantity')}</div>
                <div className="basket-page__header-item">{t('price')}</div>
            </div>
        </div>
    );
};
