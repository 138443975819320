import React from 'react';
import {useTranslation} from 'react-i18next';
import {Input} from "../globalComponents/Input/Input";

export const AddressForm = props => {
    const [t] = useTranslation('common');

    return (
        <label className={`basket-form__address-delivery ${props.isActive ? 'active' : ''}`}>
            <label className="form__label street">
                <Input
                    name="street"
                    value={props.address.street}
                    handleChange={props.handleChange}
                    title={t('form.address.street')}
                />
            </label>

            <label className="form__label building">
                <Input
                    name="building"
                    value={props.address.building}
                    handleChange={props.handleChange}
                    title={t('form.address.building')}
                />
            </label>

            <label className="form__label apartment">
                <Input
                    name="flat"
                    value={props.address.flat}
                    handleChange={props.handleChange}
                    title={t('form.address.flat')}
                />
            </label>
        </label>
    );
};
