import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import useLocalizedPath from '../../../customHooks/useLocalizedPath';

import closeButton from '../../../images/close-btn.svg';
import {connect} from "react-redux";
import {toggleEmptyBasket, toggleModal} from "../../../redux/actionCreators/modalActions";

const EmptyBasketModalContainer = props => {
    const [t] = useTranslation('common');
    const localizedPathForRedirect = useLocalizedPath(`catalog/${props.currentCategory.slug}`);
    const routerHistory = useHistory();

    const moveToCatalog = () => {
        routerHistory.push(localizedPathForRedirect);
        closeModals();
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleEmptyBasket(false);
    };

    return (
        <div className={`empty-products ${props.emptyBasket ? 'active' : ''}`}>
            <div className="close-btn" onClick={closeModals}>
                <img src={closeButton} alt="close" />
            </div>
            <h4 className="empty-products__title">{t('emptyBasketModal.title')}</h4>
            <p className="empty-products__subtitle">{t('emptyBasketModal.emptyMessage')}</p>
            <p className="empty-products__descr">
                {t('emptyBasketModal.recommendation')}
            </p>
            <button className="empty-products__button btn-ghost-secondary" onClick={moveToCatalog}>{t('moveToCatalogButton')}</button>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        emptyBasket: state.modalsState.emptyBasket,
        currentCategory: state.catalogState.categoryDetails,
        language: state.globalReducer.language,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleEmptyBasket,
};

export const EmptyBasketModal = connect(mapStateToProps, mapDispatchToProps)(EmptyBasketModalContainer);
