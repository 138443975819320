import React from 'react';
import checkmark from '../../images/checkmark.svg';

export const CheckboxGroup = props => {
    return (
        <form className="filter__form">
            {
                props.specification.values.map(value => (
                    <label
                        key={value.name}
                        className="checkbox-wrap"
                    >
                        <input
                            type="checkbox"
                            name={value.name}
                            checked={props.selectedSpecifications ? props.selectedSpecifications[value.name] ? !!props.selectedSpecifications[value.name][value.value] : false : false}
                            onChange = {() => props.onChange(value.name, value.value)}
                        />

                        <span className="checkbox">
                            <img src={checkmark} alt="checkmark" />
                            <span>{value.title}</span>
                        </span>
                    </label>
                ))
            }
        </form>
    );
};
