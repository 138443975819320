import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {createDeal} from '../../API/birixApi/createDeal';
import {createOrder} from '../../API/requests';
import {paymentForm} from '../../API/requests/paymentForm';
import {_discount} from '../../globalConsts';
import {setCheckoutErrors, setCheckoutPersonalInformation} from '../../redux/actionCreators/checkoutActions';
import {toggleApplicationSuccess, toggleDealSuccess, toggleModal} from '../../redux/actionCreators/modalActions';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';

import './Checkout.scss';

import {CheckoutEntity} from './CheckoutEntity';
import {CheckoutIndividual} from './CheckoutIndividual';
import {CheckoutOrder} from './CheckoutOrder';

const deliveryPrice = 100;

const CheckoutContainer = props => {
    const [t] = useTranslation('checkout');
    const wayForPayButtonRef = useRef(false);

    const [discountAmount, setDiscountAmount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalBonuses, setTotalBonuses] = useState(0);
    const [activeTypeOfUser, setActiveTypeOfUser] = useState('individual');

    const [isCommentInputActive, setIsCommentInputActive] = useState(false);
    const [comment, setComment] = useState('');
    const [isEquipmentInstallationOrdered, setIsEquipmentInstallationOrdered] = useState(false);

    const toggleOrderOfEquipmentInstallation = () => {
        setIsEquipmentInstallationOrdered(prevState => !prevState);
    };

    const [wayForPay, setWayForPay] = useState('payment_on_delivery');
    const [callToConfirm, setCallToConfirm] = useState(true);

    useEffect(() => {
        setWayForPay(props.checkout.paymentMethodRepeat);
        if (props.checkout.installationRepeat) {
            setIsEquipmentInstallationOrdered(Object.keys(props.checkout.installationRepeat).length > 0);
        }
    }, []);

    useEffect(() => {
        if (props.isLogged) {
            props.user.is_organization
                ? setActiveTypeOfUser('entity')
                : setActiveTypeOfUser('individual')
        }
    }, [props.user, props.isLogged]);

    useEffect(() => {
        let totalProductsPrice = props.basketItems.reduce((cost, currentItem) => {
            return cost + currentItem.quantity * currentItem.final_price;
        }, 0);

        const totalProductsBonuses = props.basketItems.reduce((bonuses, currentItem) => {
            return bonuses + currentItem.quantity * currentItem.bonus_points;
        }, 0);

        setDiscountAmount(totalProductsPrice * _discount);
        totalProductsPrice = totalProductsPrice * (1 - _discount);

        if (props.checkout.delivery.deliveryType === 'delivery_address') {
            totalProductsPrice += 100;
        }

        setTotalPrice(totalProductsPrice);
        setTotalBonuses(totalProductsBonuses);
    }, [props.basketItems, props.checkout.delivery.deliveryType]);

    const openSuccessModal = () => {
        props.toggleModal(true);
        props.toggleDealSuccess({
            isActive: true,
            isCallbackRequested: callToConfirm,
        });
    };

    const validateCheckout = () => {
        let isCheckoutValid = true;
        const deliveryType = props.checkout.delivery.deliveryType;

        if (!deliveryType) {
            isCheckoutValid = false;
            props.setCheckoutErrors({
                field: 'deliveryType',
                value: true,
            });
        }

        const personalInformation = props.checkout.personalInformation;
        let isPersonalInformationValid = true;

        Object.keys(personalInformation).forEach(key => {
            if (!personalInformation[key].trim()) {
                isPersonalInformationValid = false;
            }
        });

        if (!isPersonalInformationValid) {
            isCheckoutValid = false;
            props.setCheckoutErrors({
                field: 'personalInformation',
                value: true,
            });
        }

        if (deliveryType === 'delivery_new_post_office') {
            if (!props.checkout.delivery.cityDelivery.trim() || !props.checkout.delivery.warehouse.trim()) {
                isCheckoutValid = false;
                props.setCheckoutErrors({
                    field: 'deliveryInformation',
                    value: true,
                });
            }
        }

        if (deliveryType === 'delivery_new_post_courier' || deliveryType === 'delivery_address') {
            if (!props.checkout.delivery.deliveryAddress.street.trim() || !props.checkout.delivery.deliveryAddress.building.trim() || !props.checkout.delivery.cityDelivery.trim()) {
                isCheckoutValid = false;
                props.setCheckoutErrors({
                    field: 'deliveryInformation',
                    value: true,
                });
            }
        }

        return isCheckoutValid;
    };

    const submitOrder = async () => {
        const isCheckoutValid = validateCheckout();

        if (isCheckoutValid) {
            let deliveryMethod;
            let shipTo;
            switch (props.checkout.delivery.deliveryType) {
                case 'delivery_pickup': {
                    deliveryMethod = 0;
                    shipTo = null;
                    break;
                }
                case 'delivery_new_post_office': {
                    deliveryMethod = 1;
                    shipTo = {
                        city: props.checkout.delivery.cityDelivery,
                        new_post_office: props.checkout.delivery.warehouse,
                    }
                    break;
                }
                case 'delivery_new_post_courier': {
                    deliveryMethod = 2;
                    shipTo = {
                        city: props.checkout.delivery.cityDelivery,
                        ...props.checkout.delivery.deliveryAddress,
                    }
                    break;
                }
                case 'delivery_address': {
                    deliveryMethod = 3;
                    shipTo = {
                        city: props.checkout.delivery.cityDelivery,
                        ...props.checkout.delivery.deliveryAddress,
                    }
                    break;
                }
                default:
                    deliveryMethod = 0
            }

            let paymentMethod;
            switch (wayForPay) {
                case 'payment_on_delivery': {
                    paymentMethod = 0;
                    break;
                }
                case 'payment_by_card' : {
                    paymentMethod = 1;
                    break;
                }
                case 'payment_by_invoice' : {
                    break;
                }
                default:
                    paymentMethod = 0
            }

            const orderInformation = {
                delivery_method: deliveryMethod,
                payment_method: paymentMethod,
                back_call: callToConfirm,
                delivery_price: deliveryMethod === 3 ? deliveryPrice : 0,
                order_items: props.basketItems.map(product => ({
                    quantity: product.quantity,
                    product_slug: product.slug,
                })),
                bill_to: props.checkout.personalInformation,
                ship_to: shipTo,
                installation: isEquipmentInstallationOrdered
                    ? {
                        ...props.checkout.installation,
                        date: moment(props.checkout.installation.date).format('DD.MM.YYYY'),
                    } : null,
                comment: isCommentInputActive ? comment : null,
            };

            const leadId = await createLead(orderInformation);


            const payment_data = {
                'action': 'pay',
                'amount': totalPrice,
                'currency': 'UAH',
                'description': 'Оплатіть',
                'order_id': leadId,
                'version': '3',
                'language': `${props.language.key}`,
                'result_url': `https://totalcontrol.ua?order_id=${leadId}`,
                'server_url': `https://adminka.totalcontrol.ua/api/orders/${leadId}/callback`,
            };
            orderInformation.external_order_id = `${leadId}`;
            orderInformation.payment_payload = {...payment_data};

            const orderCreationResult = await createOrder(orderInformation);
            const orderId = orderCreationResult.data[0].data.storeOrder.id;

            if (paymentMethod === 1) {
                wayForPayButtonRef.current.innerHTML  = await paymentForm(orderId);

                wayForPayButtonRef.current.firstChild.submit();
            } else openSuccessModal();
        }
    };

    const createLead = information => {
        let deliveryMethod;
        let paymentMethod;
        switch (information.delivery_method) {
            case 0:
                deliveryMethod = 'Самовивіз із офісу';
                break;
            case 1:
                deliveryMethod = '«Нова пошта» до відділення';
                break;
            case 2:
                deliveryMethod = 'Кур\'єрська доставка «Нова пошта»';
                break;
            case 3:
                deliveryMethod = 'Адресна доставка';
                break;
        }
        switch (information.payment_method) {
            case 0:
                paymentMethod = 'Замовити рахунок';
                break;
            case 1:
                paymentMethod = 'Карта Visa і MasterCard (WAYFORPAY)';
                break;
            case 2:
                paymentMethod = 'Рахунок-фактура';
                break;
        }
        const informationForDeal = {
            deliveryMethod,
            paymentMethod,
            comment,
            installation: information.installation,
            deliveryAddress: information.ship_to,
            backCall: information.back_call,
        };

        return createDeal(
            props.basketItems,
            totalPrice,
            props.checkout.personalInformation,
            informationForDeal,
            props.isLogged,
            props.user,
            activeTypeOfUser,
        ).catch(error => console.log(error));
    };

    const changeWayForPay = event => {
        setWayForPay(event.target.value);
    };

    const changeActiveTypeOfUser = userType => {
        setActiveTypeOfUser(userType);
        setIsEquipmentInstallationOrdered(false);
    };

    const changeCallToConfirm = () => {
        setCallToConfirm(prevState => !prevState)
    };

    return (
        <div className="container">
            <MetaTagsComponent
                title={t('title')}
                seoTitle={t('title')}
                seoDescription={t('title')}
            />

            <div ref={wayForPayButtonRef}/>

            <div className="basket">
                <div className="basket__body">
                    <div className="basket-form-wrap">
                        <div className="basket-form__header">
                            <h2 className="basket-form__title">{t('title')}</h2>
                            {
                                props.isLogged
                                    ? (
                                        (activeTypeOfUser === 'individual') ? (
                                            <div className="user-type-selector">
                                                <button
                                                    className={`user-type-selector__btn ${activeTypeOfUser === 'individual' ? 'active' : ''}`}
                                                    onClick={() => changeActiveTypeOfUser('individual')}
                                                >
                                                    {t('userIndividual')}
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="user-type-selector">
                                                <button
                                                    className={`user-type-selector__btn ${activeTypeOfUser === 'entity' ? 'active' : ''}`}
                                                    onClick={() => changeActiveTypeOfUser('entity')}
                                                >
                                                    {t('userEntity')}
                                                </button>
                                            </div>
                                        )
                                    ) : (
                                        <div className="user-type-selector">
                                            <button
                                                className={`user-type-selector__btn ${activeTypeOfUser === 'individual' ? 'active' : ''}`}
                                                onClick={() => changeActiveTypeOfUser('individual')}
                                            >
                                                {t('userIndividual')}
                                            </button>

                                            <button
                                                className={`user-type-selector__btn ${activeTypeOfUser === 'entity' ? 'active' : ''}`}
                                                onClick={() => changeActiveTypeOfUser('entity')}
                                            >
                                                {t('userEntity')}
                                            </button>
                                        </div>
                                    )
                            }
                        </div>

                        <div className="basket-form-container-wrap">
                            <div className="basket-form-container">
                                <section className={`basket-form ${activeTypeOfUser === 'entity' ? 'active' : ''}`}>
                                    <CheckoutIndividual
                                        isActive={activeTypeOfUser === 'individual'}
                                        handleFunctions={{
                                            changeWayForPay,
                                            changeCallToConfirm,
                                            toggleOrderOfEquipmentInstallation,
                                            setIsCommentInputActive,
                                            setComment,
                                        }}
                                        values={{
                                            wayForPay,
                                            callToConfirm,
                                            isEquipmentInstallationOrdered,
                                            comment,
                                            isCommentInputActive,
                                        }}
                                    />

                                    <CheckoutEntity
                                        isActive={activeTypeOfUser === 'entity'}
                                        handleFunctions={{
                                            changeWayForPay,
                                            changeCallToConfirm,
                                            toggleOrderOfEquipmentInstallation,
                                            setIsCommentInputActive,
                                            setComment,
                                        }}
                                        values={{
                                            wayForPay,
                                            callToConfirm,
                                            isEquipmentInstallationOrdered,
                                            comment,
                                            isCommentInputActive,
                                        }}
                                    />
                                </section>
                            </div>
                        </div>
                    </div>

                    <div className="basket-container">
                        <CheckoutOrder
                            totalPrice={totalPrice}
                            submitOrder={submitOrder}
                            totalBonuses={totalBonuses}
                            discountAmount={discountAmount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


const mapStateToProps = store => {
        return {
            basketItems: store.basketState.basketItems,
            isUserLogged: store.userAccountState.isLogged,
            user: store.userAccountState.user,
            isLogged: store.userAccountState.isLogged,
            checkout: store.checkoutState,
            language: store.globalReducer.language,
            googleId: store.globalReducer.googleId,
            utmParams: store.globalReducer.utmParams,
        };
    }
;

const mapDispatchToProps =
    {
        setCheckoutPersonalInformation,
        setCheckoutErrors,
        toggleModal,
        toggleApplicationSuccess,
        toggleDealSuccess,
    }
;

export const Checkout = connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);
