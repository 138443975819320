export const getProductBySlug = async productSlug => {
    return window.axios.get('', {
        params: {
            query: `
                query ($slug: String!) {
                    product(slug: $slug) {
                        id
                        name
                        slug
                        images
                        short_description
                        advantages
                        price
                        discount_percent
                        final_price
                        bonus_points
                        description
                        description_secondary
                        solutions_for                           
                        specifications
                        documentation
                        seo_keywords
                        seo_description
                        sets {
                            name
                            id
                            price
                            final_price
                            discount
                            separated_price
                            products {
                                id
                                name
                                final_price
                                slug
                                images
                            }
                        }
                        recommended_products {
                            id
                            name
                            images
                            slug
                            final_price
                            short_description
                            category {
                                slug
                                name
                            }
                        }
                        alternative_products {
                            id
                            name
                            images
                            slug
                            final_price
                            short_description
                            category {
                                slug
                                name
                            }
                        }
                        category {
                            slug
                            name
                        }
                    }
                }
            `,
            variables: {
                slug: productSlug,
            },
        },
    }).then(response => response.data.data.product)
        .catch(error => console.log(error));
};
