import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {_api} from '../../../../API/apiConsts';
import useLocalizedPath from '../../../../customHooks/useLocalizedPath';
import checkmark from '../../../../images/checkmark.svg';
import {loginUser} from '../../../../API/requests';
import {getUserInformation} from '../../../../redux/actionCreators/getActionCreators/userAccountActions';
import {toggleUserLogin} from '../../../../redux/actionCreators/userActions';
import {Input} from '../../Input/Input';



const EntryModalLoginContainer = props => {
    const [t] = useTranslation('common');
    const localizedUserPath = useLocalizedPath('user');

    const form = t('form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;

    const formFields = [
        {
            name: 'email',
            title: form.email,
            type: 'email',
            errorMessages: [],
        },
        {
            name: 'password',
            title: form.password,
            type: 'password',
            errorMessages: [],
        },
    ];

    const routerHistory = useHistory();
    const [isLoginErrorOccurred, setIsLoginErrorOccurred] = useState(false);
    const [loginFormValues, setLoginFormValues] = useState({
        email: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage: errorMessage,
        },
        password: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage: errorMessage,
        },
    });
    const [rememberMe, setRememberMe] = useState(false);

    const handleLoginFormChange = event => {
        const {name, value} = event.target;

        setLoginFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value,
                error: false,
            },
        }));
    };

    const handleLoginSubmit = event => {
        event.preventDefault();

        let isFormValid = true;

        Object.keys(loginFormValues).forEach(field => {
            if(!validateLoginField(field, loginFormValues[field].value)) {
                isFormValid = false;
            }
        });

        if(isFormValid) {
            const loginInfo = {
                email: loginFormValues.email.value,
                password: loginFormValues.password.value,
            };

            loginUser(loginInfo).then(login => {
                if(login) {
                    localStorage.setItem('token', login.token);

                    window.axios.defaults.baseURL = _api
                    window.axios.defaults.headers.Authorization = `Bearer ${login.token}`

                    props.toggleUserLogin(true);

                    props.closeModals();
                    routerHistory.push(localizedUserPath);
                } else {
                    setIsLoginErrorOccurred(true);
                }
            }).catch(error => console.log(error));
        }
    };

    const validateLoginField = (name, value) => {
        setLoginFormValues(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                wasTouched: true,
            },
        }));

        if(!value.trim()) {
            setLoginFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    error: true,
                },
            }));

            return false;
        }

        return true;
    };

    const changeRememberMe = () => {
        setRememberMe(prevState => !prevState);
    };

    return (
        <div className="enter-modal__body enter-modal__body--enter">
            <form className="enter-modal__form" onSubmit={handleLoginSubmit}>
                {
                    formFields.map(field => (
                        <Input
                            key={field.name}
                            className={`form__input ${loginFormValues[field.name].error ? 'invalid' : ''} ${!loginFormValues[field.name].error && loginFormValues[field.name].wasTouched ? 'valid' : ''}`}
                            type={field.type}
                            name={field.name}
                            placeholder=" "
                            required
                            value={loginFormValues[field.name].value}
                            handleChange={event => {
                                handleLoginFormChange(event);
                                validateLoginField(event.target.name, event.target.value);
                            }}
                            handleBlur={event => validateLoginField(event.target.name, event.target.value)}
                            title={field.title}
                            isErrorOccurred={loginFormValues[field.name].error && loginFormValues[field.name].wasTouched}
                            errorMessage={loginFormValues[field.name].errorMessage}
                        />
                    ))
                }

                <p className={`enter-modal__login-error ${isLoginErrorOccurred ? 'active' : ''}`}>{form.wrongPassOrLogin}</p>

                <label className="checkbox-wrap">
                    <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={changeRememberMe}
                    />
                    <span className="checkbox">
                        <img src={checkmark} alt="checkmark" />
                        <span>{form.rememberMe}</span>
                    </span>
                </label>

                <div className="enter-modal__footer">
                    <button className="enter-modal__footer-btn btn-secondary" type="submit">{form.loginButton}</button>
                    <p className="enter-modal__forgot-password" onClick={props.openPasswordRecoveryModal}>{form.forgotPasswordQuestion}</p>
                </div>
            </form>


        </div>
    );
};

const mapDispatchToProps = {
    toggleUserLogin,
    getUserInformation,
};

export const EntryModalLogin = connect(null, mapDispatchToProps)(EntryModalLoginContainer);
