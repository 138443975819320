import {parseProductEntity} from '../../utils/parsers/parsers';
import {GET_PRODUCT} from "../actionConsts/actionConsts";

const initialProduct = {
    product: {
        final_price: '',
        id: 0,
        name: '',
        short_description: '',
        price: 0,
        discount: 0,
        bonus_points: 0,
        images: [],
        advantages: [
            {
                icon: '',
                text: '',
            },
        ],
        implementations: [
            {
                title: '',
                image: '',
            },
            {
                title: '',
                image: '',
            },
            {
                title: '',
                image: '',
            },
        ],
        recommended_products: [],
        alternative_products: [],
        sets: [
            {
                separated_price: '',
                final_price: '',
                products: [
                    {
                        final_price: '',
                        images: [],
                    },
                    {
                        images: [],
                        final_price: '',
                    }
                ],
            }
        ],

        description: '',
        description_secondary: '',
        solutionsFor: [
        ],

        specifications: {
        },

        documentation: [
            {
                file: '',
                text: ''
            },

        ],

    }
}

const productItemReducer = (state = initialProduct, action) => {
    switch(action.type) {
        case GET_PRODUCT: {
            return {
                product: parseProductEntity(action.payload),
            };
        }
        default:
            return state;
    }
}

export default productItemReducer;
