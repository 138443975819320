export const getPosts = (
    topic,
    section,
    activePage,
    perPage = 12,
) => {
    return window.axios.get('', {
        params: {
            query: `
                query(
                    $pagination: PaginationInput
                    $section: Int
                    $topic: Int
                ) {
                    posts(
                        pagination: $pagination
                        section: $section
                        topic: $topic
                    ) {
                        data {
                            id
                            title
                            image
                            short_description
                            section {
                                name
                            }
                            topic {
                                name
                            }
                        }
                        last_page
                    }
                    postFilters
                }
            `,
            variables: {
                topic,
                section,
                pagination: {
                    page: activePage,
                    per_page: perPage,
                }
            },
        },
    }).then(response => response.data.data)
        .catch(error => console.log(error))
};
