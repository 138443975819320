import React from 'react';

import './Pagination.scss';

export const Pagination = props => {
    return (
        <div className="pagination-wrap">
            <div className="pagination">
                <div className="pagination__arrow pagination__arrow--left" onClick={() => props.changeActivePage('prev')}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M14.41 16L19.7 21.29C19.89 21.47 20 21.72 20 22C20 22.55 19.55 23 19 23C18.72 23 18.47 22.89 18.29 22.71L12.29 16.71C12.11 16.53 12 16.28 12 16C12 15.72 12.11 15.47 12.29 15.29L18.29 9.29C18.47 9.11 18.72 9 19 9C19.55 9 20 9.45 20 10C20 10.28 19.89 10.53 19.71 10.71L14.41 16Z"
                              fill="#2C599D"
                        />
                    </svg>
                </div>

                <div className="pagination__items">
                    {Array(props.numberOfPages).fill(1).map((page, index) => (
                        <div
                            key={index}
                            className={`pagination__item ${index + 1 === props.activePage ? 'active' : ''}`}
                            onClick={() => props.changeActivePage(index + 1)}
                        >
                            {index + 1}
                        </div>
                    ))}
                </div>

                <div className="pagination__arrow pagination__arrow--right" onClick={() => props.changeActivePage('next')}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M17.59 16L12.3 21.29C12.11 21.47 12 21.72 12 22C12 22.55 12.45 23 13 23C13.28 23 13.53 22.89 13.71 22.71L19.71 16.71C19.89 16.53 20 16.28 20 16C20 15.72 19.89 15.47 19.71 15.29L13.71 9.29C13.53 9.11 13.28 9 13 9C12.45 9 12 9.45 12 10C12 10.28 12.11 10.53 12.29 10.71L17.59 16Z"
                              fill="#2C599D"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
}