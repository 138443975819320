import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import {toggleModal, toggleQuestion} from "../../redux/actionCreators/modalActions";
import LocalizedLink from '../globalComponents/LocalizedLink';

const ProductBuyInfoContainer = props => {
    const [t] = useTranslation(['productCard', 'common']);
    const buyInformation = t('buyInformation', {returnObjects: true});
    const payOptions = t('payOptions', {returnObjects: true});
    const warranty = t('warranty', {returnObjects: true});
    const tariff = t('tariffInformation', {returnObjects: true});

    return (
        <div className="product__buy-info">
            <div className="buy-info__section">
                <h5 className="buy-info__title">{tariff.title}</h5>
                <div className="buy-info__subtitle">
                    {
                        props.productSlug === '115-personalnii-treker-bi-310-hw2-trek' || props.productSlug === '112-personalnii-treker-scontrol'
                            ? tariff.partialSubtitle
                            : tariff.subtitle
                    }
                </div>

                <LocalizedLink
                    to={`/tariffs`}
                    target={"_blank"}
                    className="buy-info__link"
                >
                    {tariff.linkTitle}
                    </LocalizedLink>
            </div>

            <div className="buy-info__section">
                <h5 className="buy-info__title">{buyInformation.title}</h5>
                <div className="buy-info__row">
                    <div className="buy-info__row-item">{buyInformation.delivery.title}</div>
                    <div className="buy-info__row-item">{buyInformation.delivery.time}</div>
                    <div className="buy-info__row-item">{buyInformation.delivery.price}</div>
                </div>

                <div className="buy-info__row">
                    <div className="buy-info__row-item">{buyInformation.installation.title}</div>
                    <div className="buy-info__row-item">{buyInformation.installation.time}</div>
                    <div className="buy-info__row-item">
                        {
                            props.productSlug === '115-personalnii-treker-bi-310-hw2-trek' || props.productSlug === '112-personalnii-treker-scontrol'
                                ? buyInformation.installation.partialPrice
                                : buyInformation.installation.price
                        }
                    </div>
                </div>
            </div>

            <div className="buy-info__section">
                <h5 className="buy-info__title">{payOptions.title}</h5>
                <div className="buy-info__subtitle">{payOptions.options}</div>
            </div>

            <div className="buy-info__section">
                <div className="buy-info__subtitle">{warranty.title} <span>{warranty.time}</span></div>
                <div className="buy-info__subtitle">
                    {warranty.goodsReturn} <span>{warranty.returnTime}</span>
                </div>

                <button
                    className="buy-info__question-btn btn-ghost-secondary question"
                    onClick={
                        () => {
                            props.toggleModal(true);
                            props.toggleQuestion(true);
                        }
                    }
                >
                    {t('common:questionRequest')}
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleQuestion,
};

export const ProductBuyInfo = connect(null, mapDispatchToProps)(ProductBuyInfoContainer);
