import {parseCaseEntities} from '../../utils/parsers/parsers';
import {GET_CASES} from "../actionConsts/actionConsts";

const initialCases = {
    cases: [
        {
            id: 1,
            title: '',
            short_description: '',
            image: '',
        },
    ],
};

const casesReducer = (state = initialCases, action) => {
    switch (action.type) {
        case GET_CASES: {
            const currentCases = action.payload || [];

            return {
                ...state,
                cases: parseCaseEntities(currentCases),
            };
        }
        default:
            return state;
    }
};

export default casesReducer;
