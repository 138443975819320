import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  toggleCitySelect,
  toggleModal,
} from "../../../redux/actionCreators/modalActions";
import { changeUserLocation } from "../../../redux/actionCreators/globalActions";
import axios from "axios";

const CitySelectContainer = (props) => {
  const [t] = useTranslation("common");
  const listOfCities = t("citySelectModal.listOfCities", {
    returnObjects: true,
  });

  const closeModals = () => {
    props.toggleModal(false);
    props.toggleCitySelect(false);
  };

  const globalCity = [
    [
      listOfCities.Kyiv,
      listOfCities.Vinnytsia,
      listOfCities.Dnipro,
      listOfCities.Zaporizhzhia,
      listOfCities.Alexandria,
    ],
    [
      listOfCities.IvanoFrankivsk,
      listOfCities.Chernihiv,
      listOfCities.Lviv,
      listOfCities.Odessa,
      listOfCities.Poltava,
    ],
    [
      listOfCities.Zhytomyr,
      listOfCities.Mykolaiv,
      listOfCities.Sumy,
      listOfCities.Kharkiv,
      listOfCities.Chernivtsi,
    ],
  ];

  const [city, setCity] = useState();
  const [cityList, setCityList] = useState([]);
  const [activeList, setActiveList] = useState(false);

  useEffect(() => {
    axios
      .post("https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/", {
        apiKey: "4ef01d3b8cb8b07bb46b5068c4239ca2",
        modelName: "Address",
        calledMethod: "searchSettlements",
        methodProperties: {
          CityName: city,
          Limit: 10,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          setCityList(
            res.data.data[0].Addresses ? res.data.data[0].Addresses : []
          );
        }
      });
  }, [city]);

  return (
    <div
      className={`header-top__location-dropdown modal-location ${
        props.citySelect ? "active" : ""
      }`}
    >
      <div
        className="header-top__modal-close modal__close"
        onClick={closeModals}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L13.5858 15L8.29289 20.2929C7.90237 20.6834 7.90237 21.3166 8.29289 21.7071C8.68342 22.0976 9.31658 22.0976 9.70711 21.7071L15 16.4142L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.4142 15L21.7071 9.70711C22.0976 9.31658 22.0976 8.68342 21.7071 8.29289C21.3166 7.90237 20.6834 7.90237 20.2929 8.29289L15 13.5858L9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289Z"
            fill="#8D8D8D"
          />
          <mask
            id="mask0"
            maskUnits="userSpaceOnUse"
            x="8"
            y="8"
            width="14"
            height="14"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L13.5858 15L8.29289 20.2929C7.90237 20.6834 7.90237 21.3166 8.29289 21.7071C8.68342 22.0976 9.31658 22.0976 9.70711 21.7071L15 16.4142L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.4142 15L21.7071 9.70711C22.0976 9.31658 22.0976 8.68342 21.7071 8.29289C21.3166 7.90237 20.6834 7.90237 20.2929 8.29289L15 13.5858L9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect width="30" height="30" fill="#8D8D8D" />
          </g>
        </svg>
      </div>
      <div className="header-top__label">
        {t("citySelectModal.title")}:
        <input
          className="header-top__input"
          type="text"
          placeholder={t("citySelectModal.cityInputPlaceholder")}
          value={city}
          onClick={() => setActiveList(true)}
          onChange={(e) => {
            setCity(e.target.value);
          }}
        />
      </div>
      {cityList.length > 0 ? (
        <div
          className={`cityListWrapper ${activeList ? "active" : ""}`}
          onFocus={() => setActiveList(true)}
        >
          <ul className="cityList">
            {cityList.map((data, i) => {
              return (
                <li
                  className="cityListItem"
                  key={i}
                  onClick={() => {
                    props.changeUserLocation(data.MainDescription);
                    localStorage.setItem(
                      "userLocation",
                      JSON.stringify(data.MainDescription)
                    );
                    setCity(data.MainDescription);
                    setActiveList(false);
                    closeModals();
                  }}
                >
                  {data.Present}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
      <div className="header-top__city-list">
        {globalCity.map((data, i) => {
          return (
            <ul className="header-top__city-column" key={i}>
              {data.map((item, i) => {
                return (
                  <li
                    className="header-top__city-item"
                    key={i}
                    onClick={() => {
                      props.changeUserLocation(item);
                      localStorage.setItem(
                        "userLocation",
                        JSON.stringify(item)
                      );
                      closeModals();
                    }}
                  >
                    {item}
                  </li>
                );
              })}
            </ul>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    citySelect: state.modalsState.citySelect,
    userLocation: state.globalReducer.userLocation,
  };
};

const mapDispatchToProps = {
  toggleModal,
  toggleCitySelect,
  changeUserLocation,
};

export const CitySelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CitySelectContainer);
