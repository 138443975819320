import React from 'react';
import {connect} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import {_language} from '../../globalConsts';

const LocalizedLink = ({language, to, type, children, className, onClick = () => {}}) => {
    return (
        <>
            {
                type === 'nav_link'
                    ? (
                        <NavLink
                            exact
                            activeClassName="active"
                            className={className}
                            to={language.key === _language.key ? to : `/${language.key}${to}`}
                            onClick={onClick}
                        >
                            {children}
                        </NavLink>
                    )
                    : (
                        <Link
                            to={language.key === _language.key ? to : `/${language.key}${to}`}
                            className={className}
                            onClick={onClick}
                        >
                            {children}
                        </Link>
                    )
            }
        </>
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
    };
};

export default connect(mapStateToProps)(LocalizedLink);
