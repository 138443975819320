import React from 'react';

import Slider from 'react-slick';
import capabilityBg from '../../../images/transport-solutions/capabilities-bg.webp';
import {CapabilitiesSliderNextArrow} from "./CapabilitiesSliderNextArrow";
import {CapabilitiesSliderPrevArrow} from "./CapabilitiesSliderPrevArrow";

export const CapabilitiesSlider = props => {
    const settings = {
        className: 'capabilities__tabs',
        lazyLoad: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: true,
        dots: false,
        waitForAnimate: true,
        adaptiveHeight: true,
        prevArrow: <CapabilitiesSliderPrevArrow />,
        nextArrow: <CapabilitiesSliderNextArrow />,
        responsive: [
            {
                breakpoint: 1620,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1285,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 880,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 540,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    dots: true,
                    dotsClass: 'slider-dots'
                }
            },
        ]
    };

    return (
        <section className="capabilities">
            <div className="capabilities__bg">
                <img src={props.backgroundImage ? props.backgroundImage : capabilityBg} alt="capability" />
            </div>
            <div className="capabilities__container">
                <h3 className="capabilities__title">{props.title ? props.title : 'Возможности и преимущества решения'}</h3>
                {
                    (props.capabilities) && (
                        <div className="capabilities__tabs-wrap">
                            <Slider {...settings}>
                                {
                                    props.capabilities.map((capability, index) =>  (
                                        <div key={index} className="capabilities__tab" >
                                            <div className="capabilities__tab-ico-border">
                                                <div className="capabilities__tab-ico-wrap">
                                                    <img src={capability.icon} alt={capability.description} />
                                                </div>
                                            </div>
                                            <p className="capabilities__tab-subtitle">{capability.description}</p>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                    )
                }
            </div>
        </section>
    );
};
