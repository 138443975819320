import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from "react-redux";
import useLocalizedPath from '../../../../customHooks/useLocalizedPath';

import closeBtn from '../../../../images/close-btn.svg';
import {toggleBasket, toggleModal} from "../../../../redux/actionCreators/modalActions";
import {
    decreaseProductQuantity,
    deleteProductFromBasket,
    increaseProductQuantity,
} from "../../../../redux/actionCreators/basketActions";
import {useHistory} from 'react-router-dom';
import LocalizedLink from '../../LocalizedLink';

export const BasketModalContainer = props => {
    const [t] = useTranslation('common');
    const localizedOrderPath = useLocalizedPath('order');

    const [totalPrice, setTotalPrice] = useState(0);
    const [totalBonuses, setTotalBonuses] = useState(0);
    const history = useHistory()

    useEffect(() => {
        countTotalCost();
    }, [props.basketItems])

    const countTotalCost = () => {
        const totalProductsCost = props.basketItems.reduce((cost, currentItem) => {
            return cost + currentItem.quantity * currentItem.final_price;
        }, 0);

        const totalProductsBonuses = props.basketItems.reduce((bonuses, currentItem) => {
            return bonuses + currentItem.quantity * currentItem.bonus_points;
        }, 0);

        setTotalPrice(totalProductsCost);
        setTotalBonuses(totalProductsBonuses);
    };

    const closeModals = () => {
        props.toggleModal(false);
        props.toggleBasket(false);
    };

    const increaseQuantity = itemId => {
        props.increaseProductQuantity(itemId);
    };

    const decreaseQuantity = itemId => {
        props.decreaseProductQuantity(itemId);
    };

    const deleteProduct = itemId => {
        props.deleteProductFromBasket(itemId);
    };

    return(
        <div className={`basket-modal ${props.basketModal ? 'active' : ''}`}>
            <div className="modal__close" onClick={closeModals}>
                <img src={closeBtn} alt="close" />
            </div>
            <div className="basket-modal__body">
                <h4 className="basket-modal__title">{t('basketModal.message')}</h4>
                <div className="basket-modal__products">
                    {
                        props.basketItems.map(product => (
                            <div key={product.id} className="basket-modal__product">
                                <div className="basket-modal__img">
                                    <img src={product.images[0]} alt={product.name} />
                                </div>

                                <div className="basket-modal__left-section">
                                    <LocalizedLink
                                        to={`/product/${product.slug}`}
                                        className="basket-modal__name"
                                        onClick={closeModals}
                                    >
                                        {product.name}
                                    </LocalizedLink>

                                    <div className="basket-modal-counter">
                                        <div
                                            className="basket-modal-counter__dec"
                                            onClick={() => decreaseQuantity(product.id)}
                                        >
                                            -
                                        </div>

                                        <div className="basket-counter__quantity">
                                            {product.quantity}
                                        </div>

                                        <div
                                            className="basket-modal-counter__inc"
                                            onClick={() => increaseQuantity(product.id)}
                                        >
                                            +
                                        </div>
                                    </div>
                                </div>

                                <div className="basket-modal__right-section">
                                    <div className="basket-modal__price">{`${(product.final_price * product.quantity).toLocaleString()} грн`}</div>
                                    <div
                                        className="close-btn"
                                         onClick={() => {
                                             deleteProduct(product.id)
                                             if(props.basketItems.length <= 1) {
                                                 localStorage.setItem('basket', JSON.stringify([]))
                                             }
                                         }}
                                    >
                                        <img
                                            src={closeBtn}
                                            alt="delete"
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="basket-modal__footer">
                    <div className="basket-modal__footer-top">
                        <div className="basket-modal__footer-bonus">
                            {/*{t('basketModal.bonusPointsMessage')} <a className="basket-modal__footer-bonus-val" href="/">{`+${totalBonuses} ${t('basketModal.points')}`}</a>*/}
                        </div>

                        <div className="basket-modal__footer-sum">
                            {`${t('basketModal.total')}:`}<span className="basket-modal__footer-price">{`${totalPrice.toLocaleString()} грн`}</span>
                        </div>
                    </div>
                    <div className="basket-modal__footer-bottom">
                        <button
                            className="basket-modal__continue-btn btn-ghost-secondary"
                            onClick={closeModals}
                        >
                            {t('basketModal.continueButton')}
                        </button>

                        <button
                            className="basket-modal__confirm-btn btn-secondary"
                            onClick={() => {
                                history.push(localizedOrderPath);
                                closeModals();
                            }}
                        >
                            {t('basketModal.checkoutButton')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        basketItems: state.basketState.basketItems,
        basketModal: state.modalsState.basket,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleBasket,
    deleteProductFromBasket,
    increaseProductQuantity,
    decreaseProductQuantity,
};

export const BasketModal = connect(mapStateToProps, mapDispatchToProps)(BasketModalContainer);
