import React from 'react';

export const Input = props => {
    return (
        <label className="form__label">
            {/*{*/}
            {/*    (props.name === 'service') && (<span className="label-tooltip">Введіть</span>)*/}
            {/*}*/}
            <input
                className={props.className ? props.className : 'form__input'}
                type={props.type ? props.type : 'text'}
                placeholder={props.placeholder ? props.placeholder : ' '}
                required
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
            />
            <span className="required field-title">{props.title}</span>
            <span className={`error-message ${props.isErrorOccurred ? 'active' : ''}`}>
                {props.errorMessage}
            </span>
        </label>
    );
};
