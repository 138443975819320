import React from 'react';

import '../SolutionChoosing/Solutions.scss'
import Slider from 'react-slick';
import LocalizedLink from './LocalizedLink';
import {SliderPrevArrow} from "./SliderPrevArrow";
import {SliderNextArrow} from "./SliderNextArrow";

export const OtherSolutions = props => {
    const settings = {
        className: 'other-solutions__industry-solutions-slider',
        infinite: true,
        slidesToShow: props.solutionsCategory === 'industry' ? 4 : 3,
        slidesToScroll: 2,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        prevArrow: <SliderPrevArrow />,
        nextArrow: <SliderNextArrow />,
        responsive: [
            {
                breakpoint: 1230,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
            {
                breakpoint: 790,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
            {
                breakpoint: 510,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    dotsClass: 'main-sl-dots'
                }
            },
        ]
    };

    return (
        <section className="other-solutions">
            <h3 className="other-solutions__title">{props.title}</h3>

            <div className="other-solutions__slider-wrap">
                <Slider {...settings}>
                    {
                        (props.solutionsCategory === 'industry')
                            ? (
                                props.solutions.map((solution, index) => (
                                    <LocalizedLink
                                        key={index}
                                        to={`/solution/industry/${solution.type}`}
                                        className="sc-solution-item"
                                    >
                                        <img
                                            className="sc-solution-item__bg"
                                            src={solution.image}
                                            alt={solution.name}
                                        />
                                        <h4 className="sc-solution-item__title">{solution.name}</h4>
                                    </LocalizedLink>
                                ))
                            )
                            : (
                                props.solutions.map((solution, index) => (
                                    <LocalizedLink
                                        key={index}
                                        to={`/solution/transport/${solution.type}`}
                                        className="sc-transport-item"
                                    >
                                        <div className="sc-transport-slide__img-section">
                                            <div className="sc-transport-item__img-wrap sc-transport-item__img-wrap--standard">
                                                <img
                                                    className="sc-transport-item__img"
                                                    src={solution.image}
                                                    alt={solution.name}
                                                />
                                            </div>

                                            <div
                                                className="sc-transport-item__img-wrap sc-transport-item__img-wrap--standard-alt">
                                                <img
                                                    className="sc-transport-item__img"
                                                    src={solution.image}
                                                    alt={solution.name}
                                                />
                                            </div>
                                        </div>
                                        <h4 className="sc-transport-item__title">{solution.name}</h4>
                                    </LocalizedLink>
                                ))
                            )
                    }

                </Slider>
            </div>
        </section>
    );
};
