import React, {useEffect, useState} from 'react';

import './GpsMonitoring.scss';
import {connect} from 'react-redux';
import {_image} from '../../API/apiConsts';
import {solutionTypes} from '../../utils/parsers/solutionTypes';
import {MetaTagsComponent} from '../globalComponents/MetaTagsComponent';
import {PreLoader} from '../globalComponents/PreLoader';

import {GpsMonitoringHeader} from "./GpsMonitoringHeader";
import {GpsMonitoringBody} from "./GpsMonitoringBody";
import {Recommendations} from "../globalComponents/Recommendations";
import {OtherServices} from "../globalComponents/OtherServices";
import {getStaticService} from "../../API/requests";

const GpsMonitoringContainer = props => {
    const [service, setService] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setStaticService(props.language.key);
    }, [props.language]);

    const setStaticService = (language) => {
        setIsLoading(true);
        getStaticService(1).then((service) => {
            parseService(service, language);
        }).then(() => {setIsLoading(false)});
    };

    const parseService = (service, language) => {
        const parsedService = {
            ...service,
            data: {
                ...service.data,
                title: service.data[`title_${language}`] || service.data[`title_uk`],
                short_description: service.data[`short_description_${language}`] || service.data['short_description_uk'],
                subtitle: service.data[`subtitle_${language}`] || service.data['subtitle_uk'],
                opportunities: service.data.opportunities ? service.data.opportunities.map(opportunity => ({
                    ...opportunity,
                    description: opportunity.description[language],
                    title: opportunity.title[language],
                    image: `${_image}/${opportunity.image}`,
                    solution_for: opportunity.solution_for ? opportunity.solution_for.map(solution => solutionTypes[solution]) : [],
                })) : [],
                products: service.data.products ? service.data.products.map(product => ({
                    ...product,
                    name: product.name[language],
                    category: {
                        ...product.category,
                        name: product.category.name[language],
                    },
                    short_description: product.short_description[language],
                    images: product.images ? product.images.map(image => `${_image}/${image}`) : [],
                    specifications: product.specifications.reduce((acc, specification) => ({
                        ...acc,
                        [specification.name]: specification.value,
                    }), {})
                })) : [],
            },
        };

        setService(parsedService);
    };

    return (
        <main className="services">
            <MetaTagsComponent
                title={service.data?.title}
                seoTitle={service.data?.title}
                seoDescription={service.data?.short_description}
            />

            {
                (isLoading)
                    ? (
                        <PreLoader />
                    )
                    : (
                        (service.data) && (
                            <>
                                <GpsMonitoringHeader service={service} />

                                <div className="container">
                                    <GpsMonitoringBody service={service} />

                                    <OtherServices />

                                    <Recommendations recommendedProducts={service.data.products}/>
                                </div>
                            </>
                        )
                    )
            }
        </main>
    );
};

const mapStateToProps = store => {
    return {
        language: store.globalReducer.language,
    };
};

export const GpsMonitoring = connect(mapStateToProps)(GpsMonitoringContainer);
