export const getDiscountCatalogInformation = () => {
    return window.axios.get('', {
        params: {
            query: `
                query {
                    discountProducts {
                        data {
                            id
                            name
                            slug
                            images
                            short_description
                            discount
                            discount_percent
                            final_price
                            price
                            advantages
                            bonus_points
                            specifications
                            category {
                                name
                                slug
                            }
                        }
                        last_page
                    }
                    discountProductsFilters
                }
            `
        }
    }).then(response => response.data.data)
        .catch(error => console.log(error));
};
