import React from 'react';

export const CasesArticleHeader = props => {
    return (
        <section className="cases-article-header">
            <div className="cases-article-header__bg">
                <img
                    src={props.image}
                    alt={props.title}
                />
            </div>
            <div className="cases-article-wrap">
                <h1 className="cases-article-header__title">
                    <p className="cases-article-header__title-head">{`${props.caseTitleTranslation}:`}</p>
                    <p className="cases-article-header__title-text">{props.title}</p>
                </h1>
            </div>
        </section>
    );
};
