import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {createLead} from '../../../API/birixApi/createLead';
import {sendSupportRequest} from '../../../API/requests/sendSupportRequest';

import {toggleApplicationSuccess, toggleModal} from '../../../redux/actionCreators/modalActions';
import {Input} from "../../globalComponents/Input/Input";

const SupportFormContainer = props => {
    const [t] = useTranslation(['support', 'common']);
    const form = t('common:form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;

    const formFields = [
        {
            name: 'name',
            title: form.name,
            type: 'text',
        },
        {
            name: 'phone',
            title: form.phone,
            type: 'tel',
        },
        {
            name: 'email',
            title: form.email,
            type: 'email',
        },
    ];

    const [supportFormValues, setSupportFormValues] = useState({
        name: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        phone: {
            value: '+380',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        email: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        comment: '',
    });

    const handleChange = event => {
        const {name, value} = event.target;

        if(name === 'comment') {
            setSupportFormValues(prevState => ({
                ...prevState,
                comment: value,
            }))
        } else {
            setSupportFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value,
                    error: false,
                }
            }));
        }
    };

    const handleSubmit = event => {
        event.preventDefault();

        let isFormValid = true;

        Object.keys(supportFormValues).forEach(field => {
            if(!validateSupportField(field, supportFormValues[field].value)) {
                isFormValid = false;
            }
        });

        const {
            name,
            phone,
            email,
            comment,
        } = supportFormValues;


        if(isFormValid) {
            const userInfo = {
                name: name.value,
                phone: phone.value,
                email: email.value,
                comment,
            }

            createLead(userInfo, 'support')
                .then(response => sendSupportRequest(userInfo, response))
            openApplicationSuccessModal();
        }
    };

    const validateSupportField = (name, value) => {
        if(name !== 'comment') {
            setSupportFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    wasTouched: true,
                },
            }));

            if (!value.trim()) {
                setSupportFormValues(prevState => ({
                    ...prevState,
                    [name]: {
                        ...prevState[name],
                        error: true,
                    },
                }));

                return false;
            }

        }

        if(name === 'phone') {
            const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

            if(!regEx.test(value)) {
                setSupportFormValues(prevState => ({
                    ...prevState,
                    phone: {
                        ...prevState.phone,
                        error: true,
                        errorMessage: form.notValidFormat,
                    },
                }));

                return false;
            }
        }

        return true;
    };

    const openApplicationSuccessModal = () => {
        props.toggleModal(true);
        props.toggleApplicationSuccess(true);
    };

    return (
        <div className="support__request-form-wrapper">
            <form
                className="support__request-form form"
                id="support-request"
                onSubmit={handleSubmit}
            >
                <h2 className="support__section-title">{t('supportFormTitle')}</h2>

                <div className="support__form-wrap support__form-wrap--left">
                    {
                        formFields.map(field => (
                            <Input
                                key={field.name}
                                className={`form__input ${supportFormValues[field.name].error ? 'invalid' : ''} ${!supportFormValues[field.name].error && supportFormValues[field.name].wasTouched ? 'valid' : ''}`}
                                type={field.type}
                                name={field.name}
                                placeholder=" "
                                required
                                value={supportFormValues[field.name].value}
                                handleChange={event => {
                                    handleChange(event);
                                    validateSupportField(event.target.name, event.target.value);
                                }}
                                handleBlur={event => validateSupportField(event.target.name, event.target.value)}
                                title={field.title}
                                isErrorOccurred={supportFormValues[field.name].error}
                                errorMessage={supportFormValues[field.name].errorMessage}
                            />
                        ))
                    }
                </div>

                <div className="support__form-wrap support__form-wrap--right">
                    <label className="textarea-wrap">
                        <textarea
                            className="form__input form__textarea"
                            name="comment"
                            value={supportFormValues.comment}
                            cols="30"
                            rows="4"
                            onChange={handleChange}
                        />
                        <span>{form.comment}</span>
                    </label>

                    {/*<div className="file-upload">*/}
                    {/*    <img alt="folder icon" className="file-upload-ico" src={folderIco} />*/}

                    {/*    <span className="file-upload-text">*/}
                    {/*    {*/}
                    {/*        supportFormValues.file.name*/}
                    {/*            ? (*/}
                    {/*                supportFormValues.file.name*/}
                    {/*            )*/}
                    {/*            : (*/}
                    {/*                form.addFile*/}
                    {/*            )*/}
                    {/*    }*/}
                    {/*</span>*/}

                    {/*    <label className="file-upload-label" htmlFor="file-input">*/}
                    {/*        <input*/}
                    {/*            type="file"*/}
                    {/*            name="file"*/}
                    {/*            id="file-input"*/}
                    {/*            onChange={handleChange}*/}
                    {/*        />*/}
                    {/*        <button className="file-upload-btn btn-ghost-secondary">{form.file}</button>*/}
                    {/*    </label>*/}
                    {/*</div>*/}
                </div>
                <button className="support__submit-btn btn-primary" type="submit">{form.sendButton}</button>
            </form>
        </div>

    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleApplicationSuccess,
};

export const SupportForm = connect(null, mapDispatchToProps)(SupportFormContainer);
