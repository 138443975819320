import React, {useState} from 'react';

import './Footer.scss';
import {useTranslation} from 'react-i18next';

import footerLogo from '../../../images/new-logo-footer.svg';
import LocalizedLink from '../LocalizedLink';
import {FooterEquipment} from "./FooterEquimpent";
import {FooterServices} from "./FooterServices";
import {FooterSolutions} from "./FooterSolutions";
import {FooterClients} from "./FooterClients";
import {FooterContact} from "./FooterContact";

export const Footer = () => {
    const [t] = useTranslation('footer');
    const [isMenusActive, setIsMenusActive] = useState({
        equipment: false,
        services: false,
        solutions: false,
        clients: false,
    });

    const toggleMenu = menuName => {
        setIsMenusActive(prevState => ({
            ...prevState,
            [menuName]: !prevState[menuName],
        }));
    };

    return (
        <footer className="footer">
            <div className="container-header">
                <button className="page-up-btn">
                    <div onClick={() => window.scroll({top: 0, left: 0, behavior: "smooth"})}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect
                                width="40"
                                height="40"
                                rx="8"
                                fill="#EBF4F8"
                            />

                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M20 17.615L12.065 25.55C11.795 25.835 11.42 26 11 26C10.175 26 9.5 25.325 9.5 24.5C9.5 24.08 9.665 23.705 9.935 23.435L18.935 14.435C19.205 14.165 19.58 14 20 14C20.42 14 20.795 14.165 21.065 14.435L30.065 23.435C30.335 23.705 30.5 24.08 30.5 24.5C30.5 25.325 29.825 26 29 26C28.58 26 28.205 25.835 27.935 25.565L20 17.615Z"
                                fill="#273043"
                            />
                        </svg>
                    </div>
                </button>

                <div className="footer__top-section">
                    <div className="footer__section footer__section--logo">
                        <div className="footer__section-logo">
                            <img
                                className="footer__logo"
                                alt="Total Control"
                                src={footerLogo}
                            />
                        </div>
                    </div>

                    <FooterEquipment
                        toggleMenu={() => toggleMenu('equipment')}
                        isActive={isMenusActive.equipment}
                        translation={t('footerEquipment', {returnObjects: true})}
                    />

                    <FooterServices
                        toggleMenu={() => toggleMenu('services')}
                        isActive={isMenusActive.services}
                        translation={t('footerServices', {returnObjects: true})}
                    />

                    <FooterSolutions
                        toggleMenu={() => toggleMenu('solutions')}
                        isActive={isMenusActive.solutions}
                        translation={t('footerSolutions', {returnObjects: true})}
                    />

                    <FooterClients
                        toggleMenu={() => toggleMenu('clients')}
                        isActive={isMenusActive.clients}
                        translation={t('footerClients', {returnObjects: true})}
                    />

                    <FooterContact
                        buttonTextTranslation={t('backCallButtonText')}
                    />
                </div>

                <div className="footer__bottom-section">
                    <span className="footer__rights">
                        {`2010-${(new Date().getFullYear())} © ${t('rightsText')}`}
                    </span>
                    <a
                        href="/documents/cooperationDelivery.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="footer__agreements"
                    >
                        {t('agreementsText')}
                    </a>

                    <LocalizedLink
                        to="/sitemap"
                        className="footer__agreements"
                    >
                        {t('siteMap')}
                    </LocalizedLink>

                    <span className="footer__developer">
                        <a
                            href="https://sysale.ua/development/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('developerText')}
                        </a>

                        <a
                            href="https://sysale.ua/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            &nbsp;SySale
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    );
};
