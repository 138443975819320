import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import Slider from 'react-slick';

import avgust from '../../images/about-us/avgust.png';
import anserglob from '../../images/about-us/anserglob.png';
import uniqa from '../../images/about-us/uniqa.png'
import ascaniaFlora from '../../images/about-us/ascania-flora.png';
import osnova from '../../images/about-us/osnova.png';
import lvivOblEnergo from '../../images/about-us/lviv-obl-energo.png';
import deere from '../../images/about-us/deere.png';
import belUkraine from '../../images/about-us/bel-ukraine.svg';
import kernel from '../../images/about-us/kernel.svg';
import carlsbergUkraine from '../../images/about-us/carlsberg-ukraine.png';
import tmmExpress from '../../images/about-us/tmm-express.png';
import tripoliLand from '../../images/about-us/tripoli-logo.png';
import biscuitUkraine from '../../images/about-us/biscuit.png';

const clients = [
    {
        name: 'Ansertglob',
        image: anserglob,
    },
    {
        name: 'Tripoli Land',
        image: tripoliLand,
    },
    {
        name: 'Biscuit',
        image: biscuitUkraine,
    },
    {
        name: 'TMM express',
        image: tmmExpress,
    },
    {
        name: 'Carlsberg Ukraine',
        image: carlsbergUkraine,
    },
    {
        name: 'UNIQA Insurance Group AG',
        image: uniqa,
    },
    {
        name: 'Avgust Crop Protection',
        image: avgust,
    },
    {
        name: 'Ascania-Flora',
        image: ascaniaFlora,
    },
    {
        name: 'Osnova',
        image: osnova,
    },
    {
        name: 'LOE',
        image: lvivOblEnergo,
    },
    {
        name: 'Deere',
        image: deere,
    },
    {
        name: 'Bel Ukraine',
        image: belUkraine,
    },
    {
        name: 'Kernel',
        image: kernel,
    },
];

export const AboutCompanyClients = props => {
    const [t] = useTranslation('aboutUs');
    const settings = {
        className: 'about-us-clients__slider',
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 2,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: false,
        dots: false,
        waitForAnimate: true,
        dotsClass: 'main-sl-dots',
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1445,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1185,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 748,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 540,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 395,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    const sliderRef = useRef();

    return (
        <>
            <section className="about-us-clients">
                <div className="about-us-clients__header">
                    <h3 className="about-us-clients__title">{t('clientsTitle')}</h3>
                    <div className="about-us-clients__arrows">
                        <div className="about-us-clients__arrow arrow-left slick-arrow" onClick={() => sliderRef.current.slickPrev()}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M11.9131 14L18.8562 20.9431C19.1056 21.1794 19.25 21.5075 19.25 21.875C19.25 22.5969 18.6594 23.1875 17.9375 23.1875C17.57 23.1875 17.2419 23.0431 17.0056 22.8069L9.13062 14.9319C8.89437 14.6956 8.75 14.3675 8.75 14C8.75 13.6325 8.89437 13.3044 9.13062 13.0681L17.0056 5.19313C17.2419 4.95688 17.57 4.8125 17.9375 4.8125C18.6594 4.8125 19.25 5.40312 19.25 6.125C19.25 6.4925 19.1056 6.82063 18.8694 7.05688L11.9131 14Z"
                                      fill="#2C599D" />
                            </svg>
                        </div>
                        <div className="about-us-clients__arrow arrow-right slick-arrow" onClick={() => sliderRef.current.slickNext()}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M16.0869 14L9.14375 20.9431C8.89438 21.1794 8.75 21.5075 8.75 21.875C8.75 22.5969 9.34062 23.1875 10.0625 23.1875C10.43 23.1875 10.7581 23.0431 10.9944 22.8069L18.8694 14.9319C19.1056 14.6956 19.25 14.3675 19.25 14C19.25 13.6325 19.1056 13.3044 18.8694 13.0681L10.9944 5.19313C10.7581 4.95688 10.43 4.8125 10.0625 4.8125C9.34062 4.8125 8.75 5.40312 8.75 6.125C8.75 6.4925 8.89437 6.82063 9.13062 7.05688L16.0869 14Z"
                                      fill="#2C599D" />
                            </svg>
                        </div>
                    </div>
                </div>

                <Slider ref={sliderRef} {...settings}>
                    {
                        clients.map((client, index) => (
                            <div key={index} className="about-us-clients__slider-item">
                                <img src={client.image} alt={client.name} />
                            </div>
                        ))
                    }
                </Slider>
            </section>
        </>
    );
};
