export const getFilteredProducts = async (
    category,
    filters,
    sort,
    brand,
    page = 1,
    perPage = 12,
) => {
    let functionName;
    let variableQuery;
    let parameterQuery;
    let filterVariable = {};

    if(brand) {
        functionName = 'brandProducts';
        variableQuery = '$brand: String! $filters: BrandCatalogFiltersInput';
        parameterQuery = 'brand: $brand';
        filterVariable = {
            categories: filters.categories,
            prices: filters.prices,
        }
    } else {
        if(category === 'discount-products') {
            functionName = 'discountProducts';
            variableQuery = '$filters: FiltersInput';
            parameterQuery = '';
            filterVariable = {
                brands: filters.brands,
                prices: filters.prices,
                specifications: filters.specifications,
            }
        } else {
            functionName = 'products';
            variableQuery = '$category: String! $filters: FiltersInput';
            parameterQuery = 'category: $category';
            filterVariable = {
                brands: filters.brands,
                prices: filters.prices,
                specifications: filters.specifications,
            }
        }
    }

    return window.axios.get('', {
        params: {
            query: `
                query (
                    ${variableQuery}
                    $page: Int!
                    $sort: SortInput!
                    $perPage: Int!
                ) {
                    ${functionName}(
                        ${parameterQuery}
                        filters: $filters
                        sort: $sort
                        pagination: {
                            page: $page
                            per_page: $perPage
                        }
                    ) {
                        data {
                            id
                            name
                            slug
                            images
                            short_description
                            discount
                            discount_percent
                            bonus_points
                            final_price
                            price
                            specifications
                            advantages
                            category {
                                name
                                slug
                            }
                        }
                        total
                        last_page
                    }
                }
            `,
            variables: {
                category,
                sort,
                brand,
                page,
                perPage,
                filters: filterVariable,
            },
        },
    }).then(response => response.data.data.products || response.data.data.discountProducts || response.data.data.brandProducts)
        .catch(error => console.log(error));
};
