import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import useLocalizedPath from '../../../../customHooks/useLocalizedPath';
import LoginMenu from "./LoginMenu";

import menuBonus from "../../../../images/header/menu-bonus.svg";
import menuUser from "../../../../images/header/menu-user.svg";
import menuCompare from "../../../../images/header/menu-compare.svg";
import menuCart from "../../../../images/header/menu-cart.svg";
import menuSettings from "../../../../images/header/menu-settings.svg";
import {connect} from "react-redux";
import {
    toggleEmptyBasket,
    toggleEmptyCompare,
    toggleEntry,
    toggleModal
} from "../../../../redux/actionCreators/modalActions";

const timeToClose = 400;

export const HeaderBottomMenuContainer = props => {
    const [t] = useTranslation('header');
    const localizedCartPath = useLocalizedPath('cart');
    const localizedComparePath = useLocalizedPath('compare');
    const localizedUserPath = useLocalizedPath('user');
    const routerHistory = useHistory();
    let loginMenuTimer = null;
    const [isLoginMenuActive, setIsLoginMenuActive] = useState(false);

    const toggleLoginMenu = () => {
        setIsLoginMenuActive(prevState => !prevState)
    };

    const closeLoginMenuWithInterval = () => {
        loginMenuTimer = setTimeout(() => (
            setIsLoginMenuActive(false)
        ), timeToClose);
    };


    const cancelClosingLoginMenu = () => {
        clearTimeout(loginMenuTimer);
    };

    const openCart = () => {
        props.basketItems.length
            ? routerHistory.push(localizedCartPath)
            : openEmptyBasketModal()
    };

    const openCompare = () => {
        props.productsCompare.length
            ? routerHistory.push(localizedComparePath)
            : openEmptyCompareModal()
    };

    const openUserAccount = () => {
        props.isLogged
            ? routerHistory.push(localizedUserPath)
            : openEntryModal()
    };

    const openEmptyBasketModal = () => {
        props.toggleModal(true);
        props.toggleEmptyBasket(true);
    };

    const openEmptyCompareModal = () => {
        props.toggleModal(true);
        props.toggleEmptyCompare(true);
    };

    const openEntryModal = () => {
        props.toggleModal(true);
        props.toggleEntry(true);
    };

    return (
        <div className="header-bottom__menu-section">
            <div
                className="header-bottom__menu-item header-bottom__menu-item--dropdown"
                onMouseLeave={closeLoginMenuWithInterval}
                onMouseEnter={cancelClosingLoginMenu}
                onClick={toggleLoginMenu}
            >
                <img
                    alt="settings"
                    src={menuSettings}
                />

                <span className="header-bottom__mobile-text">{t('headerBottomMenu.loginMenuTitle')}</span>

                <svg className={`arrow-down ${isLoginMenuActive ? 'active' : ''}`} width="12" height="8" viewBox="0 0 12 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 1.5L6 6.5L11 1.5"
                        stroke="#2C599D"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>

                <LoginMenu
                    translations={t('headerBottomMenu.loginMenu', {returnObjects: true})}
                    toggleLoginMenu={toggleLoginMenu}
                    isActive={isLoginMenuActive}
                    cancelClosing={cancelClosingLoginMenu}
                />
            </div>

            {/*<div className="header-bottom__menu-item header-bottom__menu-item--bonus bonus-active">*/}
            {/*    <Link to={`/`} onClick={props.closeSideMenu}>*/}
            {/*        <img*/}
            {/*            alt="menu bonus"*/}
            {/*            src={menuBonus}*/}
            {/*        />*/}
            {/*        <span className="header-bottom__mobile-text">{t('headerBottomMenu.bonusProgramTitle')}</span>*/}
            {/*    </Link>*/}
            {/*</div>*/}

            <div className="header-bottom__menu-item">
                <div onClick={() => {
                    openUserAccount();
                    props.closeSideMenu();
                }}>
                    <img
                        alt="menu user"
                        src={menuUser}
                    />
                    <span className="header-bottom__mobile-text">{t('headerBottomMenu.accountTitle')}</span>
                </div>
            </div>

            <div className="header-bottom__menu-item">
                <div onClick={openCompare}>
                    <img
                        alt="menu compare"
                        src={menuCompare}
                    />
                </div>
            </div>

            <div className="header-bottom__menu-item">
                <div onClick={openCart}>
                    <img
                        alt="menu cart"
                        src={menuCart}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {
        basketItems: store.basketState.basketItems,
        productsCompare: store.compareState.products,
        isLogged: store.userAccountState.isLogged,
        language: store.globalReducer.language,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleEmptyBasket,
    toggleEmptyCompare,
    toggleEntry,
};

export const HeaderBottomMenu = connect(mapStateToProps, mapDispatchToProps)(HeaderBottomMenuContainer);
