import React from 'react';
import _ from 'lodash';

import reportWebVitals from './reportWebVitals';

import App from './App';
import ReactDOM from 'react-dom';
import './i18n/i18n';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {ScrollToTop} from "./components/globalComponents/ScrollToTop"

import store from "./store/configureStore";
import axios from 'axios';
import {_api} from "./API/apiConsts";

let token = localStorage.getItem('token');
let language = JSON.parse(localStorage.getItem('language'));

document.documentElement.lang = language ? language.key : 'uk';

let axiosConfig = {
    baseURL: _api,
    headers: {
        "Locale": language ? language.key : 'uk',
    },
};

if (token) {
    axiosConfig.headers.Authorization = `Bearer ${token}`;
}

window.axios = axios.create(axiosConfig);

window._ = _;

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <Router>
              <ScrollToTop />
              <App />
          </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
