import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {createLead} from '../../API/birixApi/createLead';
import checkmark from "../../images/checkmark.svg";
import {Input} from '../globalComponents/Input/Input';
import {connect} from "react-redux";
import {toggleApplicationSuccess, toggleModal} from "../../redux/actionCreators/modalActions";

const AboutCompanyVacanciesFormContainer = props => {
    const [t] = useTranslation(['aboutUs', 'common']);
    const form = t('common:form', {returnObjects: true});
    const errorMessage = form.emptyFieldErrorMessage;
    const [agreement, setAgreement] = useState(false);

    const formFields = [
        {
            name: 'name',
            title: form.name,
            type: 'text',
        },
        {
            name: 'lastName',
            title: form.lastName,
            type: 'text',
        },
        {
            name: 'phone',
            title: form.phone,
            type: 'tel',
        },
        {
            name: 'email',
            title: form.email,
            type: 'email',
        }
    ];

    const [vacancyFormValues, setVacancyFormValues] = useState({
        name: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        lastName: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        phone: {
            value: '+380',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        email: {
            value: '',
            error: false,
            wasTouched: false,
            errorMessage,
        },
        file: {},
    });

    useEffect(() => {
        if(props.isLogged) {
            const formInfoToSet = {
                name: {
                    ...vacancyFormValues.name,
                    value: props.user.name,
                    wasTouched: true,
                },
                lastName: {
                    ...vacancyFormValues.lastName,
                    value: props.user.last_name,
                    wasTouched: true,
                },
                phone: {
                    ...vacancyFormValues.phone,
                    value: props.user.phone,
                    wasTouched: true,
                },
                email: {
                    ...vacancyFormValues.email,
                    value: props.user.email,
                    wasTouched: true,
                },
            }

            setVacancyFormValues(prevState => ({
                ...prevState,
                ...formInfoToSet,
            }));
        }

    }, [props.user, props.isLogged]);
    
    const handleChangeVacancyFormValues = event => {
        const {name, value} = event.target;

        if(name !== 'file') {
            setVacancyFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value,
                    error: false,
                },
            }));
        } else {
            setVacancyFormValues(prevState => ({
                ...prevState,
                file: event.target.files[0],
            }));
        }
    };

    const validateVacancyField = (name, value) => {
        if(name !== 'file') {
            setVacancyFormValues(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    wasTouched: true,
                },
            }));

            if(!value.trim()) {
                setVacancyFormValues(prevState => ({
                    ...prevState,
                    [name]: {
                        ...prevState[name],
                        error: true,
                    },
                }));

                return false;
            }
        }

        if(name === 'phone') {
            const regEx = new RegExp(/^\+?(380)\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/);

            if(!regEx.test(value)) {
                setVacancyFormValues(prevState => ({
                    ...prevState,
                    phone: {
                        ...prevState.phone,
                        error: true,
                        errorMessage: form.notValidFormat
                    }
                }));

                return false;
            }
        }

        return true;
    };

    const upload = async (vacancy, agreement) => {
        let  bodyFormData = new FormData();
        bodyFormData.set('operations', JSON.stringify({
            'query':
                `mutation (
                    $vacancy: Int!
                    $first_name: String!
                    $last_name: String!
                    $phone: String!
                    $email: String!
                    $agreement: Boolean!
                    $file: Upload!
                ) {
                    mailToSydorenkoWithVacancy(
                        vacancy: $vacancy
                        first_name: $first_name
                        last_name: $last_name
                        phone: $phone
                        email: $email
                        agreement: $agreement
                        vc: $file
                    )
                }`,
            'variables': {
                vacancy: parseInt(vacancy),
                agreement,
                first_name: vacancyFormValues.name.value,
                last_name: vacancyFormValues.lastName.value,
                phone: vacancyFormValues.phone.value,
                email: vacancyFormValues.email.value,
                file: vacancyFormValues.file
            }
        }));
        bodyFormData.set('operationName', null);
        bodyFormData.set('map', JSON.stringify({"file":["variables.file"]}));
        bodyFormData.append('file', vacancyFormValues.file);

        return  await window.axios.post('', bodyFormData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });
    };

    const handleVacancyRequestSubmit = event => {
        event.preventDefault();

        let isFormValid = true;
        Object.keys(vacancyFormValues).forEach(field => {
            if(!validateVacancyField(field, vacancyFormValues[field].value)) {
                isFormValid = false;
            }
        });

        if(!agreement) {
            isFormValid = false;
        }

        if(isFormValid) {
            const userInfo = {
                name: `${vacancyFormValues.name.value} ${vacancyFormValues.lastName.value}`,
                phone: vacancyFormValues.phone.value,
                email: vacancyFormValues.email.value,
                file: vacancyFormValues.file,
            };
            createLead(userInfo, 'vacancy');
            upload(props.selectedVacancy, agreement).then(result => {
                props.toggleApplicationSuccess(true);
                props.toggleModal(true);
            });
        }
    };

    return (
        <form className="vacancies-request" onSubmit={handleVacancyRequestSubmit}>
            <h5 className="vacancies-request__title">{t('vacancies.vacancyRequestTitle')}</h5>
            <p className="vacancies-request__subtitle">{t('vacancies.vacancyRequestSubtitle')}</p>
            {
                formFields.map(field => (
                    <Input
                        key={field.name}
                        className={`form__input ${vacancyFormValues[field.name].error ? 'invalid' : ''} ${!vacancyFormValues[field.name].error && vacancyFormValues[field.name].wasTouched ? 'valid' : ''}`}
                        type={field.type}
                        name={field.name}
                        placeholder=" "
                        required
                        value={vacancyFormValues[field.name].value}
                        handleChange={event => {
                            handleChangeVacancyFormValues(event);
                            validateVacancyField(event.target.name, event.target.value);
                        }}
                        handleBlur={event => validateVacancyField(event.target.name, event.target.value)}
                        title={field.title}
                        isErrorOccurred={vacancyFormValues[field.name].error}
                        errorMessage={vacancyFormValues[field.name].errorMessage}
                    />
                ))
            }
            {
                vacancyFormValues.file.name
                    ? (
                        <div className="vacancies-request__download-body">
                            <a className="vacancies-request__file-link" href="/">
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21.3542 6.25H16.1458C15.8583 6.25 15.625 6.01563 15.625 5.72915V0.52085C15.625 0.23335 15.3917 0 15.1042 0H4.6875C3.82603 0 3.125 0.701025 3.125 1.5625V23.4375C3.125 24.299 3.82603 25 4.6875 25H20.3125C21.174 25 21.875 24.299 21.875 23.4375V6.77085C21.875 6.48335 21.6417 6.25 21.3542 6.25ZM20.8333 23.4375C20.8333 23.724 20.6 23.9583 20.3125 23.9583H4.6875C4.4 23.9583 4.16665 23.724 4.16665 23.4375V1.5625C4.16665 1.27603 4.4 1.04165 4.6875 1.04165H14.5833V5.72915C14.5833 6.59062 15.2844 7.29165 16.1458 7.29165H20.8333V23.4375V23.4375Z"
                                        fill="#2C599D" />
                                    <path
                                        d="M21.7228 6.40234L15.4728 0.152344C15.2697 -0.0507812 14.9395 -0.0507812 14.7363 0.152344C14.5332 0.355469 14.5332 0.685693 14.7363 0.888818L20.9863 7.13882C21.0884 7.23984 21.2217 7.29092 21.3551 7.29092C21.4884 7.29092 21.6217 7.23989 21.7228 7.13882C21.9259 6.93569 21.9259 6.60547 21.7228 6.40234Z"
                                        fill="#2C599D" />
                                </svg>
                                <span>{vacancyFormValues.file.name}</span>
                            </a>
                            <label className="vacancies-request__download-btn">
                                <input
                                    type="file"
                                    name="file"
                                    onChange={handleChangeVacancyFormValues}
                                    accept="application/pdf"
                                />
                                {t('vacancies.form.replaceFile')}
                            </label>
                        </div>
                    )
                    : (
                        <div className="vacancies-request__download-body empty">
                            <label className="vacancies-request__download-btn">
                                <input
                                    type="file"
                                    name="file"
                                    accept="application/pdf"
                                    onChange={handleChangeVacancyFormValues}
                                />
                                {t('vacancies.form.addFile')}
                            </label>
                        </div>
                    )
            }
            <label className="checkbox-wrap">
                <input
                    type="checkbox"
                    checked={agreement}
                    onChange={() => {
                        setAgreement(prevState => !prevState)
                    }}
                />
                <span className="checkbox" >
                    <img src={checkmark} alt="checkmark" />
                    <span>{t('vacancies.form.informationAgreement')}</span>
                </span>
            </label>

            <button className="vacancies-request__confirm-btn btn-accent" type="submit">{t('vacancies.form.sendRequest')}</button>
        </form>
    );
};

const mapStateToProps = store => {
    return {
        isLogged: store.userAccountState.isLogged,
        user: store.userAccountState.user,
    };
};

const mapDispatchToProps = {
    toggleModal,
    toggleApplicationSuccess,
};

export const AboutCompanyVacanciesForm = connect(mapStateToProps, mapDispatchToProps)(AboutCompanyVacanciesFormContainer);
