import React from 'react';

import phoneIcon from '../../../images/footer/phone-ico.svg';
// import vodaphoneIcon from '../../../images/footer/vodaphone.svg';
// import kyivStarIcon from '../../../images/footer/kyivstar.svg';
// import lifecellIcon from '../../../images/footer/lifecell.svg';
import mastercard from '../../../images/footer/mastercard.png'
import visa from '../../../images/footer/visa.png'

import {connect} from 'react-redux';
import {toggleCallback, toggleModal} from '../../../redux/actionCreators/modalActions';
import {useTranslation} from 'react-i18next';

export const FooterContactContainer = props => {
    const [t] = useTranslation('common');

    const openModals = () => {
        props.toggleModal(true);
        props.toggleCallback(true);
    };

    return (
        <nav className="footer__section footer__section--contact">
            <ul className="footer__cellphone-section">
                <li className="footer__cellphone-item">
                    <img
                        alt="phone icon"
                        src={phoneIcon}
                    />
                    <a href="tel:+380800339651">0(800)33 96 51</a>
                </li>

                {/*<li className="footer__cellphone-item">*/}
                {/*    <img*/}
                {/*        alt="vodaphone"*/}
                {/*        src={vodaphoneIcon}*/}
                {/*    />*/}
                {/*    <a href="tel:+380993339651">(099)333 96 51</a>*/}
                {/*</li>*/}

                {/*<li className="footer__cellphone-item">*/}
                {/*    <img*/}
                {/*        alt="kyivstar"*/}
                {/*        src={kyivStarIcon}*/}
                {/*    />*/}
                {/*    <a href="tel:+380673339651">(067)333 96 51</a>*/}
                {/*</li>*/}

                {/*<li className="footer__cellphone-item">*/}
                {/*    <img*/}
                {/*        alt="lifecell"*/}
                {/*        src={lifecellIcon}*/}
                {/*    />*/}
                {/*    <a href="tel:+380933339651">(093)333 96 51</a>*/}
                {/*</li>*/}

                <button className="footer__cellback-button product-btn"
                        onClick={openModals}>{props.buttonTextTranslation}</button>
            </ul>

            <div className="footer__boxes">
                <div className="footer__social-box">
                    <a
                        className="footer__facebook-link"
                        href="https://www.facebook.com/SControl.ua/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="34" height="34" rx="8" fill="white"/>

                            <path
                                d="M18.1351 28.3336V17.9942H21.6056L22.1252 13.9648H18.1351V11.3921C18.1351 10.2255 18.4591 9.43043 20.1321 9.43043L22.2659 9.42945V5.82559C21.8967 5.77667 20.6301 5.66699 19.1566 5.66699C16.0803 5.66699 13.9741 7.54472 13.9741 10.9933V13.9649H10.4946V17.9943H13.974V28.3337L18.1351 28.3336Z"
                                fill="#273043"
                            />
                        </svg>
                    </a>

                    <a
                        className="footer__facebook-link"
                        href="https://www.youtube.com/channel/UCI8jIw2Mvs0-kwj3R_fJFOQ"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect width="34" height="34" rx="8" fill="white"/>
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M25.3144 10.0157C26.2246 10.2607 26.9423 10.9784 27.1873 11.8886C27.6424 13.5515 27.6249 17.0173 27.6249 17.0173C27.6249 17.0173 27.6249 20.4656 27.1873 22.1285C26.9423 23.0387 26.2246 23.7563 25.3144 24.0014C23.6515 24.439 17 24.439 17 24.439C17 24.439 10.3659 24.439 8.68553 23.9839C7.77532 23.7388 7.05766 23.0212 6.8126 22.1109C6.375 20.4656 6.375 16.9998 6.375 16.9998C6.375 16.9998 6.375 13.5515 6.8126 11.8886C7.05766 10.9784 7.79283 10.2432 8.68553 9.99815C10.3484 9.56055 17 9.56055 17 9.56055C17 9.56055 23.6515 9.56055 25.3144 10.0157ZM20.4132 17.0001L14.8819 20.1858V13.8143L20.4132 17.0001Z"
                                fill="#273043"
                            />
                        </svg>
                    </a>
                </div>

                <div className="footer__logos-box">
                    <p className="footer__logos-title">{t('payments')}</p>

                    <img
                        className="footer__logos-box-image"
                        src={mastercard}
                        alt="mastercard"
                    />

                    <img
                        className="footer__logos-box-image"
                        src={visa}
                        alt="visa"
                    />
                </div>
            </div>
        </nav>
    );
};

const mapDispatchToProps = {
    toggleModal,
    toggleCallback,
};

export const FooterContact = connect(null, mapDispatchToProps)(FooterContactContainer);
